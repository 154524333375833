export const FETCH_USER = 'fetch_user';
export const FETCH_REGISTERED_USER = 'fetch_registered_user';
export const FETCH_SURVEYS = 'fetch_surveys';
export const SET_WALLET_PROVIDER = 'set_wallet_provider';
export const SET_WALLET_ACCOUNT = 'set_wallet_account';
export const SET_WALLET_SIGNER = 'set_wallet_signer';
export const SET_WALLET_NETWORK = 'set_wallet_network';
export const FETCH_CURRENT_ETHEREUM_ACCOUNT = 'fetch_current_ethereum_account';
export const SET_TOKEN_GATED_ACCESS_PRIORITIZED_ACCESS_TOKEN = 'set_token_gated_access_prioritized_access_token';
export const SET_REFRESH_WALLET_TOKENS = 'set_refresh_wallet_tokens';
export const SET_REFRESH_CONTRACT_MEMBERSHIPS = 'set_refresh_contract_memberships';
export const FETCH_ALERTS_COUNT = 'fetch_alerts_count';
