import { useState, useEffect } from 'react';
import axios from 'axios';

import { find, first, intersectionWith } from 'lodash';
import accessTokens from '../config/accessTokens';

const useTokenGatedAccess = (account) => {
    const [access, setAccess] = useState(null);                               // access should be a boolean
    const [walletAccessTokens, setWalletAccessTokens] = useState([]);         // walletAccessTokens should be an array of strings (contract addresses)
    const [prioritizedAccessToken, setPrioritizedAccessToken] = useState(''); // prioritizedAccessToken should be a string (contract address)
    const [openStoreTokenId, setOpenStoreTokenId] = useState('');             // openStoreTokenId should be a string (a hex string as tokenId)

    
    /* Determines value of access, walletAccessTokens
     *
     * This effect determines if the account has access according to their NFT tokens.
     */
    useEffect(() => {
        const fetchAccessStatus = async () => {
            const response = await axios.get(`/api/get/wallets/${account}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setAccess(response.data.access)
            setWalletAccessTokens(response.data.walletAccessTokens)
        }
        if (account) fetchAccessStatus() // avoid querying an account's tokens if there is no account present
    }, [account])

    // This contract is used for Floor Gen I
    // It's possible (likely?) that there are other OPENSTORE contracts or other places
    // that mint 721 or 1155 tokens in a similar way to this contract
    const OPENSTORE_CONTRACT = '0x495f947276749ce646f68ac8c248420045cb7b5e';

    /* Determines value of prioritizedAccessToken and openStoreTokenId
     *
     * This effect determines a token that will be used as prioritiy over others,
     * when a user has multiple access tokens (i.e., NFTs of multiple communities).
     *
     * The strategy is to identify access tokens in order declared by config/accessTokens.js
     * If a different priority strategy is desired (i.e., other than stack rank by order defined),
     * this useEffect hook needs to be changed.
     */
    useEffect(() => {
        // 1. compute the intersection because the order of walletAccessTokens is unknown or could change,
        // as determined upstream by the Alchemy NFT API response
        // 2. find the first contract address string in the intersection
        const intersectionOfAccessTokens = intersectionWith(accessTokens, walletAccessTokens, (accessToken, walletAccessToken) => {
            if (accessToken.contractAddress === OPENSTORE_CONTRACT) { // if this is the OpenStore contract ...
                // check that this is a Floor App Early Supporter Token
                return accessToken.contractAddress === walletAccessToken.contractAddress && accessToken.hexTokenId === walletAccessToken.hexTokenId;
            } else { // othwerwise, we can just rely on the contractAddress
                return accessToken.contractAddress === walletAccessToken.contractAddress;
            }
        })
        const prioritizedToken = first(intersectionOfAccessTokens);
        
        if (walletAccessTokens && prioritizedToken) {
            setPrioritizedAccessToken(prioritizedToken.contractAddress);
            setOpenStoreTokenId(prioritizedToken.hexTokenId);
        }
    }, [accessTokens, walletAccessTokens])

    /* Determines value of prioritizedAccessToken
     *
     * This effect ensures that regardless of previous effects, the FNFTM Genesis NFT is given priority.
     */
    useEffect(() => {
        // Ensure that FNFTM Token is always prioritized first
        // Assume that the config always specifices FNFTM Genesis first
        const futureNftMintsGenesisTokenContractAddres = accessTokens[0].contractAddress;
        // Check for FNFTM Genesis Token
        const genesisAccessToken = find(walletAccessTokens, { contractAddress: futureNftMintsGenesisTokenContractAddres });
        if (genesisAccessToken) setPrioritizedAccessToken(genesisAccessToken.contractAddress)
    }, [walletAccessTokens])
 
    return { access, prioritizedAccessToken, openStoreTokenId, walletAccessTokens }
}

export default useTokenGatedAccess
