import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import useTokenGatedAccess from '../../../hooks/useTokenGatedAccess';

// components
import PleaseConnectWallet from '../../access/PleaseConnectWallet';
import PleaseWait from '../../access/PleaseWait';
import PleaseRegister from '../../access/PleaseRegister';
import PleaseSignIn from '../../access/PleaseSignIn';
import AccessDenied from '../../access/AccessDenied';
import WeeklyLeaderboard from './WeeklyLeaderboard';

const LeaderboardMain = ({ auth, wallet, registeredUser }) => {    

    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, _walletAccessTokens } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars    

    const [leaderboardData, setLeaderboardData] = useState([]);   
    const [userLeaderboardData, setUserLeaderboardData] = useState('none');   
    const [publicUser, setPublicUser] = useState(false);     

    useEffect(() => {
        const fetchLeaderboardData = async () => {
            const response = await axios.get(`/api/get/leaderboard/weekly`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setLeaderboardData(response.data);            
        }
        fetchLeaderboardData()
    }, []) 
    
    useEffect(() => {
        const fetchUserLeaderboardData = async () => {
            const response = await axios.get(`/api/get/leaderboard/weekly/${registeredUser.username}`, {                
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setUserLeaderboardData(response.data);
        }
        if (registeredUser?.username) fetchUserLeaderboardData();        
    }, [registeredUser, auth])     
    
    //this avoids a flicker where the public info is shown just before the logged in
    //user's info is recognized which changes the info on the page to the appropriate details
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const delayPublic = async () => {
        await delay(1500);
        //console.log("Waited 5s");
        setPublicUser(true);
      };
    delayPublic();

    const renderLeaderboard = () => {
        if (!account && publicUser) {
            return ( <WeeklyLeaderboard leaderboardData={leaderboardData} userLeaderboardData="none" access="public" entries={registeredUser.current_giveaway_entries}  account={account} /> ); 
        }        
        switch(access) { 
            case null:
                return <PleaseWait />;
            case false:                
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;            
                if (registeredUser.account_type === 'top 100') {
                    return ( <WeeklyLeaderboard leaderboardData={leaderboardData} userLeaderboardData={userLeaderboardData} access="premium" entries={registeredUser.current_giveaway_entries}  account={account} /> );
                }
                else {
                    return ( <WeeklyLeaderboard leaderboardData={leaderboardData} userLeaderboardData={userLeaderboardData} access="free" entries={registeredUser.current_giveaway_entries}  account={account} /> );
                }
            case true:
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <PleaseRegister />;          
                if (registeredUser.username) {                    
                    return ( <WeeklyLeaderboard leaderboardData={leaderboardData} userLeaderboardData={userLeaderboardData} access="premium" entries={registeredUser.current_giveaway_entries}  account={account}/> ); 
                }                
                else { 
                    return (  <PleaseRegister />  );    
                }
            default:
                return <p style={{ color: "white" }}>Something went wrong. Do you <a href="/mint">have a token</a>?</p>;
        }
    }

    return (
        <div style={{ color: "rgba(255,255,255,0.8)"}}>                        
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderLeaderboard() }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser }) {
    return { auth, wallet, registeredUser };
}

export default connect(mapStateToProps)(LeaderboardMain);
