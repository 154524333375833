import { Link } from 'react-router-dom';

const Header = ({access, page}) => {

    const publicUser = () => {
        return (
            <>
            Create a FREE account by connecting your wallet and registering to earn points. The higher your Leaderboard ranking, the more Weekly Giveaway entries you earn. Plus, Top 100 users receive Premium access to Future Mints.
            </>
        );
    };
    
    const notPublicUser = () => {
        return (
            <>
                Top 100 users by Week, Month, or Lifetime automatically receive Premium access to Future Mints.
            </>
        );
    };

    const weeklyHighlight = page === 'weekly' ? '#7945E1' : '#140510';
    const weeklyStyleInputs = { color:"#ffffff", backgroundColor: weeklyHighlight, padding:"5px", textDecoration:"none" };    
    const weeklyText = page === 'notifications' ? <strong>Week</strong> : <span>Week</span>;

    const monthlyHighlight = page === 'monthly' ? '#7945E1' : '#140510';
    const monthlyStyleInputs = { color:"#ffffff", backgroundColor: monthlyHighlight, padding:"5px", textDecoration:"none" };    
    const monthlyText = page === 'watchlist' ? <strong>Month</strong> : <span>Month</span>; 
    
    const lifetimeHighlight = page === 'lifetime' ? '#7945E1' : '#140510';
    const lifetimeStyleInputs = { color:"#ffffff", backgroundColor: lifetimeHighlight, padding:"5px", textDecoration:"none" };    
    const lifetimeText = page === 'watchlist' ? <strong>Lifetime</strong> : <span>Lifetime</span>; 

    return (
        <>
            
                <div className="row rounded">
                    <div className="col-sm-3 col-xl-2 col-12 text-center" style={{ marginBottom:"10px"}}>
                        <img alt="" src="/images/trophy.png"  className="d-inline-block align-top img-fluid rounded" />  
                    </div>                
                    <div className="col-sm-9 col-xl-10 col-12 p-3">
                        <div style={{fontSize:"2em"}}>
                            <strong>Leaderboard</strong>                                                       
                        </div>                                                                                    
                        <div style={{fontSize:"1em", display:"inline"}}>
                            Earn points for participating in the Future Mints platform to enter <Link to="/giveaways" style={{color:"rgba(255,255,255,0.8"}}>Weekly Giveaways</Link>.
                            <br /><br />
                            {access == 'public' ? publicUser() : notPublicUser()}
                        </div>                            
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 rounded text-center" style={{fontSize:"1em"}}>
                        <Link to="/leaderboard/weekly" className="rounded" style={weeklyStyleInputs}>{weeklyText}</Link>&nbsp;|&nbsp;
                        <Link to="/leaderboard/monthly" className="rounded" style={monthlyStyleInputs}>{monthlyText}</Link>&nbsp;|&nbsp;
                        <Link to="/leaderboard/lifetime" className="rounded" style={lifetimeStyleInputs}>{lifetimeText}</Link>                        
                    </div>
                </div>                                            
                                        
        </>
    );
};

export default Header;
