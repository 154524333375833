import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import * as actions from '../../../actions';

import useTokenGatedAccess from '../../../hooks/useTokenGatedAccess';
import useWalletLinkStatus from '../../../hooks/useWalletLinkStatus';
import getTokenDetails from '../../../utils/getTokenDetails';

// components
import PleaseConnectWallet from '../../access/PleaseConnectWallet';
import PleaseWait from '../../access/PleaseWait';
import AccessDenied from '../../access/AccessDenied';
import PleaseSignIn from '../../access/PleaseSignIn';
import PleaseRegister from '../../access/PleaseRegister';
import SomethingWentWrong from '../../access/SomethingWentWrong';
import Header from './Header';
import Body from './Body';

import axios from 'axios';

const Main = ({ auth, wallet, registeredUser, fetchRegisteredUser }) => {
    
    const { account } = wallet; // use the account from the wallet store object    
    useEffect(() => {
        fetchRegisteredUser(account);
    }, [account]);
    

    // Pass the account to the useTokenGatedAccess hook to determine if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access } = useTokenGatedAccess(account);            
    
    const renderForm = () => {

        if (!account) return <PleaseConnectWallet />;

        switch(access) {
            case null:
                return <PleaseWait />;
            case false:
                
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <PleaseRegister />; // when the PleaseSignIn component renders, the user will be prompted to sign a message
                if (registeredUser.username && registeredUser.product_use === null) { 
                                    
                    return  <>
                        <Header username={registeredUser.username} />
                        <Body productUse={registeredUser.product_use} />
                        
                    </>;
                }
                else if (registeredUser.username ) {
                    return <Redirect to="/dashboard/home" />
                }

                else { return <PleaseRegister />  };
            case true:
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <PleaseRegister />; // when the PleaseSignIn component renders, the user will be prompted to sign a message
                if (registeredUser.username && registeredUser.product_use === null) { 
                               
                    return  <>
                        <Header username={registeredUser.username} />
                        <Body productUse={registeredUser.product_use} />
                        
                    </>;
                }
                else if (registeredUser.username ) {
                    return <Redirect to="/dashboard/home" />
                }
                else { return <PleaseRegister />  };
            default:
                return <SomethingWentWrong />;
        }
    }

    return (
        <div>
            {renderForm()}
        </div>
    );
}

function mapStateToProps({ auth, wallet, registeredUser, fetchRegisteredUser }) {
    return { auth, wallet, registeredUser, fetchRegisteredUser };
}

export default connect(mapStateToProps, actions)(Main);
