import { Link } from 'react-router-dom';

const RenderCollectionImage = ({ collection_image, collection_slug }) => {

    if ( collection_slug !== null ) {
        const collectionPageURL = '/collection/' + collection_slug;
        return <>
            <Link to={{ pathname: collectionPageURL}} style={{ color:"rgba(255,255,255,.8)"}}>
                <img
                    alt=""
                    src={collection_image}
                    height="30"
                    className="d-inline-block align-top rounded"
                    style={{margin:"0px"}}
                /> 
            </Link>
        </>;
    }
    else {
        return <>
            <img
                alt=""
                src={collection_image}
                height="30"                    
                className="d-inline-block align-top rounded"
                style={{margin:"0px"}}
            /> 
        </>;
    }
}

export default RenderCollectionImage;