import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import axios from 'axios';
import * as actions from '../../actions';

import useTokenGatedAccess from '../../hooks/useTokenGatedAccess';

// components
import PleaseConnectWallet from '../access/PleaseConnectWallet';
import PleaseWait from '../access/PleaseWait';
import PleaseRegister from '../access/PleaseRegister';
import NoAccessPublic from '../access/NoAccessPublic';
import PleaseSignIn from '../access/PleaseSignIn';
import AccessDenied from '../access/AccessDenied';
import Alerts from './Alerts';

const AlertsMain = ({ auth, wallet, registeredUser }) => {    

    const { account } = wallet; // use the account from the wallet store object
    
    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, _walletAccessTokens } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars      
    const [allAlerts, setAllAlerts] = useState([]);  
    const [publicUser, setPublicUser] = useState(false);      

    useEffect(() => {
        const fetchAlerts = async () => {
            const response = await axios.get('/api/get/alerts?sort=addedDateLatToEar', {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setAllAlerts(response.data);
        }
        fetchAlerts()
    }, [])

    //make sure that the user is taken to the top of the page each time it loads
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])   
    
    //this avoids a flicker where the public info is shown just before the logged in
    //user's info is recognized which changes the info on the page to the appropriate details
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const delayPublic = async () => {
        await delay(5000);
        //console.log("Waited 5s");
        setPublicUser(true);
      };
    delayPublic();

    const renderAlerts = () => {
        if (!account && publicUser) {
            return <Redirect to="/all-nfts" /> 
        }           
        switch(access) { 
            case null:                
                return <PleaseWait />;
            case false:                              
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;
                if (registeredUser.account_type === 'top 100') {                    
                    if ( allAlerts === '' ) { window.location.reload() }
                    axios.get(`/api/update-earliest-mint-date`); 
                    return ( <Alerts allAlerts={allAlerts} account_type={registeredUser.account_type}/> ); 
                }                    
                else if (registeredUser.username) {                    
                    if ( allAlerts === '' ) { window.location.reload() }
                    axios.get(`/api/update-earliest-mint-date`); 
                    return ( <Alerts allAlerts={allAlerts} account_type={registeredUser.account_type}/> ); 
                }
                return <p style={{ color: "white" }}>Something went wrong. Try disconnecting your wallet and refresh the page.</p>;
            case true:                
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;          
                if (registeredUser.username) {
                    if ( allAlerts === '' ) { window.location.reload() }
                    axios.get(`/api/update-earliest-mint-date`); 
                    return ( <Alerts allAlerts={allAlerts} account_type={registeredUser.account_type}/> ); 
                }                
                else { 
                    return (  <Redirect to="/register" />  );    
                }
            default:
                return <p style={{ color: "white" }}>Something went wrong. Do you <a href="/mint">have a token</a>?</p>;
        }
    }

    return (
        <div>                        
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderAlerts() }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser, alerts }) {
    return { auth, wallet, registeredUser, alerts };
}

export default connect(mapStateToProps, actions)(AlertsMain);
