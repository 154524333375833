import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';

import useTokenGatedAccess from '../../hooks/useTokenGatedAccess';
import useNFTData from '../../hooks/useNFTData';
import useMarketplaces from '../../hooks/useMarketplaces';

// components
import PleaseConnectWallet from '../access/PleaseConnectWallet';
import PleaseWait from '../access/PleaseWait';
import AccessDenied from '../access/AccessDenied';
import PleaseRegister from '../access/PleaseRegister';
import PleaseSignIn from '../access/PleaseSignIn';
import AddNFTForm from '../addNFT/AddNFTForm';

import axios from 'axios';

const prepareS3Image = async ( file ) => {

    //console.log(values);
    // get secure URL from our server
    const { url } = await fetch("/api/s3Url").then( res => res.json())
    //console.log(url)
    // post the image directly to s3 bucket
    
    //console.log(file)
    await fetch(url, {
        method: "PUT",
        headers: {
            "Content-Type": "multipart/form-data"
        },
        body: file
    })    

    return url.split('?')[0];
}

const deleteS3Image = async ( file ) => {

    const oldImageFile = String(file.split("/").pop());
    await axios.post('/api/s3Delete', {
        fileName: oldImageFile
      })
      .then(function (response) {
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });


}

const EditNFTCollection = ({ auth, wallet, registeredUser, form }) => {

    const { nft_collection_id } = useParams();
    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access } = useTokenGatedAccess(account);
    const { values } = useNFTData(nft_collection_id);   
    const { marketplaces } = useMarketplaces();  

    if (nft_collection_id === null ) return <Redirect to="/all-nfts" />;
    
    const renderForm = () => {
        if (!account) return <PleaseConnectWallet />;

        switch(access) {
            case null:
                return <PleaseWait />;
            case false:
                return <><div className="row">
                <div className="col-3"></div>
                <div className="col-6" style={{ fontSize: "2em"}}>
                    Edit Collection
                </div>
            </div><AccessDenied /></>;
            case true:
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <PleaseRegister />;
                // initialValues is a prop for redux-form https://redux-form.com/8.3.0/examples/initializefromstate/
                if (!registeredUser.username) return <PleaseRegister />;                           
                if (auth.account_type === 'user' || auth.account_type === 'guest' || nft_collection_id === null ) return <Redirect to="/all-nfts" />;
                const initialValues = values;                
                //TODO: add proper redirect on form submission
                return form.nftForm?.submitSucceeded ? <Redirect to="/admin/review" />
                    : <>
                    <AddNFTForm
                        //enableReinitialize={true}
                        initialValues={initialValues}
                        marketplaces={marketplaces}
                        onCollectionSubmit={
                            async (values) => {
                                try {
                                    // https://axios-http.com/docs/instance
                                    // axios#post(url[, data[, config]])
                                    const imageInput = document.querySelector('#imageInput')
                                    const file = imageInput.files[0]
                                    if ( file !== undefined ) {
                                        
                                        const currentImage = values?.collectionImage                                        
                                        
                                        
                                        const imageUrl = await prepareS3Image( file );
                                        
                                        if (!!currentImage) {
                                            const imageToReplace = String(currentImage.split("/").pop());
                                            //console.log('delete');
                                            await deleteS3Image( imageToReplace );
                                        }
                                        // replace image link in db
                                        values.collectionImage = imageUrl;
                                    }    
                                    
                                    await axios.post(`/api/update/nft/${nft_collection_id}`, values);   
                                    
                                    if ( values?.marketplace === 'Other' && values?.addMarketplace !== undefined) {
                                        await axios.put(`/api/put/marketplace/${values.addMarketplace}`);
                                    }
                                    
                                } catch (error) {
                                    // This should be visible in FS and (hopefully) alert FS of the issue
                                    console.error(error.response)
                                }
                            }
                        }
                    />
                </>;
            default:
                return <p style={{ color: "white" }}>Something went wrong. Do you <a href="/mint">have a token</a>?</p>;
        }
    }

    return (
        <div style={{ backgroundColor: "#000000", color: "#ffffff"}}>
            {renderForm()}
        </div>
    );
}

function mapStateToProps({ auth, wallet, registeredUser, form }) {
    return { auth, wallet, registeredUser, form };
}

export default connect(mapStateToProps, actions)(EditNFTCollection);
