module.exports = {
    /* TESTING - GOERLI */
    /*0x61B60950f1BE4746a1810F71f1E5E54A62fB4AA4*/
    /*
    "CONTRACT_ADDRESS": "0x3E95fD077e4D00883f9FfdFdD17a6C5DE7916E78",
    "NETWORK": {
      "NAME": "Goerli Testnet",
      "SYMBOL": "ETH",
      "ID": 5
    },
    "MINT_DESCRIPTION": "Allow List Mint is Oct 20th. Public Oct 21st.",
    "NFT_NAME": "Annual Pass - Season One",
    "SYMBOL": "FMAPS1",
    "ALLOW_LIST_ALLOCATION": 2,
    "PRESALE_LIST_ALLOCATION": 2,
    "PUBLIC_ALLOCATION": 5,
    "MAX_SUPPLY": 550,
    "WEI_COST": 50000000000000000,
    "GAS_LIMIT": 100000,
    "MARKETPLACE": "OPENSEA",
    "MARKETPLACE_LINK": "https://testnets.opensea.io/collection/future-nft-mints-genesis-nft",
    */
    /* MAINNET */
    
    "CONTRACT_ADDRESS": "0x61f5F79e0679aBFd6ddd124551d8cb2B5dF200C0",
    "NETWORK": {
      "NAME": "Mainnet",
      "SYMBOL": "ETH",
      "ID": 1
    },
    "MINT_DESCRIPTION": "Access through Dec 31, 2024",
    "NFT_NAME": "Annual Pass - Season One",
    "SYMBOL": "FMAPS1",
    "ALLOW_LIST_ALLOCATION": 2,
    "PRESALE_LIST_ALLOCATION": 2,
    "PUBLIC_ALLOCATION": 5,
    "MAX_SUPPLY": 550,
    "WEI_COST": 50000000000000000,
    "GAS_LIMIT": 100000,
    "MARKETPLACE": "OPENSEA",
    "MARKETPLACE_LINK": "https://opensea.io/collection/future-mints-annual-pass-season-one",
}
  