import { connect } from 'react-redux';
import * as actions from '../actions';
import axios from 'axios';

import web3Modal from "../utils/web3Modal";

const disconnectWallet = async () => {
    try {
        await web3Modal.clearCachedProvider();        
    } catch (error) {
        console.error("Caught error in disconnectWallet:", error);
    }
}

const Logout = async () => {
    
    disconnectWallet();
    
    await axios.get(`/api/logout`, {
        validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
        }
    });    

    window.location = "/";

    //return( <Redirect to="/" /> );
};

function mapStateToProps({ wallet, registeredUser }) {
    return { wallet, registeredUser };
}

export default connect(mapStateToProps, actions)(Logout);