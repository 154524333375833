import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

const GiveawaysRedirect = () => {

    const [liveGiveawaysCount, setLiveGiveawaysCount] = useState(-1);

    useEffect(() => {
        const fetchGiveawaysData = async () => {
            const response = await axios.get("/api/get/giveaways/live", {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });

            setLiveGiveawaysCount(response.data.length);
        }
        
        fetchGiveawaysData();
    }, [])  

    if ( liveGiveawaysCount > 0 ) {
        return <Redirect to="/giveaways/live" />
    }
    else if ( liveGiveawaysCount === 0 ) {
        return <Redirect to="/giveaways/upcoming" />
    }
    
}

export default GiveawaysRedirect;