import { SET_REFRESH_WALLET_TOKENS, SET_REFRESH_CONTRACT_MEMBERSHIPS } from '../actions/types';

const refreshReducer = (state = {}, action) => {
    switch(action.type) {
        case SET_REFRESH_WALLET_TOKENS:
            // action.payload should include a walletTokens key; e.g., { walletTokens: false }
            // console.log('refreshReducer:', state, action.type, action.payload);
            // console.log({ ...state, ...action.payload });
            return { ...state, ...action.payload };
        case SET_REFRESH_CONTRACT_MEMBERSHIPS:
            // action.payload should include a contractMemberships key; e.g., { contractMemberships: false }
            // console.log('refreshReducer:', state, action.type, action.payload);
            // console.log({ ...state, ...action.payload });
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default refreshReducer
