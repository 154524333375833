import { connect } from 'react-redux';

import useAuthenticated from '../../hooks/useAuthenticated';

const PleaseSignIn = ({ wallet }) => {

    const { account, signer } = wallet; // use the account from the wallet store object

    // Prompt the user to sign a message and establish a session
    useAuthenticated(account, signer);

    return <div className="row">
        <div className="col-1 col-sm-3"></div>
        <div className="col-10 col-sm-6">
            <span style={{ color: "rgba(255,255,255,0.8)" }}>Please sign the message to confirm ownership of the wallet.</span>
        </div>
    </div>
}

function mapStateToProps({ wallet }) {
    return { wallet };
}

export default connect(mapStateToProps)(PleaseSignIn);
