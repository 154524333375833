import { Link } from 'react-router-dom';

const RenderTwitterURL = ({ twitter, twitter_follower_count }) => {
    
    if (twitter === null || twitter === '' ) {
        return <>
            
        </>;
    }
    else {
        const twitter_url = 'https://twitter.com/' + twitter + '?utm_source=futurenftmints';
        
        let twitter_follower_count_formatted = twitter_follower_count;

        if ( twitter_follower_count > 1000000 ) {
            const whole_number = Math.floor(twitter_follower_count / 1000000);            
            const remainder = Math.round(twitter_follower_count % 1000000 * 0.00001) / 10;
            twitter_follower_count_formatted = whole_number + remainder + 'M';
        }
        else if ( twitter_follower_count > 1000 ) {
            const whole_number = Math.floor(twitter_follower_count / 1000);
            const remainder = Math.round(twitter_follower_count % 1000 * 0.01) / 10;
            twitter_follower_count_formatted = whole_number + remainder + 'K';
        }

        return <>
            <Link to={{ pathname: twitter_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}>
                <img alt="" src="/images/twitter.png" height="20" className="d-inline-block align-middle" style={{margin:"0px"}} /> 
                <br />
                <span style={{fontSize:"0.75em"}}>
                    {twitter_follower_count_formatted}
                </span>                
            </Link>       
        </>
    }
}

export default RenderTwitterURL;