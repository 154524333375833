import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';


const RenderWatchIcon = ({ id, access, style, mobile }) => {
    
    const [watching, setWatching] = useState(false);

    useEffect(() => {
        const fetchWatching = async () => {
            const response = await axios.get(`/api/fetch-watching/${id}`);
            setWatching(response?.data);
        }
        if ( access !== 'public' ) {
            fetchWatching();
        }
    }, [id, access])

    const updateWatching = async (id, watching, access) => {
        if ( access !== 'public ') {
            try {
                const response = await axios.put(`/api/watching/${id}`, { watching });         
                return response.data.watching;
            } catch (error) {
                console.error(error.response);
            }
        }
        
        return '';
    }

    let size = '16';

    if ( mobile ) {
        size='24';
    }

    if ( style === 'heart' ) {
        //console.log(watching);
        if ( access !== 'public' ) {
            if ( watching ) {
                return <>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width={size} 
                        height={size}
                        fill="#7945E1" 
                        className="bi bi-heart-fill" 
                        viewBox="0 0 16 16"
                        onClick={async () => {
                            const newWatching = await updateWatching(id, !watching);
                            setWatching(newWatching);                            
                        }}
                    >
                        <path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                    </svg>                                
                </>;            
            }
            else {
                return <>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width={size}
                        height={size}
                        fill="#ffffff" 
                        className="bi bi-heart" 
                        viewBox="0 0 16 16"
                        onClick={async () => {
                            const newWatching = await updateWatching(id, !watching);
                            setWatching(newWatching);    
                        }}
                    >
                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                    </svg>                
                </>;   
            }
        }
        else {
            return <>
                <Link to="/signup">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width={size}
                        height={size}
                        fill="#ffffff" 
                        className="bi bi-heart" 
                        viewBox="0 0 16 16"                    
                    >
                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                    </svg> 
                </Link>
            </>;   
        }
    }
    else if ( style === 'eye' ) {
        //console.log(watching);
        if ( access !== 'public' ) {
            if ( watching ) {
                return <>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width={size}
                        height={size}
                        fill="#7945E1" 
                        className="bi bi-eye-fill" 
                        viewBox="0 0 16 16"
                        onClick={async () => {
                            const newWatching = await updateWatching(id, !watching);
                            setWatching(newWatching);                            
                        }}
                    >
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                    </svg>                                                   
                </>;            
            }
            else {
                return <>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width={size}
                        height={size}
                        fill="#ffffff" 
                        className="bi bi-eye-fill" 
                        viewBox="0 0 16 16"
                        onClick={async () => {
                            const newWatching = await updateWatching(id, !watching);
                            setWatching(newWatching);                            
                        }}
                    >
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                    </svg>                                
                </>;   
            }
        }
        else {
            return <>
                <Link to="/signup">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width={size}
                        height={size}
                        fill="#ffffff" 
                        className="bi bi-eye-fill" 
                        viewBox="0 0 16 16"                        
                    >
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                    </svg>
                </Link>
            </>;   
        }
    }
}

export default RenderWatchIcon;