const PleaseRegister = () => {
    return <div className="row">
        <div className="col-3"></div>
        <div className="col-6">
            <p style={{ color: "rgba(255,255,255,0.8)" }}>Please <a href="/register" style={{color:"#rgba(255,255,255,0.8)"}}>register</a>.</p>
        </div>
    </div>
}

export default PleaseRegister;
