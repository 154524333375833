import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';
import axios from 'axios';

import RenderField from '../calendar/RenderFieldMinMax';
import RenderCheckbox from '../addNFT/RenderCheckbox';

let collectionNames = [];
let collectionImages = [];
let initialVariablesSet = true;

const SelectField = ({ input, label, meta: {touched, error}, children }) => (
    <div className="field">
        <label style={{marginBottom: "5px", color:"rgba(255,255,255,0.8)"}}>{label}</label>
        <div className="control">
            <div className={'select ' + (touched ? (error ? 'is-danger' : 'is-success') : '')}>
                <select {...input} className="form-select" style={{backgroundColor:"#120B21", color:"rgba(255,255,255,0.8)", backgroundImage:"linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc)", backgroundPosition: "calc(100% - 17px) calc(1em + 2px), calc(100% - 12px) calc(1em + 2px), calc(100% - 2em) 0.4em", backgroundSize:"5px 5px, 5px 5px, 1px 1.5em", backgroundRepeat: "no-repeat"}}>
                {children}
                </select>
                {touched && (error && <p className="help is-danger">{error}</p>)}
            </div>
        </div>
    </div>
);



const fetchCollectionData = async (warnings) => {
    
    let url = '/api/nft/all';
    if ( warnings ) {
        url += '?warnings=true';
    }
    const response = await axios.get(url, {
        validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
        }
    });
    
    collectionNames = [];
    collectionImages = [];
    for (let i = 0; i < response.data.length; i++) {
        collectionNames.push(response.data[i].nft_collection);
        // if collection image is null use placeholder
        if (response.data[i].collection_image){
            collectionImages.push(response.data[i].collection_image);
        }
        else{
            collectionImages.push('');
        }
    }
}


const AllNFTsFiltersForm = ({ onCalendarFilterSubmit, initialValues, handleSubmit, onSubmit}) => {

    if ( initialVariablesSet ) {
        fetchCollectionData(initialValues.warnings ? true : false);
        initialVariablesSet = false;
    }

    return (
        <>
            <div className="col-12" style={{backgroundColor: "#280B22", borderRadius:"1em", borderCollapse:"collapse", overflow:"hidden" }}>
                {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
                <form onSubmit={handleSubmit( values => onSubmit({
                    ...values
                }) )}>                                        
                    
                    <div className="row" style={{marginTop:"15px"}}>                                                                                                                        

                        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
                            <div className="form-group">
                                <div>                                    
                                    <Field
                                        className="form-select"
                                        name="blockchain"
                                        component={SelectField}
                                        type="select"
                                        label="Blockchain"
                                    >
                                        <option value=''>All</option>
                                        <option value="ETH">Ethereum (ETH)</option>
                                        <option value="FLOW">Flow</option>
                                        <option value="PALM">Palm</option>
                                        <option value="MATIC">Polygon (MATIC)</option>   
                                        <option value="SOL">Solana (SOL)</option>   
                                        <option value="ADA">Cardano (ADA)</option>  
                                        <option value="AVAX">Avalanche (AVAX)</option>
                                        <option value="BNB">Binance (BNB)</option>
                                        <option value="OP">Optimism (OP)</option>
                                    </Field>
                                </div>
                            </div>
                        </div>   

                        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
                            <div className="form-group">
                                <div className="row">                                    
                                <label style={{ color: "rgba(255,255,255,0.8)", marginBottom: "5px" }}>Supply</label>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="supplyMin"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Min"
                                            label="" />
                                    </div>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="supplyMax"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Max"
                                            label="" />
                                    </div>  
                                </div>
                            </div>
                        </div>                                                                

                        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
                            <div className="form-group">
                                <div className="row">                                    
                                <label style={{ color: "#rgba(255,255,255,0.8)", marginBottom: "5px" }}>Twitter Followers</label>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="twitterMin"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Min"
                                            label="" />
                                    </div>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="twitterMax"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Max"
                                            label="" />
                                    </div>  
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
                            <div className="form-group">
                                <div className="row">                                    
                                <label style={{ color: "#rgba(255,255,255,0.8)", marginBottom: "5px" }}>Discord Users</label>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="discordMin"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Min"
                                            label="" />
                                    </div>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="discordMax"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Max"
                                            label="" />
                                    </div>  
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
                            <div className="form-check">
                                <Field 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    component={RenderCheckbox}
                                    name="warnings"
                                    label="NFTs w/ Warnings" 
                                    onClick={async (e) => fetchCollectionData(e.target.checked)} />                                    
                                
                            </div>
                        </div>

                    </div>

                    <div className="row" style={{ marginTop: "30px", marginBottom: "10px" }}>
                        <div className="col-lg-12 col-sm-3 col-12">
                            <button className="btn btn-light" style={{ width: "100%", height: "50px" }} >
                                Submit
                            </button>
                        </div>                        
                    </div>
                </form>
                <div className="row text-center" style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <div className="col-lg-12 col-sm-3 col-12">
                        <a href="/all-nfts" style={{ color: "#ffffff", verticalAlign: "baseline" }}>
                            Reset
                        </a>
                    </div>                        
                </div>
            </div>
        </>
    );
}

const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}    

    if (values.priceMin) {
        
        if (typeof values.priceMin != 'number' && values.priceMin.search(/[a-zA-Z!#$%&’*+/=?^_`{|}~-]/) > -1) {
            errors.priceMin = 'Only input a number or decimal'
        }

        if (values.priceMin.length > 10) {
         errors.priceMin = 'Too long for a number'
        }        
    }

    if (values.priceMax) {
        
        if (typeof values.priceMax != 'number' && values.priceMax.search(/[a-zA-Z!#$%&’*+/=?^_`{|}~-]/) > -1) {
            errors.priceMax = 'Only input a number or decimal'
        }

        if (values.priceMax.length > 10) {
         errors.priceMax = 'Too long for a number'
        }        
    }

    if (values.supplyMin) {
        
        if (! ( values.supplyMin > 0 ) || ( typeof values.supplyMin != 'number' && values.supplyMin.search(/[.]/) > -1 ) ) {
         errors.supplyMin = 'Must be a whole number'
        }

        if (values.supplyMin.length > 10) {
            errors.supplyMin = 'Too long for a number'
        }
    }

    if (values.supplyMax) {
        
        if (! ( values.supplyMax > 0 ) || ( typeof values.supplyMax != 'number' && values.supplyMax.search(/[.]/) > -1 ) ) {
         errors.supplyMax = 'Must be a whole number'
        }

        if (values.supplyMax.length > 10) {
            errors.supplyMax = 'Too long for a number'
        }
    }

    if (values.twitterMin) {
        
        if (! ( values.twitterMin > 0 ) || ( typeof values.twitterMin != 'number' && values.twitterMin.search(/[.]/) > -1 ) ) {
         errors.twitterMin = 'Must be a whole number'
        }

        if (values.twitterMin.length > 10) {
            errors.twitterMin = 'Too long for a number'
        }
    }

    if (values.twitterMax) {
        
        if (! ( values.twitterMax > 0 ) || ( typeof values.twitterMax != 'number' && values.twitterMax.search(/[.]/) > -1 ) ) {
         errors.twitterMax = 'Must be a whole number'
        }

        if (values.twitterMax.length > 10) {
            errors.twitterMax = 'Too long for a number'
        }
    }

    if (values.discordMin) {
        
        if (! ( values.discordMin > 0 ) || ( typeof values.discordMin != 'number' && values.discordMin.search(/[.]/) > -1 ) ) {
         errors.discordMin = 'Must be a whole number'
        }

        if (values.discordMin.length > 10) {
            errors.discordMin = 'Too long for a number'
        }
    }

    if (values.discordMax) {
        
        if (! ( values.discordMax > 0 ) || ( typeof values.discordMax != 'number' && values.discordMax.search(/[.]/) > -1 ) ) {
         errors.discordMax = 'Must be a whole number'
        }

        if (values.discordMax.length > 10) {
            errors.discordMax = 'Too long for a number'
        }
    }

    // console.log('errors', errors)
    return errors
}


function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'allNFTsFiltersForm',
    validate,
})(AllNFTsFiltersForm));