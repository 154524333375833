const GiveawayAddHeader = () => {
    return (
        <div className="row">            
            <div className="col-12" style={{ fontSize: "2em"}}>
                Add Giveaway
            </div>
        </div>
    );
};

export default GiveawayAddHeader;
