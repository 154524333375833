import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import * as actions from '../../actions';

const GiveawayCard = ({giveawayData, page, fetchRegisteredUser, account, registeredUser}) => {        

    const [userGiveawaysData, setUserGiveawaysData] = useState([]);

    useEffect(() => {
        const fetchUserGiveawaysData = async () => {
            const response = await axios.get(`/api/get/giveaways/live/user/${giveawayData?.id}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });

            setUserGiveawaysData(response.data);
            setInputRaffleSpots(response.data?.quantity === undefined ? 0 : response.data?.quantity); 
            setYourEntries(response.data?.quantity === undefined ? 0 : response.data?.quantity);            
        }
        
        fetchUserGiveawaysData();
    }, [])

    const [yourEntries, setYourEntries] = useState(0);
    const [inputRaffleSpots, setInputRaffleSpots] = useState(0);
    const [updateEntry, setUpdateEntry] = useState(giveawayData?.id + 'No');
    const [totalEntries, setTotalEntries] = useState(giveawayData?.entries_count > 0 ? giveawayData?.entries_count * 1  : 0);


    const raffleSpotsName = 'raffleSpots' + giveawayData?.id;

    const startDate = new Date(giveawayData?.start_date);
    const startDateConverted = startDate.toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });

    const endDate = new Date(giveawayData?.end_date);
    const endDateConverted = endDate.toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });

    const convertTime = (time) => {
        if (time !== null && time !== undefined) {
            const timeSplit = time.split(':');  
    
            let hour = timeSplit[0] % 12;
            if (hour === 0) { hour = 12 };
    
            let timePeriod;
            if (timeSplit[0] / 12 >= 1) { timePeriod = ' PM' } else { timePeriod = ' AM'}
    
            return hour + ':' + timeSplit[1] + timePeriod + ' ET';
        }
        return 'TBD';
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const delayUpdateFade = async () => {
        await delay(3000);
        setUpdateEntry(giveawayData.id + 'No');
      };

    const inputGivewayEntries = async (id, quantity) => {        
        quantity = quantity * 1;
        
        const values = {
            id,
            quantity
        }
        
        try {                                                        
            const result = await axios.post(`/api/post/giveaway/entries/entered`, values);   
            
            if ( result.data.result === 'Not enough entries' ) {
                setUpdateEntry(result.data.result);
                delayUpdateFade();  
            }
            else {
                setTotalEntries(totalEntries + result.data.change * 1);
                setYourEntries(quantity);
                fetchRegisteredUser(account);
                
                setUpdateEntry(giveawayData?.id + 'Yes');
                delayUpdateFade();  
            }  
        } catch (error) {
            // This should be visible in FS and (hopefully) alert FS of the issue
            console.error(error.response)
        }            

    }

    const Winner = ({winner}) => {
        return <>
            {winner}
            <br />
        </>
    }
    const winnerBackgroundColor = giveawayData?.winners?.includes(registeredUser.username) && inputRaffleSpots > 0 ? '#7945E1' : '#280B22';
    
    return (
        <>           
            <div className="col m-2">
                <div className="card mx-auto border-secondary" style={{width: "15rem", zIndex:"0", padding:"0px", backgroundColor:`${winnerBackgroundColor}`}}>
                    {page === 'ended' ?
                        giveawayData?.winners?.includes(registeredUser.username) ? 
                            <>
                                <span className="text-center">CONGRATS, YOU WON!</span>
                            </> 
                            : 
                            inputRaffleSpots > 0 ? 
                                <>
                                    <span className="text-center">Did not win</span>
                                </> 
                                : 
                                ''
                        :
                        ''
                    }
                    <img className="card-img-top" src={giveawayData?.collection_image} alt="Card image cap" />
                    <div className="card-body" style={{backgroundColor:"#280B22", color:"rgba(255,255,255,.8)", fontSize:"0.8em"}}>                     
                        <h5 className="card-title">
                            <Link to={'/collection/' + giveawayData?.nft_collection_id} style={{color:"rgba(255,255,255,0.8"}}>
                                {giveawayData?.nft_collection}
                            </Link>
                        </h5>                    
                        <div className="row card-text">                        
                            <div className="col-6">
                                Quantity: {giveawayData?.quantity}
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <a href={giveawayData?.marketplace_link} target="_BLANK" rel="noreferrer" style={{color:"rgba(255,255,255,0.8"}}>
                                    {giveawayData?.marketplace === 'OpenSea' ? <img alt="" src="/images/opensea.png" height="20" className="d-inline-block align-top" /> : <>{giveawayData?.marketplace}</>}
                                </a>
                            </div>
                        </div>
                        <br />
                        <p className="card-text">
                            {page === 'upcoming' ? <>Start: {startDateConverted + ' ' + convertTime(giveawayData?.start_time)}<br /></> : null}
                            End: {endDateConverted + ' ' + convertTime(giveawayData?.end_time)}
                        </p>  
                        <p className="card-text">
                            Your Entries: {yourEntries}
                            <br />Total Entries: {totalEntries}                        
                        </p>    
                        {page === 'live' && registeredUser.username !== undefined ? 
                            <>
                                <div className="row" style={{paddingLeft:"16px", paddingRight:"28px"}}>
                                    <div className="col-8" style={{padding:"0px"}}>
                                        <input className="form-control" placeholder="Entries" type="number" name={raffleSpotsName} value={inputRaffleSpots} onChange={(e) => setInputRaffleSpots(e.currentTarget.value)} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/>
                                        
                                        {updateEntry === giveawayData?.id + 'Yes' ? <><span style={{color:"rgba(255,255,255,0.8)"}}>Updated</span></> : updateEntry === giveawayData.id + 'No' ? '' : updateEntry}  
                                    </div>
                                    <div className="col-4" style={{padding:"0px"}}>
                                    <button className="btn btn-light" style={{color:"#29194A"}} onClick={() => inputGivewayEntries(giveawayData?.id, document.getElementsByName(raffleSpotsName)[0].value )} >
                                        {inputRaffleSpots > 0 ? 'Update' : 'Enter' }
                                    </button>                                      
                                    </div>  
                                </div>
                            </>
                            :
                            null
                        }
                        {page === 'ended' ? 
                            <>
                                <div className="row">
                                    <div className="col-6">
                                        <strong>WINNER{giveawayData?.winners.length > 1 ? 'S' : ''}:</strong>
                                    </div> 
                                    <div className="col-6">
                                        <span className="justify-content-end">{giveawayData?.winners.map(winner => <Winner key={winner} winner={winner} />)}</span>
                                    </div>
                                </div>
                            </> 
                            :
                            null 
                        }
                    </div>
                    
                        
                        <div className="card-footer text-muted" style={{padding: "10px", textAlign:"center", backgroundColor:"#280B22"}}>                         

                            <div className="col-12">We airdrop NFT to winner{giveawayData?.quantity > 1 ? 's' : null}</div> 

                            <div className="col-12 mt-2" style={{fontSize:"0.5em"}}>Giveaway ID #{giveawayData?.id}</div>                                     
                        </div>                       
                        
                </div>   
            </div>                        
        </>
    )
};

function mapStateToProps({ auth, wallet, registeredUser, fetchRegisteredUser }) {
    return { auth, wallet, registeredUser, fetchRegisteredUser };
}

export default connect(mapStateToProps, actions)(GiveawayCard);