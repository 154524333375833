import { useState, useMemo, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import axios from 'axios';

import AllNFTsFiltersForm from './AllNFTsFiltersForm';
import AllNFTsHeader from './AllNFTsHeader';

import Pagination from '../pagination/Pagination';

import RenderAllowList from '../dashboard/layoutComponents/RenderAllowList';
import RenderCollectionImage from '../dashboard/layoutComponents/RenderCollectionImage';
import RenderCollectionName from '../dashboard/layoutComponents/RenderCollectionName';
import RenderDiscordURL from '../dashboard/layoutComponents/RenderDiscordURL';
import RenderMarketplaceURL from '../dashboard/layoutComponents/RenderMarketplaceURL';
import RenderTwitterURL from '../dashboard/layoutComponents/RenderTwitterURL';
import RenderVote from '../dashboard/layoutComponents/RenderVote';
import RenderVoteMobile from '../dashboard/layoutComponents/RenderVoteMobile';
import RenderWatchIcon from '../dashboard/layoutComponents/RenderWatchIcon';
import RenderWebsiteURL from '../dashboard/layoutComponents/RenderWebsiteURL';

const convertTime = (time) => {

    if ( time !== null ) {
        const timeSplit = time.split(':');  

        let hour = timeSplit[0] % 12;
        if (hour === 0) { hour = 12 };

        let timePeriod;
        if (timeSplit[0] / 12 >= 1) { timePeriod = ' PM' } else { timePeriod = ' AM'}

        return hour + ':' + timeSplit[1] + timePeriod + ' ET';
    }

    return null;
}

const TableRowDesktop = ({ nft, access, upcomingMints, freeMints }) => {


    const { id, nft_collection, earliest_mint_date, earliest_mint_date_time, earliest_mint_date_type, collection_image, collection_supply, twitter, twitter_follower_count, discord, discord_user_count, website, vote_percent, upvote_count, downvote_count, slug, mint_status, warning, warning_message, marketplace, marketplace_link, public_mint_price, allow_list_mint_price, blockchain } = nft;            
    
    const collectionPageURL = '/collection/' + slug;

    const earliestMintDate = new Date(earliest_mint_date);
    const earliestMintDateConverted = earliestMintDate.toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });
    
    const today = new Date();
    today.setUTCHours(0,0,0,0);
    
    let mintStatus = earliestMintDate < today ? 'Minted' : 'Upcoming';    
    mintStatus = earliest_mint_date_type === 'TBD' ? 'TBD' : mintStatus;
    //mintStatus = earliest_mint_date_type === '' ? 'TBD' : mintStatus;
    //mintStatus = earliest_mint_date_type === null ? 'TBD' : mintStatus;    

    const renderWarningSymbol = ( warning, warning_message ) => {
        
        if ( warning === 'Caution' ) {
            return <>
                &nbsp;<img alt="" src="/images/cautionSymbol.png" style={{width:"20px"}} />                        
            </>
        }   
        else if ( warning === 'Avoid' ) {
            return <>
                &nbsp;<img alt="" src="/images/avoidSymbol.png" style={{width:"20px"}} />                        
            </>
        }     
    }    

    //{renderThumbs(access, vote)}   
    return <>
    <tr style={{backgroundColor:"#280B22", border:"1px #000000 solid", color:"rgba(255,255,255,.8)"}}>

        <td style={{width:"54px", textAlign:"center"}}>
            <div className="row">
                <span>
                    <RenderCollectionImage collection_image={collection_image} collection_slug={slug} />                    
                </span>                
            </div>
        </td>

        <td style={{maxWidth: "300px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
            <span style={{fontSize:"1.25em"}}>
                <RenderCollectionName collection_name={nft_collection} collection_slug={slug} />  
                {warning !== 'None' ? renderWarningSymbol(warning) : null} 
            </span>   
            <br />
            <span style={{fontSize:"0.75em"}}>
                {collection_supply > 0 ? 'Supply: ' + collection_supply : ''}
            </span>
                       
        </td>        
                        
        <td style={{textAlign:"center"}}><RenderTwitterURL twitter={twitter} twitter_follower_count={twitter_follower_count} /></td>
        <td style={{textAlign:"center"}}><RenderDiscordURL discord={discord} discord_user_count={discord_user_count}/></td>
        <td><RenderWebsiteURL website={website} /></td>
        <td><RenderMarketplaceURL marketplace={marketplace} marketplace_link={marketplace_link} /></td>                
        <td>
            {mintStatus === 'Upcoming' ? <>{earliestMintDateConverted}<br /><span style={{fontSize:"0.75em", paddingLeft:"0px"}}>{convertTime(earliest_mint_date_time)}</span></> : mintStatus === 'TBD' ? <>{earliestMintDateConverted}<br /><span style={{fontSize:"0.75em", paddingLeft:"0px"}}>TBD</span></> : null} 
        </td>    
        { upcomingMints === 'true' || freeMints === 'true' ? 
            <>
                <td>{public_mint_price === null ? allow_list_mint_price : public_mint_price} {blockchain}</td>
            </>
            :
            null

        } 
        { access !== 'public' ?
            <td style={{minWidth: "110px", width:"110px"}}>
            {mintStatus === 'TBD' || mintStatus === 'Upcoming' ? <RenderAllowList key={id} id={id} access={access} mobile='false' /> : ''}
            </td>
            :
            null
        }           

        <RenderVote access={access} id={id} upvote_count={upvote_count} downvote_count={downvote_count} vote_percent={vote_percent} />

        <td>
            <RenderWatchIcon id={id} access={access} style="eye" mobile="false" />    
        </td>
                
    </tr>
    </>;
}

const TableRowMobile = ({ nft, access,  upcomingMints, freeMints }) => {


    const { id, nft_collection, earliest_mint_date, earliest_mint_date_time, earliest_mint_date_type, collection_image, collection_supply, twitter, twitter_follower_count, discord, discord_user_count, website, vote_percent, upvote_count, downvote_count, slug, mint_status, warning, warning_message, marketplace, marketplace_link, public_mint_price, allow_list_mint_price, blockchain } = nft;            
    
    const collectionPageURL = '/collection/' + slug;

    const earliestMintDate = new Date(earliest_mint_date);
    const earliestMintDateConverted = earliestMintDate.toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });
    
    const today = new Date();
    today.setUTCHours(0,0,0,0);
    
    let mintStatus = earliestMintDate < today ? 'Minted' : 'Upcoming';    
    mintStatus = earliest_mint_date_type === 'TBD' ? 'TBD' : mintStatus;
    //mintStatus = earliest_mint_date_type === '' ? 'TBD' : mintStatus;
    //mintStatus = earliest_mint_date_type === null ? 'TBD' : mintStatus;    

    const renderWarningSymbol = ( warning, warning_message ) => {
        
        if ( warning === 'Caution' ) {
            return <>
                &nbsp;<img alt="" src="/images/cautionSymbol.png" style={{width:"20px"}} />                        
            </>
        }   
        else if ( warning === 'Avoid' ) {
            return <>
                &nbsp;<img alt="" src="/images/avoidSymbol.png" style={{width:"20px"}} />                        
            </>
        }     
    }    

    //{renderThumbs(access, vote)}   
    return <>
    <tr style={{backgroundColor:"#280B22", border:"5px #000000 solid", color:"rgba(255,255,255,.8)"}}>

        <td style={{width:"204px"}}>
            <div className="row">
                <div className="col-3" style={{paddingRight:"0px"}}>
                    <RenderCollectionImage collection_image={collection_image} collection_slug={slug} />                    
                </div>      
                <div className="col-9" style={{paddingLeft:"0px", fontSize:"1em", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", border:"1px #280B22 solid"}}>
                    <RenderCollectionName collection_name={nft_collection} collection_slug={slug} />  
                    {warning !== 'None' ? renderWarningSymbol(warning) : null}  
                    <br />
                    <span style={{fontSize:"0.75em"}}>
                        {collection_supply > 0 ? 'Supply: ' + collection_supply : <>&nbsp;</>}
                    </span>
                </div>          
            </div>
            <div className="row mt-3">
                <div className="col-2">
                    <RenderTwitterURL twitter={twitter} twitter_follower_count={twitter_follower_count} />
                </div>
                <div className="col-2">
                    <RenderDiscordURL discord={discord} discord_user_count={discord_user_count}/>
                </div>
                <div className="col-2">
                    <RenderWebsiteURL website={website} />
                </div>
                <div className="col-2">
                    <RenderMarketplaceURL marketplace={marketplace} marketplace_link={marketplace_link} />
                </div>
            </div>
        </td>                
                     
        <td>
            <div className="row">
                {mintStatus === 'Upcoming' ? 
                    <>{earliestMintDateConverted}
                        <br />
                        <span style={{fontSize:"0.75em", paddingLeft:"0px"}}>{convertTime(earliest_mint_date_time)}</span>                        
                        <span style={{fontSize:"0.75em", paddingLeft:"0px"}}>{public_mint_price === null ? ( allow_list_mint_price !== null ? <>{allow_list_mint_price} {blockchain}</> : null ) : <>{public_mint_price} {blockchain}</>}</span>
                    </> 
                    :
                    mintStatus === 'TBD' ? 
                    <>{earliestMintDateConverted}
                        <br />
                        <span style={{fontSize:"0.75em", paddingLeft:"0px"}}>TBD</span>
                        <span style={{fontSize:"0.75em", paddingLeft:"0px"}}>{public_mint_price === null ? ( allow_list_mint_price !== null ? <>{allow_list_mint_price} {blockchain}</> : null ) : <>{public_mint_price} {blockchain}</>}</span>
                    </> 
                    : 
                    null
                } 
            </div>
            <div className="row mt-3" style={{minWidth: "80px", width:"80px"}}>
                {mintStatus === 'TBD' || mintStatus === 'Upcoming' ? <RenderAllowList key={id} id={id} access={access} mobile='true' /> : ''}
            </div>
        </td>        
        

        <RenderVoteMobile access={access} id={id} upvote_count={upvote_count} downvote_count={downvote_count} vote_percent={vote_percent} />        
                
    </tr>
    </>;
}

const AllNFTs = ({ access, registeredUsername, allNFTsPremiumCount, pageSize, allNFTs, totalCount, originalFilters, thisPage, urlParams, url, originalParams }) => {    

    //make sure that the user is taken to the top of the page each time it loads
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    const [filtersButton, setFiltersButton] = useState(false);
    const [currentPage, setCurrentPage] = useState(thisPage);   
    const [filters, setFilters] = useState(originalFilters);
    const [params, setParams] = useState(originalParams);

    let pageData = allNFTs;
    
    let initialValues, keyValues, sort, blockchain, startDate, endDate, priceMin, priceMax, supplyMin, supplyMax, twitterMin, twitterMax, discordMin, discordMax, watchlist, haveAL, wantAL, mintStatus, search, warnings, upcomingMints, freeMints, linesFat;

    if (urlParams != '') {        
        let initialValuesStartSplit = urlParams.split('?');
        let initialValuesKeyValues = initialValuesStartSplit[1].split('&');

        for ( let i = 0; i < initialValuesKeyValues.length ; ++i ) {
            keyValues = initialValuesKeyValues[i].split('=');
            
            if (keyValues[0] === 'sort') {
                sort = keyValues[1];
            }

            if (keyValues[0] === 'blockchain') {
                blockchain = keyValues[1];
            }

            if (keyValues[0] === 'startDate') {
                startDate = keyValues[1];
            }

            if (keyValues[0] === 'endDate') {
                endDate = keyValues[1];
            }

            if (keyValues[0] === 'priceMin') {
                priceMin = keyValues[1];
            }

            if (keyValues[0] === 'priceMax') {
                priceMax = keyValues[1];
            }

            if (keyValues[0] === 'supplyMin') {
                supplyMin = keyValues[1];
            }

            if (keyValues[0] === 'supplyMax') {
                supplyMax = keyValues[1];
            }

            if (keyValues[0] === 'twitterMin') {
                twitterMin = keyValues[1];
            }

            if (keyValues[0] === 'twitterMax') {
                twitterMax = keyValues[1];
            }

            if (keyValues[0] === 'discordMin') {
                discordMin = keyValues[1];
            }

            if (keyValues[0] === 'discordMax') {
                discordMax = keyValues[1];
            }

            if (keyValues[0] === 'watchlist') {
                watchlist = keyValues[1];
            }

            if (keyValues[0] === 'haveAL') {
                haveAL = keyValues[1];
            }

            if (keyValues[0] === 'wantAL') {
                wantAL = keyValues[1];
            }

            if (keyValues[0] === 'warnings') {
                warnings = keyValues[1];
            }

            if (keyValues[0] === 'mintStatus') {
                mintStatus = keyValues[1];
            }

            if (keyValues[0] === 'search') {
                search = keyValues[1];
            }

            if (keyValues[0] === 'upcomingMints') {
                upcomingMints = keyValues[1];
            }

            if (keyValues[0] === 'freeMints') {
                freeMints = keyValues[1];
            }

            if (keyValues[0] === 'linesFat') {
                linesFat = keyValues[1];
            }
        }
        
        initialValues = { 
            'sort':             sort,
            'blockchain':       blockchain,
            'startDate':        startDate,
            'endDate':          endDate,
            'priceMin':         priceMin,
            'priceMax':         priceMax,
            'supplyMin':        supplyMin,
            'supplyMax':        supplyMax,
            'twitterMin':       twitterMin,
            'twitterMax':       twitterMax,
            'discordMin':       discordMin,
            'discordMax':       discordMax,
            'watchlist':        watchlist,
            'haveAL':           haveAL,
            'wantAL':           wantAL,
            'mintStatus':       mintStatus,
            'warnings':         warnings,
            'upcomingMints':    upcomingMints,
            'freeMints':        freeMints
        }

        if ( search !== undefined ) {
            initialValues.search= search;
        }
    }
    else {
        initialValues = { }
    }

    const noSearchResultsDesktop = (upcomingMints, freeMints) => {
        
        const colSpan = upcomingMints === 'true' || freeMints === 'true' ? 14 : 12;

        return <>
        <tr style={{backgroundColor:"#280B22", border:"1px #000000 solid", color:"rgba(255,255,255,.8)"}} key="1">
            <td colSpan={colSpan}>
                No results. Please <Link to="/add/nft/quick" style={{ color: "rgba(255,255,255,.8)"}}>Add NFT</Link> (all we need is a name), and we'll make sure it's added. Plus, you earn points!
                <br /><br />
                <a href="/all-nfts" style={{ color: "rgba(255,255,255,.8)", verticalAlign: "baseline" }}>
                    Reset Search
                </a>
            </td>
        </tr>
        </>;
    }

    const noSearchResultsMobile = () => {
        return <>
        <tr style={{backgroundColor:"#280B22", border:"1px #000000 solid", color:"rgba(255,255,255,.8)"}} key="1">
            <td colSpan="5">
                No results
                <br /><br />
                <a href="/all-nfts" style={{ color: "rgba(255,255,255,.8)", verticalAlign: "baseline" }}>
                    Reset
                </a>
            </td>
        </tr>
        </>;
    }

    const renderAllNFTFilters = () => {
        return <>
                        
                    <div className="row">
                        <AllNFTsFiltersForm 
                            initialValues={initialValues}
                            onCalendarFilterSubmit
                            onSubmit={
                                async (values) => {
                                    try {
                                        //const { url } = await fetch('/s3Url').then(res => res.json())
                                        //console.log(url)
                                        // https://axios-http.com/docs/instance
                                        // axios#post(url[, data[, config]])
                                        //console.log(values);
                                        let paramsNew = url + '?';
                                        
                                        if (values.search){
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'search=' + values.search;
                                        }

                                        if (values.sort){
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'sort=' + values.sort;
                                        }

                                        if (values.startDate) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'startDate=' + values.startDate;
                                        }

                                        if (values.endDate) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'endDate=' + values.endDate;
                                        }

                                        if ( values.blockchain ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'blockchain=' + values.blockchain;
                                        };

                                        if ( values.priceMin ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'priceMin=' + values.priceMin;
                                        };

                                        if ( values.priceMax ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'priceMax=' + values.priceMax;
                                        };

                                        if ( values.supplyMin ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'supplyMin=' + values.supplyMin;
                                        };

                                        if ( values.supplyMax ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'supplyMax=' + values.supplyMax;
                                        };

                                        if ( values.twitterMin ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'twitterMin=' + values.twitterMin;
                                        };

                                        if ( values.twitterMax ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'twitterMax=' + values.twitterMax;
                                        };

                                        if ( values.discordMin ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'discordMin=' + values.discordMin;
                                        };

                                        if ( values.discordMax ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'discordMax=' + values.discordMax;
                                        };

                                        if ( values.watchlist ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'watchlist=' + values.watchlist;
                                        };
                                        
                                        if ( values.haveAL ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'haveAL=' + values.haveAL;
                                        };

                                        if ( values.wantAL ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'wantAL=' + values.wantAL;
                                        };

                                        if ( values.warnings ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'warnings=' + values.warnings;
                                        };

                                        if ( values.mintStatus ) {
                                            if ( paramsNew != (url + '?' ) ) {
                                                paramsNew += '&';
                                            }
                                            paramsNew += 'mintStatus=' + values.mintStatus;
                                        };
                                        
                                        //console.log(paramsNew);
                                        setParams(paramsNew);
                                        var split = paramsNew.split("?");
                                        split.shift();
                                        setFilters('?' + split.join(''));
                                        
                                        window.location.assign(paramsNew.replace('/api/nft/all', '/all-nfts'));
                                           
                                        

                                        
                                    } catch (error) {
                                        // This should be visible in FS and (hopefully) alert FS of the issue
                                        console.error(error.response)
                                    }
                                }
                            }
                        />
                    </div>
            
        </>;
    }    

    const showNFTFilters = () => {

        return <>
            <div className="col-lg-2 col-12">
                <div style={{ padding:"10px"}}>
                    {renderAllNFTFilters()}
                </div>
            </div>                
        </>;
    }

    const nftCollectionSort = ( sort, upcomingMints, freeMints, linesFat ) => {              
        
        let appendToUrl = '';
        if ( upcomingMints === 'true' ) {
            appendToUrl = '&upcomingMints=true';
        }
        else if ( freeMints === 'true' ) {
            appendToUrl = '&freeMints=true';
        }

        if ( linesFat === 'true' ) {
            appendToUrl = '&linesFat=true';
        }

        if ( sort === 'collectionNameAtoZ') {
            return '/all-nfts?sort=collectionNameZtoA' + appendToUrl;
        }
        else {
            return '/all-nfts?sort=collectionNameAtoZ' + appendToUrl;
        }
    }

    const mintDateSort = ( sort, upcomingMints, freeMints, linesFat ) => {      
        
        let appendToUrl = '';
        if ( upcomingMints === 'true' ) {
            appendToUrl = '&upcomingMints=true';
        }
        else if ( freeMints === 'true' ) {
            appendToUrl = '&freeMints=true';
        }

        if ( linesFat === 'true' ) {
            appendToUrl = '&linesFat=true';
        }

        if ( sort === 'mintDateNewToOld') {
            return '/all-nfts?sort=mintDateOldToNew' + appendToUrl;
        }
        else {
            return '/all-nfts?sort=mintDateNewToOld' + appendToUrl;
        }
    }

    const likesSort = ( sort, upcomingMints, freeMints, linesFat ) => {      
        
        let appendToUrl = '';
        if ( upcomingMints === 'true' ) {
            appendToUrl = '&upcomingMints=true';
        }
        else if ( freeMints === 'true' ) {
            appendToUrl = '&freeMints=true';
        }

        if ( linesFat === 'true' ) {
            appendToUrl = '&linesFat=true';
        }

        if ( sort === 'likesHighToLow') {
            return '/all-nfts?sort=likesLowToHigh' + appendToUrl;
        }
        else {
            return '/all-nfts?sort=likesHighToLow' + appendToUrl;
        }
    }

    const pageHeaderSelect = ( upcomingMints, freeMints ) => {
        
        if ( upcomingMints === 'true' ) {
            return 'upcoming-mints';
        }
        else if ( freeMints === 'true' ) {
            return 'free-mints';
        }
        else {
            return 'all-nfts';
        }
    }

    return (
        <>
        <AllNFTsHeader page={pageHeaderSelect(upcomingMints, freeMints)} layout={linesFat === 'true' ? 'linesFat' : 'lines'} />
        
        <div className="row" style={{marginTop:"40px"}}>
                
            <div className="col-12 d-lg-block d-none">
                <svg xmlns="http://www.w3.org/2000/svg" 
                    width="20" 
                    height="20" 
                    fill="#ffffff" 
                    className="bi bi-funnel-fill" 
                    viewBox="0 0 16 16"
                    onClick={async () => {
                        setFiltersButton(!filtersButton);
                    }}
                    style={{marginRight:"30px"}}
                >
                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
                </svg>
                    
                { totalCount < 50 ? <>{totalCount} Total</> :
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalCount}
                        pageSize={pageSize}
                        filters={filters}
                        onPageChange={page => setCurrentPage(page)}
                    />
                }
            </div>

            <div className="col-10 d-block d-lg-none">  
                { totalCount < 50 ? <>{totalCount} Total</> :
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalCount}
                        pageSize={pageSize}
                        filters={filters}
                        onPageChange={page => setCurrentPage(page)}
                    />
                }
            </div>
            <div className="col-2 d-block d-lg-none">
                <span className="d-flex justify-content-end">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="20" 
                        height="20" 
                        fill="#ffffff" 
                        className="bi bi-funnel-fill" 
                        viewBox="0 0 16 16"
                        onClick={async () => {
                            setFiltersButton(!filtersButton);
                        }}
                        
                    >
                        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
                    </svg>
                </span>
            </div>
            
        </div>
        <div className="row" style={{marginTop:"10px"}}>                    
                    
            {filtersButton === true ? showNFTFilters() : null}          
                        
            {/*DESKTOP*/}
            <div className={filtersButton === true ? "d-lg-block d-none col-lg-10 table-responsive text-nowrap" : "d-lg-block d-none col-lg-12 table-responsive text-nowrap"} style={{ padding:"10px"}} >
                
                <table className="table" style={{ borderRadius: "1em", borderCollapse: "collapse", overflow:"hidden", width:"100%" }}>
                    <thead>
                        <tr style={{ backgroundColor:"#29194A", borderBottom:"2px #000000 solid", color:"rgba(255,255,255,.8)"}}>                            
                            <th scope="col" colSpan="2">
                                <a href={nftCollectionSort(sort, upcomingMints, freeMints, linesFat)} style={{color:"rgba(255,255,255,0.8)", textDecoration:"none"}}>
                                    NFT Collection&nbsp;
                                    { sort === 'collectionNameAtoZ' ? 
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="16" 
                                            height="16" 
                                            fill="#7945E1" 
                                            className="bi bi-arrow-down-circle-fill" 
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                        </svg>
                                        :
                                        sort === 'collectionNameZtoA' ?
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="16" 
                                            height="16" 
                                            fill="#7945E1" 
                                            className="bi bi-arrow-up-circle-fill" 
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="16" 
                                            height="16" 
                                            fill="#ffffff" 
                                            className="bi bi-arrow-down" 
                                            viewBox="0 0 16 16"
                                        >
                                            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                        </svg>
                                    }
                                </a>
                            </th>
                            
                            <th scope="col" colSpan="4">Key Links</th>                          
                            <th scope="col">
                                <a href={mintDateSort(sort, upcomingMints, freeMints, linesFat)} style={{color:"rgba(255,255,255,0.8)", textDecoration:"none"}}>
                                    Mint&nbsp;
                                    { sort === 'mintDateNewToOld' ? 
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="16" 
                                            height="16" 
                                            fill="#7945E1" 
                                            className="bi bi-arrow-down-circle-fill" 
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                        </svg>
                                        :
                                        sort === 'mintDateOldToNew' ?
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="16" 
                                            height="16" 
                                            fill="#7945E1" 
                                            className="bi bi-arrow-up-circle-fill" 
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="16" 
                                            height="16" 
                                            fill="#ffffff" 
                                            className="bi bi-arrow-down" 
                                            viewBox="0 0 16 16"
                                        >
                                            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                        </svg>
                                    }
                                </a>
                            </th>
                            { upcomingMints === 'true' || freeMints === 'true' ?
                                <>
                                <th scope="col">
                                    Price
                                </th>
                                </>
                                :
                                null
                            }
                            { access !== 'public' ?
                                <th scope="col" style={{minWidth: "110px", width:"110px"}}>
                                    <Link to="/dashboard/allow-lists" style={{color:"rgba(255,255,255,0.8)", textDecoration:"none"}}>
                                        Allowlist
                                    </Link>
                                </th>
                                :
                                null
                            }

                            <th scope="col" colSpan="1" style={{textAlign:"center"}}>
                                <a href={likesSort(sort, upcomingMints, freeMints, linesFat)} style={{color:"rgba(255,255,255,0.8)", textDecoration:"none"}}>                                    
                                    { sort === 'likesHighToLow' || sort === undefined ? 
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="16" 
                                            height="16" 
                                            fill="#7945E1" 
                                            className="bi bi-arrow-down-circle-fill" 
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                        </svg>
                                        :
                                        sort === 'likesLowToHigh' ?
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="16" 
                                            height="16" 
                                            fill="#7945E1" 
                                            className="bi bi-arrow-up-circle-fill" 
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="16" 
                                            height="16" 
                                            fill="#ffffff" 
                                            className="bi bi-arrow-down" 
                                            viewBox="0 0 16 16"
                                        >
                                            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                        </svg>
                                    }
                                </a>
                            </th>

                            <th scope="col"></th>

                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody style={{ fontSize:".8em"}}>
                        { ( access === 'free' || access === 'public' ) && allNFTsPremiumCount > 0  ?                        
                            <tr style={{backgroundColor:"#280B22", border:"1px #000000 solid", color:"rgba(255,255,255,.8)", textAlign:"center", fontSize:"1.5em"}}>
                                <td colSpan="12">
                                    Access all {allNFTsPremiumCount} NFTs with Premium.&nbsp;
                                    <Link to="/signup">
                                        <button className="btn btn-sm btn-light" style={{color:"#7945E1"}}>
                                            Upgrade now
                                        </button>
                                    </Link> 
                                </td>
                            </tr>
                            :
                            null
                        }
                        {pageData.length > 0 ? pageData.map(nft => <TableRowDesktop key={nft.id} nft={nft} access={access} upcomingMints={upcomingMints} freeMints={freeMints} />)  : noSearchResultsDesktop(upcomingMints, freeMints)}
                    </tbody>
                    <tfoot style={{ backgroundColor:"#140510", textAlign: "center", color:"rgba(255,255,255,.8)", border:"1px #000000 solid"}}>
                        <tr>                     
                            <td colSpan="12">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={totalCount}
                                    pageSize={pageSize}
                                    filters={filters}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            {/*MOBILE*/}
            <div className="d-block d-lg-none col-12 table-responsive text-nowrap" style={{ padding:"10px"}} >
                
                {linesFat === 'true' ?
                    <table className="table" style={{ borderRadius: "1em", borderCollapse: "collapse", overflow:"hidden", width:"100%" }}>
                        <thead>
                            <tr style={{ backgroundColor:"#29194A", borderBottom:"2px #000000 solid", color:"rgba(255,255,255,.8)"}}>                            
                                <th scope="col" style={{minWidth:"204px"}}>
                                    <a href={nftCollectionSort(sort, upcomingMints, freeMints, linesFat)}  style={{color:"rgba(255,255,255,0.8)", textDecoration:"none"}}>
                                        NFT Collection&nbsp;
                                        { sort === 'collectionNameAtoZ' ? 
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#7945E1" 
                                                className="bi bi-arrow-down-circle-fill" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                            </svg>
                                            :
                                            sort === 'collectionNameZtoA' ?
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#7945E1" 
                                                className="bi bi-arrow-up-circle-fill" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#ffffff" 
                                                className="bi bi-arrow-down" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                            </svg>
                                        }
                                    </a>
                                </th>                                                  
                                <th scope="col">
                                    <a href={mintDateSort(sort, upcomingMints, freeMints, linesFat)} style={{color:"rgba(255,255,255,0.8)", textDecoration:"none"}}>
                                        Mint&nbsp;
                                        { sort === 'mintDateNewToOld' ? 
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#7945E1" 
                                                className="bi bi-arrow-down-circle-fill" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                            </svg>
                                            :
                                            sort === 'mintDateOldToNew' ?
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#7945E1" 
                                                className="bi bi-arrow-up-circle-fill" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#ffffff" 
                                                className="bi bi-arrow-down" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                            </svg>
                                        }
                                    </a>
                                </th>

                                <th scope="col" colSpan="1" style={{textAlign:"center"}}>
                                    <a href={likesSort(sort, upcomingMints, freeMints, linesFat)} style={{color:"rgba(255,255,255,0.8)", textDecoration:"none"}}>                                    
                                        { sort === 'likesHighToLow' || sort === undefined ? 
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#7945E1" 
                                                className="bi bi-arrow-down-circle-fill" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                            </svg>
                                            :
                                            sort === 'likesLowToHigh' ?
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#7945E1" 
                                                className="bi bi-arrow-up-circle-fill" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#ffffff" 
                                                className="bi bi-arrow-down" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                            </svg>
                                        }
                                    </a>
                                </th>

                                <th scope="col"></th>

                            </tr>
                        </thead>
                        <tbody style={{ fontSize:".8em"}}>
                            { ( access === 'free' || access === 'public' ) && allNFTsPremiumCount > 0  ?                        
                                <tr style={{backgroundColor:"#280B22", border:"1px #000000 solid", color:"rgba(255,255,255,.8)", textAlign:"center", fontSize:"1.2em"}}>
                                    <td colSpan="5">
                                        Access all {allNFTsPremiumCount} NFTs with Premium.&nbsp;
                                        <Link to="/signup">
                                            <button className="btn btn-sm btn-light" style={{color:"#7945E1"}}>
                                                Upgrade now
                                            </button>
                                        </Link> 
                                    </td>
                                </tr>
                                :
                                null
                            }
                            {pageData.length > 0 ? pageData.map(nft => <TableRowMobile key={nft.id} nft={nft} access={access} upcomingMints={upcomingMints} freeMints={freeMints}  />)  : noSearchResultsMobile()}
                        </tbody>
                        <tfoot style={{ backgroundColor:"#140510", textAlign: "center", color:"rgba(255,255,255,.8)", border:"1px #000000 solid"}}>
                            <tr>                     
                                <td colSpan="5">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={totalCount}
                                        pageSize={pageSize}
                                        filters={filters}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                :
                    <table className="table" style={{ borderRadius: "1em", borderCollapse: "collapse", overflow:"hidden", width:"100%" }}>
                        <thead>
                            <tr style={{ backgroundColor:"#29194A", borderBottom:"2px #000000 solid", color:"rgba(255,255,255,.8)"}}>                            
                                <th scope="col" colSpan="2">
                                    <a href={nftCollectionSort(sort, upcomingMints, freeMints, linesFat)} style={{color:"rgba(255,255,255,0.8)", textDecoration:"none"}}>
                                        NFT Collection&nbsp;
                                        { sort === 'collectionNameAtoZ' ? 
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#7945E1" 
                                                className="bi bi-arrow-down-circle-fill" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                            </svg>
                                            :
                                            sort === 'collectionNameZtoA' ?
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#7945E1" 
                                                className="bi bi-arrow-up-circle-fill" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#ffffff" 
                                                className="bi bi-arrow-down" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                            </svg>
                                        }
                                    </a>
                                </th>
                                <th scope="col" colSpan="4">Key Links</th>                          
                                <th scope="col">
                                    <a href={mintDateSort(sort, upcomingMints, freeMints, linesFat)} style={{color:"rgba(255,255,255,0.8)", textDecoration:"none"}}>
                                        Mint&nbsp;
                                        { sort === 'mintDateNewToOld' ? 
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#7945E1" 
                                                className="bi bi-arrow-down-circle-fill" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                            </svg>
                                            :
                                            sort === 'mintDateOldToNew' ?
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#7945E1" 
                                                className="bi bi-arrow-up-circle-fill" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#ffffff" 
                                                className="bi bi-arrow-down" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                            </svg>
                                        }
                                    </a>
                                </th>
                                { upcomingMints === 'true' || freeMints === 'true' ?
                                    <>
                                    <th scope="col">
                                        Price
                                    </th>                                    
                                    </>
                                    :
                                    null
                                }
                                { access !== 'public' ?
                                    <th scope="col" style={{minWidth: "110px", width:"110px"}}>
                                        <Link to="/dashboard/allow-lists" style={{color:"rgba(255,255,255,0.8)", textDecoration:"none"}}>
                                            Allowlist
                                        </Link>
                                    </th>
                                    :
                                    null 
                                }

                                <th scope="col" colSpan="1" style={{textAlign:"center"}}>
                                    <a href={likesSort(sort, upcomingMints, freeMints, linesFat)} style={{color:"rgba(255,255,255,0.8)", textDecoration:"none"}}>                                    
                                        { sort === 'likesHighToLow' || sort === undefined ? 
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#7945E1" 
                                                className="bi bi-arrow-down-circle-fill" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                            </svg>
                                            :
                                            sort === 'likesLowToHigh' ?
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#7945E1" 
                                                className="bi bi-arrow-up-circle-fill" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#ffffff" 
                                                className="bi bi-arrow-down" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                                            </svg>
                                        }
                                    </a>
                                </th>

                                <th scope="col"></th>

                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize:".8em"}}>
                            { ( access === 'free' || access === 'public' ) && allNFTsPremiumCount > 0  ?                        
                                <tr style={{backgroundColor:"#280B22", border:"1px #000000 solid", color:"rgba(255,255,255,.8)", textAlign:"center", fontSize:"1.5em"}}>
                                    <td colSpan="12">
                                        Access all {allNFTsPremiumCount} NFTs with Premium.&nbsp;
                                        <Link to="/signup">
                                            <button className="btn btn-sm btn-light" style={{color:"#7945E1"}}>
                                                Upgrade now
                                            </button>
                                        </Link> 
                                    </td>
                                </tr>
                                :
                                null
                            }
                            {pageData.length > 0 ? pageData.map(nft => <TableRowDesktop key={nft.id} nft={nft} access={access} upcomingMints={upcomingMints} freeMints={freeMints} />)  : noSearchResultsDesktop(upcomingMints, freeMints)}
                        </tbody>
                        <tfoot style={{ backgroundColor:"#140510", textAlign: "center", color:"rgba(255,255,255,.8)", border:"1px #000000 solid"}}>
                            <tr>                     
                                <td colSpan="12">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={totalCount}
                                        pageSize={pageSize}
                                        filters={filters}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>  
                }
            </div>
        </div>                
        
        </>
    );
};

function mapStateToProps({ form }) {
    return { form };
}

export default connect(mapStateToProps, actions)(AllNFTs);
