import { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Body = ({productUse}) => {    

    const [personalUse, setPersonalUse] = useState(productUse === 'buyer' || productUse === 'both' ? true : false);  
    const [personalUseBackgroundColor, setPersonalUseBackgroundColor] = useState(productUse === 'personal' || productUse === 'both' ? '#29194A' : 'transparent');   
    
    const [businessUse, setBusinessUse] = useState(productUse === 'builder' || productUse === 'both' ? true : false);  
    const [businessUseBackgroundColor, setBusinessUseBackgroundColor] = useState(productUse === 'business' || productUse === 'both' ? '#29194A' : 'transparent');   

    const updateUse = async (personal, business) => {

        const use = personal && business ? 'both' : personal ? 'buyer' : business ? 'builder' : '';

        try {
            await axios.put(`/api/put/product_use`, { use });                     
        } catch (error) {
            console.error(error.response);
        }

    }
    const updatePersonalUse = (personalUse, businessUse) => {
        
        updateUse(!personalUse, businessUse);
        if ( !personalUse ) {
            setPersonalUseBackgroundColor("#29194A");
        }
        else {
            setPersonalUseBackgroundColor("transparent");
        }
        
        setPersonalUse(!personalUse);        
        
    }

    const updateBusinessUse = (businessUse, personalUse) => {
        
        updateUse(personalUse, !businessUse);
        if ( !businessUse ) {
            setBusinessUseBackgroundColor("#29194A");
        }
        else {
            setBusinessUseBackgroundColor("transparent");
        }
        
        setBusinessUse(!businessUse);
        
    }

    return (
        <>                  

                <div className="row" style={{ paddingTop:"25px"}}>
                    <div className="col-lg-2 col-1"></div>
                    <div className="col-lg-8 col-10">
                        <div className="row" style={{height:"300px"}}>
                            <div className="col-6">
                                <div className="row h-100">
                                    <div className="col-11 border border-secondary rounded p-2 mx-auto" style={{backgroundColor:personalUseBackgroundColor, height:"100%" }} onClick={async() => updatePersonalUse(personalUse, businessUse)}>
                                        <div className="d-md-block d-none">
                                            <div className="row">                            
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" 
                                                    width="64" 
                                                    height="64" 
                                                    fill="#ffffff" 
                                                    className="bi bi-person-fill" 
                                                    viewBox="0 0 16 16">
                                                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                </svg>
                                                <br />
                                                <span style={{fontSize:"3em", textAlign:"center"}} >
                                                    <strong>Buyer</strong>
                                                </span>
                                                
                                            </div>
                                            <div className="row mt-2">
                                                <span>
                                                    <ul>
                                                        <li>NFT benefit alerts</li>
                                                        <li>Upcoming mints</li>
                                                        <li>NFT Research</li>
                                                        <li>Earn points</li>
                                                        <li>Enter Giveaways</li>
                                                        <li>And more!</li>            
                                                    </ul>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-md-none d-block">
                                            <div className="row mt-2">                            
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" 
                                                    width="24" 
                                                    height="24" 
                                                    fill="#ffffff" 
                                                    className="bi bi-person-fill" 
                                                    viewBox="0 0 16 16">
                                                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                </svg>
                                                <br />
                                                <span style={{fontSize:"1.4em", textAlign:"center"}} >
                                                    <strong>Buyer</strong>
                                                </span>
                                                
                                            </div>
                                            <div className="row mt-2">
                                                <span style={{padding:"0px", fontSize:"0.8em"}}>
                                                    <ul style={{padding:"20px"}}>
                                                        <li>NFT benefit alerts</li>
                                                        <li>Upcoming mints</li>
                                                        <li>NFT Research</li>
                                                        <li>Earn points</li>
                                                        <li>Enter Giveaways</li>
                                                        <li>And more!</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row h-100">   
                                    
                                    <div className="col-11 border border-secondary rounded p-2 mx-auto" style={{backgroundColor:businessUseBackgroundColor }} onClick={async() => updateBusinessUse(businessUse, personalUse)}>
                                        <div className="d-md-block d-none">
                                            <div className="row">                            
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" 
                                                    width="64" 
                                                    height="64" 
                                                    fill="#ffffff" 
                                                    className="bi bi-hammer" 
                                                    viewBox="0 0 16 16">
                                                    <path d="M9.972 2.508a.5.5 0 0 0-.16-.556l-.178-.129a5.009 5.009 0 0 0-2.076-.783C6.215.862 4.504 1.229 2.84 3.133H1.786a.5.5 0 0 0-.354.147L.146 4.567a.5.5 0 0 0 0 .706l2.571 2.579a.5.5 0 0 0 .708 0l1.286-1.29a.5.5 0 0 0 .146-.353V5.57l8.387 8.873A.5.5 0 0 0 14 14.5l1.5-1.5a.5.5 0 0 0 .017-.689l-9.129-8.63c.747-.456 1.772-.839 3.112-.839a.5.5 0 0 0 .472-.334z"/>
                                                </svg>
                                                <br />
                                                <span style={{fontSize:"3em", textAlign:"center"}} >
                                                    <strong>Builder</strong>
                                                </span>
                                                
                                            </div>
                                            <div className="row mt-2">
                                                <span>
                                                    <ul>
                                                        <li>Community alerts</li>
                                                        <li>Discover partners</li>
                                                        <li>Reward community</li>
                                                        <li>Benefits History</li>                                                        
                                                        <li>NFT Research</li>
                                                        <li>And more!</li>            
                                                    </ul>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-md-none d-block">
                                            <div className="row mt-2">                            
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" 
                                                    width="24" 
                                                    height="24" 
                                                    fill="#ffffff" 
                                                    className="bi bi-hammer" 
                                                    viewBox="0 0 16 16">
                                                    <path d="M9.972 2.508a.5.5 0 0 0-.16-.556l-.178-.129a5.009 5.009 0 0 0-2.076-.783C6.215.862 4.504 1.229 2.84 3.133H1.786a.5.5 0 0 0-.354.147L.146 4.567a.5.5 0 0 0 0 .706l2.571 2.579a.5.5 0 0 0 .708 0l1.286-1.29a.5.5 0 0 0 .146-.353V5.57l8.387 8.873A.5.5 0 0 0 14 14.5l1.5-1.5a.5.5 0 0 0 .017-.689l-9.129-8.63c.747-.456 1.772-.839 3.112-.839a.5.5 0 0 0 .472-.334z"/>
                                                </svg>

                                                <br />
                                                <span style={{fontSize:"1.4em", textAlign:"center"}} >
                                                    <strong>Builder</strong>
                                                </span>
                                                
                                            </div>
                                            <div className="row mt-2">
                                                <span style={{padding:"0px", fontSize:"0.8em"}}>
                                                    <ul style={{padding:"20px"}}>
                                                        <li>Community alerts</li>
                                                        <li>Discover partners</li>
                                                        <li>Reward community</li>
                                                        <li>Benefits History</li>                                                        
                                                        <li>NFT Research</li>
                                                        <li>And more!</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 
                <div className="row" style={{ marginTop: "30px", marginBottom: "30px", padding:"0.5em" }}>
                    <div className="col-lg-2 col-1"></div>
                    <div className="col-10 col-lg-8 p-0">
                        <Link to="/onboard/step-3">
                            <button className="btn" style={{ backgroundColor: "#7945E1", border: "#7945E1", color:"rgba(255,255,255,1)", width: "100%", height: "50px" }}>
                                Next
                            </button>
                        </Link>
                    </div>
                </div> 
            
        </>
    );
};

export default Body;
