import { useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import Pagination from '../../../pagination/Pagination';
import RelatedHeader from '../RelatedHeader';
import ReviewNav from '../../ReviewNav';

const TableRow = ({ item }) => {

    const { nft_collection_id, relationship_id, associated, primary, nft_collection, create_ts } = item;
    

    const createTsDate = new Date(create_ts);
    const createTsDateConverted = createTsDate.toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });

    return <tr>
        <td><Link to={`/collection/${nft_collection_id}`} style={{color:'#ffffff'}}>{nft_collection}</Link></td>
        <td>{relationship_id}</td>
        <td>{associated}</td>
        <td>{primary}</td>
        <td>{createTsDateConverted}</td>        
    </ tr>;
}

let PageSize = 50;
let filters = '';

const Review = ({ approved, accountType, reviewCount }) => {
    
    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);    

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return approved.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, approved]);

    return (
        <>        
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-2">
                <ReviewNav page="related" accountType={accountType}/>
            </div>
            <div className="col-10">
                <RelatedHeader page="approved" reviewCount={reviewCount}/>                
                <div className="row table-responsive" style={{ marginTop:"20px"}}>
                    <div className="col-12">                    
                    <table className="table" style={{ color:"#ffffff" }}>
                        <thead className="text-nowrap">
                            <tr style={{ backgroundColor:"#1F2731"}}>                                
                                <th scope="col">NFT</th>
                                <th scope="col">Relationship ID</th>
                                <th scope="col">Associated</th>
                                <th scope="col">Primary</th>
                                <th scope="col">Create Date</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize:".8em"}}>
                            {pageData.map(item => <TableRow key={item.id} item={item} />)}
                        </tbody>
                        <tfoot style={{ backgroundColor:"#1F2731", textAlign: "center"}}>
                            <tr>                     
                                <td colSpan="10">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={approved.length}
                                        pageSize={PageSize}
                                        filters={filters}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Review;
