//SurveyNew shows SurveyForm and SurveyReview
import React from 'react';
import { Field, reduxForm } from 'redux-form';
//import { submitRegister } from '../../actions/index';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';
import PromoCodeForm from '../admin/owner/promoCodes/PromoCodeForm';

const renderField = ({ input, className, label, type, meta: { touched, error, warning } }) => (
    <div>
      <label style={{ marginBottom: "5px" }}>{label}</label>
      <div>
        <input {...input} className={className} placeholder={label} type={type} />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
)

const renderBox = ({ input, className, label, type, meta: { touched, error, warning } }) => (
    <div>      
        <input {...input} className={className} placeholder={label} type={type} />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
)

const promoCodeField = () => {
    return (
        <div className="row" style={{ marginTop: "10px" }}>
            <div className="col-12">
                <div className="form-group">
                    <div>
                        <Field
                            className="form-control"
                            name="promoCode"
                            component={renderField}
                            type="text"
                            placeholder=""
                            label="Promo Code"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

const RegisterForm = ({ tokenContractAddress, initialValues, openStoreTokenId, onRegisterSubmit, handleSubmit, pristine, error, reset, submitting, withRouter }) => {
    return <>
        {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
        <form onSubmit={handleSubmit( onRegisterSubmit )} style={{ paddingBottom: "50px" }}>

            <div className="row">
                {/* Hide the label when using a hidden input (uncomment to show label) */}
                {/* <label className="col-md-3 col-xs-4 col-form-label" style={{ color: "#ffffff" }}>Wallet</label> */}
                <div className="col-8">
                    <Field
                        className="form-control-plaintext"
                        style={{ marginBottom: "5px" }}
                        name="account"
                        component="input"
                        type="hidden" readOnly
                        aria-label="readonly account" />
                </div>
            </div>

            <div className="row">
                {/* Hide the label when using a hidden input (uncomment to show label) */}
                {/* <label className="col-md-3 col-xs-4 col-form-label" style={{ color: "rgba(255,255,255,0.8)" }}>NFT</label> */}
                <div className="col-8">
                    <Field
                        className="form-control-plaintext"
                        style={{ marginBottom: "5px" }}
                        name="tokenContractAddress"
                        component="input"
                        type="hidden" readOnly
                        aria-label="readonly tokenContractAddress"
                        value={tokenContractAddress} />
                </div>
            </div>

            <div className="row">
                {/* Hide the label when using a hidden input (uncomment to show label) */}
                {/* <label className="col-md-3 col-xs-4 col-form-label" style={{ color: "#ffffff" }}>OpenStore Token ID</label> */}
                <div className="col-8">
                    <Field
                        className="form-control-plaintext"
                        style={{ marginBottom: "5px" }}
                        name="openStoreTokenId"
                        component="input"
                        type="hidden" readOnly
                        aria-label="readonly openStoreTokenId"
                        value={openStoreTokenId} />
                </div>
            </div>
            <div className="d-block d-lg-none">
                <div className="row">
                    <div className="col-1"></div>
                        <div className="col-10">
                        {error && <span className="text-danger">{error}</span>}
                    </div>
                </div>
                <div className="row" >
                    <div className="col-1"></div>
                    <div className="col-10">                        

                            <div className="row" style={{marginTop:"20px"}}>
                                <div className="col-12">
                                    <div className="form-group">
                                        <div>
                                            <Field
                                                className="form-control"
                                                name="username"
                                                component={renderField}
                                                type="text"
                                                placeholder=""
                                                label="Username"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>                    


                            <div className="row" style={{ marginTop: "10px" }}>
                                <div className="col-12">
                                    <div className="form-group">
                                        <div>
                                            <Field
                                                className="form-control"
                                                name="email"
                                                component={renderField}
                                                type="text"
                                                placeholder=""
                                                label="Email (not required)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {initialValues.tokenContractAddress === 'none' ? promoCodeField() : null}

                            <div className="row" style={{ marginTop: "10px" }}>
                                <div className="col-1">
                                    <div className="form-group">
                                        <div>
                                            <Field
                                                className="form-check-input"
                                                name="termsOfService"
                                                component={renderBox}
                                                type="checkbox"
                                                placeholder=""
                                                label=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-11">
                                    I agree to the <a href="/privacy" rel="noreferrer" target="_BLANK">Privacy Policy</a> and <a href="/tos" rel="noreferrer" target="_BLANK">Terms of Service</a>.
                                </div>
                            </div>



                            <div className="row" style={{ marginTop: "30px", marginBottom: "30px" }}>
                                <div className="col-md-12">
                                    <button className="btn" style={{ backgroundColor: "#7945E1", border: "#7945E1", color:"rgba(255,255,255,1)", width: "100%", height: "50px" }} disabled={pristine || submitting}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                    </div>
                </div>
                <div style={{height:"50px"}}>

                </div>
            </div>
            <div className="d-lg-block d-none">
                <div className="row">
                    <div className="col-2"></div>
                        <div className="col-8">
                        {error && <span className="text-danger">{error}</span>}
                    </div>
                </div>
                <div className="row" >
                    <div className="col-2"></div>
                    <div className="col-8">
                        

                            <div className="row" style={{marginTop:"20px"}}>
                                <div className="col-12">
                                    <div className="form-group">
                                        <div>
                                            <Field
                                                className="form-control"
                                                name="username"
                                                component={renderField}
                                                type="text"
                                                placeholder=""
                                                label="Username"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>                    


                            <div className="row" style={{ marginTop: "10px" }}>
                                <div className="col-12">
                                    <div className="form-group">
                                        <div>
                                            <Field
                                                className="form-control"
                                                name="email"
                                                component={renderField}
                                                type="text"
                                                placeholder=""
                                                label="Email (not required)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {initialValues.tokenContractAddress === 'none' ? promoCodeField() : null}

                            <div className="row" style={{ marginTop: "10px" }}>
                                <div className="col-1">
                                    <div className="form-group">
                                        <div>
                                            <Field
                                                className="form-check-input"
                                                name="termsOfService"
                                                component={renderBox}
                                                type="checkbox"
                                                placeholder=""
                                                label=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-11">
                                    I agree to the <a href="/privacy" rel="noreferrer" target="_BLANK">Privacy Policy</a> and <a href="/tos" rel="noreferrer" target="_BLANK">Terms of Service</a>.
                                </div>
                            </div>



                            <div className="row" style={{ marginTop: "30px", marginBottom: "30px" }}>
                                <div className="col-md-12">
                                    <button className="btn" style={{ backgroundColor: "#7945E1", border: "#7945E1", color:"rgba(255,255,255,1)", width: "100%", height: "50px" }} disabled={pristine || submitting}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        
                    </div>
                </div>
                <div style={{height:"150px"}}>

                </div>
            </div>
        </form>
        </>
    
  }


const validate = values => {
    // console.log('values', values)
    const errors = {}
    if (!values.termsOfService) {
        errors.termsOfService = 'Required'
       }

    if (!values.username) {
      errors.username = 'Required'
     } //else if (values.username.length > 15) {
    //   errors.username = 'Must be 15 characters or less'
    // }

    if (values.username) {
        if ( values.username.search(/\s/) > -1 ) {
            errors.username = 'No spaces allowed'
        }
    }
    
    if (values.email && !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    // console.log('errors', errors)
    return errors
}


function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'registerForm',
    validate,
})(RegisterForm));
