import React, { Component, Fragment, useState } from "react";
import { Field, reduxForm, propTypes } from 'redux-form';
import RenderField from './RenderSearchField'

import axios from 'axios';

export default ({ fieldName, hiddenField, label, placeholder, endpoint } ) => {  

    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [userInput, setUserInput] = useState(undefined);    

    const fetchCollectionData = async (searchTerm) => {
        
        const url = endpoint + searchTerm;

        const response = await axios.get(url, {
            validateStatus: function (status) {
                return status < 500; // Resolve only if the status code is less than 500
            }
        });
        
        let newCollectionNames;
        let newCollectionImages;
        let newCollectionWarnings;
        let newCollectionId; 
        let suggestionZip = [];

        for (let i = 0; i < response.data.length; i++) {
            newCollectionNames = response.data[i].nft_collection;
            newCollectionId = response.data[i].id;
            // if collection image is null use placeholder
            if (response.data[i].collection_image){
                newCollectionImages = response.data[i].collection_image;
            }
            else{
                newCollectionImages = '';
            }

            if (response.data[i].warning === 'Caution' || response.data[i].warning === 'Avoid'){
                newCollectionWarnings = response.data[i].warning;
            }
            else{
                newCollectionWarnings = '';
            }

            suggestionZip.push( [ newCollectionNames, newCollectionImages, newCollectionWarnings, newCollectionId  ] );
        }         

        setFilteredSuggestions(suggestionZip);    
           
    }
    const onChange = async e => {    
                
        await fetchCollectionData(e.currentTarget.value);        

        setActiveSuggestion(0);
        
        if ( e.currentTarget.value !== '' ) {
            setShowSuggestions(true);
        }
        else {
            setShowSuggestions(false);
        }

        document.getElementsByName(hiddenField)[0].value = '';
        //setUserInput(e.currentTarget.value);       
    };

    const onMouseDown = e => {
        setActiveSuggestion(0);
        setFilteredSuggestions([]);
        setShowSuggestions(false);

        if ( e.target.childNodes[0].nodeName === 'IMG' ) {
            document.getElementsByName(hiddenField)[0].value = e.target.childNodes[1].innerText;
        }
        else {
            document.getElementsByName(hiddenField)[0].value = e.target.childNodes[0].innerText;
        }
        
        document.getElementsByName(fieldName)[0].value = e.currentTarget.innerText;        
        
    };

    const onBlur = () => {
        setShowSuggestions(false);
    }

    const onKeyDown = e => {

        // User pressed the up arrow
        if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            setActiveSuggestion(activeSuggestion - 1);
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            setActiveSuggestion( activeSuggestion + 1 );
        }
    };

    const SuggestionsListComponent = () => {        

        let suggestionsListComponent;
        //console.log( userInput );
        
        if (showSuggestions) {
            
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                <ul className="suggestionsNavbarMerge" style={{position:"relative", zIndex:"99"}}>
                    {filteredSuggestions.map((suggestion, index) => {
                        let className;

                        // Flag the active suggestion with a class
                        if (index === activeSuggestion) {
                            className = "suggestion-active";
                        } 
                        //console.log(index);
                            return (
                                <li className={className} key={index} onMouseDown={onMouseDown}>
                                    {filteredSuggestions[index][1] !== '' ? <img src={filteredSuggestions[index][1]} width="25" height="25"></img> : null}
                                    
                                    <span id="collectionIdValue" hidden>{filteredSuggestions[index][3]}</span>{filteredSuggestions[index][0]} {filteredSuggestions[index][2] === 'Caution' ? <>&nbsp;<img alt="" src="/images/cautionSymbol.png" style={{width:"20px"}} /></> : filteredSuggestions[index][2] === 'Avoid' ? <>&nbsp;<img alt="" src="/images/avoidSymbol.png" style={{width:"20px"}} /></> : null}
                                </li>)
                        })}
                </ul>
                );
            } else {                
                document.getElementsByName(hiddenField)[0].value = '';
                suggestionsListComponent = (
                    <ul className="no-suggestions-merge" style={{position:"relative", zIndex:"99"}}>
                        <li>Collection not found</li>
                    </ul>
                );
            }
        }
        
        return suggestionsListComponent;
        
    }    
    
    //value1={userInput}
    return (
        <>
        <Field
            name={fieldName}
            className="form-control"
            type="text"
            component={RenderField}
            onChange={onChange}
            onKeyDown={onKeyDown}            
            value1={userInput}
            label={label}
            placeholder={placeholder}
            onBlur={onBlur}
            
            
        />
        {SuggestionsListComponent()}        
        

        <input type="hidden" name={hiddenField} />
        </>
    );  
}