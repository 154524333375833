
const ProgressIndicator = ({ current, total }) => {

    let progressIndicators = [];
    for ( let i=1; i <= total; ++i) {

        if ( i === current ) {

            progressIndicators.push( 
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="30" 
                        height="30" 
                        fill="#7945E1" 
                        className="bi bi-dot d-inline" 
                        viewBox="0 0 26 16">
                        <path d="M8 9.5a5.5 5.5 0 1 0 0-3 5.5 5.5 0 0 0 0 3z"/>
                    </svg>
            );
        }
        else {
            progressIndicators.push(
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="30" 
                        height="30" 
                        fill="rgba(255,255,255,0.6)" 
                        className="bi bi-dot d-inline" 
                        viewBox="0 0 26 16">
                        <path d="M8 9.5a5.5 5.5 0 1 0 0-3 5.5 5.5 0 0 0 0 3z"/>
                    </svg>
            );
        }
    }

    return <>
    <div className="row text-center d-inline p-0 m-0">
        <div className="col-12">
            {progressIndicators}
        </div>
    </div>
        </>    

}
export default ProgressIndicator;