import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import axios from 'axios';

import useTokenGatedAccess from '../../../hooks/useTokenGatedAccess';
import PleaseSignIn from '../../access/PleaseSignIn';

import GiveawayCard from '../GiveawayCard';
import GiveawaysHeader from '../GiveawaysHeader';

const SignupMain = ({ auth, wallet, registeredUser, form }) => {        

    const { account } = wallet; // use the account from the wallet store object    

    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, _walletAccessTokens } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars      

    const [giveawaysData, setGiveawaysData] = useState([]);

    useEffect(() => {
        const fetchGiveawaysData = async () => {
            const response = await axios.get("/api/get/giveaways/upcoming", {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            
            setGiveawaysData(response.data);
        }
        
        fetchGiveawaysData();
    }, [])
    
    const [delayComplete, setDelayComplete] = useState(false);     

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const delayPublic = async () => {
        await delay(1000);
        //console.log("Waited 5s");
        setDelayComplete(true);
      };
    delayPublic();    

    if (!auth && registeredUser.username) {
        return <PleaseSignIn />;
    }
    else {
        return (
            <div>            
                {/* In lieu of a redirect, provide a link to the register page. */}
                {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
                {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
                {/* If it did, there is a risk of infinite redirects */}
                {/*renderSignup()*/}
                <GiveawaysHeader page="upcoming" entries={registeredUser.current_giveaway_entries} account={account} />
                <div className="d-lg-block d-none" >
                    <div className="row">
                        {giveawaysData.length > 0 ? giveawaysData.map(giveawayData => <GiveawayCard key={giveawayData.id} giveawayData={giveawayData} page="upcoming"  />) : null}                    
                    </div>
                </div>
                <div className="d-block d-lg-none" style={{paddingBottom:"100px"}}>
                    <div className="row">
                        {giveawaysData.length > 0 ? giveawaysData.map(giveawayData => <GiveawayCard key={giveawayData.id} giveawayData={giveawayData} page="upcoming"  />) : null}                    
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ auth, wallet, registeredUser, form }) {
    return { auth, wallet, registeredUser, form };
}

export default connect(mapStateToProps)(SignupMain);
