import { useState, useMemo, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import axios from 'axios';

import AllowListButtons from './AllowListButtons';
import DashboardHeader from '../DashboardHeader';
import Pagination from '../../pagination/Pagination';

let PageSize = 50;

const TableRow = ({ nft }) => {


    const { contract_id, collection_name, collection_image, collection_slug, have, want, earliest_mint_date, nft_collection_id } = nft;        

    //make sure that the user is taken to the top of the page each time it loads
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])    
    
    const renderCollectionImage = ( collection_image, collection_slug ) => {

        if ( collection_slug !== null ) {
            const collectionPageURL = '/collection/' + collection_slug;
            return <>
                <Link to={{ pathname: collectionPageURL}} style={{ color:"rgba(255,255,255,.8)"}}>
                    <img
                        alt=""
                        src={collection_image}
                        height="30"
                        className="d-inline-block align-top rounded"
                    /> 
                </Link>
            </>;
        }
        else {
            return <>
                <img
                    alt=""
                    src={collection_image}
                    height="30"                    
                    className="d-inline-block align-top rounded"
                /> 
            </>;
        }
    }

    const renderCollectionName = ( collection_name, collection_slug ) => {

        if ( collection_slug !== null ) {
            const collectionPageURL = '/collection/' + collection_slug;
            return <>
                <Link to={{ pathname: collectionPageURL}} style={{ color:"rgba(255,255,255,.8)"}}>
                    {collection_name}
                </Link>
            </>;
        }
        else {
            return <>
                {collection_name}
            </>;
        }
    }

    return <>
    <tr style={{backgroundColor:"#280B22", border:"1px #000000 solid", color:"rgba(255,255,255,.8)"}}>
        <AllowListButtons id={nft_collection_id} />
        
        <td className="d-block d-md-none inline">
            <div className="row">
                <div className="col-3">
                    {renderCollectionImage( collection_image, collection_slug )}
                </div>
            
                <div className="col-9">                                
                
                    <span className="d-block d-md-none inline" style={{fontSize:"1em", maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", border:"1px #280B22 solid"}}>
                        {renderCollectionName( collection_name, collection_slug )}
                    </span>
                </div>
            </div>
        </td>
        <td className="d-md-block d-none inline">
            <div className="row">
                <div className="col-1">
                    {renderCollectionImage( collection_image, collection_slug )}
                </div>
            
                <div className="col-11">
                    <span className="d-md-block d-none inline" style={{fontSize:"1.25em", maxWidth: "600px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", border:"1px #280B22 solid"}}>
                        {renderCollectionName( collection_name, collection_slug )}
                    </span>                    
                </div>
            </div>
        </td>
        <td>{new Date(earliest_mint_date).toLocaleDateString("en-US", { timeZone: "UTC", weekday: 'short', month:'short', year:'numeric', day:'numeric' })}</td>
    </tr>
    </>;
}

const AllowLists = ({ initialUserNFTs }) => {    
 
    const [totalCount, setTotalCount] = useState( initialUserNFTs.length );    
    const [userNFTs, setUserNFTs] = useState([initialUserNFTs]);
    const filters = '';
    
    useEffect(() => {
        
        const fetchUserNFTs = async () => {
            const response = await axios.get('/api/get/dashboard/allowlists', {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setUserNFTs(response.data[0]);
            setTotalCount(response.data[0].length);
        }
        fetchUserNFTs()
    }, []);

    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);   

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;        
        return userNFTs.slice(firstPageIndex, lastPageIndex);        
    }, [currentPage, userNFTs]);
    
    return (
        <>
                
        <DashboardHeader page='allowLists' />
        <div className="row" style={{marginTop:"10px"}}>                                         

            <div className="col-12 table-responsive text-nowrap" style={{ padding:"10px"}} >                
                <table className="table" style={{ borderRadius: "1em", borderCollapse: "collapse", overflow:"hidden", width:"100%" }}>
                    <thead>                        
                        <tr style={{ backgroundColor:"#29194A", borderBottom:"2px #000000 solid", color:"rgba(255,255,255,.8)"}}>
                            <th>Have</th>
                            <th>Want</th>
                            <th scope="col">Collection</th>
                            <th>Mint Date</th>
                        </tr>
                    </thead>
                    <tbody style={{ fontSize:".8em"}}>
                        {pageData.length > 0 ? pageData.map(nft => <TableRow key={nft.contract_id} nft={nft}  />) : null}
                    </tbody>
                    <tfoot style={{ backgroundColor:"#140510", textAlign: "center", color:"rgba(255,255,255,.8)", border:"1px #000000 solid"}}>
                        <tr>                     
                            <td colSpan="4">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={totalCount}
                                    pageSize={PageSize}
                                    filters={filters}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>                
        
        </>
    );
};

function mapStateToProps({ form }) {
    return { form };
}

export default connect(mapStateToProps, actions)(AllowLists);
