import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import useTokenGatedAccess from '../../../hooks/useTokenGatedAccess';

// components
import PleaseConnectWallet from '../../access/PleaseConnectWallet';
import PleaseWait from '../../access/PleaseWait';
import PleaseSignIn from '../../access/PleaseSignIn';
import PleaseRegister from '../../access/PleaseRegister';
import AccessDenied from '../../access/AccessDenied';
import SomethingWentWrong from '../../access/SomethingWentWrong';
import NotificationsPage from './NotificationsPage';

const NotificationsMain = ({ auth, wallet, registeredUser }) => {

    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determine if this account has access
    const { access } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars

    const [notifications, setNotifications] = useState([]);  
    const [dailySummaryCheckedInitial, setDailySummaryCheckedInitial] = useState(false);           

    useEffect(() => {
        const fetchNotifications = async () => {
            const response = await axios.get("/api/get/notifications", {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setNotifications(response.data);     
            for ( let i=0; i < response.data.length; ++i ) {
                if ( response.data[i].template === 'Daily Summary' ) {
                    setDailySummaryCheckedInitial( response.data[i].opt_in );
                }
            }       
        }
        if (auth) {
            fetchNotifications()
        }
    }, [auth])

    const renderNotifications = () => {
        switch(access) {
            case null:
                return <PleaseWait />;
            case false:
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <PleaseRegister />; // when the PleaseSignIn component renders, the user will be prompted to sign a message
                if (registeredUser.username) { 
                    return <NotificationsPage registeredUser={registeredUser} account={account} notifications={notifications} dailySummaryCheckedInitial={dailySummaryCheckedInitial} />;
                }
                else { return <PleaseRegister />  };
            case true:
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <PleaseRegister />; // when the PleaseSignIn component renders, the user will be prompted to sign a message
                if (registeredUser.username) { 
                    return <NotificationsPage registeredUser={registeredUser} account={account} notifications={notifications} dailySummaryCheckedInitial={dailySummaryCheckedInitial} />;
                }
                else { return <PleaseRegister />  };
            default:
                return <SomethingWentWrong />;
        }
    }

    return (
        <div>            
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderNotifications() }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser, fetchRegisteredUser }) {
    return { auth, wallet, registeredUser, fetchRegisteredUser };
}

export default connect(mapStateToProps)(NotificationsMain);
