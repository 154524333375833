import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const RenderAllowList = ({ id, access, mobile }) => {

    const [allowlist, setAllowlist] = useState({have:false, want:false});        

    useEffect(() => {
        const fetchAllowlist = async () => {
            const response = await axios.get(`/api/fetch-allowlist/${id}`);
            setAllowlist(response?.data);
        }
        if ( access !== 'public' ) {
            fetchAllowlist();
        }
    }, [id, access])

    const updateAllowlist = async (id, updatedAllowlist, access) => {
        if ( access !== 'public ') {
            try {
                const response = await axios.put(`/api/allowlist/${id}`, { updatedAllowlist });         
                return response.data.updatedAllowlist;
                
            } catch (error) {
                console.error(error.response);
            }
        }
        
        return '';
    }    

    let defaultValue = 'none';    
    
    if ( allowlist?.have === true ) {
        defaultValue = 'have';
    }
    else if ( allowlist?.want === true ) {
        defaultValue = 'want';
    }

    let formSelect = 'form-select';
    let fontSize = '1em';
    let paddingLeft = '12px';
    
    if ( mobile === 'true' ) {
        formSelect += '-sm';
        fontSize = '0.9em';
        paddingLeft = '0px'
    }

    if ( access !== 'public' ) {
        return <>        
            <select     className={formSelect}
                        id={id}
                        onChange={async (e) => {
                            if ( e.target.value === 'have' ) {
                                const newAllowlist = await updateAllowlist(id, { have: true, want: false });
                                setAllowlist(newAllowlist);
                            }
                            else if ( e.target.value === 'want' ) {
                                const newAllowlist = await updateAllowlist(id, { have: false, want: true });
                                setAllowlist(newAllowlist);
                            }
                            else if ( e.target.value === 'none' ) {
                                const newAllowlist = await updateAllowlist(id, { have: false, want: false });
                                setAllowlist(newAllowlist);
                            }
                        }}
                        value={defaultValue}
                        style={{fontSize:fontSize, paddingLeft: paddingLeft }}
            >
                <option value="none"></option>
                <option value="have">{mobile === 'true' ? 'Have AL' : 'Have'}</option>
                <option value="want">{mobile === 'true' ? 'Want AL' : 'Want'}</option>
            </select>
        </>;
    }
    else {
        return '';
    }
    
}

export default RenderAllowList;