import { useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import Pagination from '../../pagination/Pagination';
import OpenseaHeader from './Header';
import ReviewNav from '../ReviewNav';

import NFTWithContractAutocomplete from "./NFTWithContractAutocomplete";

const PageContent = ({ accountType }) => {

    const [mergedStatus, setMergedStatus] = useState(null);

    const renderNFTWithContract = () => {
        const initialValues = {};
        const url = "/all-nfts?warnings=true";

        return <>
                        
            <div className="row">
                <NFTWithContractAutocomplete 
                    initialValues={initialValues}
                    onSubmit={
                        async (values) => {
                            try {
                                
                                
                                //console.log(values.nftWithContractId);  
                                //console.log(values.nftWithoutContractId);                                
                                
                                const response = await axios.post(`/api/post/merge`, {nftWithContractId: values.nftWithContractId, nftWithoutContractId: values.nftWithoutContractId}, {
                                    validateStatus: function (status) {
                                        return status < 500; // Resolve only if the status code is less than 500
                                    }
                                });      
                                
                                setMergedStatus(response.data.status);
                                                                
                                
                            } catch (error) {
                                // This should be visible in FS and (hopefully) alert FS of the issue
                                console.error(error.response)
                            }
                        }
                    }
                />
            </div>
            
        </>;
    }

    return (
        <>
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-2">
                <ReviewNav page="merge" accountType={accountType}/>
            </div>
            <div className="col-10">            
                <OpenseaHeader />
                    
                {mergedStatus !== 'merged' ? renderNFTWithContract() : <>The NFTs are merged. Refresh to merge another pair. And for Pete's sake, go make sure things worked correctly!</>}
                
            </div>
        </div>
        </>
    );
};

export default PageContent;
