import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const AllowListButtons = ({ id }) => {

    const [allowlist, setAllowlist] = useState({have:false, want:false});        

    useEffect(() => {
        const fetchAllowlist = async () => {
            const response = await axios.get(`/api/fetch-allowlist/${id}`);
            setAllowlist(response?.data);
        }
        
        fetchAllowlist();
    }, [id])

    const updateAllowlist = async (id, updatedAllowlist) => {
        try {
            const response = await axios.put(`/api/allowlist/${id}`, { updatedAllowlist });         
            return response.data.updatedAllowlist;
            
        } catch (error) {
            console.error(error.response);
        }
        
    }

    const HaveAllowlist = ({ allowlist }) => {
        return <>
            <button 
                className="btn btn-sm btn-success"
                style={{padding:"4px"}}
                onClick={async () => {
                    const newAllowlist = await updateAllowlist(id, { have: false, want: !!allowlist?.want });
                    setAllowlist(newAllowlist);                     
                }}
            >
                <i className="bi bi-check-square-fill"></i>
            </button>
        </>;
    }

    const WantAllowlist = ({ allowlist }) => {
        return <>
            <button 
                className="btn btn-sm btn-success"
                style={{padding:"4px"}}
                onClick={async () => {
                    const newAllowlist = await updateAllowlist(id, { have: !!allowlist?.have, want: false });
                    setAllowlist(newAllowlist);
                }}
            >
                <i className="bi bi-check-square-fill"></i>
            </button> 
        </>
    }

    const DontHaveAllowlist = ({ allowlist }) => {
        return <>
            <button 
                className="btn btn-sm btn-secondary"
                style={{padding:"4px"}}
                onClick={async () => {
                    const newAllowlist = await updateAllowlist(id, { have: true, want: !!allowlist?.want });
                    setAllowlist(newAllowlist);      
                }}
            >
                <i className="bi bi-square"></i>
            </button>
        </>;
    }

    const DontWantAllowlist = ({ allowlist }) => { 
        return <>
            <button 
                className="btn btn-sm btn-secondary"
                style={{padding:"4px"}}
                onClick={async () => {
                    const newAllowlist = await updateAllowlist(id, { have: !!allowlist?.have, want: true });
                    setAllowlist(newAllowlist);
                }}
            >
                <i className="bi bi-square"></i>
            </button> 
        </>
    }

    
    if ( !!allowlist?.have && !!allowlist?.want ) {
        return <>
           <td><HaveAllowlist allowlist={allowlist}/></td>
           <td><WantAllowlist allowlist={allowlist}/></td>
        </>;
    }
    else if ( !allowlist?.have && !!allowlist?.want ) {
        return <>
            <td><DontHaveAllowlist allowlist={allowlist}/></td>
            <td><WantAllowlist allowlist={allowlist}/></td>
        </>;
    }
    else if ( !!allowlist?.have && !allowlist?.want ) {
        return <>
            <td><HaveAllowlist allowlist={allowlist}/></td>
            <td><DontWantAllowlist allowlist={allowlist}/></td>
        </>;
    }
    else if ( !allowlist?.have && !allowlist?.want ) {
        return <>
            <td><DontHaveAllowlist allowlist={allowlist}/></td>
            <td><DontWantAllowlist allowlist={allowlist}/></td>
        </>;
    }
    
    
}

export default AllowListButtons;