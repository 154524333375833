const LinkWalletsHeader = () => {
    return (
        <>
        <div className="row">            
            <div className="col-12" style={{ fontSize: "2em"}}>
                Link Wallets
            </div>
        </div>
        <div className="row">            
            <div className="col-12" style={{ fontSize: "1em"}}>
                You can login with any linked wallet, including an empty wallet.
                <br />We encourage you to connect valuable wallets ONCE, so that we can send you benefit alerts on your NFTs.
                <br />Wallets that are already connected to another account in any status (primary, linked, and pending) cannot be added to your account.
            </div>
        </div>
        </>
    );
};

export default LinkWalletsHeader;
