import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import axios from 'axios';

import useTokenGatedAccess from '../../../../hooks/useTokenGatedAccess';
import useBenefitsTypes from '../../../../hooks/useBenefitsTypes';

// components
import PleaseConnectWallet from '../../../access/PleaseConnectWallet';
import PleaseWait from '../../../access/PleaseWait';
import PleaseSignIn from '../../../access/PleaseSignIn';
import PleaseRegister from '../../../access/PleaseRegister';
import AccessDenied from '../../../access/AccessDenied';
import SomethingWentWrong from '../../../access/SomethingWentWrong';

import GiveawayAdd from './GiveawayAdd';

const GiveawayAddMain = ({ auth, wallet, registeredUser, form }) => {

    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determine if this account has access
    const { access } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars
    const { benefitsTypes } = useBenefitsTypes();  

    const [benefitData, setBenefitData] = useState([]);  
    const benefit_id = useParams().benefit_id;     

    const renderGiveawayAdd = () => {
        if (!account) return <PleaseConnectWallet />;

        switch(access) {
            case null:
                return <PleaseWait />;
            case false:
                return <AccessDenied />;
            case true:
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <PleaseRegister />; // when the PleaseSignIn component renders, the user will be prompted to sign a message
                if (auth.account_type === 'user' || auth.account_type === 'guest' ) return <Redirect to="/dashboard/home" />;
                if (registeredUser.username) { 
                    return <GiveawayAdd accountType={auth.account_type}/>;
                }
                else { return <PleaseRegister />  };
            default:
                return <SomethingWentWrong />;
        }
    }

    return (
        <div>            
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderGiveawayAdd() }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser, form }) {
    return { auth, wallet, registeredUser, form };
}

export default connect(mapStateToProps)(GiveawayAddMain);
