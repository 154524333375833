import { useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import Pagination from '../../../pagination/Pagination';
import ReviewRaffleHeader from '../ReviewRaffleHeader';
import ReviewNav from '../../ReviewNav';

const updateStatus = async (id, status) => {
    try {
        const response = await axios.put(`/api/admin/raffles/${id}/update_status`, { status });
        return response.data;
    } catch (error) {
        console.error(error.response)
    }
}

const TableRow = ({ raffleData }) => {

    const { id, nft_collection_id, nft_collection, partner, quantity, register_by_date, register_by_time, signup_link, raffle_status, submitted_ts, submitted_username, submitted_user_id } = raffleData;
    const [status, setStatus] = useState(raffleData.approval_status ? 'approved' : 'not approved');    
    const [reviewed_by, setReviewedBy] = useState(raffleData.reviewed_username);

    const badgeModifierClassName = {
        "not approved": "bg-danger",
        "approved": "bg-success"
    };

    const ApproveButton = () => {
        return <button
            onClick={async () => {
                const data = await updateStatus(id, 'approved');
                setStatus(data.status ? 'approved' : 'not approved');
                setReviewedBy(data.reviewed_username);
            }}
            type="button" className="btn btn-success btn-sm me-2">
            &#x2713;
        </button>;
    }

    const DisapproveButton = () => {
        return <button
            onClick={async () => {
                const data = await updateStatus(id, 'not approved');
                setStatus(data.status ? 'approved' : 'not approved');
                setReviewedBy(data.reviewed_username);
            }}
            type="button" className="btn btn-danger btn-sm">
            X
        </button>;
    }

    const renderActionsButtons = () => {
        switch(status) {            
            case 'not approved':
                return <ApproveButton />;
            case 'approved':
                return <div className="float-end"><DisapproveButton /></div>;
            default:
                return null;
        }
    }

    const convertTime = (time) => {
        if (time !== null && time !== undefined) {
            const timeSplit = time.split(':');  
    
            let hour = timeSplit[0] % 12;
            if (hour === 0) { hour = 12 };
    
            let timePeriod;
            if (timeSplit[0] / 12 >= 1) { timePeriod = ' PM' } else { timePeriod = ' AM'}
    
            return hour + ':' + timeSplit[1] + timePeriod + ' ET';
        }
        return 'TBD';
    }

    const registerByDate = new Date(register_by_date);
    const registerByDateConverted = registerByDate.toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });

    const dateSubmitted = new Date(submitted_ts);
    const dateSubmittedConverted = dateSubmitted.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });    

    return <tr>
        <td style={{width:"100px", minWidth:"100px"}}>{renderActionsButtons()}</td>
        <td>{submitted_username}</td>
        <td>{reviewed_by}</td>
        <td><Link to={`/collection/${nft_collection_id}`} style={{color:'#ffffff'}}>{nft_collection}</Link></td>
        <td>{registerByDateConverted}<br />{convertTime(register_by_time)}</td>
        <td>{dateSubmittedConverted}</td>
        <td>Partner: {partner}<br />Quantity: {quantity}<br />Link: {signup_link}</td>
        <td><span className={`badge ${badgeModifierClassName[status]} text-capitalize`}>{status}</span></td>
    </ tr>;
}

let PageSize = 50;
let filters = '';

const ReviewRaffles = ({ rafflesData, automatedRafflesCount, accountType }) => {
    
    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);    

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return rafflesData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, rafflesData]);

    return (
        <>
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-2">
                <ReviewNav page="raffles" accountType={accountType}/>
            </div>
            <div className="col-10">            
                <ReviewRaffleHeader page="userInput" automatedRafflesCount={automatedRafflesCount} />
                <div className="row table-responsive text-nowrap" style={{ marginTop:"20px"}}>
                    <div className="col-12"></div>
                    <div className="col-12">
                    <table className="table" style={{ color:"#ffffff" }}>
                        <thead>
                            <tr style={{ backgroundColor:"#1F2731"}}>
                                <th scope="col" style={{width:"100px", minWidth:"100px"}}>Action</th>
                                <th scope="col">User</th>
                                <th scope="col">Reviewed By</th>
                                <th scope="col">NFT Collection</th>
                                <th scope="col">Register by</th>
                                <th scope="col">Date Submitted</th>
                                <th scope="col">Raffle Data</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize:".8em"}}>
                            {pageData.map(raffleData => <TableRow key={raffleData.id} raffleData={raffleData} />)}
                        </tbody>
                        <tfoot style={{ backgroundColor:"#1F2731", textAlign: "center"}}>
                            <tr>                     
                                <td colSpan="8">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={rafflesData.length}
                                        pageSize={PageSize}
                                        filters={filters}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default ReviewRaffles;
