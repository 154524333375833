import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import axios from 'axios';

import useTokenGatedAccess from '../../hooks/useTokenGatedAccess';
import useBenefitsTypes from '../../hooks/useBenefitsTypes';

// components
import PleaseConnectWallet from '../access/PleaseConnectWallet';
import PleaseWait from '../access/PleaseWait';
import PleaseRegister from '../access/PleaseRegister';
import PleaseSignIn from '../access/PleaseSignIn';
import AccessDenied from '../access/AccessDenied';
import CollectionPage from './collectionPage';

const CollectionPageMain = ({ auth, wallet, registeredUser }) => {    

    const { account } = wallet; // use the account from the wallet store object
    
    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, _walletAccessTokens } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars    

    const [collectionData, setCollectionData] = useState([]);   
    const [likeData, setLikeData] = useState([]);   
    const [dislikeData, setDislikeData] = useState([]);   
    const [publicUser, setPublicUser] = useState(false);      
    const [raffleData, setRaffleData] = useState([]);
    const [benefitsData, setBenefitsData] = useState([]);
    const [relatedNFTsData, setRelatedNFTsData] = useState([]);
    const [collectionID, setCollectionID] = useState(false);  
    
    
    const collectionValue = useParams().nft_collection_value;

    const { benefitsTypes } = useBenefitsTypes();      

    useEffect(() => {
        const fetchCollectionID = async () => {
            const response = await axios.get(`/api/get/collection/id/${collectionValue}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            //console.log("response", response.data);
            setCollectionID(response.data);            
        }

        const fetchCollectionData = async () => {
            const response = await axios.get(`/api/collection/${collectionID}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            //console.log("response", response.data);
            setCollectionData(response.data);            
        }
        const fetchLikeData = async () => {
            const response = await axios.get(`/api/likes/${collectionID}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            //console.log("response", response.data);
            setLikeData(response.data);            
        }
        const fetchDislikeData = async () => {
            const response = await axios.get(`/api/dislikes/${collectionID}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            //console.log("response", response.data);
            setDislikeData(response.data);            
        }
        const fetchRaffleData = async () => {
            const response = await axios.get(`/api/get/raffles/${collectionID}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            //console.log("response", response.data);
            setRaffleData(response.data[0]);            
        }
        const fetchBenefitsData = async () => {
            const response = await axios.get(`/api/get/benefits/${collectionID}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            //console.log("response", response.data);
            setBenefitsData(response.data[0]);            
        }
        const fetchRelatedNFTsData = async () => {
            const response = await axios.get(`/api/get/related-nfts/${collectionID}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            //console.log("response", response.data[0]);
            setRelatedNFTsData(response.data[0]);            
        }        

        fetchCollectionID(collectionValue);
        if ( collectionID ) {
            fetchCollectionData();
            fetchLikeData();
            fetchDislikeData();
            fetchRaffleData();
            fetchBenefitsData();
            fetchRelatedNFTsData();
        }
    }, [auth, collectionID, collectionValue])

    //console.log(collectionData);

    //this avoids a flicker where the public info is shown just before the logged in
    //user's info is recognized which changes the info on the page to the appropriate details
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const delayPublic = async () => {
        await delay(1000);
        //console.log("Waited 5s");
        setPublicUser(true);
      };
    delayPublic();

    const renderCollectionPage = () => {
        if (!account && publicUser) {
            return ( <CollectionPage collectionData={collectionData} accountType='' likeData={likeData} dislikeData={dislikeData} userUsername={registeredUser.username} raffleData={raffleData} benefitsData={benefitsData} benefitsTypes={benefitsTypes} relatedNFTsData={relatedNFTsData} access='public' /> ); 
        }   
        switch(access) { 
            case null:
                return <PleaseWait />;
            case false:                
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;
                if (registeredUser.account_type === 'top 100') {
                    return ( <CollectionPage collectionData={collectionData} accountType='' likeData={likeData} dislikeData={dislikeData} userUsername={registeredUser.username} raffleData={raffleData} benefitsData={benefitsData} benefitsTypes={benefitsTypes} relatedNFTsData={relatedNFTsData} access='premium' /> ); 
                }                    
                else if (registeredUser.username) {                    
                    return ( <CollectionPage collectionData={collectionData} accountType='' likeData={likeData} dislikeData={dislikeData} userUsername={registeredUser.username} raffleData={raffleData} benefitsData={benefitsData} benefitsTypes={benefitsTypes} relatedNFTsData={relatedNFTsData} access='free'/> ); 
                }
            case true:
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;          
                if (registeredUser.username) {
                    return ( <CollectionPage collectionData={collectionData} accountType={registeredUser.account_type} likeData={likeData} dislikeData={dislikeData} userUsername={registeredUser.username} raffleData={raffleData} benefitsData={benefitsData} benefitsTypes={benefitsTypes} relatedNFTsData={relatedNFTsData} access='premium' /> ); 
                }                
                else { 
                    return (  <PleaseRegister />  );    
                }
            default:
                return <p style={{ color: "white" }}>Something went wrong. Do you <a href="/mint">have a token</a>?</p>;
        }
    }

    return (
        <div style={{ color: "#ffffff"}}>                        
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { collectionID ? renderCollectionPage() : null }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser }) {
    return { auth, wallet, registeredUser };
}

export default connect(mapStateToProps)(CollectionPageMain);
