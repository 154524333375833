import { Link } from 'react-router-dom';

const RenderCollectionName = ({ collection_name, collection_slug }) => {

    if ( collection_slug !== null ) {
        const collectionPageURL = '/collection/' + collection_slug;
        return <>
            <Link to={{ pathname: collectionPageURL}} style={{ color:"rgba(255,255,255,.8)"}}>
                {collection_name}
            </Link>
        </>;
    }
    else {
        return <>
            {collection_name}
        </>;
    }
}

export default RenderCollectionName;