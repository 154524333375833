import { Link } from 'react-router-dom';

const RelatedHeader = ({page, reviewCount }) => {
    
    const reviewHighlight = page === 'review' ? '#B0228C' : '#000000';
    const reviewUnderline = page === 'review' ? 'none' : 'underline';
    const reviewStyleInputs = { color:"#ffffff", backgroundColor: reviewHighlight, padding:"5px", textDecoration: reviewUnderline };

    const approvedHighlight = page === 'approved' ? '#B0228C' : '#000000';
    const approvedUnderline = page === 'approved' ? 'none' : 'underline';
    const approvedStyleInputs = { color:"#ffffff", backgroundColor: approvedHighlight, padding:"5px", textDecoration: approvedUnderline };
    
    return (
        <>
        <div className="row">
            <div className="col-12" style={{ fontSize: "2em"}}>
                Related
            </div>
        </div>
        <div className="row">
            <div className="col-6 col-lg-3" style={{ fontSize: "1em"}}>
                <Link to="/admin/related" className="rounded" style={reviewStyleInputs}>Review ({reviewCount})</Link>
            </div>
            <div className="col-6 col-lg-3" style={{ fontSize: "1em"}}>
                <Link to="/admin/related-approved" className="rounded" style={approvedStyleInputs}>Approved</Link>
            </div>
        </div>
        </>
    );
};

export default RelatedHeader;