import React, { useState } from 'react';
import RenderFormField from './RenderFormField';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';

import AutocompletePartner from "./AutocompletePartner";
require("./searchStyles.css");

const BenefitsForm = ({ onBenefitsFormSubmit, initialValues, handleSubmit, error, submitting, benefitsTypes }) => {
    
    const [benefitType, setBenefitType] = useState('');

    const renderAddType = () => {
        return <>
            <td style={{width:"100px", minWidth:"100px"}}>
                <Field
                    className="form-control"
                    name="addType"
                    component={RenderFormField}
                    type="text"
                    placeholder="ex: Raffle"
                    label="Add Type" />
            </td>   
        </>
    }

    const renderPartner = () => {
        return <>
            <td style={{width:"100px", minWidth:"100px"}}>
                <Field
                    className="form-control"
                    name="benefitPartner"
                    fieldName="benefitPartner"
                    hiddenField="benefitPartnerId"
                    endpoint="/api/get/autocomplete/search?searchTerm="
                    component={AutocompletePartner}     
                    placeholder="Benefit Partner"      
                    initialSearch={initialValues?.search !== undefined ? initialValues.search : ''}                                      
                    label="Benefit Partner"
                    />
            </td>
        </>
    }

    const renderRaffleType = () => {

        return <>
            <td style={{width:"200px", minWidth:"200px"}}>
                <Field
                    className="form-control"
                    name="raffleQuantity"
                    component={RenderFormField}
                    type="number"
                    placeholder="Quantity"
                    label="Quantity"
                />
            </td>
            
            {renderPartner()} 

            <td style={{width:"150px", minWidth:"150px"}}>
                <Field
                    className="form-control"
                    name="detailsLink"
                    component={RenderFormField}
                    type="text"
                    placeholder="Details Link"
                    label="Details Link"
                />
            </td> 
            <td>
                <Field
                    className="form-control"
                    name="claimDate"
                    component={RenderFormField}
                    type="date"
                    placeholder="mm/dd/yyyy"
                    label="Claim Date" 
                />
            </td>
            <td>
                <Field
                    className="form-control"
                    name="claimTime"
                    component={RenderFormField}
                    type="time"
                    placeholder="00:00 AM"
                    label="Claim Time (ET)" 
                />
            </td>                                 
            <td>
                <button className="btn btn" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff" }} disabled={submitting}>
                    Add
                </button>  
            </td> 
        </>
    }

    const renderNonRaffleType = () => {
        
        return <>
            <td style={{width:"200px", minWidth:"200px"}}>
                <Field
                    className="form-control"
                    name="description"
                    component={RenderFormField}
                    type="text"
                    placeholder="Description"
                    label="Description"
                />
            </td>

            {renderPartner()} 

            <td style={{width:"150px", minWidth:"150px"}}>
                <Field
                    className="form-control"
                    name="detailsLink"
                    component={RenderFormField}
                    type="text"
                    placeholder="Details Link"
                    label="Details Link"
                />
            </td> 
            <td>
                <Field
                    className="form-control"
                    name="claimDate"
                    component={RenderFormField}
                    type="date"
                    placeholder="mm/dd/yyyy"
                    label="Claim Date" 
                />
            </td>
            <td>
                <Field
                    className="form-control"
                    name="claimTime"
                    component={RenderFormField}
                    type="time"
                    placeholder="00:00 AM"
                    label="Claim Time (ET)" 
                />
            </td>                                 
            <td>
                <button className="btn btn" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff" }} disabled={submitting}>
                    Add
                </button>  
            </td> 
        </>
    }    

    const submitForm = () => {
        return (
            handleSubmit( values => onBenefitsFormSubmit({
                ...values, partner: document.getElementsByName('benefitPartner')[0].value, partnerId: document.getElementsByName('benefitPartnerId')[0].value          
            }) )
        )
    }
    
    return (<>
        {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
        <div className="row table-responsive text-nowrap my-3" style={{fontSize:"1em"}}>                
            <div className="col-12">
                <form onSubmit={submitForm()}>
                    <div>Add a benefit</div>
                    <table className="table" style={{ color:"#ffffff" }}>                        
                        <tbody>
                            <tr>
                                <td style={{width:"100px", minWidth:"100px"}}>
                                    <Field
                                        className="form-select"
                                        name="type"
                                        component="select"
                                        type="select"
                                        label="Type"
                                        placeholder="Type"
                                        onChange={e => setBenefitType(e.target.value)}
                                        style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)", backgroundImage:"linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc)", backgroundPosition: "calc(100% - 17px) calc(1em + 2px), calc(100% - 12px) calc(1em + 2px), calc(100% - 2em) 0.4em", backgroundSize:"5px 5px, 5px 5px, 1px 1.5em", backgroundRepeat: "no-repeat"}}
                                        >
                                        <option value="" key={-1} disabled>Type</option>
                                        {benefitsTypes.map(benefitsType =>
                                            <option key={benefitsType.id} value={benefitsType.benefit_type}>{benefitsType.benefit_type}</option>                                            
                                        )};
                                        <option value="Other" key={-2}>Other</option>                                        
                                    </Field>
                                </td>

                                {benefitType === 'Other' ? renderAddType() : null} 

                                {benefitType === 'Raffle' ? renderRaffleType() : benefitType !== '' ? renderNonRaffleType() : null} 

                                    
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    
    </>
    );
}

const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}
    
    if (values.partner) {
        if (values.partner.length > 250) {
         errors.partner = 'Must be 250 characters or less'
        }
    }    

    if (values.quantity) {
        
        if (! ( values.quantity > 0 ) || ( typeof values.quantity != 'number' && values.quantity.search(/[.]/) > -1 ) ) {
         errors.quantity = 'Must be a whole number'
        }

        if (values.quantity.length > 10) {
            errors.quantity = 'Too long for a number'
        }
    }   

    if (values.description) {
        if (values.description.length > 500) {
         errors.partner = 'Must be 500 characters or less'
        }
    }  
    
    if (!values.detailsLink) {
        errors.detailsLink = 'Link cannot be empty';
    }  
    

    // console.log('errors', errors)
    return errors
}

function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'benefitsForm',
    validate,
})(BenefitsForm));