import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

import useTokenGatedAccess from '../../../hooks/useTokenGatedAccess';
import PleaseSignIn from '../../access/PleaseSignIn';

import GiveawayCard from '../GiveawayCard';
import GiveawaysHeader from '../GiveawaysHeader';

const SignupMain = ({ auth, wallet, registeredUser, form }) => {        

    const { account } = wallet; // use the account from the wallet store object    

    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, _walletAccessTokens } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars
    
    const [delayComplete, setDelayComplete] = useState(false);     

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const delayPublic = async () => {
        await delay(1000);
        //console.log("Waited 5s");
        setDelayComplete(true);
      };
    delayPublic();    

    if (!auth && registeredUser.username) {
        return <PleaseSignIn />;
    }
    else {
        return (
            <div>            
                {/* In lieu of a redirect, provide a link to the register page. */}
                {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
                {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
                {/* If it did, there is a risk of infinite redirects */}
                {/*renderSignup()*/}
                <GiveawaysHeader page="" entries={registeredUser.current_giveaway_entries}  account={account} />
                
                <div className="row"> 
                    <div className="col-1"></div>
                    <div className="col-md-10" style={{fontSize:"1em", color:"rgba(255,255,255,0.8)", marginTop:"30px", textAlign:"left"}}>
                        HOW TO EARN GIVEAWAY ENTRIES (least to most)
                        <br />Sept 28, 2022
                        <br /><br />1) <Link to="/register" style={{color:"rgba(255,255,255,0.8)"}}>Register</Link> for a FREE Future Mints account. Every week, you'll automatically receive 1 stackable entry per week. (Passive)
                        <br /><br />2) Earn at least one point on our leaderboard each week, and you'll receive at least 1 stackable entry (see #4). (Active)             
                        <br /><br />3) Everyone who owns a Genesis (12 per day) and Annual Pass (2 per day) NFT will receive stackable entries each day for each Future Mints NFT they own in a connected wallet to their Future Mints account. (Passive)
                        <br /><br />4) Earn enough points to be in the Weekly Leaderboard Top 100 users each week. Each week, stackable entries will be awarded with the following pattern according to your ranking: #1 receiving 100, #2 receiving 99, and so on until #100 who will receive 1 entry. You cannot receive more entries than the points you have. So if you are #1 but only earned 48 points, then you will receive 48 entries. (Active)
                        <br /><br />5) Earn enough points to be in the Monthly Leaderboard Top 100 users each month. Each month, stackable entries will be awarded with the following pattern according to your ranking: #1 receiving 200, #2 receiving 198, and so on until #100 who will receive 2 entry. You cannot receive more entries than the points you have. So if you are #1 but only earned 48 points, then you will receive 96 entries. (Active)
                        <br /><br />6) Earn enough points to be in the Lifetime Leaderboard Top 100 Lifetime users each month. Each month, stackable entries will be awarded with the following pattern according to your ranking: #1 receiving 400, #2 receiving 396, and so on until #100 who will receive 4 entry. You cannot receive more entries than the points you have. So if you are #1 but only earned 48 points, then you will receive 192 entries. (Active)
                    </div>                              
                </div>    

                <div className="row"> 
                    <div className="col-1"></div>
                    <div className="col-md-10" style={{fontSize:"1em", color:"rgba(255,255,255,0.8)", marginTop:"30px", textAlign:"left"}}>
                        OFFICIAL RULES
                        <br />Sept 28, 2022
                        <br /><br />Our goal is to make it as clear as possible how our Giveaways will be run fairly and openly. If there's anything that we didn't think about or cover in sufficient detail, please let us know by emailing info@futurenftmints.com. We'll respond within 2 business days.
                        <br /><br />VOID WHERE PROHIBITED. NO PURCHASE NECESSARY.
                    </div>
                </div>
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-md-8" style={{fontSize:"1em", color:"rgba(255,255,255,0.8)", marginTop:"10px", textAlign:"left"}}>
                        1) The Future Mints Weekly Giveaways are run as a Sweepstakes. We may use the term Giveaway or Sweepstakes interchangably below.
                        <br /><br />2) Entry begins Wednesday at 1pm ET and each Sweepstakes ends the following Wednesday at 12pm ET, unless otherwise noted on the individual Sweepstakes.
                        <br /><br />3) One account per person. Future Mints reserves the right to remove or invalidate entries if someone is determined to be operating more than one account.
                        <br /><br />4) Anyone is able to receive at least 1 entry for free each week.
                        <br /><br />5) There is no expiration to your entries, so if you choose not to enter in 1 week, you can save your entries for a future sweepstakes.
                        <br /><br />6) Entries stack. This means that you are able to accumulate multiple entries. For instance, a single user may earn 1 entry per week for having an account, 1 entry for earning at lesat one point, 12 entries for a Genesis NFT, 4 entries for two Annual pass NFTs, and 54 entries for being ranked #46 on our leaderboard. This would be a total of 72 entries for the given time period.                    
                        <br /><br />7) There is no limit to the number of Sweepstakes you may enter.
                        <br /><br />8) There is no limit to the number of entries you may enter.
                        <br /><br />9) Only Sweepstakes that begin and end at the same time will be considered as a part of the same Sweepstakes. 
                        <br /><br />10) Each Sweepstakes that begins and ends at the same time will include prizes with a total USD value no greater than $4,000 at the beginning of the Sweepstakes. Only prizes that begin and end at the same time will be added to the total. For the purposes of this calculation, raffle entry spots will be valued at $10 each. 
                        <br /><br />11) At the discretion of Future Mints, we may choose to create, increase or decrease the number of entries awarded for any particular action within the product. Any changes that reduce or eliminate the number of entries awarded will take effect no earlier than the beginning of the next Sweepstakes or Giveaway. 
                        <br /><br />12) Future Mints reserves the right to award additional entries at Future Mints' discretion.
                        <br /><br />13) Entries will be awarded with an automated process that will run approximately at 1am ET every day. Daily entry awards will be awarded daily. Weekly entry awards will be awarded weekly on Mondays for the prior week leaderboard rankings. Monthly entry awards will be awarded monthly on the first day of the month for the prior month and lifetime leaderboard rankings.
                        <br /><br />14) Leaderboard rankings will be based on the number of points accumulated during the period. For rankings prior to Oct 1, all points will be counted, regardless when they were earned. For rankings post Oct 1, all weekly points will be counted from Monday at 12:00am ET through Sunday at 11:59pm ET. For rankings post Oct 1, all monthly points will be counted from the first of the month at 12:00am ET through the last day of the month at 11:59pm ET. For lifetime rankings, all points will be counted through the last day of the given month at 11:59pm ET.
                        <br /><br />15) The rules for earning points will be posted in the Leaderboard. At Future Mints' discretion, the points per action may be created, increased, or reduced. Any changes that reduce points will NOT be retroactive, but changes that award points for past actions MAY be retroactive. Point earning changes may take effect at the discretion of Future Mints.<br /><br />16) To receive your completely free entry every week, simply <Link to="/register" style={{color:"rgba(255,255,255,0.8)"}}>register</Link> for an account. The only requirement is an Ethereum wallet, which is where we will send any NFT that you win.

                    </div>  
                </div>         
            
            </div>
        );
    }
};

function mapStateToProps({ auth, wallet, registeredUser, form }) {
    return { auth, wallet, registeredUser, form };
}

export default connect(mapStateToProps)(SignupMain);
