import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import * as actions from '../../actions';

import useTokenGatedAccess from '../../hooks/useTokenGatedAccess';
import useWalletLinkStatus from '../../hooks/useWalletLinkStatus';
import getTokenDetails from '../../utils/getTokenDetails';

// components
import PleaseConnectWallet from '../access/PleaseConnectWallet';
import PleaseWait from '../access/PleaseWait';
import AccessDenied from '../access/AccessDenied';
import SomethingWentWrong from '../access/SomethingWentWrong';
import RegisterPrompt from './RegisterPrompt';
import RegisterAccountDetails from './RegisterAccountDetails';
import RegisterForm from './RegisterForm';

import axios from 'axios';

const Register = ({ wallet, registeredUser, fetchRegisteredUser }) => {
    
    const { account } = wallet; // use the account from the wallet store object    

    // Pass the account to the useTokenGatedAccess hook to determine if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, prioritizedAccessToken } = useTokenGatedAccess(account);    
    const { walletLinkedStatus } = useWalletLinkStatus(account);
    
    // Determin the token contract name and platform access expiry
    const [tokenName, setTokenName] = useState('')
    const [accessEndDate, setAccessEndDate] = useState('')
    const [tokenContractAddress, setTokenContractAddress] = useState('')
    const [message, setMessage] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [openStoreTokenId, setOpenStoreTokenId] = useState('')
    useEffect(() => {
        // For now, pick only the first token if there are many
        // TBD: How do we want this form to behave if the user holds many access tokens
        const { name, contractAddress, expiresOn, message, imageUrl, hexTokenId } = getTokenDetails(prioritizedAccessToken)
        setTokenName(name)
        setTokenContractAddress(contractAddress)
        setAccessEndDate(expiresOn)
        setMessage(message)
        setImageUrl(imageUrl)
        setOpenStoreTokenId(hexTokenId)
    }, [prioritizedAccessToken])
    
    //console.log( 'walletLinkedStatus: ', walletLinkedStatus);
    if ( walletLinkedStatus === 'pending' ) {
        return <Redirect to="/confirm/link-wallet" />
    }
    const renderForm = () => {

        let initialValues;
        if (!account) return <PleaseConnectWallet />;

        switch(access) {
            case null:
                return <PleaseWait />;
            case false:
                // initialValues is a prop for redux-form https://redux-form.com/8.3.0/examples/initializefromstate/
                initialValues = {
                    account: wallet.account,
                    tokenContractAddress: tokenContractAddress ? tokenContractAddress : 'none',
                    openStoreTokenId
                }                
                return registeredUser.username ? <Redirect to="/onboard/step-2" state={ registeredUser } /> : <>
                    <RegisterPrompt nft={tokenName} />
                    <RegisterAccountDetails wallet={wallet.account} nft={tokenName} accessEndDate={accessEndDate?.toString()} message={message} imageUrl={imageUrl} />
                    <RegisterForm
                        initialValues={initialValues}
                        onRegisterSubmit={
                            async (values) => {
                                try {
                                    // https://axios-http.com/docs/instance
                                    // axios#post(url[, data[, config]])
                                    await axios.post('/api/register', values);
                                } catch (error) {
                                    // This should be visible in FS and (hopefully) alert FS of the issue
                                    console.error(error.response)

                                    // This is the error-handling for server-side validation (and network errors) in redux-form
                                    // For client-side (prior to submission), see RegisterForm's validate function
                                    // https://redux-form.com/8.3.0/docs/migrationguide.md/#-code-handlesubmit-code-and-code-onsubmit-code-
                                    // https://redux-form.com/8.3.0/examples/submitvalidation/
                                    // https://redux-form.com/8.3.0/docs/api/submissionerror.md/
                                    let responseData = error.response.data

                                    if (responseData.name === 'AccountAlreadyRegisteredError') {
                                        console.log('Registration ERROR: AccountAlreadyRegisteredError: ', wallet.account)
                                        throw new SubmissionError({
                                            _error: 'Account already registered.',
                                            username: responseData.errors['username']?.message,
                                            account: responseData.errors['account']?.message,
                                        })
                                    // the error was due to an existing username
                                    } else if (responseData.name === 'UsernameAlreadyRegisteredError') {
                                        console.log('Registration ERROR: UsernameAlreadyRegisteredError: ', wallet.account)
                                        throw new SubmissionError({
                                            _error: 'Username already registered.',
                                            username: responseData.errors['username']?.message,
                                            account: responseData.errors['account']?.message,
                                        })
                                    // the error was due to Sequelize record validation
                                    } else if (responseData.name === 'SequelizeValidationError') {
                                        console.log('Registration ERROR: SequelizeValidationError: ', wallet.account)
                                        throw new SubmissionError({
                                            _error: 'Failed to register.',
                                            username: _.find(responseData.errors, { path: "username" })?.message,
                                            account: _.find(responseData.errors, { path: "account" })?.message,
                                        })
                                    } else { // an error scenario that for which we are unaware
                                        console.log('Registration ERROR: Not sure: ', wallet.account)
                                        throw new SubmissionError({ _error: 'Registration failed.' })
                                    }
                                }
                                fetchRegisteredUser(account);
                            }
                        }
                    />
                </>;
            case true:
                // initialValues is a prop for redux-form https://redux-form.com/8.3.0/examples/initializefromstate/
                initialValues = {
                    account: wallet.account,
                    tokenContractAddress: tokenContractAddress ? tokenContractAddress : 'none',
                    openStoreTokenId
                }                
                return registeredUser.username ? <Redirect to="/onboard/step-2" state={ registeredUser } /> : <>
                    <RegisterPrompt nft={tokenName} />
                    <RegisterAccountDetails wallet={wallet.account} nft={tokenName} accessEndDate={accessEndDate?.toString()} message={message} imageUrl={imageUrl} />
                    <RegisterForm
                        initialValues={initialValues}
                        onRegisterSubmit={
                            async (values) => {
                                try {
                                    // https://axios-http.com/docs/instance
                                    // axios#post(url[, data[, config]])
                                    await axios.post('/api/register', values);
                                } catch (error) {
                                    // This should be visible in FS and (hopefully) alert FS of the issue
                                    console.error(error.response)

                                    // This is the error-handling for server-side validation (and network errors) in redux-form
                                    // For client-side (prior to submission), see RegisterForm's validate function
                                    // https://redux-form.com/8.3.0/docs/migrationguide.md/#-code-handlesubmit-code-and-code-onsubmit-code-
                                    // https://redux-form.com/8.3.0/examples/submitvalidation/
                                    // https://redux-form.com/8.3.0/docs/api/submissionerror.md/
                                    let responseData = error.response.data

                                    if (responseData.name === 'AccountAlreadyRegisteredError') {
                                        throw new SubmissionError({
                                            _error: 'Account already registered.',
                                            username: responseData.errors['username']?.message,
                                            account: responseData.errors['account']?.message,
                                        })
                                    // the error was due to an existing username
                                    } else if (responseData.name === 'UsernameAlreadyRegisteredError') {
                                        throw new SubmissionError({
                                            _error: 'Username already registered.',
                                            username: responseData.errors['username']?.message,
                                            account: responseData.errors['account']?.message,
                                        })
                                    // the error was due to Sequelize record validation
                                    } else if (responseData.name === 'SequelizeValidationError') {
                                        throw new SubmissionError({
                                            _error: 'Failed to register.',
                                            username: _.find(responseData.errors, { path: "username" })?.message,
                                            account: _.find(responseData.errors, { path: "account" })?.message,
                                        })
                                    } else { // an error scenario that for which we are unaware
                                        throw new SubmissionError({ _error: 'Registration failed.' })
                                    }
                                }
                                fetchRegisteredUser(account);
                            }
                        }
                    />
                </>;
            default:
                return <SomethingWentWrong />;
        }
    }

    return (
        <div>
            {renderForm()}
        </div>
    );
}

function mapStateToProps({ auth, wallet, registeredUser, fetchRegisteredUser }) {
    return { auth, wallet, registeredUser, fetchRegisteredUser };
}

export default connect(mapStateToProps, actions)(Register);
