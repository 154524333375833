import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';

import useTokenGatedAccess from '../../hooks/useTokenGatedAccess';
import useMarketplaces from '../../hooks/useMarketplaces';

// components
import PleaseConnectWallet from '../access/PleaseConnectWallet';
import PleaseWait from '../access/PleaseWait';
import AccessDenied from '../access/AccessDenied';
import PleaseRegister from '../access/PleaseRegister';
import PleaseSignIn from '../access/PleaseSignIn';
import QuickAddNFTForm from './QuickAddNFTForm';


import axios from 'axios';

const QuickAddNFT = ({ auth, wallet, registeredUser, form }) => {

    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access } = useTokenGatedAccess(account);

    const [publicUser, setPublicUser] = useState(false);  

    //this avoids a flicker where the public info is shown just before the logged in
    //user's info is recognized which changes the info on the page to the appropriate details
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const delayPublic = async () => {
        await delay(1000);
        //console.log("Waited 5s");
        setPublicUser(true);
      };
    delayPublic();

    const initialValues = {
    }

    const renderNFTForm = ( accountType ) => {
        return <>
        <QuickAddNFTForm
                        initialValues={initialValues}
                        accountType={accountType}                        
                        onCollectionSubmit={
                            async (values) => {
                                try {                                    
                                    
                                    await axios.post('/api/post/quick-add-nft', values);                                    

                                } catch (error) {
                                    // This should be visible in FS and (hopefully) alert FS of the issue
                                    console.error(error.response)
                                }
                            }
                        }
                    />
        </>
    }

    const redirectLogic = ( auth ) => {
        if (auth.account_type === 'owner' || auth.account_type === 'researcher' || auth.account_type === 'admin' ) {
            return <Redirect to="/admin/review" />;
        }
        
        return <Redirect to="/dashboard/home" />;
    }

    const renderForm = () => {
        if (!account && publicUser) {
            
            return form.quickAddNFTForm?.submitSucceeded ? redirectLogic( 'public' ) : renderNFTForm( 'public' ); 
        }

        switch(access) {
            case null:
                return <PleaseWait />;
            case false:
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;
                if (registeredUser.account_type === 'top 100') {
                    return form.quickAddNFTForm?.submitSucceeded ? redirectLogic(auth) : renderNFTForm( 'premium' ); 
                }
                else if (registeredUser.username) {
                    return form.quickAddNFTForm?.submitSucceeded ? redirectLogic(auth) : renderNFTForm( 'free' ); 
                }
            case true:
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <PleaseRegister />;
                // initialValues is a prop for redux-form https://redux-form.com/8.3.0/examples/initializefromstate/
                //TODO: add proper redirect on form submission
                return !registeredUser.username ? <PleaseRegister /> : form.quickAddNFTForm?.submitSucceeded ? redirectLogic(auth) : renderNFTForm( 'premium' )
                    
                ;
            default:
                return <p style={{ color: "white" }}>Something went wrong. Do you <a href="/mint">have a token</a>?</p>;
        }
    }

    return (
        <>
        <div>                        
            {renderForm()}
        </div>
        </>
    );
}

function mapStateToProps({ auth, wallet, registeredUser, form }) {
    return { auth, wallet, registeredUser, form };
}

export default connect(mapStateToProps, actions)(QuickAddNFT);
