import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../../actions';

const PassLocation = ({ wallet, registeredUser, fetchRegisteredUser }) => {

    console.log('use Location: ', useLocation().pathname);
    console.log('test: ', window.location.pathname);
    const getUseLocation = useLocation().pathname;
    //return getUseLocation ? getUseLocation : window.location.pathname;
    return '/';
}

function mapStateToProps({ wallet, registeredUser }) {
    return { wallet, registeredUser };
}

export default connect(mapStateToProps, actions)(PassLocation);