import React, { useState } from 'react';
import RenderFormField from '../../collectionPage/RenderFormField';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

const LinkWalletsForm = ({ onLinkWalletsFormSubmit, initialValues, handleSubmit, error, submitting }) => {    

    return (<>
        {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
        <div className="row table-responsive my-3" style={{fontSize:"1em"}}>                
            <div className="col-12">
                <form onSubmit={handleSubmit( onLinkWalletsFormSubmit )}>
                    <div>Add a wallet (raw address, Ethereum only for now, and no ENS address)</div>
                    <table className="table" style={{ color:"#ffffff" }}>                        
                        <tbody>
                            <tr style={{border:"0px solid transparent"}}>
                                <td style={{padding:"8px 8px 8px 0px"}}>
                                    <Field
                                        className="form-control"
                                        name="walletAddress"
                                        component={RenderFormField}
                                        type="text"
                                        placeholder="Wallet address"
                                        label="Wallet Address"
                                    />
                                </td>                                                                                             
                                <td>
                                    <button className="btn btn" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff" }} disabled={submitting}>
                                        Add
                                    </button>  
                                </td>     
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    
    </>
    );
}

const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}
    
    if (!values.walletAddress) {
        errors.walletAddress = 'required'
    }

    if (values.walletAddress) {
        if (values.walletAddress.length > 255) {
         errors.walletAddress = 'Must be 255 characters or less'
        }
    } 
    
    if (values.walletAddress) {
        if ( values.walletAddress.search(/\s/) > -1 ) {
            errors.walletAddress = 'No spaces allowed'
        }
    }    

    // console.log('errors', errors)
    return errors
}

function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'linkWalletsForm',
    validate,
})(LinkWalletsForm));