import { useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import Pagination from '../../pagination/Pagination';
import LinkWalletsHeader from './LinkWalletsHeader';
import LinkWalletsForm from './LinkWalletsForm';
import SettingsNav from '../SettingsNav';

let PageSize = 50;
let filters = '';

const LinkWalletsPage = ({ linkedWallets, registeredUser, showLinkAccountInstructions, account }) => {
    
    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);

    const [linkWalletsFormSubmitted, setLinkWalletsFormSubmitted] = useState(null); 
    const [showLinkAccountInstructionsCurrent, setShowLinkAccountInstructionsCurrent] = useState(showLinkAccountInstructions);      

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return linkedWallets.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, linkedWallets]);

    const TableRow = ({ linkedWallet }) => {
        const { id, address, confirmed } = linkedWallet;   
        const unlinkUrl = '/unlink-wallet/' + id;
        return <tr>
            <td className="text-center">{account === address ? <i className="bi bi-check-square-fill" /> : null }</td>        
            <td>{address} {confirmed && (account === address || account === registeredUser.account) ? <Link to={unlinkUrl} className="text-danger">unlink</Link> : null}</td>
            <td>{confirmed ? <span className="badge bg-success">Linked</span> : <span className="badge bg-warning">Pending</span>}</td>
        </ tr>;
    }

    const confirmWalletInstructions = () => {
        return (
            <>
            You have a PENDING linked wallet. To complete the wallet link:
            <br />1. <Link to="/logout" style={{color:"#ffffff"}}>Logout</Link> of this account.
            <br />2. Connect the pending wallet to Future Mints.
            <br />3. Sign to prove ownership of the pending wallet.
            <br />4. Confirm the wallet link.
            </>
        )
    }

    const renderLinkWalletsForm = ( ) => {
        const initialValues = {
        }

        return <>
        <LinkWalletsForm
            initialValues={initialValues}
            onLinkWalletsFormSubmit={
                async (values) => {                        
                        try {                                                        
                            await axios.post(`/api/post/link-wallet`, values);
                            setLinkWalletsFormSubmitted("You've added a wallet, and there are a couple more steps to complete the link.");
                            setShowLinkAccountInstructionsCurrent(true);
                        } catch (error) {
                            // This should be visible in FS and (hopefully) alert FS of the issue
                            console.error(error.response)
                        }
                    }
                }
            />
        </>
    }

    const renderLinkWalletsFormSubmitted = (linkWalletsFormSubmitted) => {
        return <>
            <div className="row mt-3 mb-5">                
                <div className="col-12">
                    {linkWalletsFormSubmitted}
                </div>
            </div>
        </>;
    }   
    
    return (
        <>        
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-lg-2 col-12" style={{paddingLeft:"20px"}}>
                <SettingsNav page="link-wallets"/>
            </div>            
            <div className="col-lg-10 col-12">            
                <LinkWalletsHeader />
                
                {linkWalletsFormSubmitted === null ? renderLinkWalletsForm( ) : renderLinkWalletsFormSubmitted(linkWalletsFormSubmitted)}
                
                {showLinkAccountInstructionsCurrent ? confirmWalletInstructions() : null}

                <div className="row table-responsive text-nowrap" style={{ marginTop:"20px"}}>
                    <div className="col-12">
                    <table className="table" style={{ color:"#ffffff" }}>
                        <thead>
                            <tr style={{ backgroundColor:"#1F2731"}}>                                
                                <th scope="col" className="text-center">Logged In</th>                                
                                <th scope="col">Wallet Address</th>
                                <th scope="col">Status</th>                                                                
                            </tr>
                        </thead>
                        <tbody style={{ fontSize:".8em"}}>
                            <tr>
                                <td className="text-center">{account === registeredUser.account ? <i className="bi bi-check-square-fill" /> : null }</td>        
                                <td>{registeredUser.account}</td>
                                <td><span className="badge bg-primary">Primary</span></td>
                            </ tr>
                            
                            {pageData.map(linkedWallet => <TableRow key={linkedWallet.id} linkedWallet={linkedWallet} />)}
                            
                        </tbody>
                        <tfoot style={{ backgroundColor:"#1F2731", textAlign: "center"}}>
                            <tr>                     
                                <td colSpan="8">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={linkedWallets.length}
                                        pageSize={PageSize}
                                        filters={filters}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        <div className="row" style={{ marginTop:"100px"}}>            
            <div className="col-lg-2 col-12">                
            </div>            
            <div className="col-lg-10 col-12"> 
                <span style={{fontSize:"1.5em"}}>What do the statuses mean?</span> 
                <br /><br /><span className="badge bg-primary">Primary</span> This is the main wallet linked to your account. When you're logged in with it, you can unlink any other wallet connected. For now, this wallet cannot be removed or deleted from your account.
                <br /><br /><span className="badge bg-success">Linked</span> This wallet can be used to login to your account and change all settings. It's recommended that you link an empty wallet and use that for all logins. When logged in with this wallet, you can only unlink this wallet from your account.
                <br /><br /><span className="badge bg-warning">Pending</span> This wallet has been added to this account but not confirmed. You need to log out and log in with that wallet to first confirm it should be linked (and you control it) before the wallet has the LINKED status.
            </div>  
        </div>
        </>
    );
};

export default LinkWalletsPage;
