import React from 'react';
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Footer = () => {
    return (
        <>
        <Card.Footer style={{paddingTop:"30px", bottom: 0, left: 0, backgroundColor:"#120B21", width:"100%"}}>        
        <Row>
          <Col style={{color:"rgba(255,255,255,0.8)", textAlign:"Center", marginBottom:"30px"}}>
            Not Financial Advice (NFA)<br />Do Your Own Research (DYOR)
          </Col>
        </Row>
        <Row>
          <Col sm={4} style={{color:"rgba(255,255,255,0.8)"}}>Copyright © <a style={{ color:"rgba(255,255,255,0.8)", textDecoration:"underline"}} target="_blank" rel="noreferrer" href="https://futurenftmints.com">Future NFT Mints</a> 2022-2023</Col>
        
          <Col sm={4} style={{color:"rgba(255,255,255,0.8)", textAlign:"Center"}}>
            <a style={{ color:"rgba(255,255,255,0.8)", textDecoration:"underline"}} rel="noreferrer" href="/privacy">Privacy Policy</a>&nbsp;&nbsp;&nbsp;          
            <a style={{ color:"rgba(255,255,255,0.8)", textDecoration:"underline"}} rel="noreferrer" href="/tos">Terms of Service</a>
          </Col>          

          <Col sm={4} style={{textAlign:"right"}}>
            <a target="_blank" rel="noreferrer" style={{ color:"rgba(255,255,255,0.8)", textDecoration:"none", marginRight:"10px"}} href="https://twitter.com/futurenftmints">
              <img
                alt=""
                src="/images/twitter.png"
                height="20"
                className="d-inline-block align-top"
              />
            </a>
            <a target="_blank" rel="noreferrer" style={{ color:"rgba(255,255,255,0.8)", textDecoration:"none", marginRight:"10px"}} href="https://discord.gg/qqzF6U6ZaY">
              <img
                alt=""
                src="/images/discord.png"
                height="20"
                className="d-inline-block align-top"
              />
            </a>
            <a target="_blank" rel="noreferrer" style={{ color:"rgba(255,255,255,0.8)", textDecoration:"none", marginRight:"10px"}} href="https://opensea.io/collection/future-nft-mints-genesis-nft">
              <img
                alt=""
                src="/images/opensea.png"
                height="20"
                className="d-inline-block align-top"
              />
            </a>
            
          </Col>
        </Row>
      </Card.Footer>
        </>
    );
};

export default Footer;
