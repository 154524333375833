const HowTos = () => {

    return <>
                <div className="row rounded p-3">
                    <div className="col-md-6 col-12 mt-4"  >
                        <div style={{fontSize:"1.5em"}}>
                            How to earn points
                        </div>

                        <div style={{fontSize:"0.8em"}}>
                            Last updated: Sep 16, 2022
                        </div>

                        <div className="row mt-3" style={{fontSize:"1.1em"}}>
                            <div className="col-8">
                                <strong>Action</strong>
                            </div>
                            <div className="col-4">
                                <strong>Points</strong>
                            </div>
                        </div>
                        
                        <div className="mt-2"></div>                        
                        <div className="row" style={{fontSize:"1em"}}>
                            <div className="col-8">                            
                                Add Benefits (Approved only)
                            </div>
                            <div className="col-4">
                                20
                            </div>
                        </div>
                        <div className="row" style={{fontSize:"1em"}}>
                            <div className="col-8">                            
                                Add Raffle (Approved only)
                            </div>
                            <div className="col-4">
                                20
                            </div>
                        </div>                        
                        
                        <div className="mt-2"></div>                        
                        <div className="row" style={{fontSize:"1em"}}>
                            <div className="col-8">                            
                                Add NFT (Approved only)
                            </div>
                            <div className="col-4">
                                10
                            </div>
                        </div>                        
                        <div className="row" style={{fontSize:"1em"}}>
                            <div className="col-8">
                                Upvote on NFT you added
                            </div>
                            <div className="col-4">
                                0.2
                            </div>
                        </div>                        

                        <div className="mt-2"></div>                        
                        <div className="row" style={{fontSize:"1em"}}>
                            <div className="col-8">                            
                                Add Like / Dislike
                            </div>
                            <div className="col-4">
                                3
                            </div>
                        </div>                        
                        <div className="row" style={{fontSize:"1em"}}>
                            <div className="col-8">
                                Upvote on Like / Dislike you added
                            </div>
                            <div className="col-4">
                                0.2
                            </div>
                        </div>

                        <div className="mt-3"></div>
                        <div className="row" style={{fontSize:"1em"}}>
                            <div className="col-8">
                                Vote on NFT (up or down)*
                            </div>
                            <div className="col-4">
                                1
                            </div>
                        </div>
                        <div className="row" style={{fontSize:"1em"}}>
                            <div className="col-8">
                                Vote on Like / Dislike (up or down)*
                            </div>
                            <div className="col-4">
                                1
                            </div>
                        </div>
                        <div className="row mt-3" style={{fontSize:"0.8em"}}>
                            <div className="col-12">
                                *You cannot earn more than 1,000 points from voting. Once you reach this in your lifetime points, you will no longer earn points for voting.
                            </div>                            
                        </div>

                    </div>  
                    <div className="col-md-6 col-12 mt-4"  >
                        <div style={{fontSize:"1.5em"}}>
                            How points matter
                        </div>

                        <div style={{fontSize:"0.8em"}}>
                            Last updated: Sep 26, 2022
                        </div>

                        <div className="row mt-3" style={{fontSize:"1.1em"}}>
                            <div className="col-12">
                                <ul>
                                    <li>Every week, we award Giveaway Entries to the weekly Top 100 users. Weeks start on Monday and end on Sunday.</li>
                                    <li>Every month, we award Giveaway Entries to the monthly and lifetime Top 100 users.</li>
                                    <li>Everyone on the leaderboard earns at least 1 Giveaway Entry.</li>
                                    <li>You cannot receive more entries than points.</li>
                                    <li>Point awards subject to change. Entry awards subject to change.</li>
                                    <li>One account per person.</li>
                                    <li>All points are calculated in a given period based on Eastern Time (ET).</li>
                                </ul>
                            </div>
                        </div>                                            

                    </div>  
                </div>   
    </>;
}

export default HowTos;