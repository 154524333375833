import { useState, useMemo } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import axios from 'axios';

import BenefitEditHeader from './BenefitEditHeader';
import BenefitEditForm from './BenefitEditForm';
import ReviewNav from '../../ReviewNav';

const BenefitEdit = ({ benefitData, benefitsTypes, accountType }) => {

    const { id, added_by_username, benefit_type, nft_collection_id, nft_collection, claim_deadline_date, claim_deadline_time, description, url } = benefitData;

    const initialValues = {
        'nftCollection':        benefitData.nft_collection,
        'nftCollectionID':      benefitData.nft_collection_id,
        'type':                 benefitData.benefit_type,
        'description':          benefitData.description,        
        'benefitPartner':       benefitData.partner,
        'benefitPartnerId':     benefitData.partner_id,
        'detailsLink':          benefitData.url,        
        'claimDate':            benefitData.claim_deadline_date,        
        'claimTime':            benefitData.claim_deadline_time,      
        'raffleQuantity':       benefitData.raffle_quantity,  
    }

    return (
        <>
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-2">
                <ReviewNav page="" accountType={accountType}/>
            </div>
            <div className="col-10">            
                <BenefitEditHeader />
                <div className="row table-responsive text-nowrap" style={{ marginTop:"20px"}}>
                    <div className="col-12">

                        <BenefitEditForm
                            initialValues={initialValues}
                            benefitsTypes={benefitsTypes}
                            onBenefitEditFormSubmit={
                                async (values) => {
                                                                        
                                    try {                                                        
                                        await axios.post(`/api/admin/post/edit/benefit/${id}`, values);
                                        window.location.assign('/admin/benefits');
                                    } catch (error) {
                                        // This should be visible in FS and (hopefully) alert FS of the issue
                                        console.error(error.response)
                                    }
                                }
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default BenefitEdit;
