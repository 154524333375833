import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

const REACT_APP_INFURA_PROJECT_ID = process.env.REACT_APP_INFURA_PROJECT_ID;
const web3Modal = new Web3Modal({
    network: "mainnet",
    disableInjectedProvider: false, // e.g., MetaMask
    cacheProvider: true,
    providerOptions: {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                infuraId: REACT_APP_INFURA_PROJECT_ID
            }
        },
        coinbasewallet: {
            package: CoinbaseWalletSDK, // Required
            options: {
                appName: "Future Mints", // Required
                infuraId: REACT_APP_INFURA_PROJECT_ID, // Required
                // rpc: "", // Optional if `infuraId` is provided; otherwise it's required
                // chainId: 1, // Optional. It defaults to 1 if not provided
                // darkMode: false // Optional. Use dark theme, defaults to false
            }
        }
    }
});

export default web3Modal
