import React, {Component} from 'react'
import { Field, reduxForm } from 'redux-form';
import RenderField from './RenderField';

export default class AdditionalInformation extends Component{

    render(){
        return(
            <>
            <div className="row" style={{ color: "#ffffff", marginTop:"25px"}}>
                <div className="col-md-12">
                        <h3>
                            Additional Information
                        </h3>
                        <hr></hr>
                </div>
            </div>

            <div className="row" >
                <div className="col-12">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="team"
                                component={RenderField}
                                type="text"
                                placeholder="Enter details about the team"
                                label="Who is the team behind the collection? Are they doxxed? What are their Twitter handles?"
                            />
                        </div>
                    </div>
                </div>
            
                <div className="col-12" style={{marginTop:"5px"}}>
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="utility"
                                component={RenderField}
                                type="text"
                                placeholder="Enter details about the utlity"
                                label="Give further information about the utility of holding this NFT" />
                        </div>
                    </div>
                </div>

                <div className="col-12" style={{marginTop:"5px"}}>     
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="perks"
                                component={RenderField}
                                type="text"
                                placeholder="Enter details about the perks"
                                label="Give further information about the perks of holding this NFT" />
                        </div>
                    </div>                   
                </div>

                <div className="col-12" style={{marginTop:"5px"}}>     
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="royalty"
                                component={RenderField}
                                type="text"
                                placeholder="ex: 7.5%"
                                label="What percentage royalties are the creators taking from secondary sales?" />
                        </div>
                    </div>                   
                </div>
                
            </div> 

            <div className="row" style={{ color: "#ffffff", marginTop:"20px"}}>
                <div className="col-md-12">
                        <h3>
                            
                        </h3>
                        <hr></hr>
                </div>
            </div>

            <div className="row" >
                <div className="col-md-4">
                    <div className="form-group">
                        <div>
                            <label>Roadmap Category #1</label>
                            <Field
                                className="form-select"
                                name="roadmapCategory1"
                                component="select"
                                type="select"
                                label="Roadmap Category #1">
                                <option value=""></option>
                                <option value="Access Future NFTs">Access Future NFTs</option>
                                <option value="Airdrop">Airdrop</option>
                                <option value="Allow List Other NFTs">Allow List Other NFTs</option>
                                <option value="Analysis">Analysis</option>
                                <option value="Android App">Android App</option>
                                <option value="Art">Art</option>
                                <option value="Beta Access">Beta Access</option>
                                <option value="Carbon Offset">Carbon Offset</option>
                                <option value="CC0">CC0</option>
                                <option value="Celebrate Diversity">Celebrate Diversity</option>
                                <option value="Charity">Charity</option>
                                <option value="Coffee">Coffee</option>
                                <option value="Community Fund">Community Fund</option>
                                <option value="DAO">DAO</option>
                                <option value="Discount">Discount</option>
                                <option value="Education">Education</option>
                                <option value="Free Shipping">Free Shipping</option>
                                <option value="Game">Game</option>
                                <option value="iPhone App">iPhone App</option>
                                <option value="IRL Events">IRL Events</option>
                                <option value="Merchandise">Merchandise</option>
                                <option value="Metaverse">Metaverse</option>
                                <option value="Newsletter">Newsletter</option>
                                <option value="NFT Calendar">NFT Calendar</option>
                                <option value="Movie">Movie</option>
                                <option value="Music">Music</option>
                                <option value="Other">Other</option>
                                <option value="PFP">PFP</option>
                                <option value="Physical Product">Physical Product</option>
                                <option value="Play-to-Earn">Play-to-Earn</option>
                                <option value="Product Roadmap Influence">Product Roadmap Influence</option>
                                <option value="Q&As">Q&As</option>
                                <option value="Raffle">Raffle</option>
                                <option value="Rent / Lend">Rent / Lend</option>
                                <option value="Research">Research</option>
                                <option value="Restaurant">Restaurant</option>
                                <option value="Shared Ownership">Shared Ownership</option>
                                <option value="Sports">Sports</option>
                                <option value="Staking">Staking</option>
                                <option value="Startup">Startup</option>
                                <option value="Token">Token</option>
                                <option value="Token-Gated Community">Token-Gated Community</option>
                                <option value="Token-Gated Content">Token-Gated Content</option>
                                <option value="Toy">Toy</option>
                                <option value="Virtual Events">Virtual Events</option>

                            </Field>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="form-group">
                        <div>
                            <label>Roadmap Category #2</label>
                            <Field
                                className="form-select"
                                name="roadmapCategory2"
                                component="select"
                                type="select"
                                label="Roadmap Category #2">
                                <option value=""></option>
                                <option value="Access Future NFTs">Access Future NFTs</option>
                                <option value="Airdrop">Airdrop</option>
                                <option value="Allow List Other NFTs">Allow List Other NFTs</option>
                                <option value="Analysis">Analysis</option>
                                <option value="Android App">Android App</option>
                                <option value="Art">Art</option>
                                <option value="Beta Access">Beta Access</option>
                                <option value="Carbon Offset">Carbon Offset</option>
                                <option value="CC0">CC0</option>
                                <option value="Celebrate Diversity">Celebrate Diversity</option>
                                <option value="Charity">Charity</option>
                                <option value="Coffee">Coffee</option>
                                <option value="Community Fund">Community Fund</option>
                                <option value="DAO">DAO</option>
                                <option value="Discount">Discount</option>
                                <option value="Education">Education</option>
                                <option value="Free Shipping">Free Shipping</option>
                                <option value="Game">Game</option>
                                <option value="iPhone App">iPhone App</option>
                                <option value="IRL Events">IRL Events</option>
                                <option value="Merchandise">Merchandise</option>
                                <option value="Metaverse">Metaverse</option>
                                <option value="Newsletter">Newsletter</option>
                                <option value="NFT Calendar">NFT Calendar</option>
                                <option value="Movie">Movie</option>
                                <option value="Music">Music</option>
                                <option value="Other">Other</option>
                                <option value="PFP">PFP</option>
                                <option value="Physical Product">Physical Product</option>
                                <option value="Play-to-Earn">Play-to-Earn</option>
                                <option value="Product Roadmap Influence">Product Roadmap Influence</option>
                                <option value="Q&As">Q&As</option>
                                <option value="Raffle">Raffle</option>
                                <option value="Rent / Lend">Rent / Lend</option>
                                <option value="Research">Research</option>
                                <option value="Restaurant">Restaurant</option>
                                <option value="Shared Ownership">Shared Ownership</option>
                                <option value="Sports">Sports</option>
                                <option value="Staking">Staking</option>
                                <option value="Startup">Startup</option>
                                <option value="Token">Token</option>
                                <option value="Token-Gated Community">Token-Gated Community</option>
                                <option value="Token-Gated Content">Token-Gated Content</option>
                                <option value="Toy">Toy</option>
                                <option value="Virtual Events">Virtual Events</option>

                            </Field>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="form-group">
                        <div>
                            <label>Roadmap Category #3</label>
                            <Field
                                className="form-select"
                                name="roadmapCategory3"
                                component="select"
                                type="select"
                                label="Roadmap Category #3">
                                <option value=""></option>
                                <option value="Access Future NFTs">Access Future NFTs</option>
                                <option value="Airdrop">Airdrop</option>
                                <option value="Allow List Other NFTs">Allow List Other NFTs</option>
                                <option value="Analysis">Analysis</option>
                                <option value="Android App">Android App</option>
                                <option value="Art">Art</option>
                                <option value="Beta Access">Beta Access</option>
                                <option value="Carbon Offset">Carbon Offset</option>
                                <option value="CC0">CC0</option>
                                <option value="Celebrate Diversity">Celebrate Diversity</option>
                                <option value="Charity">Charity</option>
                                <option value="Coffee">Coffee</option>
                                <option value="Community Fund">Community Fund</option>
                                <option value="DAO">DAO</option>
                                <option value="Discount">Discount</option>
                                <option value="Education">Education</option>
                                <option value="Free Shipping">Free Shipping</option>
                                <option value="Game">Game</option>
                                <option value="iPhone App">iPhone App</option>
                                <option value="IRL Events">IRL Events</option>
                                <option value="Merchandise">Merchandise</option>
                                <option value="Metaverse">Metaverse</option>
                                <option value="Newsletter">Newsletter</option>
                                <option value="NFT Calendar">NFT Calendar</option>
                                <option value="Movie">Movie</option>
                                <option value="Music">Music</option>
                                <option value="Other">Other</option>
                                <option value="PFP">PFP</option>
                                <option value="Physical Product">Physical Product</option>
                                <option value="Play-to-Earn">Play-to-Earn</option>
                                <option value="Product Roadmap Influence">Product Roadmap Influence</option>
                                <option value="Q&As">Q&As</option>
                                <option value="Raffle">Raffle</option>
                                <option value="Rent / Lend">Rent / Lend</option>
                                <option value="Research">Research</option>
                                <option value="Restaurant">Restaurant</option>
                                <option value="Shared Ownership">Shared Ownership</option>
                                <option value="Sports">Sports</option>
                                <option value="Staking">Staking</option>
                                <option value="Startup">Startup</option>
                                <option value="Token">Token</option>
                                <option value="Token-Gated Community">Token-Gated Community</option>
                                <option value="Token-Gated Content">Token-Gated Content</option>
                                <option value="Toy">Toy</option>
                                <option value="Virtual Events">Virtual Events</option>

                            </Field>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{marginTop:"10px"}} >
                <div className="col-md-4">
                    <div className="form-group">
                        <div>
                            <label>Roadmap Category #4</label>
                            <Field
                                className="form-select"
                                name="roadmapCategory4"
                                component="select"
                                type="select"
                                label="Roadmap Category #4">
                                <option value=""></option>
                                <option value="Access Future NFTs">Access Future NFTs</option>
                                <option value="Airdrop">Airdrop</option>
                                <option value="Allow List Other NFTs">Allow List Other NFTs</option>
                                <option value="Analysis">Analysis</option>
                                <option value="Android App">Android App</option>
                                <option value="Art">Art</option>
                                <option value="Beta Access">Beta Access</option>
                                <option value="Carbon Offset">Carbon Offset</option>
                                <option value="CC0">CC0</option>
                                <option value="Celebrate Diversity">Celebrate Diversity</option>
                                <option value="Charity">Charity</option>
                                <option value="Coffee">Coffee</option>
                                <option value="Community Fund">Community Fund</option>
                                <option value="DAO">DAO</option>
                                <option value="Discount">Discount</option>
                                <option value="Education">Education</option>
                                <option value="Free Shipping">Free Shipping</option>
                                <option value="Game">Game</option>
                                <option value="iPhone App">iPhone App</option>
                                <option value="IRL Events">IRL Events</option>
                                <option value="Merchandise">Merchandise</option>
                                <option value="Metaverse">Metaverse</option>
                                <option value="Newsletter">Newsletter</option>
                                <option value="NFT Calendar">NFT Calendar</option>
                                <option value="Movie">Movie</option>
                                <option value="Music">Music</option>
                                <option value="Other">Other</option>
                                <option value="PFP">PFP</option>
                                <option value="Physical Product">Physical Product</option>
                                <option value="Play-to-Earn">Play-to-Earn</option>
                                <option value="Product Roadmap Influence">Product Roadmap Influence</option>
                                <option value="Q&As">Q&As</option>
                                <option value="Raffle">Raffle</option>
                                <option value="Rent / Lend">Rent / Lend</option>
                                <option value="Research">Research</option>
                                <option value="Restaurant">Restaurant</option>
                                <option value="Shared Ownership">Shared Ownership</option>
                                <option value="Sports">Sports</option>
                                <option value="Staking">Staking</option>
                                <option value="Startup">Startup</option>
                                <option value="Token">Token</option>
                                <option value="Token-Gated Community">Token-Gated Community</option>
                                <option value="Token-Gated Content">Token-Gated Content</option>
                                <option value="Toy">Toy</option>
                                <option value="Virtual Events">Virtual Events</option>

                            </Field>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="form-group">
                        <div>
                            <label>Roadmap Category #5</label>
                            <Field
                                className="form-select"
                                name="roadmapCategory5"
                                component="select"
                                type="select"
                                label="Roadmap Category #5">
                                <option value=""></option>
                                <option value="Access Future NFTs">Access Future NFTs</option>
                                <option value="Airdrop">Airdrop</option>
                                <option value="Allow List Other NFTs">Allow List Other NFTs</option>
                                <option value="Analysis">Analysis</option>
                                <option value="Android App">Android App</option>
                                <option value="Art">Art</option>
                                <option value="Beta Access">Beta Access</option>
                                <option value="Carbon Offset">Carbon Offset</option>
                                <option value="CC0">CC0</option>
                                <option value="Celebrate Diversity">Celebrate Diversity</option>
                                <option value="Charity">Charity</option>
                                <option value="Coffee">Coffee</option>
                                <option value="Community Fund">Community Fund</option>
                                <option value="DAO">DAO</option>
                                <option value="Discount">Discount</option>
                                <option value="Education">Education</option>
                                <option value="Free Shipping">Free Shipping</option>
                                <option value="Game">Game</option>
                                <option value="iPhone App">iPhone App</option>
                                <option value="IRL Events">IRL Events</option>
                                <option value="Merchandise">Merchandise</option>
                                <option value="Metaverse">Metaverse</option>
                                <option value="Newsletter">Newsletter</option>
                                <option value="NFT Calendar">NFT Calendar</option>
                                <option value="Movie">Movie</option>
                                <option value="Music">Music</option>
                                <option value="Other">Other</option>
                                <option value="PFP">PFP</option>
                                <option value="Physical Product">Physical Product</option>
                                <option value="Play-to-Earn">Play-to-Earn</option>
                                <option value="Product Roadmap Influence">Product Roadmap Influence</option>
                                <option value="Q&As">Q&As</option>
                                <option value="Raffle">Raffle</option>
                                <option value="Rent / Lend">Rent / Lend</option>
                                <option value="Research">Research</option>
                                <option value="Restaurant">Restaurant</option>
                                <option value="Shared Ownership">Shared Ownership</option>
                                <option value="Sports">Sports</option>
                                <option value="Staking">Staking</option>
                                <option value="Startup">Startup</option>
                                <option value="Token">Token</option>
                                <option value="Token-Gated Community">Token-Gated Community</option>
                                <option value="Token-Gated Content">Token-Gated Content</option>
                                <option value="Toy">Toy</option>
                                <option value="Virtual Events">Virtual Events</option>

                            </Field>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="form-group">
                        <div>
                            <label>Roadmap Category #6</label>
                            <Field
                                className="form-select"
                                name="roadmapCategory6"
                                component="select"
                                type="select"
                                label="Roadmap Category #6">
                                <option value=""></option>
                                <option value="Access Future NFTs">Access Future NFTs</option>
                                <option value="Airdrop">Airdrop</option>
                                <option value="Allow List Other NFTs">Allow List Other NFTs</option>
                                <option value="Analysis">Analysis</option>
                                <option value="Android App">Android App</option>
                                <option value="Art">Art</option>
                                <option value="Beta Access">Beta Access</option>
                                <option value="Carbon Offset">Carbon Offset</option>
                                <option value="CC0">CC0</option>
                                <option value="Celebrate Diversity">Celebrate Diversity</option>
                                <option value="Charity">Charity</option>
                                <option value="Coffee">Coffee</option>
                                <option value="Community Fund">Community Fund</option>
                                <option value="DAO">DAO</option>
                                <option value="Discount">Discount</option>
                                <option value="Education">Education</option>
                                <option value="Free Shipping">Free Shipping</option>
                                <option value="Game">Game</option>
                                <option value="iPhone App">iPhone App</option>
                                <option value="IRL Events">IRL Events</option>
                                <option value="Merchandise">Merchandise</option>
                                <option value="Metaverse">Metaverse</option>
                                <option value="Newsletter">Newsletter</option>
                                <option value="NFT Calendar">NFT Calendar</option>
                                <option value="Movie">Movie</option>
                                <option value="Music">Music</option>
                                <option value="Other">Other</option>
                                <option value="PFP">PFP</option>
                                <option value="Physical Product">Physical Product</option>
                                <option value="Play-to-Earn">Play-to-Earn</option>
                                <option value="Product Roadmap Influence">Product Roadmap Influence</option>
                                <option value="Q&As">Q&As</option>
                                <option value="Raffle">Raffle</option>
                                <option value="Rent / Lend">Rent / Lend</option>
                                <option value="Research">Research</option>
                                <option value="Restaurant">Restaurant</option>
                                <option value="Shared Ownership">Shared Ownership</option>
                                <option value="Sports">Sports</option>
                                <option value="Staking">Staking</option>
                                <option value="Startup">Startup</option>
                                <option value="Token">Token</option>
                                <option value="Token-Gated Community">Token-Gated Community</option>
                                <option value="Token-Gated Content">Token-Gated Content</option>
                                <option value="Toy">Toy</option>
                                <option value="Virtual Events">Virtual Events</option>

                            </Field>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{ color: "#ffffff", marginTop:"25px"}}>
                <div className="col-md-12">
                        <h3>
                            Likes / Dislikes
                        </h3>
                        <hr></hr>
                </div>
            </div>

            <div className="row" >
                <div className="col-12">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="like1"
                                component={RenderField}
                                type="text"
                                placeholder="Enter the first thing you like"
                                label="Like"
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="like2"
                                component={RenderField}
                                type="text"
                                placeholder="Enter the second thing you like"
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="like3"
                                component={RenderField}
                                type="text"
                                placeholder="Enter the third thing you like"
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="like4"
                                component={RenderField}
                                type="text"
                                placeholder="Enter the fourth thing you like"
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="like5"
                                component={RenderField}
                                type="text"
                                placeholder="Enter the fifth thing you like"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{marginTop:"20px"}}>
                <div className="col-12">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="dislike1"
                                component={RenderField}
                                type="text"
                                placeholder="Enter the first thing you dislike"
                                label="Dislike"
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="dislike2"
                                component={RenderField}
                                type="text"
                                placeholder="Enter the second thing you dislike"
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="dislike3"
                                component={RenderField}
                                type="text"
                                placeholder="Enter the third thing you dislike"
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="dislike4"
                                component={RenderField}
                                type="text"
                                placeholder="Enter the fourth thing you dislike"
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="dislike5"
                                component={RenderField}
                                type="text"
                                placeholder="Enter the fifth thing you dislike"
                            />
                        </div>
                    </div>
                </div>
            </div>
                </>
        )
    }
}