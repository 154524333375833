
import { connect } from 'react-redux';

import useTokenGatedAccess from '../../hooks/useTokenGatedAccess';

const TokenGatedContent = ({ wallet }) => {

    const account = wallet.account; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, walletAccessTokens } = useTokenGatedAccess(account);

    const renderConnectedAcount = () => {
        if (!account) return <p style={{ color: "white" }}>Please Connect Wallet</p>;
        return <p style={{ color: "white" }}>Account: {account}</p>
    }

    const renderWalletAccessTokens = () => {
        return walletAccessTokens?.length ? <p style={{ color: "white" }}>Your Access Tokens: {walletAccessTokens.join(', ')}</p> : null
    }

    const renderAccessMessage = () => {
        if (!account) return <p style={{ color: "white" }}>Please Connect Wallet</p>;

        switch(access) {
            case null:
                return <p style={{ color: "white" }}>⏳ Please wait ... if it's been 5 seconds, refresh the page</p>;
            case false:
                return <p style={{ color: "white" }}>Access Denied. Please <a href="/mint">mint a token</a>.</p>;
            case true:
                return <p style={{ color: "white" }}>Access Granted</p>;
            default:
                return <p style={{ color: "white" }}>Something went wrong. Do you <a href="/mint">have a token</a>?</p>;
        }
    }

    return (
        <div style={{ textAlign: 'center' }}>
            <h1 style={{ color: "white" }}>Token-Gated Content Header</h1>
            {renderConnectedAcount()}
            {renderWalletAccessTokens()}
            {renderAccessMessage()}
        </div>
    );
};

function mapStateToProps({ wallet }) {
    return { wallet };
}

export default connect(mapStateToProps)(TokenGatedContent);
