import { FETCH_USER, FETCH_CURRENT_ETHEREUM_ACCOUNT } from '../actions/types';
// eslint-disable-next-line
export default function(state = null, action) {

    switch(action.type) {
        case FETCH_USER:
            return action.payload || false;
        case FETCH_CURRENT_ETHEREUM_ACCOUNT:
            return action.payload || false;
        default:
            return state;
    }
}
