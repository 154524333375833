import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import useWalletLinkStatus from '../../../hooks/useWalletLinkStatus';

import PleaseWait from '../../access/PleaseWait';

const ConfirmLinkedWallet = ({ auth, wallet, registeredUser }) => {

    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determine if this account has access
    const { userData } = useWalletLinkStatus(account); // eslint-disable-line no-unused-vars    
    //console.log(userData);
    const [done, setDone] = useState('');
    
    useEffect(() => {
        const fetchAndSetRejectLinkWallet = async (account, userData) => {            
            const response = await axios.post('/api/post/link-wallet/reject', { address: account, user_id: userData.user_id });
            //console.log(response);
            setDone(response.data.done);
        }
        if (userData.user_id !== undefined) fetchAndSetRejectLinkWallet(account, userData);
    }, [account, userData])    

    const renderDone = () => {        

        switch(done) {
            case null:
                return <PleaseWait />;
            case true:
                return <Redirect to="/register"/>;
        }
    }

    return (
        <div style={{ backgroundColor: "#000000", color: "#ffffff"}}>            
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderDone() }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser }) {
    return { auth, wallet, registeredUser };
}

export default connect(mapStateToProps)(ConfirmLinkedWallet);
