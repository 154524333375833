import { useState, useEffect } from "react";
import axios from 'axios';

const Ratings = ({ nftCollectionID }) => {

    const [ratingsByContract, setRatingsByContract] = useState([])

    useEffect(() => {
        const fetchRatingsByContract = async () => {
            const response = await axios.get(`/api/collection/${nftCollectionID}/ratings_by_contract`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            if (nftCollectionID) setRatingsByContract(response.data.ratingsByContract)     
                  
        }
        fetchRatingsByContract()
    }, [nftCollectionID])    

    const TableRow = ({ contract }) => {
        const { address, name, collection_image_url } = contract;

        const totalVotes = (parseInt(contract.up_votes) + parseInt(contract.down_votes));
        const upVotePercent = ((parseInt(contract.up_votes) / totalVotes) * 100.0).toFixed(0);
        const downVotePercent = ((parseInt(contract.down_votes) / totalVotes) * 100.0).toFixed(0);
        
        return <>
       <tr>
            <td><img src={collection_image_url} alt={collection_image_url ? address : address.slice(0, 14)} width={30} />&nbsp;{name}</td>
            <td>{contract.up_votes}</td>
            <td>{isNaN(upVotePercent) ? null : upVotePercent} {isNaN(upVotePercent) ? null : "%"}</td>
            <td>{contract.down_votes}</td>
            <td>{isNaN(downVotePercent) ? null : downVotePercent} {isNaN(downVotePercent) ? null : "%"}</td>
        </tr>        
        </>
    }

    return <>
    <div className="row table-responsive text-nowrap my-3" style={{fontSize:"1em"}}>                
        <div className="col-12">
            <table className="table" style={{ color:"#ffffff" }}>
                <thead>
                    <tr style={{ backgroundColor:"#1F2731"}}>
                        <th scope="col">NFT Community</th>
                        <th scope="col">Likes</th>
                        <th scope="col">Likes (%)</th>
                        <th scope="col">Dislikes</th>
                        <th scope="col">Dislikes (%)</th>                                
                    </tr>
                </thead>
            
    
                <tbody>
                    {ratingsByContract.length > 0 ? ratingsByContract.map(contract => <TableRow key={contract.id} contract={contract} />) : <tr><td colSpan="5">No ratings for this NFT in our platform. Vote now!</td></tr>}                    
                </tbody>
            </table>
        </div>
    </div>
    
    
    
        

    </>;
}

export default Ratings;
