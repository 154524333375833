import React, { Component, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, propTypes } from 'redux-form';
import RenderField from './RenderField'
import { change } from 'redux-form'

let userInput

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
    imageUrls: PropTypes.instanceOf(Array),
    ids: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: [],
    imageUrls: [],
    ids: []
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: null,
      // TEST
      newUserInput: ""
    };
  }

  updateNewUserInput ( value  ) {
    this.setState(() => {
      return {newUserInput: value}
    });
  }

  onChange = e => {    

    const { suggestions } = this.props;
    const { imageUrls } = this.props;
    const { ids } = this.props;
    let suggestionZip = [], i = -1;
    while ( suggestions[++i] ) { 
        suggestionZip.push( [ suggestions[i], imageUrls[i], ids[i]  ] );
      }

    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestionZip.filter(
      x =>
        x[0].toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.updateNewUserInput(e.currentTarget.value);

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value      
    });
    //console.log(this.state.userInput, e.currentTarget.value, this.state.newUserInput);
  };

  onClick = e => {
    //console.log(e);
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [[]],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
    //console.log(userInput);
    //console.log(e); 
    if ( e.target.childNodes[2] === undefined ) {
      //console.log(e.target.childNodes[0].innerText); 
      document.getElementsByName('collectionId')[0].value = e.target.childNodes[0].innerText;      
      //console.log(document.getElementsByName('collectionId')[0].value);
    }
    else {
      //console.log(e.target.childNodes[1].innerText); 
      document.getElementsByName('collectionId')[0].value = e.target.childNodes[1].innerText;
      //console.log(document.getElementsByName('collectionId')[0].value);
    }
    //console.log(e.target);    
    //console.log(e.target.getElementById("collectionIdValue").innerText)
    document.getElementsByName('search')[0].value = e.currentTarget.innerText;
    //console.log(document.getElementsByName('collectionId')[0].value);
    //console.log(document.getElementsByName('search')[0].value);
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key
    // if (e.keyCode === 13) {
    //   this.setState({
    //     activeSuggestion: 0,
    //     showSuggestions: false,
    //     userInput: filteredSuggestions[activeSuggestion][0]
    //   });
    // }
    // User pressed the up arrow
    if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;
    const { imageUrls } = this.props;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              } 
              //console.log(index);
                return (
                    <li className={className} key={index} onClick={onClick} onChange={onChange}>
                      {filteredSuggestions[index][1] !== '' ? <img src={filteredSuggestions[index][1]} width="25" height="25"></img> : null}
                      <span id="collectionIdValue" hidden>{filteredSuggestions[index][2]}</span>{filteredSuggestions[index][0]}
                    </li>)
            })}
          </ul>
        );
      } else {                
        document.getElementsByName('collectionId')[0].value = '';
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>Collection not found.</em>
          </div>
        );
      }
    }
  
    const { initialSearch } = this.props;
    return (
      <>
        <Field
          name='search'
          className="form-control"
          type="text"
          component={RenderField}
          onChange={onChange}
          onClick={onClick}
          onKeyDown={onKeyDown}
          value1={this.state.userInput === null ? ( initialSearch !== undefined ? decodeURIComponent(initialSearch) : '' ) : this.state.userInput}
        />
        {suggestionsListComponent}

        

        <input type="hidden" name="collectionId" />
      </>
    );
  }
}

export default Autocomplete;

/*<Field
          name='collectionId'
          type='hidden'
          component={RenderField}
        />*/