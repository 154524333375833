import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';
import axios from 'axios';

import Autocomplete from "./SearchBarAutocompleteMobile";
require("./searchStyles.css");


const SearchForm = ({ initialValues, handleSubmit, onSubmit}) => {

    const submitForm = () => {
        return (
            handleSubmit( values => onSubmit({
                ...values, search: document.getElementsByName('searchBar')[0].value, collectionId: document.getElementsByName('collectionId')[0].value
            }) )
        )
    }

    return (
        <>
            <div className="col-12" style={{width:"168px", marginRight:"20px"}}>
                {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
                <form name="searchBarAutocomplete" onSubmit={submitForm()}>                                        

                    <div className="row">    
                        <div className="col-12">
                            <div className="form-group">
                                <div className="row">                                    
                                    <div className="col-10" style={{paddingRight:"0px"}}>
                                        <Field
                                            className="form-control"
                                            name="searchBar"
                                            component={Autocomplete}                                            
                                            submitForm={submitForm()}
                                            placeholder="Search"      
                                            initialSearch={initialValues?.search !== undefined ? initialValues.search : ''}                                      
                                            label=""
                                         />
                                    </div>
                                    <div className="col-2" style={{paddingLeft:"0px"}}>
                                        <button className="btn btn-light" >
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#7945E1" 
                                                className="bi bi-search" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                            </svg>
                                        </button>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>                    
                </form>                
            </div>
        </>
    );
}

const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}        

    // console.log('errors', errors)
    return errors
}


function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'allNFTsFiltersForm',
    validate,
})(SearchForm));