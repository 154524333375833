import { useState, useMemo, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import axios from 'axios';

import Pagination from '../../pagination/Pagination';

let PageSize = 50;

const BenefitsRow = ({ benefit }) => {    

    const convertTime = (time) => {
        if (time !== null && time !== undefined) {
            const timeSplit = time.split(':');  
    
            let hour = timeSplit[0] % 12;
            if (hour === 0) { hour = 12 };
    
            let timePeriod;
            if (timeSplit[0] / 12 >= 1) { timePeriod = ' PM' } else { timePeriod = ' AM'}
    
            return hour + ':' + timeSplit[1] + timePeriod + ' ET';
        }
        return '';
    }

    const convertDate = (date) => {
        const dateFormatted = new Date(date);
        const dateConverted = dateFormatted.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' }); 

        return dateConverted;
    }

    const [benefitRedeemed, setBenefitRedeemed] = useState(benefit.redeemed_ts !== null ? true : false);

    const redeemBenefit = async (redeemStatus, benefit_id) => {
        console.log(redeemStatus, benefit_id);
        try {
            const response = await axios.put(`/api/put/benefit/redeem`, { redeemStatus, benefit_id });         
            setBenefitRedeemed(redeemStatus);
            
        } catch (error) {
            console.error(error.response);
        }
        
    }
    
    return <>
    <tr style={{backgroundColor:"#140510", border:"1px #000000 solid", color:"rgba(255,255,255,.8)"}}>
        <td>
            <input className="form-check-input" type="checkbox" checked={benefitRedeemed === true ? 'checked' : ''} onChange={e => redeemBenefit(e.target.checked, benefit.benefit_id)} />
        </td>
        <td>{benefit.benefit_type}</td>
        <td style={{whiteSpace: "normal"}}>
            {benefit.claim_deadline_date === null ? 'Expiration TBD' : convertDate(benefit.claim_deadline_date)}<br />{convertTime(benefit.claim_deadline_time)}<br /><a href={benefit.url} target="_BLANK" rel="noreferrer" className="justify-content-bottom" style={{color:"rgba(255,255,255,.8)"}}>{benefit.url !== null ? 'Link' : null}</a>
        </td>
        <td style={{whiteSpace: "normal"}}>{benefit.description}</td>
    </tr>
    </>;
}

const UserNFTs = ({ initialUserNFTs, onboarding, initialWalletCount, free }) => {    
 
    const [totalCount, setTotalCount] = useState( initialUserNFTs.length );    
    const [userNFTs, setUserNFTs] = useState([initialUserNFTs]);
    const [walletCount, setWalletCount] = useState(initialWalletCount > 0 ? initialWalletCount : 1);
    const [alertCount, setAlertCount] = useState(0);

    const filters = '';
    
    useEffect(() => {
        
        const fetchUserNFTs = async () => {
            const response = await axios.get('/api/get/dashboard/user-nfts', {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setUserNFTs(response.data[0]);
            setTotalCount(response.data[0].length);
        }
        const fetchWalletCount = async () => {
            const response = await axios.get('/api/get/wallet-count', {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setWalletCount(response.data.wallet_count * 1);            
        }
        const fetchAlertCount = async () => {
            const response = await axios.get('/api/get/alert-count', {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setAlertCount(response.data.alert_count * 1);
        }
        fetchUserNFTs();
        fetchWalletCount();
        fetchAlertCount();
    }, []);

    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);   

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;        
        return userNFTs.slice(firstPageIndex, lastPageIndex);        
    }, [currentPage, userNFTs]);

    const columnWidthMobile = (onboarding ? "col-10 col-lg-8 table-responsive text-nowrap" : "col-12 table-responsive text-nowrap" ) + " d-block d-sm-none ";

    const columnWidthDesktop = (onboarding ? "col-10 col-lg-8 table-responsive text-nowrap" : "col-12 table-responsive text-nowrap" ) + " d-none d-sm-block ";

    const alertCountUpdate = async ( ) => {        
        try { 
            const alertCount = await axios.get('/api/get/alert-count', {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setAlertCount(alertCount.data.alert_count * 1);

            const userNFTs = await axios.get('/api/get/dashboard/user-nfts', {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setUserNFTs(userNFTs.data[0]);
            setTotalCount(userNFTs.data[0].length);
        } catch (error) {
            // This should be visible in FS and (hopefully) alert FS of the issue
            console.error(error.response)
        }    
    }

    const TableRowMobile = ({ nft, onboarding, walletCount, alertCount }) => {


        const { contract_id, collection_name, collection_image, collection_slug, hide, alert, benefits_count, redeemed_benefits_count } = nft;        
    
        const [alertChecked, setAlertChecked] = useState(alert);
        const [hideChecked, setHideChecked] = useState(hide);
        const [showBenefits, setShowBenefits] = useState(false);
        const [benefitsList, setBenefitsList] = useState([]);    
    
        const hideNFTUpdate = async ( hide, contract_id ) => {        
            const values = { hide, contract_id };
            try { 
                await axios.post(`/api/post/contract/hide`, values);
                setHideChecked( hide );
            } catch (error) {
                // This should be visible in FS and (hopefully) alert FS of the issue
                console.error(error.response)
            }    
        }

        const alertNFTUpdate = async ( alert, contract_id ) => {        
            
            const values = { alert, contract_id };
            try { 
                await axios.post(`/api/post/contract/alert`, values);                
                //console.log(alertChecked);
                await alertCountUpdate();
                await setAlertChecked( alert );
            } catch (error) {
                // This should be visible in FS and (hopefully) alert FS of the issue
                console.error(error.response)
            }    
        }
    
        const fetchBenefits = async ( contract_id ) => {        
            try { 
                const response = await axios.get(`/api/get/nft-benefits/${contract_id}`);
                setBenefitsList( response.data[0] );
            } catch (error) {
                // This should be visible in FS and (hopefully) alert FS of the issue
                console.error(error.response)
            }    
        }
        
        const renderCollectionImage = ( collection_image, collection_slug ) => {
    
            if ( collection_slug !== null ) {
                const collectionPageURL = '/collection/' + collection_slug;
                return <>
                    <Link to={{ pathname: collectionPageURL}} style={{ color:"rgba(255,255,255,.8)"}}>
                        <img
                            alt=""
                            src={collection_image}
                            height="30"
                            className="d-inline-block align-top rounded"
                        /> 
                    </Link>
                </>;
            }
            else {
                return <>
                    <img
                        alt=""
                        src={collection_image}
                        height="30"                    
                        className="d-inline-block align-top rounded"
                    /> 
                </>;
            }
        }
    
        const renderCollectionName = ( collection_name, collection_slug ) => {
    
            if ( collection_slug !== null ) {
                const collectionPageURL = '/collection/' + collection_slug;
                return <>
                    <Link to={{ pathname: collectionPageURL}} style={{ color:"rgba(255,255,255,.8)", width:"100px"}}>
                        {collection_name}
                    </Link>
                </>;
            }
            else {
                return <>
                    {collection_name}
                </>;
            }
        }
    
        return <>
        { hideChecked !== true ?
            <>
            {/*MOBILE*/}
            <tr className="d-flex" style={{backgroundColor:"#280B22", border:"1px #000000 solid", color:"rgba(255,255,255,.8)"}}>            
                <td style={{width:"40px", paddingRight:"0px"}}>
                    <div className="row">
                        <span>
                            {renderCollectionImage( collection_image, collection_slug )}
                        </span>
                    </div>
                </td>
                {/*DESKTOP*/}        
                <td className="d-md-block d-none" style={{width: "600px", overflow:"hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", border:"1px #280B22 solid"}}>
                    <span style={{fontSize:"1.25em"}}>
                        {renderCollectionName( collection_name, collection_slug )}
                    </span>            
                </td>
                      
                <td className="d-md-none d-block" style={{width: "120px", overflow: "clip", overflowClipMargin: "200px", textOverflow: "ellipsis", whiteSpace: "nowrap", border:"1px #280B22 solid", paddingLeft:"0px", borderCollapse: "collapse"}}>
                    <span style={{fontSize:"1em"}}>
                        {renderCollectionName( collection_name, collection_slug )}
                    </span>
                </td>
                {free ? <td style={{textAlign:"center", width:"55px"}}>
                    <input className="form-check-input" type="checkbox" checked={alertChecked === true ? 'checked' : ''} onChange={e => alertNFTUpdate(e.target.checked, contract_id)} disabled={alertCount < walletCount || alertChecked ? false : true} />
                </td> : '' }
                {onboarding ? '' : <td style={{textAlign:"center", width:"55px"}}>
                    <input className="form-check-input" type="checkbox" checked={hideChecked === true ? 'checked' : ''} onChange={e => hideNFTUpdate(e.target.checked, contract_id)} />
                </td>}
                <td style={{textAlign:"center", width:"85px"}}>
                    <span style={{fontSize:"1em"}}>
                       {redeemed_benefits_count} of {benefits_count} {benefits_count > 0 && !onboarding ? <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#ffffff" 
                                                className="bi bi-chevron-down" 
                                                viewBox="0 0 16 16"
                                                onClick={async () => {
                                                    setShowBenefits(!showBenefits);
                                                    fetchBenefits(contract_id);
                                                }}
                                            >
                                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                            : 
                                            null 
                                        }
                    </span>
                </td>
            </tr>
            
            {showBenefits === true && benefitsList.length > 0 ?
                <tr>
                    <td colSpan={4} style={{borderBottom:"1px #000000 solid"}}>
                        <div className="col-12 table-responsive text-nowrap">
                            <table className="table">
                                <tbody>
                                    {benefitsList.length > 0 ? benefitsList.map(benefit => <BenefitsRow key={benefit.id} benefit={benefit}  />) : null}
                                </tbody>
                            </table>     
                        </div> 
                    </td>      
                </tr>
                : null
            }
            </>
            :
            null
            }
        </>;
    }
    
    const TableRowDesktop = ({ nft, onboarding }) => {
    
    
        const { contract_id, collection_name, collection_image, collection_slug, hide, alert, benefits_count, redeemed_benefits_count } = nft;        
    
        //make sure that the user is taken to the top of the page each time it loads
        useEffect(() => {
            window.scrollTo(0, 0)
        }, [])    
    
        const [alertChecked, setAlertChecked] = useState(alert);
        const [hideChecked, setHideChecked] = useState(hide);
        const [showBenefits, setShowBenefits] = useState(false);
        const [benefitsList, setBenefitsList] = useState([]);
    
        const hideNFTUpdate = async ( hide, contract_id ) => {        
            const values = { hide, contract_id };
            try { 
                await axios.post(`/api/post/contract/hide`, values);
                setHideChecked( hide );
            } catch (error) {
                // This should be visible in FS and (hopefully) alert FS of the issue
                console.error(error.response)
            }    
        }
    
        const alertNFTUpdate = async ( alert, contract_id ) => {        
            
            const values = { alert, contract_id };
            try { 
                await axios.post(`/api/post/contract/alert`, values);                
                //console.log(alertChecked);
                await alertCountUpdate();
                await setAlertChecked( alert );
            } catch (error) {
                // This should be visible in FS and (hopefully) alert FS of the issue
                console.error(error.response)
            }    
        }
    
        const fetchBenefits = async ( contract_id ) => {        
            try { 
                const response = await axios.get(`/api/get/nft-benefits/${contract_id}`);
                setBenefitsList( response.data[0] );
            } catch (error) {
                // This should be visible in FS and (hopefully) alert FS of the issue
                console.error(error.response)
            }    
        }
        
        const renderCollectionImage = ( collection_image, collection_slug ) => {
    
            if ( collection_slug !== null ) {
                const collectionPageURL = '/collection/' + collection_slug;
                return <>
                    <Link to={{ pathname: collectionPageURL}} style={{ color:"rgba(255,255,255,.8)"}}>
                        <img
                            alt=""
                            src={collection_image}
                            height="30"
                            className="d-inline-block align-top rounded"
                        /> 
                    </Link>
                </>;
            }
            else {
                return <>
                    <img
                        alt=""
                        src={collection_image}
                        height="30"                    
                        className="d-inline-block align-top rounded"
                    /> 
                </>;
            }
        }
    
        const renderCollectionName = ( collection_name, collection_slug ) => {
    
            if ( collection_slug !== null ) {
                const collectionPageURL = '/collection/' + collection_slug;
                return <>
                    <Link to={{ pathname: collectionPageURL}} style={{ color:"rgba(255,255,255,.8)", width:"100px"}}>
                        {collection_name}
                    </Link>
                </>;
            }
            else {
                return <>
                    {collection_name}
                </>;
            }
        }
        
        
        return <>
        { hideChecked !== true ?
            <>
            
            {/*DESKTOP*/}     
            <tr style={{backgroundColor:"#280B22", border:"1px #000000 solid", color:"rgba(255,255,255,.8)"}}>            
                <td style={{width:"40px", paddingRight:"0px"}}>
                    <div className="row">
                        <span>
                            {renderCollectionImage( collection_image, collection_slug )}
                        </span>
                    </div>
                </td>
                {/*DESKTOP*/}        
                <td style={{width: "600px", overflow:"hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", border:"1px #280B22 solid"}}>
                    <span style={{fontSize:"1.25em"}}>
                        {renderCollectionName( collection_name, collection_slug )}
                    </span>            
                </td>
                                  
                {free ? <td style={{textAlign:"center"}}>
                    <input className="form-check-input" type="checkbox" checked={alertChecked === true ? 'checked' : ''} onChange={e => alertNFTUpdate(e.target.checked, contract_id)} disabled={alertCount < walletCount || alertChecked ? false : true} />
                </td> : '' }
                {onboarding ? '' : <td style={{textAlign:"center"}}>
                    <input className="form-check-input" type="checkbox" checked={hideChecked === true ? 'checked' : ''} onChange={e => hideNFTUpdate(e.target.checked, contract_id)} />
                </td>}
                <td style={{textAlign:"center"}}>
                    <span style={{fontSize:"1em"}}>
                       {redeemed_benefits_count} of {benefits_count} {benefits_count > 0 && !onboarding ? <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#ffffff" 
                                                className="bi bi-chevron-down" 
                                                viewBox="0 0 16 16"
                                                onClick={async () => {
                                                    setShowBenefits(!showBenefits);
                                                    fetchBenefits(contract_id);
                                                }}
                                            >
                                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                            : 
                                            null 
                                        }
                    </span>
                </td>
            </tr>
            {showBenefits === true && benefitsList.length > 0 ?
                <tr>
                    <td colSpan={4} style={{borderBottom:"1px #000000 solid"}}>
                        <div className="col-12 table-responsive text-nowrap">
                            <table className="table">
                                <tbody>
                                    {benefitsList.length > 0 ? benefitsList.map(benefit => <BenefitsRow key={benefit.id} benefit={benefit}  />) : null}
                                </tbody>
                            </table>     
                        </div> 
                    </td>      
                </tr>
                : null
            }
            </>
            :
            null
            }
        </>;
    }
    
    return (
        <>                        
        <div className="row mt-4">    
            
                {onboarding ? <div className="col-1 col-lg-2">&nbsp;</div> : ''}

                {/* MOBILE */}
                <div className={columnWidthMobile} style={{ padding:"10px"}} >
                    <table className="table" style={{ borderRadius: "1em", borderCollapse: "collapse", overflow:"hidden", width:"100%"}}>
                        <thead>
                            
                            <tr className="d-flex" style={{ backgroundColor:"#29194A", borderBottom:"2px transparent solid", color:"rgba(255,255,255,.8)"}}>
                                
                                <th colSpan="2" style={{textAlign:"left", width:"160px"}}>Collection</th>
                                {free ? <th style={{textAlign:"center", width:"55px"}}>Alert</th> : ''}
                                {onboarding ? '' : <th style={{textAlign:"center", width:"55px"}}>Hide</th>}
                                <th style={{textAlign:"center", width:"85px"}}>Benefits</th>                        
                            </tr>                                                 
                            
                        </thead>
                        
                        <tbody style={{ fontSize:".8em"}}>
                            {pageData.length > 0 ? pageData.map(nft => <TableRowMobile key={nft.contract_id} nft={nft} onboarding={onboarding} walletCount={walletCount} alertCount={alertCount} free={free}  />) : null}
                        </tbody>
                        
                        <tfoot style={{ backgroundColor:"#140510", textAlign: "center", color:"rgba(255,255,255,.8)", border:"1px #000000 solid"}}>
                            <tr>                     
                                <td colSpan="2">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={totalCount}
                                        pageSize={PageSize}
                                        filters={filters}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                {/* DESKTOP */}
                <div className={columnWidthDesktop} style={{ padding:"10px"}} >
                    <table className="table" style={{ borderRadius: "1em", borderCollapse: "collapse", overflow:"hidden", width:"100%"}}>
                        <thead>

                            
                            <tr style={{ backgroundColor:"#29194A", borderBottom:"2px transparent solid", color:"rgba(255,255,255,.8)"}}>
                                
                                <th colSpan="2" style={{textAlign:"left"}}>Collection</th>
                                {free ? <th style={{textAlign:"center"}}>Alert</th> : ''}
                                {onboarding ? '' : <th style={{textAlign:"center"}}>Hide</th>}
                                <th style={{textAlign:"center"}}>Benefits</th>                        
                            </tr>                        
                            
                        </thead>
                        
                        <tbody style={{ fontSize:".8em"}}>
                            {pageData.length > 0 ? pageData.map(nft => <TableRowDesktop key={nft.contract_id} nft={nft} onboarding={onboarding} walletCount={walletCount} alertCount={alertCount} free={free}  />) : null}
                        </tbody>
                        
                        <tfoot style={{ backgroundColor:"#140510", textAlign: "center", color:"rgba(255,255,255,.8)", border:"1px #000000 solid"}}>
                            <tr>                     
                                <td colSpan="2">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={totalCount}
                                        pageSize={PageSize}
                                        filters={filters}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                                                           
        </div>                
        
        </>
    );
};

function mapStateToProps({ form }) {
    return { form };
}

export default connect(mapStateToProps, actions)(UserNFTs);
