import React, { useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { connect } from 'react-redux';
import * as actions from '../../actions';

const LandingResearchBetter = ({ wallet, registeredUser, fetchRegisteredUser }) => {

    

    return ( 
        <>
            <div className="min-vh-100 container-fluid" style={{ backgroundColor: "#0F0C1D"}}>
                <Row style={{ paddingTop: "100px", paddingBottom: "50px", overflow:"hidden" }}>
                    <Col md={1}></Col>
                    <Col sm={12} lg={6} >       
                        <Row >
                        <div className="col-2">
                            <img
                            alt=""
                            src="/images/cryEmoji.png" 
                            height="50px"              
                            className="d-inline-block align-top"
                            />
                        </div>
                        <div className="col-2">
                            <img
                            alt=""
                            src="/images/discordMessages.png"
                            height="50px"  
                            className="d-inline-block align-top"
                            />
                        </div>
                        <div className="col-2">
                            <img
                            alt=""
                            src="/images/telegramMessages.png"
                            height="50px"
                            className="d-inline-block align-top"
                            />
                        </div>
                        <div className="col-2">
                            <img
                            alt=""
                            src="/images/twitterMessages.png"
                            height="50px"
                            className="d-inline-block align-top"
                            />
                        </div>
                        </Row>     
                        <Row style={{ paddingTop: "20px", color: "#ffffff", fontSize:"3em", lineHeight: "1em" }}>
                        <Col>There's a better way to research NFT mints</Col>
                        </Row>
                        <Row style={{ paddingTop: "20px" }}>
                        <Row style={{ paddingTop: "20px", color: "#ffffff", fontSize:"1.5em" }}>
                            <Col>
                            Introducing the Future Mints platform
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "20px", color: "#ffffff", fontSize:"1em" }}>
                            <Col>
                            Time-saving, in-depth, community-driven research. All in one place.           
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "60px", color: "#ffffff", fontSize:"1em" }}>
                            <Col>
                            To gain LIFETIME access to the platform, mint a Genesis NFT for 0.25 ETH.
                            </Col>
                        </Row>   
                        <Row style={{ paddingTop: "20px", color: "#ffffff", fontSize:"1em" }}>
                            <Col sm={4}>
                                <Link to="/all-nfts" style={{ textDecoration:"none"}}>
                                    <button className="btn btn-light mt-2" style={{height:"40px"}}>
                                        Try for free
                                    </button>
                                </Link>
                            </Col>
                            <Col sm={4}>
                                <Link to='/mint'>
                                    <button className="btn mt-2" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff", height:"100%", height:"40px" }}>
                                        Mint now
                                    </button> 
                                </Link>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "30px", color: "#ffffff", fontSize:"0.7em" }}>
                            <Col>
                            Already have a Genesis NFT? <a href="https://app.futurenftmints.com" style={{ color: "#ffffff" }}>Register or login here.</a>
                            </Col>
                        </Row>
                        </Row>
                    </Col>            

                    <Col lg={5}>
                        <Row >
                        <div className="col-12" style={{ paddingTop: "60px"}}>
                            <img
                            alt=""
                            src="/images/macbookpro.png" 
                            width="700px"           
                            className=""
                            />
                        </div>
                        </Row>
                    </Col>
                </Row>

                <Row style={{ paddingTop: "20px", paddingBottom: "50px", overflow:"hidden" }}>
                    <Col>
                        <Row style={{textAlign:"center"}}>
                        <hr style={{border: "0.5px solid #ffffff", width: "80%", margin:"auto"}}/>
                        <div style={{color:"#ffffff", paddingTop:"10px", fontSize:"0.7em"}}>
                            See what you can do with the Future Mints platform
                            <br />
                            <div style={{fontSize:"1.3em", paddingTop:"10px"}}>&#9660;</div>
                        </div>
                        
                        </Row>
                    </Col>
                    </Row>
                    <Row style={{paddingBottom:"100px"}}>
                    <Col md={6} style={{margin:"auto", textAlign:"center"}}>
                        <Row>
                        <div className="col-md-3"></div>
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/insightsReportIconRadial.png"               
                            width="100%"
                            />
                        </div>
                        </Row>
                    </Col>
                    <Col md={6} style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-2 col-1"></div>
                        <div className="col-md-1 col-2 text-right">
                            <img
                            alt=""
                            src="/images/stars.png" 
                            height="40px"              
                            
                            />
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            Make better buying decisions
                        </div>
                        </Row>
                        <Row className="pt-3">
                        <div className="col-3"></div>
                        <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                            Learn more about upcoming NFT collections with detailed insights reports. Including information on mint times, prices, benefits,  team, roadmap, and more.
                        </div>
                        </Row>
                    </Col>
                    </Row>
                    <Row style={{paddingBottom:"100px"}}>
                    <Col md={6} className="order-2 order-md-1" style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-3 col-1"></div>
                        <div className="col-md-1 col-2 text-right">
                            <img
                            alt=""
                            src="/images/lightbulb.png" 
                            height="40px"              
                            
                            />
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            Get valuable insights from the community
                        </div>
                        </Row>
                        <Row className="pt-3">
                        <div className="col-3 col-md-4"></div>
                        <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                            Features like upvoting and comments will help you get a better feel for how others rate NFT collections.
                        </div>
                        
                        </Row>
                    </Col>
                    <Col md={6} className="order-1 order-md-2" style={{margin:"auto", textAlign:"center"}}>
                        <Row>              
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/communitySentiment.png"               
                            width="100%"
                            />
                        </div>
                        <div className="col-md-3"></div>
                        </Row>
                    </Col>          
                    </Row>
                    <Row style={{paddingBottom:"100px"}}>
                    <Col md={6} style={{margin:"auto", textAlign:"center"}}>
                        <Row>
                        <div className="col-md-3"></div>
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/submitToCalendar.png"               
                            width="100%"
                            />
                        </div>
                        </Row>
                    </Col>
                    <Col md={6} style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-2 col-1"></div>
                        <div className="col-md-1 col-2 text-right">
                            <img
                            alt=""
                            src="/images/trophyHome.png" 
                            height="40px"              
                            
                            />
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            Contribute to the calendar and earn rewards
                        </div>
                        </Row>
                        <Row className="pt-3">
                        <div className="col-3"></div>
                        <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                            Found an interesting project you want to share? Add it to our calendar. Each NFT you submit and we approve moves you up the leaderboard to earn an NFT from our upcoming collection.
                        </div>
                        </Row>
                    </Col>
                </Row>

                <Row style={{textAlign:"center"}}>
                    <Col style={{fontSize:"1.5em", marginTop:"30px", marginBottom:"50px", color:"#ffffff"}}>
                        We publish a FREE newsletter every weekday with a new Insights Report.
                        <div style={{ marginTop:"30px"}}>
                            <a className="btn-lg btn-light" style={{ textDecoration:"none"}} target={"_blank"} href="https://futurenftmints.substack.com/subscribe">Sign up now</a>
                        </div>
                    </Col>
                </Row>   

                <a id="team"></a>
                <Row style={{ textAlign:"center", paddingTop: "40px", paddingBottom:"20px", backgroundColor: "#202124", color:"#ffffff", lineHeight: "1.25em" }}>
                    <div style={{ fontSize: "2em", paddingBottom:"40px" }}>
                        The Team
                    </div>          
                    <Col md={2}></Col>
                    <Col md={2}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Elliot Koss" src="/images/team/elliotkoss-founder-futurenftmints.png" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Elliot Koss</div>
                        Founder
                        <br /><a href="https://twitter.com/elliotkoss" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                        &nbsp;&nbsp;<a href="https://linkedin.com/in/elliotkoss" target="_BLANK"><img width="20" src="/images/linkedin-logo.png" /></a>
                    </Col>
                    <Col md={2}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Mike Taylor" src="/images/team/miketaylor-engineer-futurenftmints.jpeg" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Mike Taylor</div>
                        Senior Engineer
                        <br /><a href="https://twitter.com/sea_local" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                        &nbsp;&nbsp;<a href="https://linkedin.com/in/miketayloreit" target="_BLANK"><img width="20" src="/images/linkedin-logo.png" /></a>
                    </Col>
                    <Col md={2}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Samantha Lane" src="/images/team/samanthalane-designer-futurenftmints.jpeg" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Samantha Lane</div>
                        Designer
                        <br /><a href="https://twitter.com/laneyx" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                        &nbsp;&nbsp;<a href="https://www.linkedin.com/in/samanthajlane/" target="_BLANK"><img width="20" src="/images/linkedin-logo.png" /></a>
                    </Col>
                    <Col md={2}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Mason Tilghman" src="/images/team/masontilghman-engineer-futurenftmints.jpeg" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Mason Tilghman</div>
                        Engineer
                        <br /><a href="https://twitter.com/mas1alpha" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                        &nbsp;&nbsp;<a href="https://www.linkedin.com/in/mason-tilghman/" target="_BLANK"><img width="20" src="/images/linkedin-logo.png" /></a>
                    </Col>
                    </Row>

                    <Row style={{ textAlign:"center", paddingTop:"10px", paddingBottom: "40px", backgroundColor: "#202124", color:"#ffffff" }}>     
                    <Col md={2}></Col>          
                    <Col md={2}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Harrison Smith" src="/images/team/harrisonsmith-nft-researcher-futurenftmints.jpeg" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Harrison Smith</div>
                        NFT Researcher
                        <br /><a href="https://twitter.com/hgrey0001" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                        &nbsp;&nbsp;<a href="https://www.linkedin.com/in/harrisongsmith/" target="_BLANK"><img width="20" src="/images/linkedin-logo.png" /></a>
                    </Col>
                    <Col md={2}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Mike Taylor" src="/images/team/krishnabrahmaroutu-nft-researcher-futurenftmints.jpg" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Krishna Brahmaroutu</div>
                        NFT Reseacher
                        <br /><a href="https://twitter.com/krishnaenae" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                        &nbsp;&nbsp;<a href="https://www.linkedin.com/mwlite/in/krishna-brahmaroutu-926251186" target="_BLANK"><img width="20" src="/images/linkedin-logo.png" /></a>
                    </Col>          
                    <Col md={2}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Andrew Cosgrove" src="/images/team/andrewcosgrove-legaladvisor-futurenftmints.jpeg" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Andrew Cosgrove</div>
                        Legal Advisor
                        <br /><a href="https://twitter.com/AndrewTCosgrove" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                        &nbsp;&nbsp;<a href="https://www.linkedin.com/in/andrew-cosgrove-26062784/" target="_BLANK"><img width="20" src="/images/linkedin-logo.png" /></a>
                    </Col>
                </Row>  
            </div>
            
        </>
    );
};

function mapStateToProps({ wallet, registeredUser }) {
    return { wallet, registeredUser };
}

export default connect(mapStateToProps, actions)(LandingResearchBetter);
