import { useState, useEffect } from 'react';
import axios from 'axios';

const useBenefitsTypes = ( ) => {

    const [benefitsTypes, setBenefitsTypes] = useState(null);   
    
    useEffect(() => {
        const fetchBenefitsTypes = async () => {
            const benefitsTypesData = await axios.get(`/api/get/benefit-types`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setBenefitsTypes(benefitsTypesData.data);
        }

        fetchBenefitsTypes();

    }, []);
    
    return { benefitsTypes };   
}

export default useBenefitsTypes;
