const NotificationsHeader = () => {
    return (
        <>
        <div className="row">            
            <div className="col-12" style={{ fontSize: "2em"}}>
                Notifications
            </div>
        </div>     
        <div className="row">            
            <div className="col-12" style={{ fontSize: "1em"}}>
                Please input a personal phrase so that when we send you a notification, you know it's legit.
            </div>
        </div>        
        </>
    );
};

export default NotificationsHeader;
