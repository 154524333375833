const ConfirmedLinkedWalletHeader = () => {
    return (
        <>
        <div className="row">
            <div className="col-lg-2 col-1"></div>
            <div className="col-lg-8 col-10" style={{ fontSize: "2em"}}>
                Confirm Linked Wallet
            </div>
        </div>
        <div className="row">
            <div className="col-lg-2 col-1"></div>
            <div className="col-lg-8 col-10" style={{ fontSize: "1em"}}>
                Link this wallet to the username.
                <br />A wallet can only be associated with one username at a time. You can unlink a wallet from your account at any time.
            </div>
        </div>        
        </>
    );
};

export default ConfirmedLinkedWalletHeader;