import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Pagination from '../../pagination/Pagination';
import Header from '../Header';
import HowTos from '../HowTos';

const TableRow = ({ leaderboardRow, user, userData }) => {
    
    if ( leaderboardRow?.username != 1 && leaderboardRow?.username ) {
        if (user == 'yes') {
            return <>
            <tr style={{backgroundColor:"#7945E1", border:"1px #000000 solid", color:"rgba(255,255,255,.8)"}}>
                <td>{leaderboardRow.rank}</td>
                <td>{leaderboardRow.entries}</td>
                <td>{leaderboardRow.username} (me)</td>
                <td>{leaderboardRow.points}</td>
                <td>{leaderboardRow.benefits_added}</td>
                <td>{leaderboardRow.benefits_submitted}</td>   
                <td>{leaderboardRow.raffles_added}</td>
                <td>{leaderboardRow.raffles_submitted}</td>   
                <td>{leaderboardRow.votes}</td>
                <td>{leaderboardRow.likes_dislikes_votes}</td>
                <td>{leaderboardRow.approved_nfts}</td>        
                <td>{leaderboardRow.submitted_nfts}</td>
                <td>{leaderboardRow.upvotes_nfts}</td>       
                <td>{leaderboardRow.likes_dislikes_added}</td>
                <td>{leaderboardRow.upvotes_likes_dislikes_added}</td> 

            </ tr>
            <tr><td colSpan="7"></td></tr>
            </>;
        }
        else if (userData.username === leaderboardRow.username) {
            return <>
            <tr style={{backgroundColor:"#7945E1", border:"1px #000000 solid", color:"rgba(255,255,255,.8)"}}>
                <td>{leaderboardRow.rank}</td>
                <td>{leaderboardRow.entries}</td>
                <td>{leaderboardRow.username} (me)</td>
                <td>{leaderboardRow.points}</td>
                <td>{leaderboardRow.benefits_added}</td>
                <td>{leaderboardRow.benefits_submitted}</td>   
                <td>{leaderboardRow.raffles_added}</td>
                <td>{leaderboardRow.raffles_submitted}</td>   
                <td>{leaderboardRow.votes}</td>
                <td>{leaderboardRow.likes_dislikes_votes}</td>
                <td>{leaderboardRow.approved_nfts}</td>        
                <td>{leaderboardRow.submitted_nfts}</td>
                <td>{leaderboardRow.upvotes_nfts}</td>       
                <td>{leaderboardRow.likes_dislikes_added}</td>
                <td>{leaderboardRow.upvotes_likes_dislikes_added}</td> 
            </ tr>
            </>;
        }
        else {
            return <>
            <tr style={{backgroundColor:"#280B22", border:"1px #000000 solid", color:"rgba(255,255,255,.8)"}}>
                <td>{leaderboardRow.rank}</td>
                <td>{leaderboardRow.entries}</td>
                <td>{leaderboardRow.username}</td>
                <td>{leaderboardRow.points}</td>
                <td>{leaderboardRow.benefits_added}</td>
                <td>{leaderboardRow.benefits_submitted}</td>   
                <td>{leaderboardRow.raffles_added}</td>
                <td>{leaderboardRow.raffles_submitted}</td>   
                <td>{leaderboardRow.votes}</td>
                <td>{leaderboardRow.likes_dislikes_votes}</td>
                <td>{leaderboardRow.approved_nfts}</td>        
                <td>{leaderboardRow.submitted_nfts}</td>
                <td>{leaderboardRow.upvotes_nfts}</td>       
                <td>{leaderboardRow.likes_dislikes_added}</td>
                <td>{leaderboardRow.upvotes_likes_dislikes_added}</td>                             
            </ tr>
            </>;
        }
    }
}



const WeeklyLeaderboard = ({leaderboardData, userLeaderboardData, access, account, entries}) => {

    let PageSize = 100;
    const timestamp = new Date(leaderboardData[0]?.timestamp); 

    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);    

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return leaderboardData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, leaderboardData]);

    return <>    
    <div className="row mt-3">
        <div className="col-lg-1"></div>             
        <div className="col-lg-10 col-12 rounded" >
            <Header access={access} page="monthly" />
        
            <div className="row rounded p-3 mt-2">
                <div className="col-12"  >
                    <div className="row" style={{fontSize:"1.5em"}}>
                        Standings                            
                    </div>
                    <div className="row" style={{fontSize:"0.8em"}}>   
                        Last updated: {!isNaN(timestamp) ? timestamp?.toLocaleDateString('en-US', {timeZone: 'America/New_York', month: 'long', day: 'numeric', year: 'numeric'}) + ' ' + timestamp?.toLocaleTimeString('en-US', {timeZone: 'America/New_York', timeZoneName:"short"}) : ''}
                    </div>
                    <div className="row mt-2">             
                        <div className="col-12" style={{fontSize:"1em", color:"rgba(255,255,255,0.8)", padding:"0px"}}>
                            {account !== undefined ? <>You have {entries > 0 ? entries : 0} entries</> : ''}
                        </div>                            
                    </div>
                    
                    <div className="row table-responsive mt-4 rounded text-nowrap">
                        <table className="table" style={{ borderRadius: "1em", borderCollapse: "collapse", overflow:"hidden", width:"100%" }}>
                            <thead>
                                <tr style={{ backgroundColor:"#29194A", borderBottom:"2px #000000 solid", color:"rgba(255,255,255,.8)"}} key="0">
                                    <th scope="col">Rank</th>
                                    <th scope="col">Est. Entries</th>
                                    <th scope="col">Username</th>
                                    <th scope="col">Points</th>                                        
                                    <th scope="col">Added Benefit (20 pts)</th> 
                                    <th scope="col">Submitted Benefit (0 pts)</th> 
                                    <th scope="col">Added Raffle (20 pts)</th> 
                                    <th scope="col">Submitted Raffle (0 pts)</th> 
                                    <th scope="col">Vote on NFT (1 pt)</th>
                                    <th scope="col">Vote on Likes/Dislikes (1 pt)</th>                                        
                                    <th scope="col">Approved NFTs (10 pts)</th>  
                                    <th scope="col">Submitted NFTs (0 pts)</th>        
                                    <th scope="col">Added NFT Upvotes (0.2 pts)</th> 
                                    <th scope="col">Added Likes/Dislikes (3 pts)</th> 
                                    <th scope="col">Added Likes/Dislikes Upvotes (0.2 pts)</th>                                        
                                                                                                                                    
                                </tr>
                            </thead>
                            <tbody style={{ fontSize:".8em"}}>                                    
                                {userLeaderboardData != 'none' ? <TableRow key={userLeaderboardData.username + '1'} leaderboardRow={userLeaderboardData} user="yes" /> : userLeaderboardData[0]?.username}
                                <tr></tr>
                                {pageData.map(leaderboardRow => <TableRow key={leaderboardRow.username} leaderboardRow={leaderboardRow} userData={userLeaderboardData} user="no" />)}
                            </tbody>
                            <tfoot style={{ backgroundColor:"#140510", borderBottom:"1px #000000 solid", color:"rgba(255,255,255,.8)", textAlign: "center"}}>
                                <tr>                     
                                    <td colSpan="12">
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={leaderboardData.length}
                                            pageSize={PageSize}
                                            onPageChange={page => setCurrentPage(page)}
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="row" style={{fontSize:"0.8em"}}>   
                        Updates ~10 min after last update
                    </div>
                </div>                  
            </div>
            <HowTos />
        </div>
    </div>
    </>
}

export default WeeklyLeaderboard;