import { useState, useMemo, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import axios from 'axios';

import DashboardHeader from '../DashboardHeader';
import Pagination from '../../pagination/Pagination';

let PageSize = 50;

const TableRow = ({ entryRow }) => {

    const { action, details, quantity, add_entries, created_ts } = entryRow;            
    
    return <>
    <tr style={{backgroundColor:"#280B22", border:"1px #000000 solid", color:"rgba(255,255,255,.8)"}}>
        
        <td>{new Date(created_ts).toLocaleDateString("en-US", { timeZone: "UTC", weekday: 'short', month:'short', year:'numeric', day:'numeric' })}</td>
        <td>{action}</td>        
        <td>{details}</td>
        <td>{add_entries === true ? '+' : '-'} {quantity}</td>
    </tr>
    </>;
}

const AllowLists = ({ entriesHistory, entries, account }) => {    
 
    const [totalCount, setTotalCount] = useState( entriesHistory.length );    
    
    const filters = '';    

    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);   

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;        
        return entriesHistory.slice(firstPageIndex, lastPageIndex);        
    }, [currentPage, entriesHistory]);
    
    return (
        <>
                
        <DashboardHeader page='entriesHistory' />        
        <div className="row text-center mt-3">             
            <div className="col-12" style={{fontSize:"2em", color:"rgba(255,255,255,0.8)", textAlign:"center"}}>
                {account !== undefined ? <>You currently have {entries > 0 ? entries : 0} entries</> : ''}
            </div>
            <div className="col-12" style={{fontSize:"1em", color:"rgba(255,255,255,0.8)", textAlign:"center"}}>
                <Link to="/giveaways/rules" style={{color:"rgba(255,255,255,0.8)"}}>How To Earn Entries & Official Rules</Link>
            </div>
        </div> 
        <div className="row" style={{marginTop:"10px"}}>                                         

            <div className="col-12 table-responsive text-nowrap" style={{ padding:"10px"}} >                
                <table className="table" style={{ borderRadius: "1em", borderCollapse: "collapse", overflow:"hidden", width:"100%" }}>
                    <thead>                        
                        <tr style={{ backgroundColor:"#29194A", borderBottom:"2px #000000 solid", color:"rgba(255,255,255,.8)"}}>
                            <th>Date</th>
                            <th>Action</th>
                            <th scope="col">Details</th>
                            <th>Entries</th>
                        </tr>
                    </thead>
                    <tbody style={{ fontSize:".8em"}}>
                        {pageData.length > 0 ? pageData.map(entryRow => <TableRow key={entryRow.id} entryRow={entryRow}  />) : null}
                    </tbody>
                    <tfoot style={{ backgroundColor:"#140510", textAlign: "center", color:"rgba(255,255,255,.8)", border:"1px #000000 solid"}}>
                        <tr>                     
                            <td colSpan="4">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={totalCount}
                                    pageSize={PageSize}
                                    filters={filters}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>                
        
        </>
    );
};

function mapStateToProps({ form }) {
    return { form };
}

export default connect(mapStateToProps, actions)(AllowLists);
