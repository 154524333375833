import { useState, useEffect } from 'react';
import axios from 'axios';

const useUnmatchedNFTs = () => {

    const [unmatchedNFTs, setUnmatchedNFTs] = useState(null);   

    useEffect(() => {
        const fetchUnmatchedNFTs = async () => {
            const unmatchedNFTsData = await axios.get(`/api/admin/opensea/unmatched-nfts`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            
            setUnmatchedNFTs(unmatchedNFTsData.data);
        }

        fetchUnmatchedNFTs();

    }, []);
    
    return { unmatchedNFTs };   
}

export default useUnmatchedNFTs;
