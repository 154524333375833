import { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';

const Body = () => {    
        
    

    return (
        <>      
                <div className="row mt-4">
                    <div className="col-lg-2 col-1"></div>
                    <div className="col-lg-8 col-10">
                    You've completed onboarding. Your Future Mints dashboard will help unlock the hidden value of your NFTs. Don't forget to add additional wallets.
                    <br /><br />We're open to feedback, requests, and questions. <a href="https://twitter.com/futurenftmints" target="_BLANK" rel="noreferrer" style={{color:"rgba(255,255,255,0.8)"}}>Twitter DMs open</a>.
                    </div>
                </div>
                
                
                <div className="row mt-5">
                    <div className="col-lg-2 col-1"></div>
                    <div className="col-lg-8 col-10">
                        <div className="row">
                            <Link to="/dashboard/home">
                                <button className="btn my-1" style={{ backgroundColor: "#7945E1", border: "#7945E1", color:"rgba(255,255,255,1)", width: "100%", height: "50px" }}>
                                    Go to My Dashboard
                                </button>     
                            </Link> 
                        </div>
                            
                        
                    </div>
                </div> 

                
                            
            
        </>
    );
};

export default Body;
