import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getNonce, siweMessage, promptSignature, signInWithMessage } from '../utils/authentication';
import { fetchCurrentEthereumAccount, refreshTokenContractAddress, fetchRegisteredUser } from '../actions';


const useAuthenticated = (account, signer) => {

    const [nonce, setNonce] = useState(null);
    const [authenticated, setAuthenticated] = useState(null);

    const dispatch = useDispatch();

    /* authentication
     * provide proof to the server that the client/user is the owner of the account
     */
    useEffect(() => {
        const initiateAuthentication = async () => {
            const nonce = await getNonce();
            setNonce(nonce)
        }
        if (account) initiateAuthentication() // avoid fetching a nonce if there is no account present
    }, [account])

    useEffect(() => {
        const authenticate = async () => {
            const message = siweMessage(account, nonce);
            const signature = await promptSignature(signer, message);
            const { success } = await signInWithMessage(message, signature);
            setAuthenticated(success);
            dispatch(fetchCurrentEthereumAccount());
            dispatch(refreshTokenContractAddress());
            fetchRegisteredUser(account);         
        }
        if (account && nonce && signer) authenticate() // avoid sending a message if one of nonce, account, or signer is not present
    }, [account, nonce, signer, dispatch])
    
    return { authenticated }
}

export default useAuthenticated
