import React, { useState } from "react";
import { Field } from 'redux-form';
import RenderAutocompleteField from './RenderAutocompleteField'

import axios from 'axios';

export default ({ fieldName, hiddenField, label, placeholder, endpoint, initialValue } ) => {  

    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [userInput, setUserInput] = useState(initialValue);
    const [userInputValue, setUserInputValue] = useState(false);

    const fetchCollectionData = async (searchTerm) => {
        
        const url = endpoint + searchTerm;

        const response = await axios.get(url, {
            validateStatus: function (status) {
                return status < 500; // Resolve only if the status code is less than 500
            }
        });
        
        let newCollectionNames;
        let newCollectionImages;
        let newCollectionWarnings;
        let newCollectionId; 
        let suggestionZip = [];

        for (let i = 0; i < response.data.length; i++) {
            newCollectionNames = response.data[i].nft_collection;
            newCollectionId = response.data[i].id;
            // if collection image is null use placeholder
            if (response.data[i].collection_image){
                newCollectionImages = response.data[i].collection_image;
            }
            else{
                newCollectionImages = '';
            }

            if (response.data[i].warning === 'Caution' || response.data[i].warning === 'Avoid'){
                newCollectionWarnings = response.data[i].warning;
            }
            else{
                newCollectionWarnings = '';
            }

            suggestionZip.push( [ newCollectionNames, newCollectionImages, newCollectionWarnings, newCollectionId  ] );
        }         

        setFilteredSuggestions(suggestionZip);    
           
    }
    const onChange = async e => {    
        
        setUserInputValue(true);
        setUserInput(e.target.value);
        await fetchCollectionData(e.target.value);        
        
        setActiveSuggestion(0);
        
        if ( e.target.value !== '' ) {
            setShowSuggestions(true);
        }
        else {
            setShowSuggestions(false);
        }

        document.getElementsByName(hiddenField)[0].value = '';
    };

    const onMouseDown = e => {
        setActiveSuggestion(0);
        setFilteredSuggestions([]);
        setShowSuggestions(false);

        if ( e.target.childNodes[0].nodeName === 'IMG' ) {
            document.getElementsByName(hiddenField)[0].value = e.target.childNodes[1].innerText;
        }
        else {
            document.getElementsByName(hiddenField)[0].value = e.target.childNodes[0].innerText;
        }
                
        document.getElementsByName(fieldName)[0].value = e.target.innerText;  
        setUserInput(e.target.innerText);      
        
    };

    const onBlur = () => {
        setShowSuggestions(false);
    }

    const onKeyDown = e => {

        // User pressed the up arrow
        if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            setActiveSuggestion(activeSuggestion - 1);
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            setActiveSuggestion( activeSuggestion + 1 );
        }
    };

    const SuggestionsListComponent = () => {        

        let suggestionsListComponent;
        //console.log( userInput );
        
        if (showSuggestions) {
            
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                <ul className="suggestionsNavbarGeneral" style={{position:"relative", zIndex:"99"}}>
                    {filteredSuggestions.map((suggestion, index) => {
                        let className;

                        // Flag the active suggestion with a class
                        if (index === activeSuggestion) {
                            className = "suggestion-active";
                        } 
                        //console.log(index);
                            return (
                                <li className={className} key={index} onMouseDown={onMouseDown}>
                                    {filteredSuggestions[index][1] !== '' ? <img src={filteredSuggestions[index][1]} width="25" height="25"></img> : null}
                                    
                                    <span id="collectionIdValue" hidden>{filteredSuggestions[index][3]}</span>{filteredSuggestions[index][0]} {filteredSuggestions[index][2] === 'Caution' ? <>&nbsp;<img alt="" src="/images/cautionSymbol.png" style={{width:"20px"}} /></> : filteredSuggestions[index][2] === 'Avoid' ? <>&nbsp;<img alt="" src="/images/avoidSymbol.png" style={{width:"20px"}} /></> : null}
                                </li>)
                        })}
                </ul>
                );
            } else {                
                document.getElementsByName(hiddenField)[0].value = '';
                suggestionsListComponent = (
                    <ul className="noSuggestionsGeneral" style={{position:"relative", zIndex:"99"}}>
                        <li>
                            <em>Collection not found</em>
                        </li>
                    </ul>
                );
            }
        }
        
        return suggestionsListComponent;
        
    }    
    
    /*
    <Field
            name={fieldName}
            className="form-control"
            type="text"
            component={RenderAutocompleteField}
            onChange={onChange}
            onKeyDown={onKeyDown}            
            value1={userInputValue ? userInput : initialValue}
            label={label}
            placeholder={placeholder}
            onBlur={onBlur}
            
            
        />
    */
   
    return (
        <>
        

        <input
            name={fieldName}
            style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8"}} 
            className="form-control" 
            placeholder={placeholder} 
            type="text" 
            value={userInputValue ? userInput : initialValue}
            onKeyDown={onKeyDown} 
            onChange={e => onChange(e)}
            onBlur={onBlur}                  
        />
        {SuggestionsListComponent()}        
        

        <input type="hidden" name={hiddenField} />
        </>
    );  
}