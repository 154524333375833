import { Link } from 'react-router-dom';

const ReviewNav = ({ page, accountType }) => {  
    
    const ownerNav = ( page ) => {
        
        const promoCodeHighlight = page === 'promoCode' ? '#7945E1' : '#140510';
        const promoCodeStyleInputs = { color:"#ffffff", backgroundColor: promoCodeHighlight, padding:"5px", textDecoration:"none" };
        
        return (
            <>
            <div className="row mt-5"></div>
            <div className="row m-2">            
                <div className="col-12" style={{textDecoration:"underline"}}>
                    OWNER
                </div>
            </div>
            <div className="row m-2">            
                <div className="col-12">
                    <Link to="/admin/promo-codes" className="rounded" style={promoCodeStyleInputs}>Promo Codes</Link>
                </div>
            </div>
            </>
        )
    }
    const reviewHighlight = page === 'review' ? '#7945E1' : '#140510';
    const reviewStyleInputs = { color:"#ffffff", backgroundColor: reviewHighlight, padding:"5px", textDecoration:"none" };

    const benefitsHighlight = page === 'benefits' ? '#7945E1' : '#140510';
    const benefitsStyleInputs = { color:"#ffffff", backgroundColor: benefitsHighlight, padding:"5px", textDecoration:"none" };
    
    const rafflesHighlight = page === 'raffles' ? '#7945E1' : '#140510';
    const rafflesStyleInputs = { color:"#ffffff", backgroundColor: rafflesHighlight, padding:"5px", textDecoration:"none" };

    const relatedHighlight = page === 'related' ? '#7945E1' : '#140510';
    const relatedStyleInputs = { color:"#ffffff", backgroundColor: relatedHighlight, padding:"5px", textDecoration:"none" };    

    const openseaHighlight = page === 'opensea' ? '#7945E1' : '#140510';
    const openseaStyleInputs = { color:"#ffffff", backgroundColor: openseaHighlight, padding:"5px", textDecoration:"none" };    

    const mergeHighlight = page === 'merge' ? '#7945E1' : '#140510';
    const mergeStyleInputs = { color:"#ffffff", backgroundColor: mergeHighlight, padding:"5px", textDecoration:"none" };

    const giveawaysHighlight = page === 'giveaways' ? '#7945E1' : '#140510';
    const giveawaysStyleInputs = { color:"#ffffff", backgroundColor: giveawaysHighlight, padding:"5px", textDecoration:"none" };

    return (
    <>
        <div className="row m-2">            
            <div className="col-12">
                <Link to="/admin/review" className="rounded" style={reviewStyleInputs}>NFT Collections</Link>
            </div>
        </div>
        <div className="row m-2">            
            <div className="col-12">
                <Link to="/admin/benefits" className="rounded" style={benefitsStyleInputs}>Benefits</Link>
            </div>
        </div>
        <div className="row m-2">            
            <div className="col-12">
                <Link to="/admin/review-raffles" className="rounded" style={rafflesStyleInputs}>Raffles</Link>
            </div>
        </div>
        <div className="row m-2">            
            <div className="col-12">
                <Link to="/admin/related" className="rounded" style={relatedStyleInputs}>Related</Link>
            </div>
        </div>
        <div className="row m-2">            
            <div className="col-12">
                <Link to="/admin/opensea" className="rounded" style={openseaStyleInputs}>OpenSea</Link>
            </div>
        </div>
        <div className="row m-2">            
            <div className="col-12">
                <Link to="/admin/merge" className="rounded" style={mergeStyleInputs}>Merge</Link>
            </div>
        </div>
        <div className="row m-2">            
            <div className="col-12">
                <Link to="/admin/giveaways/upcoming" className="rounded" style={giveawaysStyleInputs}>Giveaways</Link>
            </div>
        </div>


        {accountType === 'owner' ? ownerNav(page) : null}
    </>
    );
}

export default ReviewNav;