import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const CalendarHeader = ( { currentPage, access } ) => {

    const [upcomingMintCounts, setUpcomingMintCounts] = useState('');

    useEffect(() => {        
        const fetchMintCounts = async () => {
            const response = await axios.get("/api/get/upcoming-mints/counts", {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setUpcomingMintCounts(response.data);
        }
        fetchMintCounts()
    }, []);

    const renderPublicRow = (access, count) => {
        
        if ( access === 'public' ) {
            return <>
            <div className="row" style={{ paddingRight:"13px", paddingLeft:"13px" }}>
                <div className="col-12 rounded" style={{ backgroundColor:"#5F1951" }}>
                    <div className="row">
                        <div className="col-4">
                            Public
                        </div>
                        <div className="col-4">
                            3 Days
                        </div>
                        <div className="col-4">
                            {count} mints
                        </div>                             
                    </div>
                </div>
            </div>
            </>;
        }

        return <>
            <div className="row" style={{ paddingRight:"13px", paddingLeft:"13px" }}>
                <div className="col-12 rounded">
                    <div className="row">
                        <div className="col-4">
                            Public
                        </div>
                        <div className="col-4">
                            3 Days
                        </div>
                        <div className="col-4">
                            {count} mints
                        </div>                             
                    </div>
                </div>
            </div>
            </>;
    }

    const renderFreeRow = (access, count) => {
        
        if ( access === 'free' ) {
            return <>
            <div className="row" style={{ paddingRight:"13px", paddingLeft:"13px" }}>
                <div className="col-12 rounded" style={{ backgroundColor:"#5F1951" }}>
                    <div className="row">
                        <div className="col-4">
                            <Link to="/signup" style={{color:"#ffffff", textDecoration:"underline"}}>
                                Free
                            </Link>
                        </div>
                        <div className="col-4">
                            5 Days
                        </div>
                        <div className="col-4">
                            {count} mints
                        </div>                             
                    </div>
                </div>
            </div>
            </>;
        }

        return <>
            <div className="row" style={{ paddingRight:"13px", paddingLeft:"13px" }}>
                <div className="col-12 rounded">
                    <div className="row">
                        <div className="col-4">
                            <Link to="/signup" style={{color:"#ffffff", textDecoration:"underline"}}>
                                Free
                            </Link>
                        </div>
                        <div className="col-4">
                            5 Days
                        </div>
                        <div className="col-4">
                            {count} mints
                        </div>                             
                    </div>
                </div>
            </div>
            </>;
    }

    const renderPremiumRow = (access, count) => {
        
        if ( access === 'premium' ) {
            return <>
            <div className="row" style={{ paddingRight:"13px", paddingLeft:"13px" }}>
                <div className="col-12 rounded" style={{ backgroundColor:"#5F1951" }}>
                    <div className="row">
                        <div className="col-4" >
                            <Link to="/signup" style={{color:"#ffffff", textDecoration:"underline"}}>
                                Premium
                            </Link>
                        </div>
                        <div className="col-4">
                            ALL
                        </div>
                        <div className="col-4">
                            {count} mints
                        </div>    
                    </div>
                </div>                         
            </div>
            </>;
        }

        return <>
            <div className="row" style={{ paddingRight:"13px", paddingLeft:"13px" }}>
                <div className="col-12 rounded">
                    <div className="row">
                        <div className="col-4" style={{paddingRight:"25px"}}>
                            <Link to="/signup" style={{color:"#ffffff", textDecoration:"underline"}}>
                                Premium
                            </Link>
                        </div>
                        <div className="col-4">
                            ALL
                        </div>
                        <div className="col-4">
                            {count} mints
                        </div>                             
                    </div>
                </div>
            </div>
            </>;
    }
    
    return (
        <>
        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-xl-7 col-lg-8 col-sm-10 col-8" style={{ fontSize: "2em"}}>
                Upcoming Mints
            </div>
            <div className="col-xl-1 col-lg-2 col-sm-2 col-4 my-auto d-flex justify-content-end">
                <Link to="/add/nft/quick">
                    <button className="btn btn-sm btn-light">
                        Add NFT
                    </button>
                </Link>
            </div>
        </div>
                
        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-xl-8 col-lg-10 col-12" style={{ fontSize: "1em"}}>
                <div className="row" style={{ paddingRight:"13px", paddingLeft:"13px" }}>
                    <div className="col-12 rounded">
                        <div className="row" style={{borderBottom:"1px #ffffff solid"}}>
                            <div className="col-4">
                                <strong>Account</strong>
                            </div>
                            <div className="col-4">
                                <strong>Upcoming</strong>
                            </div>
                            <div className="col-4">
                                <strong>Count</strong>
                            </div>
                        </div>    
                    </div>
                </div>              
                {renderPublicRow(access, upcomingMintCounts.public_count)}
                {renderFreeRow(access, upcomingMintCounts.free_count)}
                {renderPremiumRow(access, upcomingMintCounts.premium_count)}
                                
            </div>
        </div>  
        
        
        </>
    );
};

export default CalendarHeader;