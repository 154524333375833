const PleaseWait = () => {
    return <>
    <div className="row d-flex justify-content-center">
        <div className="spinner-border text-light" role="status" stlye={{position:"relative", zIndex:"-1"}}>
            <span className="visually-hidden" stlye={{position:"relative", zIndex:"-1"}}>Loading...</span>
        </div>
    </div>
    </>
    //<p style={{ color: "white" }}>⏳ Please wait ... if it's been 5 seconds, refresh the page</p>
}

export default PleaseWait
