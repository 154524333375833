import React, { useState, Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';

//import FieldFileInput from './FileInput';
import AllowListDetails from './AllowListDetails';
import DutchAuctionDetails from './DutchAuctionDetails';
import AdditionalInformation from './AdditionalInformation';
import MintDateKnown from './MintDateKnown';
import MintDateUnknown from './MintDateUnknown';
import RenderField from './RenderField';
import RenderTextarea from './RenderTextarea';
import RenderRadio from './RenderRadio';
import CollectionImage from './CollectionImage';
import AddNFTHeader from './AddNFTHeader';
import EditNFTHeader from './EditNFTHeader';


const AddNFTForm = ({ tokenContractAddress, onCollectionSubmit, initialValues, handleSubmit, pristine, error, reset, submitting, withRouter, accountType, marketplaces }) => {
        
    const [allowListDetails, setAllowListDetails] = useState(initialValues.allowList);
    const [dutchAuctionDetails, setDutchAuctionDetails] = useState(initialValues.dutchAuction);
    const [additionalInformation, setAdditionalInformation] = useState(!!initialValues.collectionName ? false : true); 
    const [mintDateKnown, setMintDateKnown] = useState(initialValues.mintDateKnown);  
    const [submitButton] = useState(initialValues.collectionName ? 'update' : 'submit');
    const [collectionImageUrl, setCollectionImageUrl] = useState(initialValues.collectionImage);
    const [warningMessage, setWarningMessage] = useState(initialValues?.warning === undefined ? 'None' : initialValues?.warning);    
    const [marketplace, setMarketplace] = useState(initialValues?.marketplace);
    //console.log(`InitialValues in AddNFTForm: ${collectionImageUrl}`);    

    const renderWarningMessage = () => {
        return <>
            <div className="form-group" style={{ marginTop:"10px"}}>
                <div>
                    <Field
                        className="form-control"
                        name="warningMessage"
                        component={RenderTextarea}
                        placeholder="Enter the warning reason"
                        label="Warning message (max 250 char)"
                        rows="4" />
                </div>
            </div>
        </>
    }

    const renderAddMarketplace = () => {
        return <>
            <div className="col-md-4" style={{ marginTop:"5px"}}>
                <div className="form-group">
                    <div>
                        <Field
                            className="form-control"
                            name="addMarketplace"
                            component={RenderField}
                            type="text"
                            placeholder="ex: OpenSea"
                            label="Add Marketplace" />
                    </div>
                    <div></div>
                </div>
            </div>     
        </>
    }

    return (
        <>
        { !!initialValues.collectionName ? <EditNFTHeader /> : <AddNFTHeader accountType={accountType} /> }
        <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-12">
                {error && <span className="text-danger">{error}</span>}
            </div>
        </div>
        <div className="row" >
            <div className="col-lg-1"> </div>
            <div className="col-lg-10 col-12">
                {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
                <form onSubmit={handleSubmit( onCollectionSubmit )} style={{ paddingBottom: "50px" }}>
                    
                    <div className="row" style={{ color: "#ffffff", marginTop:"25px"}}>
                        <div className="col-md-12">
                                <h3>
                                    Overview
                                </h3>
                                <hr></hr>
                        </div>
                    </div>

                    <div className="row" >

                        <CollectionImage url={collectionImageUrl}/>

                        <div className="col-8">
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="collectionName"
                                        component={RenderField}
                                        type="text"
                                        placeholder="Enter the NFT collection name"
                                        label="NFT Collection Name (Required)"
                                    />
                                </div>
                            </div>
                        
                            <div className="form-group" style={{ marginTop:"10px"}}>
                                <div>
                                    <Field
                                        className="form-control"
                                        name="collectionSupply"
                                        component={RenderField}
                                        type="text"
                                        placeholder="Enter the collection supply"
                                        label="Total Supply" />
                                </div>
                            </div>
                          
                            <div className="form-group" style={{ marginTop:"10px"}}>
                                <div>
                                    <div>
                                        <label>Image (jpg, png, gif - preferred: 400px x 400px)</label>
                                        <div>
                                            <input
                                                id="imageInput"
                                                type='file'
                                                accept='.jpg, .png, .jpeg, .gif'
                                                //onChange={this.onChange}
                                                onChange={(event) => setCollectionImageUrl( URL.createObjectURL(event.target.files[0]) )} 
                                                name="imageFile"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>                                    
                                </div>
                            </div> 
                            
                            <div className="form-group" style={{ marginTop:"10px"}}>
                                <div>
                                    <label style={{marginBottom: "5px"}}>Mint Status</label>
                                    <Field
                                        className="form-select"
                                        name="mintStatus"
                                        component="select"
                                        type="select"
                                        label="Mint Status" >
                                        <option></option>
                                        <option value="Upcoming Mint">Upcoming Mint</option>
                                        <option value="Minted">Minted</option>
                                        <option value="Mint Open">Mint Open</option>
                                    </Field>
                                </div>
                            </div>   

                            <div className="form-group" style={{ marginTop:"10px"}}>
                                <div>
                                    <label style={{marginBottom: "5px"}}>Warning</label>
                                    <Field
                                        className="form-select"
                                        name="warning"
                                        component="select"
                                        type="select"
                                        label="Warning"
                                        onChange={e => setWarningMessage(e.target.value)}
                                        >
                                        <option value="None">None</option>
                                        <option value="Caution">Caution</option>
                                        <option value="Avoid">Avoid</option>                                        
                                    </Field>
                                </div>
                            </div>       

                            {warningMessage !== 'None' ? renderWarningMessage() : null}                               
                        </div>

                        
                        
                    </div>                    

                    <div className="row" style={{ color: "#ffffff", marginTop:"20px"}}>
                        <div className="col-md-12">
                                <h3>
                                    Description
                                </h3>
                                <hr></hr>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-md-12">
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="collectionDescription"
                                        component={RenderTextarea}
                                        placeholder="Enter the project description"
                                        label="Describe the project (max 500 char)"
                                        rows="4" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ color: "#ffffff", marginTop:"15px"}}>
                        <div className="col-md-12">
                                <h3>
                                    Socials & URL
                                </h3>
                                <hr></hr>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-md-4" style={{ marginTop:"5px"}}>
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="twitter"
                                        component={RenderField}
                                        type="text"
                                        placeholder="ex: futurenftmints"
                                        label="Twitter" />
                                </div>
                                <div>(input futurenftmints instead of twitter.com/futurenftmints)</div>
                            </div>
                        </div>
                        <div className="col-md-4" style={{ marginTop:"5px"}}>
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="discord"
                                        component={RenderField}
                                        type="text"
                                        placeholder="ex: futurenftmints"
                                        label="Discord" />
                                </div>
                                <div>(input futurenftmints instead of discord.gg/futurenftmints)</div>
                            </div>
                        </div>
                        <div className="col-md-4" style={{ marginTop:"5px"}}>
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="website"
                                        component={RenderField}
                                        type="text"
                                        placeholder="ex: https://futurenftmints.com"
                                        label="Website" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ color: "#ffffff", marginTop:"15px"}}>
                        <div className="col-md-12">
                                <h3>
                                    Smart Contract & Insights Report
                                </h3>
                                <hr></hr>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-md-6" style={{ marginTop:"5px"}}>
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="smartContract"
                                        component={RenderField}
                                        type="text"
                                        placeholder="ex: https://etherscan.io/address/0x5f66F72a4f87ec3Cd06241400bD2bA867F1233c7"
                                        label="Smart Contract" />
                                </div>
                                <div></div>
                            </div>
                        </div>
                        <div className="col-md-6" style={{ marginTop:"5px"}}>
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="insightsReport"
                                        component={RenderField}
                                        type="text"
                                        placeholder="ex: https://twitter.com/futurenftmints/status/1505952397025169409"
                                        label="Insights Report (Twitter URL)" />
                                </div>
                                <div></div>
                            </div>
                        </div>                        
                    </div>

                    <div className="row" style={{ color: "#ffffff", marginTop:"15px"}}>
                        <div className="col-md-12">
                                <h3>
                                    Marketplace
                                </h3>
                                <hr></hr>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-md-4" style={{ marginTop:"5px"}}>
                            <div className="form-group" >
                                <div>
                                    <label style={{marginBottom: "5px"}}>Marketplace</label>
                                    <Field
                                        className="form-select"
                                        name="marketplace"
                                        component="select"
                                        type="select"
                                        label="Marketplace"
                                        onChange={e => setMarketplace(e.target.value)}
                                        >
                                        <option></option>
                                        {marketplaces.map(marketplace =>
                                            <option key={marketplace.marketplace} value={marketplace.marketplace}>{marketplace.marketplace}</option>
                                        )};
                                        <option value="Other">Other</option>                                        
                                    </Field>
                                </div>
                            </div>                                   
                        </div>

                        {marketplace === 'Other' ? renderAddMarketplace() : null} 

                        <div className="col-md-4" style={{ marginTop:"5px"}}>
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="marketplaceLink"
                                        component={RenderField}
                                        type="text"
                                        placeholder="ex: https://opensea.io/collection/future-nft-mints-genesis-nft"
                                        label="Marketplace Link" />
                                </div>
                                <div></div>
                            </div>
                        </div>     
                    </div>

                    <div className="row" style={{ color: "#ffffff", marginTop:"20px"}}>
                        <div className="col-md-12">
                                <h3>
                                    Mint Details
                                </h3>
                                <hr></hr>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <label style={{marginBottom: "5px"}}>Blockchain</label>
                                    <Field
                                        className="form-select"
                                        name="blockchain"
                                        component="select"
                                        type="select"
                                        label="Blockchain">
                                        <option></option>
                                        <option value="ETH">Ethereum (ETH)</option>
                                        <option value="FLOW">Flow</option>
                                        <option value="PALM">Palm</option>
                                        <option value="MATIC">Polygon (MATIC)</option>   
                                        <option value="SOL">Solana (SOL)</option>   
                                        <option value="ADA">Cardano (ADA)</option>  
                                        <option value="AVAX">Avalanche (AVAX)</option>
                                        <option value="BNB">Binance (BNB)</option>
                                        <option value="OP">Optimism (OP)</option>
                                        <option value="Other">Other</option>

                                    </Field>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <label style={{marginBottom: "5px"}}>Mint Date Known</label>
                                    <Field
                                        className="form-select"
                                        name="mintDateKnown"
                                        component="select"
                                        type="select"
                                        onChange={e => setMintDateKnown(e.target.value)}
                                        >
                                        <option></option>
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>                                        
                                    </Field>
                                </div>
                            </div>
                        </div>

                        { mintDateKnown === "Yes" ? <MintDateKnown /> : mintDateKnown === "No" ? <MintDateUnknown /> : null}

                    </div>

                    <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="publicMintPrice"
                                        component={RenderField}
                                        type="text"
                                        placeholder=""
                                        label="Public Mint Price" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="publicMintQuantity"
                                        component={RenderField}
                                        type="text"
                                        placeholder=""
                                        label="Public Mint Quantity" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="limitPerWallet"
                                        component={RenderField}
                                        type="text"
                                        placeholder=""
                                        label="Limit per wallet" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="limitPerTransaction"
                                        component={RenderField}
                                        type="text"
                                        placeholder=""
                                        label="Limit per transaction" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ color: "#ffffff", marginTop:"20px"}}>
                        <div className="col-md-12">                                
                                <hr></hr>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-md-12">
                            <div className="form-group">
                                <div>
                                    <label>Will there be an Allow List?</label>
                                    <Field
                                        className="form-check-input"
                                        name="allowList"
                                        component={RenderRadio}
                                        fieldValue="Yes"
                                        onclick={() => setAllowListDetails("Yes")} />
                                    <Field
                                        className="form-check-input"
                                        name="allowList"
                                        component={RenderRadio}
                                        fieldValue="No"
                                        onclick={() => setAllowListDetails("No")} />                                       
                                </div>
                            </div>
                        </div>
                    </div>                    
                    
                    { allowListDetails === "Yes" ? <AllowListDetails /> : null}
                    
                    <div className="row" style={{ color: "#ffffff", marginTop:"20px"}}>
                        <div className="col-md-12">                                
                                <hr></hr>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-md-12">
                            <div className="form-group">
                                <div>
                                    <label>Will there be a Dutch Auction?</label>
                                    <Field
                                        className="form-check-input"
                                        name="dutchAuction"
                                        component={RenderRadio}
                                        fieldValue="Yes"
                                        onclick={() => setDutchAuctionDetails("Yes")} />
                                    <Field
                                        className="form-check-input"
                                        name="dutchAuction"
                                        component={RenderRadio}
                                        fieldValue="No"
                                        onclick={() => setDutchAuctionDetails("No")} />                                       
                                </div>
                            </div>
                        </div>
                    </div>

                    { dutchAuctionDetails === "Yes" ? <DutchAuctionDetails /> : null}                    

                    <div className="row text-center" style={{ color: "#ffffff", marginTop:"25px"}}>
                        <div className="col-4"></div>                            
                        <div className="col-4">
                            <div className="btn-sm btn-secondary" onClick={() => setAdditionalInformation(s => !s)} >
                                Add additional information
                            </div>                              
                        </div>
                    </div>
                    
                    { !additionalInformation ? <AdditionalInformation /> : null}   
                    

                    <div className="row" style={{ marginTop: "30px", marginBottom: "30px" }}>
                        <div className="col-12">
                            <button className="btn" style={{ color:"#ffffff", backgroundColor: "#F83700", border: "#F83700", width: "100%", height: "50px" }} disabled={submitting}>
                                { submitButton == 'submit' ? 'Submit' : 'Update'}
                            </button>
                        </div>
                    </div>                    
                    
                </form>
            </div>
        </div>
        <div style={{height:"300px"}}>

        </div>
        </>
    );
};


const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}
    
    if (!values.collectionName) {
        errors.collectionName = 'Required'
    }
    else if (values.collectionName) {
        if (values.collectionName.length > 250) {
         errors.collectionName = 'Must be 250 characters or less'
        }
    }

    if (values.collectionSupply) {        
        if (! ( values.collectionSupply > 0 ) || ( typeof values.collectionSupply != 'number' && values.collectionSupply.search(/[.]/) > -1 ) ) {
         errors.collectionSupply = 'Must be a whole number'
        }

        if (values.collectionSupply.length > 10) {
            errors.collectionSupply = 'Too long for a number'
        }     
    }

    if (values.collectionDescription) {
       if (values.collectionDescription.length > 5000) {
        errors.collectionDescription = 'Must be 5000 characters or less'
       }
    }

    if (values.twitter) {
        //console.log(values.twitter.search(/twitter.com/));
        if (values.twitter.search(/twitter.com/) > -1) {
            errors.twitter = 'Only input the Twitter username'
        }

        if (values.twitter.length > 50) {
         errors.twitter = 'Must be 50 characters or less'
        }        
    }

    if (values.discord) {
        
        if (values.discord.search(/discord.com/) > -1) {
            errors.discord = 'Only input the Discord server'
        }

        if (values.discord.search(/discord.gg/) > -1) {
            errors.discord = 'Only input the Discord server'
        }

        if (values.discord.length > 50) {
         errors.discord = 'Must be 50 characters or less'
        }        
    }

    if (values.publicMintPrice) {
        
        if ( typeof values.publicMintPrice != 'number' && values.publicMintPrice.search(/[a-zA-Z!#$%&’*+/=?^_`{|}~-]/) > -1) {
            errors.publicMintPrice = 'Only input a number or decimal'
        }

        if (values.publicMintPrice.length > 10) {
         errors.publicMintPrice = 'Too long for a number'
        }        
    }

    if (values.publicMintQuantity) {
        if (! ( values.publicMintQuantity > 0 ) || ( typeof values.publicMintQuantity != 'number' && values.publicMintQuantity.search(/[.]/) > -1 ) ) {
         errors.publicMintQuantity = 'Must be a whole number'
        }

        if (values.publicMintQuantity.length > 10) {
            errors.publicMintQuantity = 'Too long for a number'
        }
    }

    if (values.limitPerWallet) {
        if (! ( values.limitPerWallet > 0 ) || ( typeof values.limitPerWallet != 'number' && values.limitPerWallet.search(/[.]/) > -1 ) ) {
         errors.limitPerWallet = 'Must be a whole number'
        }

        if (values.limitPerWallet.length > 10) {
            errors.limitPerWallet = 'Too long for a number'
        }
    }

    if (values.limitPerTransaction) {
        
        if (! ( values.limitPerTransaction > 0 ) || ( typeof values.limitPerTransaction != 'number' && values.limitPerTransaction.search(/[.]/) > -1 ) ) {
         errors.limitPerTransaction = 'Must be a whole number'
        }

        if (values.limitPerTransaction.length > 10) {
            errors.limitPerTransaction = 'Too long for a number'
        }
    }

    if (values.allowListMintPrice) {
        //console.log(values.publicMintPrice.search(/[a-zA-Z!#$%&’*+/=?^_`{|}~-]$/));
        if (typeof values.allowListMintPrice != 'number' && values.allowListMintPrice.search(/[a-zA-Z!#$%&’*+/=?^_`{|}~-]/) > -1) {
            errors.allowListMintPrice = 'Only input a number or decimal'
        }

        if (values.allowListMintPrice.length > 10) {
         errors.allowListMintPrice = 'Too long for a number'
        }        
    }

    if (values.allowListMintQuantity) {
        
        if (! ( values.allowListMintQuantity > 0 ) || ( typeof values.allowListMintQuantity != 'number' && values.allowListMintQuantity.search(/[.]/) > -1 ) ) {
         errors.allowListMintQuantity = 'Must be a whole number'
        }

        if (values.allowListMintQuantity.length > 10) {
            errors.allowListMintQuantity = 'Too long for a number'
        }
    }

    if (values.allowListLimitPerWallet) {
        
        if (! ( values.allowListLimitPerWallet > 0 ) || ( typeof values.allowListLimitPerWallet != 'number' && values.allowListLimitPerWallet.search(/[.]/) > -1 ) ) {
         errors.allowListLimitPerWallet = 'Must be a whole number'
        }

        if (values.allowListLimitPerWallet.length > 10) {
            errors.allowListLimitPerWallet = 'Too long for a number'
        }
    }

    if (values.allowListLimitPerTransaction) {
        
        if (! ( values.allowListLimitPerTransaction > 0 ) || ( typeof values.allowListLimitPerTransaction != 'number' && values.allowListLimitPerTransaction.search(/[.]/) > -1 ) ) {
         errors.allowListLimitPerTransaction = 'Must be a whole number'
        }

        if (values.allowListLimitPerTransaction.length > 10) {
            errors.allowListLimitPerTransaction = 'Too long for a number'
        }
    }

    if (values.dutchAuctionMintQuantity) {
        
        if (! ( values.dutchAuctionMintQuantity > 0 ) || ( typeof values.dutchAuctionMintQuantity != 'number' && values.dutchAuctionMintQuantity.search(/[.]/) > -1 ) ) {
         errors.dutchAuctionMintQuantity = 'Must be a whole number'
        }

        if (values.dutchAuctionMintQuantity.length > 10) {
            errors.dutchAuctionMintQuantity = 'Too long for a number'
        }
    }

    if (values.dutchAuctionMintStartPrice) {
        
        if (typeof values.dutchAuctionMintStartPrice != 'number' && values.dutchAuctionMintStartPrice.search(/[a-zA-Z!#$%&’*+/=?^_`{|}~-]/) > -1) {
            errors.dutchAuctionMintStartPrice = 'Only input a number or decimal'
        }

        if (values.dutchAuctionMintStartPrice.length > 10) {
         errors.dutchAuctionMintStartPrice = 'Too long for a number'
        }        
    }

    if (values.dutchAuctionMintPriceChange) {
        
        if (typeof values.dutchAuctionMintPriceChange != 'number' && values.dutchAuctionMintPriceChange.search(/[a-zA-Z!#$%&’*+/=?^_`{|}~-]/) > -1) {
            errors.dutchAuctionMintPriceChange = 'Only input a number or decimal'
        }

        if (values.dutchAuctionMintPriceChange.length > 10) {
         errors.dutchAuctionMintPriceChange = 'Too long for a number'
        }        
    }
    
    if (values.dutchAuctionMintPriceChangeFrequency) {

        if (values.dutchAuctionMintPriceChangeFrequency.length > 50) {
         errors.dutchAuctionMintPriceChangeFrequency = 'Must be 50 characters or less'
        }        
    }

    if (values.dutchAuctionMintEndPrice) {
        
        if (typeof values.dutchAuctionMintEndPrice != 'number' && values.dutchAuctionMintEndPrice.search(/[a-zA-Z!#$%&’*+/=?^_`{|}~-]/) > -1) {
            errors.dutchAuctionMintEndPrice = 'Only input a number or decimal'
        }

        if (values.dutchAuctionMintEndPrice.length > 10) {
         errors.dutchAuctionMintEndPrice = 'Too long for a number'
        }        
    }

    if (values.team) {

        if (values.team.length > 250) {
         errors.team = 'Must be 250 characters or less'
        }        
    }

    if (values.utility) {

        if (values.utility.length > 250) {
         errors.utility = 'Must be 250 characters or less'
        }        
    }

    if (values.perks) {

        if (values.perks.length > 250) {
         errors.perks = 'Must be 250 characters or less'
        }        
    }

    if (values.royalty) {

        if (values.royalty.length > 10) {
         errors.royalty = 'Must be 10 characters or less'
        }        
    }

    if (values.like1) {
        if (values.like1.length > 250) {
         errors.like1 = 'Must be 250 characters or less'
        }        
    }

    if (values.like2) {
        if (values.like2.length > 250) {
         errors.like2 = 'Must be 250 characters or less'
        }        
    }

    if (values.like3) {
        if (values.like3.length > 250) {
         errors.like3 = 'Must be 250 characters or less'
        }        
    }

    if (values.like4) {
        if (values.like4.length > 250) {
         errors.like4 = 'Must be 250 characters or less'
        }        
    }

    if (values.like5) {
        if (values.like5.length > 250) {
         errors.like5 = 'Must be 250 characters or less'
        }        
    }

    if (values.dislike1) {
        if (values.dislike1.length > 250) {
         errors.dislike1 = 'Must be 250 characters or less'
        }        
    }

    if (values.dislike2) {
        if (values.dislike2.length > 250) {
         errors.dislike2 = 'Must be 250 characters or less'
        }        
    }

    if (values.dislike3) {
        if (values.dislike3.length > 250) {
         errors.dislike3 = 'Must be 250 characters or less'
        }        
    }

    if (values.dislike4) {
        if (values.dislike4.length > 250) {
         errors.dislike4 = 'Must be 250 characters or less'
        }        
    }

    if (values.dislike5) {
        if (values.dislike5.length > 250) {
         errors.dislike5 = 'Must be 250 characters or less'
        }        
    }

    if (values.insightsReport) {
        if (values.insightsReport.length > 2048) {
         errors.insightsReport = 'Must be 2048 characters or less'
        }
    }

    if (values.insightsReport) {
        if ( values.insightsReport.search(/\s/) > -1 ) {
            errors.insightsReport = 'No spaces allowed'
        }
    }

    if (values.smartContract) {
        if (values.smartContract.length > 2048) {
         errors.smartContract = 'Must be 2048 characters or less'
        }
    }

    if (values.smartContract) {
        if ( values.smartContract.search(/\s/) > -1 ) {
           errors.smartContract = 'No spaces allowed'
        }
    }

    if (values.warningMessage) {
        if (values.warningMessage.length > 250) {
         errors.warningMessage = 'Must be 250 characters or less'
        }
    }

    if (values.addMarketplace) {
        if (values.addMarketplace.length > 250) {
         errors.addMarketplace = 'Must be 250 characters or less'
        }
    }

    if (values.marketplaceLink) {
        if (values.marketplaceLink.length > 2048) {
         errors.marketplaceLink = 'Must be 2048 characters or less'
        }
    }

    if (values.marketplaceLink) {
        if ( values.marketplaceLink.search(/\s/) > -1 ) {
           errors.marketplaceLink = 'No spaces allowed'
        }
    }

    // console.log('errors', errors)
    return errors
}


function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'nftForm',
    validate,
})(AddNFTForm));

