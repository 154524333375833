import { SET_WALLET_PROVIDER, SET_WALLET_ACCOUNT, SET_WALLET_SIGNER, SET_WALLET_NETWORK } from '../actions/types';

const walletReducer = (state = {}, action) => {
    switch(action.type) {
        case SET_WALLET_PROVIDER:
            // action.payload should be include provider key { provider: Provider }
            // console.log('walletReducer:', action.type, action.payload);
            return { ...state, ...action.payload };
        case SET_WALLET_ACCOUNT:
            // action.payload should be include account key { account: '0xConnectedWalletAddres' }
            // console.log('walletReducer:', action.type, action.payload);
            return { ...state, ...action.payload };
        case SET_WALLET_SIGNER:
            // action.payload should include a signer key { signer: JsonRpcSigner }
            // console.log('walletReducer:', action.type, action.payload);
            return { ...state, ...action.payload };
        case SET_WALLET_NETWORK:
            // action.payload should include a network key { network: { chainId: 1, ensAddress: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e', name: 'homestead' } }
            // console.log('walletReducer:', action.type, action.payload);
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default walletReducer
