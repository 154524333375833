import { Link } from 'react-router-dom';

const convertTime = (time) => {
    if (time !== null && time !== undefined) {
        const timeSplit = time.split(':');  

        let hour = timeSplit[0] % 12;
        if (hour === 0) { hour = 12 };

        let timePeriod;
        if (timeSplit[0] / 12 >= 1) { timePeriod = ' PM' } else { timePeriod = ' AM'}

        return hour + ':' + timeSplit[1] + timePeriod + ' ET';
    }
    return 'TBD';
}

const Raffle = ( { data } ) => {    

    let raffleRegisterByDateConverted = '';

    if ( data?.register_by_date ) {
        const raffleRegisterByDate = new Date(data.register_by_date);
        raffleRegisterByDateConverted = raffleRegisterByDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });        
    }

    const raffleSubmittedTs = new Date(data.submitted_ts);
    const raffleSubmittedTsConverted = raffleSubmittedTs.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });

    return <>
    <tr>
        <td>{data.slug ? <Link to={data.slug} style={{color:"#ffffff"}}><img src={data.collection_image} width={20} />{data.partner}</Link> : data.partner }</td>
        <td>{data.quantity === null ? 'TBD' : data.quantity}</td>
        <td>{raffleRegisterByDateConverted}<br /><span style={{fontSize:"0.8em"}}>{convertTime(data?.register_by_time)}</span></td>
        <td>{data.signup_link ? <a href={data.signup_link} target="_blank" rel="noreferrer" style={{color:"#ffffff"}}><button className="btn btn-sm" style={{ backgroundColor: "#7945E1", border: "#7945E1", color:"#ffffff" }}>Link</button> </a> : ''}</td>
        <td style={{fontSize:"0.8em"}}>{data.submitted_username} on<br />{raffleSubmittedTsConverted}</td>
    </tr>
    
    </>;
}

export default Raffle;