// RenderField contains logic to render a single label and text input
import React, { Component } from 'react';

//{input} is the same as props.input
export class RenderField extends Component {

    render () {

        const { input, className, placeholder, type, value1, onKeyDown, handleSubmit, meta: { touched, error, warning } } = this.props;

        return (
        <div>
            <div>                
                <input {...input} 
                    style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8"}} 
                    className={className} 
                    placeholder={placeholder} 
                    type={type} 
                    value={value1}
                    
                    // https://github.com/redux-form/redux-form/issues/883
                    // onChange={event => {
                    //     input.onChange(event)
                    //     handleSubmit(values => {
                    //       this.props.onSubmit({
                    //         ...values,
                    //         search: event.target.value // <--- manually insert this field's value into submitted json
                    //       })()
                    //     })
                    //   }}
                    onKeyDown={onKeyDown}
                />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
            </div>
        </div>
        )
    };
};

export default RenderField;