import React, { useState, Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../actions/index';

import RenderField from '../addNFT/RenderField';
import ConfirmLinkedWalletHeader from './ConfirmLinkedWalletHeader';


const ConfirmLinkedWalletPage = ({ userData, account }) => {    

    return (
        <>
        <ConfirmLinkedWalletHeader />
        <div className="row mt-5">
            <div className="col-lg-2 col-1"></div>
            <div className="col-3" style={{ fontSize: "1em"}}>
                <strong>Username</strong>
            </div>
            <div className="col-lg-5 col-7" style={{ fontSize: "1em", wordWrap:"break-word"}}>
            {userData.username}
            </div>
        </div>
        <div className="row mt-2">
            <div className="col-lg-2 col-1"></div>
            <div className="col-3" style={{ fontSize: "1em"}}>
                <strong>Wallet</strong>
            </div>
            <div className="col-lg-5 col-7" style={{ fontSize: "1em", wordWrap:"break-word"}}>
                {account}
            </div>
        </div>    
        <div className="row" style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div className="col-lg-2 col-1 mt-5"></div>
            <div className="col-lg-4 col-5 mt-5">
                <Link to="/confirm/link-wallet/confirm">
                    <button className="btn" style={{ color:"#ffffff", backgroundColor: "#F83700", border: "#F83700", width: "100%", height: "50px" }}>
                        Confirm
                    </button>
                </Link>
            </div>
            <div className="col-lg-4 col-5 mt-5">
                <Link to="/confirm/link-wallet/reject">
                    <button className="btn" style={{ color:"#ffffff", backgroundColor: "#27292D", border: "#27292D", width: "100%", height: "50px" }}>
                        Reject
                    </button>
                </Link>
            </div>
        </div>                

        </>
    );
};





function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(ConfirmLinkedWalletPage);

