const UnlinkedWalletHeader = () => {
    return (
        <>
        <div className="row">
            <div className="col-lg-2 col-1"></div>
            <div className="col-lg-8 col-10" style={{ fontSize: "2em"}}>
                Unlink Wallet
            </div>
        </div>
        <div className="row">
            <div className="col-lg-2 col-1"></div>
            <div className="col-lg-8 col-10" style={{ fontSize: "1em"}}>
                Unlink this wallet from the username.
                <br />When you unlink, the wallet will be immediately unlinked, and if you are logged in with it, you will be immediately logged out.
            </div>
        </div>        
        </>
    );
};

export default UnlinkedWalletHeader;