import { Link } from 'react-router-dom';

const RenderMarketplaceURL = ({ marketplace, marketplace_link }) => {
    if ( marketplace_link === null || marketplace_link === '' ) {
        return <></>
    }
    if ( marketplace === null || marketplace === '' ) {
        return <></>
    }
    if ( marketplace === 'OpenSea' ) {
        const marketplace_url = marketplace_link + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: marketplace_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}><img src="/images/opensea.png" height="20" className="d-inline-block align-middle" style={{margin:"0px"}} /></Link>
        </>
    }
    else {
        const marketplace_url = marketplace_link + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: marketplace_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}>{marketplace}</Link>
        </>
    }

}

export default RenderMarketplaceURL;