const publicUser = () => {
    return (
        <>
        <img alt="" src="/images/cautionSymbol.png" height="20" className="d-inline-block align-middle"/>Public access users don't receive any credit or points for submitting NFTs which means you can't climb the <a href="/leaderboard" style={{color:"#ffffff"}}>Leaderboard</a> and earn Premium access for free by being in the Top 100 Contributors. 
        <br /><br />TIP: Create a FREE account by connecting your wallet and registering to earn points.
        </>
    );
};

const notPublicUser = () => {
    return (
        <>For every NFT that you submit AND we add to our calendar, you'll earn 10 points. Every upvote for an NFT you submit AND we approve earns an additional 0.2 points. NFTs that we can't confirm or that are duplicates for content already in our calendar will be rejected.
        </>
    );
};

const AddNFTHeader = ({ accountType }) => {
    return (
        <>
        <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-12" style={{ fontSize: "2em"}}>
                Add NFT
            </div>
        </div>
        <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-12" style={{ fontSize: "1em"}}>
                Submit an NFT for our researchers to review (targeting 24 hr review period). The minimum that's needed is a Collection Name, but it's helpful if you provide more info.
                <br /><br />                
                {accountType == 'public' ? publicUser() : notPublicUser() }
            </div>
        </div>        
        </>
    );
};

export default AddNFTHeader;