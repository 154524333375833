import { FETCH_ALERTS_COUNT } from '../actions/types';
// eslint-disable-next-line
export default function(state = null, action) {
    
    switch(action.type) {
        case FETCH_ALERTS_COUNT:
            return action.payload || false;        
        default:
            return state;
    }
}
