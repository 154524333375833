import ProgressIndicator from '../onboard/ProgressIndicator';

const RegisterPrompt = ({nft}) => {
    return (
        <>
            <ProgressIndicator current={1} total={5}/>            
            
            <div className="row">
                <div className="col-1 col-lg-2"></div>
                <div className="col-10 col-lg-8">                   
                    <span style={{fontSize:"2em"}}>
                        First things first...
                    </span>
                    <br />
                    <span style={{fontSize:"1em"}}>
                        Please complete your profile with your username and email.{ nft ? '' : ' If you have a promo code for Premium access, enter it below.'}
                    </span>
                </div>
            </div>            
        </>
    );
};

export default RegisterPrompt;
