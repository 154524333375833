import React, { useState } from 'react';
import RenderFormField from './RenderFormField';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';
import axios from 'axios';

import Autocomplete from "../calendar/Autocomplete";
require("../calendar/searchStyles.css");

let collectionNames = [];
let collectionImages = [];
let collectionId = [];
let initialVariablesSet = true;

const fetchCollectionData = async (nft_collection_id) => {
    
    const url = `/api/get/nfts/related-options/${nft_collection_id}`;    

    const response = await axios.get(url, {
        validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
        }
    });
    
    collectionNames = [];
    collectionImages = [];
    collectionId = [];
    for (let i = 0; i < response.data.length; i++) {
        collectionNames.push(response.data[i].nft_collection);
        collectionId.push(response.data[i].id);
        // if collection image is null use placeholder
        if (response.data[i].collection_image){
            collectionImages.push(response.data[i].collection_image);
        }
        else{
            collectionImages.push('');
        }
    }
}

const renderRelationshipSelect = ({ input, className, label, type, meta: { touched, error, warning } }) => (
    <div>      
      <div>
        <select {...input} className={className} placeholder={label} type={type} >
            <option value="" key={-1} disabled>Relationship</option>                                        
            <option value="Associated" key={1}>Associated</option>
            <option value="Related" key={2}>Related</option>
        </select>
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
)

const RelatedNFTsForm = ({ onRelatedNFTsFormSubmit, initialValues, handleSubmit, error, submitting }) => {

    if ( initialVariablesSet ) {
        fetchCollectionData(initialValues.nft_collection_id);
        initialVariablesSet = false;
    }

    return (<>
        {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
        <div className="row table-responsive text-nowrap my-3" style={{fontSize:"1em"}}>                
            <div className="col-12">
                <form onSubmit={handleSubmit( values => onRelatedNFTsFormSubmit({
                    ...values, search: document.getElementsByName('search')[0].value, collectionId: document.getElementsByName('collectionId')[0].value 
                }) )}>
                    <div>Add a related NFT</div>
                    <table className="table" style={{ color:"#ffffff" }}>                        
                        <tbody>
                            <tr>
                                <td>
                                    <Field
                                        className="form-select"
                                        name="relationship"
                                        component={renderRelationshipSelect}
                                        type="select"
                                        label="Relationship"
                                        placeholder="Relationship"                                        
                                        >                                        
                                    </Field>
                                    
                                </td>
                                <td>
                                    <Field
                                        className="form-control"
                                        name="search"
                                        component={Autocomplete}
                                        suggestions={collectionNames}
                                        imageUrls={collectionImages}
                                        ids={collectionId}
                                        placeholder="Search"      
                                        initialSearch={initialValues?.nft_collection !== undefined ? initialValues.nft_collection : ''}                                      
                                        label=""
                                        />
                                    
                                </td>
                                                                
                                <td>
                                    <button className="btn btn" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff" }} disabled={submitting}>
                                        Add
                                    </button>  
                                </td>     
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    
    </>
    );
}

const validate = values => {
    
    const errors = {}
    
    if (!values.relationship || values.relationship === '') {
        errors.relationship = 'Must select a relationship'
    }    

    
    if (!values.search) {        
         errors.search = 'Must select an NFT that exists in our platform';
    }

    //console.log('errors', errors);
    return errors
}

function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'relatedForm',
    validate,
})(RelatedNFTsForm));