import { Link } from 'react-router-dom';

const convertTime = (time) => {
    if (time !== null && time !== undefined) {
        const timeSplit = time.split(':');  

        let hour = timeSplit[0] % 12;
        if (hour === 0) { hour = 12 };

        let timePeriod;
        if (timeSplit[0] / 12 >= 1) { timePeriod = ' PM' } else { timePeriod = ' AM'}

        return hour + ':' + timeSplit[1] + timePeriod + ' ET';
    }
    return '';
}

const Benefits = ( { data } ) => {    
    //console.log(data);
    let benefitsRegisterByDateConverted = '';

    if ( data?.claim_deadline_date !== null ) {
        const benefitsRegisterByDate = new Date(data.claim_deadline_date);
        benefitsRegisterByDateConverted = benefitsRegisterByDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });            
    }
    const benefitsSubmittedTs = new Date(data.added_ts);
    const benefitsSubmittedTsConverted = benefitsSubmittedTs.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });
    
    return <>
    <tr>
        <td>{data.benefit_type}</td>
        <td style={{minWidth:"300px"}}>{data.description}</td>
        <td>{data.slug ? <Link to={data.slug} style={{color:"#ffffff"}}><img src={data.collection_image} width={20} />{data.partner}</Link> : data.partner }</td>
        <td>{data.url ? <a href={data.url} target="_blank" rel="noreferrer" style={{color:"#ffffff"}}><button className="btn btn-sm" style={{ backgroundColor: "#7945E1", border: "#7945E1", color:"#ffffff" }}>Link</button> </a> : ''}</td>
        <td>{benefitsRegisterByDateConverted}<br /><span style={{fontSize:"0.8em"}}>{convertTime(data?.claim_deadline_time)}</span></td>
        <td style={{fontSize:"0.8em"}}>{data.added_by_username} on<br />{benefitsSubmittedTsConverted}</td>
    </tr>
    
    </>;
}

export default Benefits;