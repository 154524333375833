import ProgressIndicator from '../../onboard/ProgressIndicator';

const RegisterPrompt = ({username}) => {
    return (
        <>
            <ProgressIndicator current={3} total={5}/>            
            
            <div className="row">
                <div className="col-1 col-lg-2"></div>
                <div className="col-10 col-lg-8">                   
                    <span style={{fontSize:"2em"}}>
                        Which plan is best for you
                    </span>                    
                </div>
            </div>            
        </>
    );
};

export default RegisterPrompt;
