import React, {Component} from 'react'
import { Field, reduxForm } from 'redux-form';
import RenderField from './RenderField';

export default class MintDateKnown extends Component{

    render(){
        return(
            <><div className="col-md-4">
                <div className="form-group">
                    <div>
                        <Field
                            className="form-control"
                            name="publicMintDate"
                            component={RenderField}
                            type="date"
                            placeholder="mm/dd/yyyy"
                            label="Public Mint Date (ET)" />
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <div>
                        <Field
                            className="form-control"
                            name="publicMintTime"
                            component={RenderField}
                            type="time"
                            placeholder="00:00 AM"
                            label="Public Mint Time (ET)" />
                    </div>
                </div>
            </div>     
            </>
        )
    }
}