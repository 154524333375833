import { Link } from 'react-router-dom';

const RenderDiscordURL = ({ discord, discord_user_count }) => {
    
    if (discord === null || discord === '' ) {
        return <>
            
        </>;
    }

    else {
        let discord_user_count_formatted = discord_user_count;

        if ( discord_user_count > 1000000 ) {
            const whole_number = Math.floor(discord_user_count / 1000000);            
            const remainder = Math.round(discord_user_count % 1000000 * 0.00001) / 10;
            discord_user_count_formatted = whole_number + remainder + 'M';
        }
        else if ( discord_user_count > 1000 ) {
            const whole_number = Math.floor(discord_user_count / 1000);
            const remainder = Math.round(discord_user_count % 1000 * 0.01) / 10;
            discord_user_count_formatted = whole_number + remainder + 'K';
        }
    
        const discord_url = 'https://discord.gg/' + discord + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: discord_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}>
                <img alt="" src="/images/discord.png" height="20" className="d-inline-block align-middle" style={{margin:"0px"}} />
                <br /> 
                <span style={{fontSize:"0.75em"}}>
                    {discord_user_count_formatted}
                </span> 
            </Link>
        </>
    }
}

export default RenderDiscordURL;
