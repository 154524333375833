import axios from 'axios';
import { useState } from 'react';
import { connect } from 'react-redux';

import useTokenGatedAccess from '../../hooks/useTokenGatedAccess';

const TokenGatedForm = (props) => {

    const account = props.wallet.account; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, walletAccessTokens } = useTokenGatedAccess(account);

    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleFormSubmission = async event => {
        event.preventDefault()
        const values = {
            username,
            firstName,
            lastName,
            email
        }
        const response = await axios.post('/api/register', values)
        if (response.status === 200) {
            setSubmitted(true)
            resetForm()
        } else {
            console.log('Unsuccessful response: ', response)
        }
    }

    const resetForm = () => {
        setUsername('')
        setFirstName('')
        setLastName('')
        setEmail('')
    }

    const successMessage = () => {
        return <p className="text-success text-center">Thank you for your submission!</p>
    }

    const form = () => {
        return <form onSubmit={handleFormSubmission} style={{ paddingTop: "50px", paddingBottom: "50px" }}>
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <label style={{ color: "#ffffff", marginBottom: "5px" }}>Username</label>
                        <div>
                            <input
                                className="form-control"
                                name="username"
                                type="text"
                                value={username} onChange={(event) => setUsername(event.target.value)}
                                placeholder="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-6">
                    <div className="form-group">
                        <label style={{ color: "#ffffff", marginBottom: "5px" }}>First Name</label>
                        <div>
                            <input
                                className="form-control"
                                name="firstName"
                                type="text"
                                value={firstName} onChange={(event) => setFirstName(event.target.value)}
                                placeholder="" />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label style={{ color: "#ffffff", marginBottom: "5px" }}>Last Name</label>
                        <div>
                            <input
                                className="form-control"
                                name="lastName"
                                type="text"
                                value={lastName} onChange={(event) => setLastName(event.target.value)}
                                placeholder="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-12">
                    <div className="form-group">
                        <label style={{ color: "#ffffff", marginBottom: "5px" }}>Email</label>
                        <div>
                            <input
                                className="form-control"
                                name="email"
                                type="text"
                                value={email} onChange={(event) => setEmail(event.target.value)}
                                placeholder="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{ marginTop: "30px", marginBottom: "30px" }}>
                <div className="col-md-12">
                    <button type="submit" className="btn" style={{ backgroundColor: "#F83700", border: "#F83700", width: "100%" }} >
                        Submit
                    </button>
                </div>
            </div>
        </form>
    }

    const renderContent = () => {
        if (!account) return <p style={{ color: "white" }}>Please Connect Wallet</p>;

        switch(access) {
            case null:
                return <p style={{ color: "white" }}>⏳ Please wait ... if it's been 5 seconds, refresh the page</p>;
            case false:
                return <p style={{ color: "white" }}>Access Denied. Please <a href="/mint">mint a token</a>.</p>;
            case true:
                return submitted ? successMessage() : form();
            default:
                return <p style={{ color: "white" }}>Something went wrong. Do you <a href="/mint">have a token</a>?</p>;
        }
    }

    return (
        <div style={{ backgroundColor: "#000000", color: "#ffffff", paddingTop:"50px"}}>
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6" style={{ fontSize: "2em"}}>
                    Token Gated Form
                </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                    Account: {account && account.toString()}
                </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                    Access: {access?.toString()}
                </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                    Access Tokens: {walletAccessTokens.join(', ').toString()}
                </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                    {renderContent()}
                </div>
            </div>
            <div style={{height:"300px"}}>

            </div>
        </div>
    )
}

function mapStateToProps({ wallet }) {
    return { wallet };
}

export default connect(mapStateToProps)(TokenGatedForm);
