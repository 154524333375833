import { useState, useEffect, useLayoutEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../../actions';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import markdownStyle from './markdown-styles.module.css';

import Ratings from './Ratings';
import LikeDislike from './LikeDislike';
import LikeForm from './LikeForm';
import DislikeForm from './DislikeForm';
import Raffle from './Raffle';
import RaffleForm from './RaffleForm';
import Benefits from './Benefits';
import BenefitsForm from './BenefitsForm';
import RelatedNFTs from './RelatedNFTs';
import RelatedNFTsForm from './RelatedNFTsForm';

import WatchListButtons from '../Watchlist';
import AllowListButtons from '../Allowlist';

const updateVote = async (id, vote) => {
    try {
        const response = await axios.put(`/api/vote/${id}`, { vote });         
        return response.data.vote;
    } catch (error) {
        console.error(error.response);
    }
}

const fetchCounts = async (id) => {
    try {
        const counts = await axios.get(`/api/count-votes/${id}`);
        return counts.data;
    } catch (error) {
        console.error(error.resposne);
    }
}

const TwitterURL = ({ twitter, twitter_follower_count }) => {
    
    if (twitter === null || twitter === '' ) {
        return <>TBD</>;
    }
    else {
        const twitter_url = 'https://twitter.com/' + twitter + '?utm_source=futurenftmints';
        
        let twitter_follower_count_formatted = twitter_follower_count;

        if ( twitter_follower_count > 1000000 ) {
            const whole_number = Math.floor(twitter_follower_count / 1000000);            
            const remainder = Math.round(twitter_follower_count % 1000000 * 0.00001) / 10;
            twitter_follower_count_formatted = whole_number + remainder + 'M';
        }
        else if ( twitter_follower_count > 1000 ) {
            const whole_number = Math.floor(twitter_follower_count / 1000);
            const remainder = Math.round(twitter_follower_count % 1000 * 0.01) / 10;
            twitter_follower_count_formatted = whole_number + remainder + 'K';
        }

        return <>
            <Link to={{ pathname: twitter_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}><img alt="" src="/images/twitter.png" height="10" className="d-inline-block align-middle"/> {twitter_follower_count_formatted}</Link>
        </>
    }
}

const CommunityTwitterURL = ({ twitter, twitter_follower_count }) => {
    
    if (twitter === null || twitter === '' ) {
        return <>TBD</>;
    }
    else {
        const twitter_url = 'https://twitter.com/' + twitter + '?utm_source=futurenftmints';
        
        let twitter_follower_count_formatted = twitter_follower_count;

        if ( twitter_follower_count > 1000000 ) {
            const whole_number = Math.floor(twitter_follower_count / 1000000);            
            const remainder = Math.round(twitter_follower_count % 1000000 * 0.00001) / 10;
            twitter_follower_count_formatted = whole_number + remainder + 'M';
        }
        else if ( twitter_follower_count > 1000 ) {
            const whole_number = Math.floor(twitter_follower_count / 1000);
            const remainder = Math.round(twitter_follower_count % 1000 * 0.01) / 10;
            twitter_follower_count_formatted = whole_number + remainder + 'K';
        }

        return <>
            <Link to={{ pathname: twitter_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}><img alt="" src="/images/twitter.png" height="10" className="d-inline-block align-middle"/> @{twitter} ({twitter_follower_count_formatted})</Link>
        </>
    }
}

const DiscordURL = ({ discord, discord_user_count }) => {
    
    if (discord === null || discord === '' ) {
        return <>TBD</>;
    }

    else {
        let discord_user_count_formatted = discord_user_count;

        if ( discord_user_count > 1000000 ) {
            const whole_number = Math.floor(discord_user_count / 1000000);            
            const remainder = Math.round(discord_user_count % 1000000 * 0.00001) / 10;
            discord_user_count_formatted = whole_number + remainder + 'M';
        }
        else if ( discord_user_count > 1000 ) {
            const whole_number = Math.floor(discord_user_count / 1000);
            const remainder = Math.round(discord_user_count % 1000 * 0.01) / 10;
            discord_user_count_formatted = whole_number + remainder + 'K';
        }
    
        const discord_url = 'https://discord.gg/' + discord + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: discord_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}><img alt="" src="/images/discord.png" height="10" className="d-inline-block align-middle"/> {discord_user_count_formatted}</Link>
        </>
    }
}

const CommunityDiscordURL = ({ discord, discord_user_count }) => {
    
    if (discord === null || discord === '' ) {
        return <>TBD</>;
    }

    else {
        let discord_user_count_formatted = discord_user_count;

        if ( discord_user_count > 1000000 ) {
            const whole_number = Math.floor(discord_user_count / 1000000);            
            const remainder = Math.round(discord_user_count % 1000000 * 0.00001) / 10;
            discord_user_count_formatted = whole_number + remainder + 'M';
        }
        else if ( discord_user_count > 1000 ) {
            const whole_number = Math.floor(discord_user_count / 1000);
            const remainder = Math.round(discord_user_count % 1000 * 0.01) / 10;
            discord_user_count_formatted = whole_number + remainder + 'K';
        }
    
        const discord_url = 'https://discord.gg/' + discord + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: discord_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}><img alt="" src="/images/discord.png" height="10" className="d-inline-block align-middle"/> {discord} ({discord_user_count_formatted})</Link>
        </>
    }
}

const WebsiteURL = ({ website }) => {
    
    if (website === null || website === '' ) {
        return <>TBD</>;
    }
    else {
        const website_url = website + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: website_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}><img alt="" src="/images/globe.png" height="10" className="d-inline-block align-middle"/> Link</Link>
        </>
    }
}

const SmartContractURL = ({ smartContract }) => {
    
    if (smartContract === null || smartContract === '' ) {
        return <>TBD</>;
    }
    else {
        const smartContract_url = smartContract + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: smartContract_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}><img alt="" src="/images/globe.png" height="10" className="d-inline-block align-middle"/> Link</Link>
        </>
    }
}

const InsightsReportURL = ({ insightsReport }) => {
    
    if (insightsReport === null || insightsReport === '' ) {
        return <>TBD</>;
    }
    else {
        const insightsReport_url = insightsReport + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: insightsReport_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}><img alt="" src="/images/globe.png" height="10" className="d-inline-block align-middle"/> Link</Link>
        </>
    }
}

const MarketplaceURL = ({ marketplace, marketplace_link }) => {
    if ( marketplace_link === null || marketplace_link === '' ) {
        return <>TBD</>
    }
    if ( marketplace === null || marketplace === '' ) {
        return <>TBD</>
    }
    else {
        const marketplace_url = marketplace_link + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: marketplace_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}>{marketplace}</Link>
        </>
    }

}

const SmartContractInsightsReportMarketplace = ({ smartContract, insightsReport, marketplace, marketplace_link }) => {

    if ( (insightsReport !== null && insightsReport !== '') || (smartContract !== null && smartContract !== '') || (marketplace !== null && marketplace !== '' && marketplace_link !== null && marketplace_link !== '') ) {
        return <>
            <div className="row px-2 my-3">
                <div className="col-12">
                    <div className="row rounded text-center pb-1" style={{ marginTop:"5px", backgroundColor:"rgba(31, 39, 49, 0.8)"}}>
                        <div className="col-4 py-2">
                            <div style={{fontSize:"0.8em", display:"inline"}}>
                                <strong>Marketplace</strong>
                            </div>
                            <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                <MarketplaceURL marketplace={marketplace} marketplace_link={marketplace_link} />
                            </div>
                        </div>    
                        <div className="col-4 py-2">
                            <div style={{fontSize:"0.8em", display:"inline"}}>
                                <strong>Smart Contract</strong>
                            </div>
                            <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                <SmartContractURL smartContract={smartContract} />
                            </div>
                        </div>
                        <div className="col-4 py-2">
                            <div style={{fontSize:"0.8em", display:"inline"}}>
                                <strong>Insights Report</strong>
                            </div>
                            <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                <InsightsReportURL insightsReport={insightsReport} />
                            </div>
                        </div>                                                                                     
                    </div>       
                </div>
            </div>   
        </>
    }
}

const CollectionPage = ({collectionData, likeData, dislikeData, userUsername, access, raffleData, benefitsData, benefitsTypes, relatedNFTsData, accountType}) => {

    const { id, username, nft_collection, collection_image, status, earliest_mint_date, earliest_mint_date_type, date_submitted, collection_supply, collection_description, twitter, twitter_follower_count, twitter_follower_count_update_ts, discord, discord_user_count, discord_user_count_update_ts, website, approved_by, approved_date, public_mint_price, blockchain, roadmap_category_1, roadmap_category_2, roadmap_category_3, roadmap_category_4, roadmap_category_5, roadmap_category_6, upvote_count, downvote_count, vote_percent, allow_list, allow_list_mint_date, allow_list_mint_time, allow_list_mint_price, allow_list_mint_quantity, allow_list_limit_per_transaction, allow_list_limit_per_wallet, public_mint_date, public_mint_quantity, public_mint_time, dutch_auction, dutch_auction_mint_date, dutch_auction_mint_time, dutch_auction_mint_start_price, dutch_auction_mint_end_price, dutch_auction_mint_quantity, dutch_auction_mint_price_change, dutch_auction_mint_price_change_frequency, limit_per_transaction, limit_per_wallet, team, utility, perks, royalty, smart_contract_url, insights_report_twitter_url, warning, warning_message, marketplace, marketplace_link, mint_status, contract_id } = collectionData;

    let reviewed_by;
    let earliestMintDateConverted;
    const approvedDate = new Date(approved_date);
    const approvedDateConverted = approvedDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });

    const [vote, setVote] = useState(null);
    const [upvote_count_update, setUpvoteCount] = useState(upvote_count);
    const [downvote_count_update, setDownvoteCount] = useState(downvote_count);
    const [vote_percent_update, setVotePercent] = useState(vote_percent);
    const [total_vote, setTotalVote] = useState(upvote_count + downvote_count);

    const [likeFormSubmitted, setLikeFormSubmitted] = useState(null);
    const [dislikeFormSubmitted, setDislikeFormSubmitted] = useState(null);

    const [raffleFormSubmitted, setRaffleFormSubmitted] = useState(null);
    const [benefitsFormSubmitted, setBenefitsFormSubmitted] = useState(null);
    const [relatedNFTsFormSubmitted, setRelatedNFTsFormSubmitted] = useState(null);

    const [somethingsOutOfDate, setSomethingsOutOfDate] = useState(false);

    const [twitterErrorCount, setTwitterErrorCount] = useState(null);
    const [discordErrorCount, setDiscordErrorCount] = useState(null);    
    
    useEffect(() => {
        const fetchVote = async () => {
            const response = await axios.get(`/api/fetch-vote/${id}`);
            setVote(response?.data);
            
            const votesCount = await fetchCounts(id);                        
            setUpvoteCount(votesCount.upvote_count);
            setDownvoteCount(votesCount.downvote_count);
            setVotePercent(votesCount.vote_percent);
            setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
        }
        const fetchTwitterErrorCount = async () => {
            const response = await axios.get(`/api/get/error-count/twitter/${twitter}`);
            setTwitterErrorCount(response?.data.recentErrorCount);             
        }
        const fetchDiscordErrorCount = async () => {
            const response = await axios.get(`/api/discord/error/${discord}`);
            setDiscordErrorCount(response?.data.recentErrorCount);
        }
        if ( access !== 'public' ) fetchVote();
        if ( twitterErrorCount === null && twitter !== undefined && twitter !== null && twitter !== '' ) fetchTwitterErrorCount();
        if ( discordErrorCount === null && discord !== undefined && discord !== null && discord !== '' ) fetchDiscordErrorCount();
    }, [id, twitter, discord, access])
    
    //console.log(`ID: ${id}\nVote: ${vote?.data}`);

    const ThumbsUpNeutral = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <button 
                    className="btn btn-lg btn-secondary"
                    onClick={async () => {
                        const newVote = await updateVote(id, 'up');
                        setVote(newVote);
                        const votesCount = await fetchCounts(id);                        
                        setUpvoteCount(votesCount.upvote_count);
                        setDownvoteCount(votesCount.downvote_count);
                        setVotePercent(votesCount.vote_percent);
                        setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                    }}
                >
                    <i className="bi bi-hand-thumbs-up"></i>
                </button>  
            </div>
            </>
        
    }

    const ThumbsUpSelected = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <button 
                    className="btn btn-lg btn-success"
                    onClick={async () => {
                        const newVote = await updateVote(id, 'neutral');
                        setVote(newVote);
                        const votesCount = await fetchCounts(id);
                        setUpvoteCount(votesCount.upvote_count);
                        setDownvoteCount(votesCount.downvote_count);
                        setVotePercent(votesCount.vote_percent);
                        setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                    }}
                >
                    <i className="bi bi-hand-thumbs-up"></i>
                </button>  
            </div>
            </>
        
    }

    const ThumbsDownNeutral = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <button 
                    className="btn btn-lg btn-secondary"
                    onClick={async () => {
                        const newVote = await updateVote(id, 'down');
                        setVote(newVote);
                        const votesCount = await fetchCounts(id);                        
                        setUpvoteCount(votesCount.upvote_count);
                        setDownvoteCount(votesCount.downvote_count);                        
                        setVotePercent(votesCount.vote_percent);
                        setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                    }}
                >
                    <i className="bi bi-hand-thumbs-down"></i>
                </button>  
            </div>
            </>
        
    }
    const ThumbsDownSelected = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <button 
                    className="btn btn-lg btn-danger"
                    onClick={async () => {
                        const newVote = await updateVote(id, 'neutral');
                        setVote(newVote);
                        const votesCount = await fetchCounts(id);
                        setUpvoteCount(votesCount.upvote_count);
                        setDownvoteCount(votesCount.downvote_count);
                        setVotePercent(votesCount.vote_percent);
                        setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                    }}
                >
                    <i className="bi bi-hand-thumbs-down"></i>
                </button>  
            </div>
            </>
        
    }

    const ThumbsUpSignup = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <Link to="/signup">
                    <button className="btn btn-lg btn-secondary">
                        <i className="bi bi-hand-thumbs-up"></i>
                    </button>  
                </Link>
            </div>
        </> 
    }
    const ThumbsDownSignup = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <Link to="/signup">
                    <button className="btn btn-lg btn-secondary">
                        <i className="bi bi-hand-thumbs-down"></i>
                    </button>  
                </Link>
            </div>
        </> 
    }

    const renderThumbs = (access) => {
        //console.log(vote);
        if ( access !== 'public' ) {
            switch(vote) {
                case 'up': 
                    return <><ThumbsUpSelected /><ThumbsDownNeutral /></>;
                case 'down':
                    return <><ThumbsUpNeutral /><ThumbsDownSelected /></>;
                default:
                    return <><ThumbsUpNeutral /><ThumbsDownNeutral /></>;
            }
        }
        else {
            return <>                                           
                <ThumbsUpSignup /><ThumbsDownSignup />
            </>;
        }
    }

    const renderVote = (access) => {        
        return <>
            <div style={{fontSize:"0.8rem"}}>
                    Your Vote
                </div>
            <div className="row text-center">                            
                {renderThumbs(access)}
            </div>
            <div className="row text-center">                            
                <div className="col-6" style={{padding:"0rem"}}>                                
                    <span style={{fontSize:"0.6em"}}>{upvote_count_update}</span>                                                                                           
                </div>                                                        
                <div className="col-6" style={{padding:"0rem"}}>                                                               
                    <span style={{fontSize:"0.6em"}}>{downvote_count_update}</span>                                
                </div>                                                        
            </div>
        </>;            
        
    }

    const renderLikeFormSubmitted = (likeFormSubmitted) => {
        return <>
            <div className="row mt-3 mb-5">
                <div className="col-1"></div>
                <div className="col-10">
                    {likeFormSubmitted}
                </div>
            </div>
        </>;
    }

    const renderDislikeFormSubmitted = (dislikeFormSubmitted) => {
        return <>
            <div className="row mt-3 mb-5">
                <div className="col-1"></div>
                <div className="col-10">
                    {dislikeFormSubmitted}
                </div>
            </div>
        </>;
    }

    const renderRaffleFormSubmitted = (raffleFormSubmitted) => {
        return <>
            <div className="row mt-3 mb-5">
                <div className="col-1"></div>
                <div className="col-10">
                    {raffleFormSubmitted}
                </div>
            </div>
        </>;
    }   
    
    const renderBenefitsFormSubmitted = (benefitsFormSubmitted) => {
        return <>
            <div className="row mt-3 mb-5">
                <div className="col-1"></div>
                <div className="col-10">
                    {benefitsFormSubmitted}
                </div>
            </div>
        </>;
    }   

    const renderRelatedNFTsFormSubmitted = (relatedNFTsFormSubmitted) => {
        return <>
            <div className="row mt-3 mb-5">
                <div className="col-1"></div>
                <div className="col-10">
                    {relatedNFTsFormSubmitted}
                </div>
            </div>
        </>;
    }   

    const history = useHistory();
    const renderRaffleForm = ( id ) => {
        const initialValues = {
        }

        return <>
        <RaffleForm
            initialValues={initialValues}
            onRaffleFormSubmit={
                async (values) => {
                        if ( access === 'public' ) {
                            history.push('/signup');
                        }
                        else {
                            try {                                                        
                                await axios.post(`/api/add/raffles/${id}`, values);
                                setRaffleFormSubmitted("Thanks for submitting a raffle! We'll review it before it is shown to others.");
                            } catch (error) {
                                // This should be visible in FS and (hopefully) alert FS of the issue
                                console.error(error.response)
                            }
                        }
                    }
                }
            />
        </>
    }

    const renderBenefitsForm = ( id ) => {
        const initialValues = {
        }

        return <>
        <BenefitsForm
            initialValues={initialValues}
            benefitsTypes={benefitsTypes}
            onBenefitsFormSubmit={
                async (values) => {
                        if ( access === 'public' ) {
                            history.push('/signup');
                        }
                        else {
                            try {                                                        
                                await axios.post(`/api/post/benefits/${id}`, values);
                                setBenefitsFormSubmitted("Thanks for submitting a benefit! We'll review it before it is shown to others.");
                            } catch (error) {
                                // This should be visible in FS and (hopefully) alert FS of the issue
                                console.error(error.response)
                            }
                        }
                    }
                }
            />
        </>
    }

    const renderRelatedNFTsForm = ( id ) => {
        const initialValues = {
            'nft_collection_id':    id
        };

        return <>
        <RelatedNFTsForm
            initialValues={initialValues}
            onRelatedNFTsFormSubmit={
                async (values) => {
                        if ( access === 'public' ) {
                            history.push('/signup');
                        }
                        else {
                            try {                                                        
                                await axios.post(`/api/post/related-nfts/${id}`, values);
                                setRelatedNFTsFormSubmitted("Thanks for submitting a related NFT! We'll review it before it is shown to others.");
                            } catch (error) {
                                // This should be visible in FS and (hopefully) alert FS of the issue
                                console.error(error.response)
                            }
                        }
                    }
                }
            />
        </>
    }

    const renderLikeForm = ( id ) => {
        const initialValues = {
        }

        return <>
        <LikeForm
            initialValues={initialValues}
            onFormSubmit={
                async (values) => {
                        if ( access === 'public' ) {                            
                            history.push('/signup');
                        }
                        else {
                            try {                                                        
                                await axios.post(`/api/add/like/${id}`, values);
                                setLikeFormSubmitted("Thanks for submitting your like! Refresh for it to appear.");
                            } catch (error) {
                                // This should be visible in FS and (hopefully) alert FS of the issue
                                console.error(error.response)
                            }
                        }
                    }
                }
            />
        </>
    }

    const renderDislikeForm = ( id ) => {
        const initialValues = {
        }

        return <>
        <DislikeForm
            initialValues={initialValues}
            onFormSubmit={
                async (values) => {
                        if ( access === 'public' ) {                            
                            history.push('/signup');
                        }
                        else {
                            try {                                                        
                                await axios.post(`/api/add/dislike/${id}`, values);
                                setDislikeFormSubmitted("Thanks for submitting your dislike! Refresh for it to appear.");
                            } catch (error) {
                                // This should be visible in FS and (hopefully) alert FS of the issue
                                console.error(error.response)
                            }
                        }
                    }
                }
            />
        </>
    }

    const renderLikesDislikes = (access) => {
        
        return <>
            <div className="row">                
                <div className="col-12 col-lg-6">
                    <div className="row" style={{fontSize:"1.5em"}}>
                        <div className="col-12 col-lg-6">
                            <img alt="" src="/images/likeIcon.png" style={{width:"40px"}} />&nbsp;&nbsp;Likes
                        </div>
                    </div>

                    {likeData.length > 0 ? likeData.map(item => <div key={item.id}><LikeDislike data={item} username={userUsername} access={access}/></div>) : <div className="row"><div className="col-1"></div><div className="col-11">No likes</div></div>}

                </div>

                <div className="col-12 d-block d-lg-none">
                    {likeFormSubmitted === null ? renderLikeForm( id ) : renderLikeFormSubmitted(likeFormSubmitted)}
                </div>

                <div className="col-12 col-lg-6">
                    <div className="row" style={{fontSize:"1.5em"}}>
                        <div className="col-12 col-lg-6">
                            <img alt="" src="/images/dislikeIcon.png" style={{width:"40px"}} />&nbsp;&nbsp;Dislikes
                        </div>
                    </div>

                    {dislikeData.length > 0 ? dislikeData.map(item => <div key={item.id}><LikeDislike data={item} username={userUsername} access={access}/></div>) : <div className="row"><div className="col-1"></div><div className="col-11">No dislikes</div></div>}

                </div>

                <div className="col-12 d-block d-lg-none">
                    {dislikeFormSubmitted === null ? renderDislikeForm( id ) : renderDislikeFormSubmitted(dislikeFormSubmitted)}
                </div>
            </div>
            <div className="row">
                <div className="col-6 d-lg-block d-none">
                    {likeFormSubmitted === null ? renderLikeForm( id ) : renderLikeFormSubmitted(likeFormSubmitted)}
                </div>
                <div className="col-6 d-lg-block d-none">
                    {dislikeFormSubmitted === null ? renderDislikeForm( id ) : renderDislikeFormSubmitted(dislikeFormSubmitted)}
                </div>
            </div>
        </>        
    }

    const renderRaffles = () => {

        return <>
            <div className="row table-responsive text-nowrap my-3" style={{fontSize:"1em"}}>                
                <div className="col-12">
                    <table className="table" style={{ color:"#ffffff" }}>
                        <thead>
                            <tr style={{ backgroundColor:"#1F2731"}}>
                                <th scope="col">Partner</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Register deadline (ET)</th>
                                <th scope="col">Signup Link</th>
                                <th scope="col">Submitted by</th>                                
                            </tr>
                        </thead>
                    
            
                        <tbody>
                            {raffleData.length > 0 ? raffleData.map(item => <Raffle key={item.id} data={item}/>) : <tr><td colSpan="5">No raffles for this NFT in our platform. Add one!</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>

            {raffleFormSubmitted === null ? renderRaffleForm( id ) : renderRaffleFormSubmitted(raffleFormSubmitted)}
             
        </>
        
    }

    const renderBenefits = () => {

        return <>
            <div className="row table-responsive my-3" style={{fontSize:"1em"}}>                
                <div className="col-12">
                    <table className="table" style={{ color:"#ffffff" }}>
                        <thead className="text-nowrap">
                            <tr style={{ backgroundColor:"#1F2731"}}>
                                <th scope="col">Type</th>
                                <th scope="col" style={{minWidth:"300px"}}>Description</th>
                                <th scope="col">Partner</th>                                
                                <th scope="col">URL</th>
                                <th scope="col">Claim Deadline</th>
                                <th scope="col">Submitted By</th>
                            </tr>
                        </thead>
                    
            
                        <tbody>
                            {benefitsData.length > 0 ? benefitsData.map(item => <Benefits key={item.id} data={item}/>) : <tr><td colSpan="6">No benefits for this NFT in our platform. Add one!</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>

            {benefitsFormSubmitted === null ? renderBenefitsForm( id ) : renderBenefitsFormSubmitted(benefitsFormSubmitted)}
             
        </>
        
    }

    const renderRelatedNFTs = (id) => {

        return <>
            <div className="row table-responsive my-3" style={{fontSize:"1em"}}>                
                <div className="col-12">
                    <table className="table" style={{ color:"#ffffff" }}>
                        <thead className="text-nowrap">
                            <tr style={{ backgroundColor:"#1F2731"}}>
                                <th scope="col">Relationship</th>
                                <th scope="col">NFT</th>                                
                            </tr>
                        </thead>
                    
            
                        <tbody>
                            {relatedNFTsData.length > 0 ? relatedNFTsData.map(item => <RelatedNFTs key={item.id} data={item}/>) : <tr><td colSpan="6">No related NFTs for this NFT in our platform. Add one!</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>

            {relatedNFTsFormSubmitted === null ? renderRelatedNFTsForm( id ) : renderRelatedNFTsFormSubmitted(relatedNFTsFormSubmitted)}
             
        </>
        
    }

    const convertTime = (time) => {
        const timeSplit = time.split(':');  

        let hour = timeSplit[0] % 12;
        if (hour === 0) { hour = 12 };

        let timePeriod;
        if (timeSplit[0] / 12 >= 1) { timePeriod = ' PM' } else { timePeriod = ' AM'}

        return hour + ':' + timeSplit[1] + timePeriod;
    }
    
    const publicMintDates = () => {
        
        let txn_limit = '';
        if ( !!limit_per_transaction ) {
            txn_limit = limit_per_transaction + ' per txn';
        }

        let wallet_limit = '';
        if ( !!limit_per_wallet ) {
            wallet_limit = limit_per_wallet + ' per wallet';
        }

        let publicMintTime = '';
        if ( !!public_mint_time ) {
            publicMintTime = convertTime (public_mint_time);
        }

        let price = '';
        if ( !!public_mint_price ) {
            price = public_mint_price + ' ' + blockchain;
        }

        if ( !!publicMintDateConverted && publicMintDateConverted !== 'Jan 1, 1983' && publicMintDateConverted !== 'Jan 1, 1970' ) {
            return <>
                    <tr>
                        <td>Public</td>
                        <td>{publicMintDateConverted}</td>
                        <td>{publicMintTime}</td>
                        <td>{price}</td>
                        <td>
                            {public_mint_quantity}
                            <br /><span style={{fontSize:"0.6em"}}>{txn_limit}</span>
                            <br /><span style={{fontSize:"0.6em"}}>{wallet_limit}</span>
                        </td>                        
                    </tr>
            </>
        }
        else {
            return <></>
        }
    }

    const allowListMintDates = () => {
        
        let txn_limit = '';
        if ( !!allow_list_limit_per_transaction ) {
            txn_limit = allow_list_limit_per_transaction + ' per txn';
        }

        let wallet_limit = '';
        if ( !!allow_list_limit_per_wallet ) {
            wallet_limit = allow_list_limit_per_wallet + ' per wallet';
        }

        let mintTime = '';
        if ( !!allow_list_mint_time ) {
            mintTime = convertTime (allow_list_mint_time);
        }

        let price = '';
        if ( !!allow_list_mint_price ) {
            price = allow_list_mint_price + ' ' + blockchain;
        }

        if ( allow_list === 'Yes' && allowListMintDateConverted !== 'Jan 1, 1983' && allowListMintDateConverted !== 'Jan 1, 1970' ) {
            return <>
                    <tr>
                        <td>Allow List</td>
                        <td>{allowListMintDateConverted}</td>
                        <td>{mintTime}</td>
                        <td>{price}</td>
                        <td>
                            {allow_list_mint_quantity}
                            <br /><span style={{fontSize:"0.6em"}}>{txn_limit}</span>
                            <br /><span style={{fontSize:"0.6em"}}>{wallet_limit}</span>
                        </td>                        
                    </tr>                    
            </>
        }
        else {
            return <></>
        }
    }

    const dutchAuctionMintDates = () => {
        
        /*
        let txn_limit = '';
        if ( !!dutch_auction_limit_per_transaction ) {
            txn_limit = dutch_auction_limit_per_transaction + ' per txn';
        }

        let wallet_limit = '';
        if ( !!dutch_auction_limit_per_wallet ) {
            wallet_limit = dutch_auction_limit_per_wallet + ' per wallet';
        }
        */

        let change = '';
        if ( !!dutch_auction_mint_price_change ) {
            change = 'Price drops ' + dutch_auction_mint_price_change + ' ' + blockchain;
        }

        if ( !!dutch_auction_mint_price_change_frequency && change !== '' ) {
            change += ' every ' + dutch_auction_mint_price_change_frequency;
        }

        let mintTime = '';
        if ( !!dutch_auction_mint_time ) {
            mintTime = convertTime (dutch_auction_mint_time);
        }

        let price = '';
        if ( !!dutch_auction_mint_start_price ) {
            price = dutch_auction_mint_start_price + ' to ' + dutch_auction_mint_end_price + ' ' + blockchain;
        }

        if ( dutch_auction === 'Yes' && dutchAuctionMintDateConverted !== 'Jan 1, 1983' && dutchAuctionMintDateConverted !== 'Jan 1, 1970' ) {
            return <>
                <tr>
                    <td>Dutch Auction</td>
                    <td>{dutchAuctionMintDateConverted}</td>
                    <td>{mintTime}</td>
                    <td>
                        {price}
                        <br /><span style={{fontSize:"0.6em"}}>{change}</span>
                    </td>
                    <td>
                        {dutch_auction_mint_quantity}                        
                    </td>                        
                </tr>                         
            </>
        }
        else {
            return <></>
        }
    }

    if ( status === 'approved' ) {
        reviewed_by = `Reviewed by ${approved_by} (${approvedDateConverted}).`;        
    }
    else {
        reviewed_by = 'NOT REVIEWED.';        
    }

    let vote_percent_render = '-';

    if ( vote_percent_update != null ) {
        vote_percent_render = `${vote_percent_update}%`;
    }    

    const dateSubmitted = new Date(date_submitted);
    const dateSubmittedConverted = dateSubmitted.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    //const dateSubmittedConverted = dateSubmitted.toISOString();

    const earliestMintDate = new Date(earliest_mint_date);      
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if ( earliestMintDate > new Date('1984-01-01') ) {
        if ( earliest_mint_date_type === 'TBD' && earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', month: 'short' }) === 'Dec' ) {
            earliestMintDateConverted = 'TBD';
        }
        else if ( earliest_mint_date_type === 'TBD' && earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric' }) === today.toLocaleDateString('en-US', {timeZone: 'UTC', year: 'numeric' }) ) {
            earliestMintDateConverted = earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', month: 'short' });
        }
        else if ( earliest_mint_date_type === 'TBD' ) {
            earliestMintDateConverted = earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short' });
        }
        else if ( earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric' }) === today.toLocaleDateString('en-US', {timeZone: 'UTC', year: 'numeric' }) ) {
            earliestMintDateConverted = earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', month: 'short', day: 'numeric' });
        }
        else {
            earliestMintDateConverted = earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });
        }
    }
    else {
        earliestMintDateConverted = 'No Future Date'
    }
    
    const twelveHoursEarlier = new Date();
    twelveHoursEarlier.setHours( twelveHoursEarlier.getHours() - 12 );     

    let twitter_ts = new Date(twitter_follower_count_update_ts);
    let discord_ts = new Date(discord_user_count_update_ts);    

    if ( twitterErrorCount === 0 && ( ( twitter !== null && twitter !== '' && twitter_ts === null ) 
            || ( twitter !== null && twitter !== '' && twitter_ts !== null && twitter_ts < twelveHoursEarlier ) ) ) {
        axios.get(`/api/twitter/${twitter}/${id}`);     
        setTwitterErrorCount(1);   
    }
    
    if ( discordErrorCount === 0 && ( ( discord !== null && discord !== '' && discord_ts === null ) 
            || ( discord !== null && discord !== '' && discord_ts !== null && discord_ts < twelveHoursEarlier ) ) ) {       
        axios.get(`/api/discord/${discord}/${id}`);
        setDiscordErrorCount(1);
    }    

    const publicMintDate = new Date(public_mint_date);
    const publicMintDateConverted = publicMintDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });

    const allowListMintDate = new Date(allow_list_mint_date);
    const allowListMintDateConverted = allowListMintDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });

    const dutchAuctionMintDate = new Date(dutch_auction_mint_date);
    const dutchAuctionMintDateConverted = dutchAuctionMintDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });

    const pageURL = window.location.href;

    const renderAdminEditButton = ( accountType ) => {        
        if ( accountType === 'owner' || accountType === 'researcher' || accountType === 'admin' ) {
            const editNFTPage = "/edit/nft/" + id;
            return (
                <>
                    
                        <Link to={editNFTPage}>
                            <button className="btn btn-sm btn-primary" style={{marginRight:"5px"}}>Edit</button> 
                        </Link>
                    
                </>
            )
        }
        return '';
    }

    const renderWarningMessage = ( warning, warning_message ) => {
        
        if ( warning === 'Caution' ) {
            return <>
            
                <div className="col-12 rounded py-2 px-3 mt-2 ml-2" style={{ backgroundColor:"rgba(31, 39, 49, 0.8)"}} >
                    <div className="row" style={{fontSize:"0.8em"}}>
                        <div className="col-2 col-md-1 align-middle">
                            <img alt="" src="/images/cautionSymbol.png" style={{width:"30px"}} />
                        </div>
                        <div className="col-10 col-md-11">
                            CAUTION. This NFT has characteristics that have raised some warnings. Make sure you research diligently.
                            <br /><br />
                            DETAILS: {warning_message}
                        </div>                    
                    </div>
                </div>
            
            </>
        }   
        else if ( warning === 'Avoid' ) {
            return <>
                <div className="col-12 rounded py-2 px-3 mt-2 ml-2" style={{ backgroundColor:"rgba(31, 39, 49, 0.8)"}} >
                    <div className="row" style={{fontSize:"0.8em"}}>
                        <div className="col-2 col-md-1 align-middle">
                            <img alt="" src="/images/avoidSymbol.png" style={{width:"30px"}} />
                        </div>
                        <div className="col-10 col-md-11">
                            AVOID. This NFT has had reports of being a scam, rug, or worse.
                            <br /><br />
                            DETAILS: {warning_message}
                        </div>                    
                    </div>
                </div>
            
            </>
        }     
    }

    const renderSomethingsOutOfDate = ( somethingsOutOfDate, id ) => {

        if (somethingsOutOfDate) {
            return "Thanks! We'll check.";
        }
        else {
            return <>
                <button 
                    className="btn btn-sm btn-secondary"        
                    onClick={async () => {
                        await notifySomethingsOutOfDate( id );
                        setSomethingsOutOfDate(true);                     
                    }}                 
                >Something's out of date
                </button> 
            </>
        }
    }

    const notifySomethingsOutOfDate = async (id) => {
        try {
            await axios.put(`/api/put/something-out-of-date/${id}`);
            
        } catch (error) {
            console.error(error.response);
        }
    }
    
    return (        
        <div className="row" style={{ marginTop:"20px"}}>
            <a name="top"></a>
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-xs-12 rounded" style={{ padding: "10px" }}>
                <div className="row text-nowrap" style={{fontSize:"0.8em"}}>
                    <div className="col-8">
                        <Link to="/all-nfts" style={{ color:"#ffffff"}} className="d-inline-block text-nowrap">All NFTs</Link> &nbsp;&gt; {nft_collection}
                    </div>     
                    <div className="col-4 align-self-end ml-auto">
                        <div className="float-end">
                            {renderAdminEditButton(accountType)}                            

                            <button 
                                className="btn btn-sm btn-secondary" 
                                onClick={() => {navigator.clipboard.writeText(pageURL)}}
                            ><i className="bi bi-link-45deg"></i> 
                            </button>    
                            
                        </div>                    
                    </div>               
                </div>           
                <div className="row mt-2">
                    <div className="col-lg-8 col-4"></div>
                    <div className="col-lg-4 col-8 align-self-end ml-auto">
                        <div className="float-end">
                            {renderSomethingsOutOfDate( somethingsOutOfDate, id )}
                        </div>
                    </div>
                </div>     
                <div className="row pt-3 px-3">                    
                    { warning !== 'None' ? renderWarningMessage( warning, warning_message) : null}
                </div>
                <div className="row pt-3">                    
                    <div className="col-sm-4 col-xl-3 col-12" style={{ marginBottom:"10px"}}>
                        <img alt="" src={collection_image}  className="d-inline-block align-top img-fluid rounded" style={{width:"100%"}} /> 
                        <div className="mt-2">
                            <WatchListButtons id={id} access={access} />
                        </div>
                        <div className="mt-2">
                            <AllowListButtons id={id} access={access} /> 
                        </div>
                    </div>                
                    <div className="col-sm-8 col-xl-9 col-12">
                        <div className="row">
                            <div className="col-sm-8 col-12" style={{display:"inline", fontSize:"2em"}}>
                                <strong>{nft_collection}</strong>                               
                            </div>
                            <div className="col-sm-4 col-12 text-center rounded" style={{ margin:"auto"}} >
                                
                                    <div className="rounded" style={{overflow:"hidden", backgroundColor:"#5F1951"}}>
                                        <div style={{fontSize:"1em"}}>{earliestMintDateConverted}</div>
                                        <div style={{fontSize:"0.5em"}}>{earliest_mint_date_type}</div>
                                    </div>
                                
                            
                            </div>   
                        </div>                        
                        <div className="row">
                            <div style={{fontSize:"0.5em", display:"inline"}}>
                                Submitted by {username} ({dateSubmittedConverted}). {reviewed_by} {contract_id !== null ? <>Matched.</> : null}
                            </div>                            
                        </div>
                        <div className="row px-2" style={{marginTop:"20px"}}>                            
                            <div className="col-5 text-center rounded py-2" style={{ backgroundColor:"rgba(31, 39, 49, 0.8)"}}>
                                
                                    <div style={{fontSize:"0.8rem"}}>
                                        Community Rating
                                    </div>
                                    <div style={{fontSize:"2rem", margin:"auto"}}>
                                        {vote_percent_render}
                                    </div>
                                    <div style={{fontSize:"0.6rem", margin:"auto"}}>
                                        {total_vote} Votes
                                    </div>
                                                        
                            </div>
                            <div className="col-2"></div>
                            <div className="col-5 text-center rounded py-2" style={{margin:"auto", backgroundColor:"rgba(31, 39, 49, 0.8)"}}>
                                {renderVote(access)}
                            </div>
                        </div>
                        <div className="row px-2 my-3">
                            <div className="col-12">
                                <div className="row rounded text-center pb-1" style={{ marginTop:"5px", backgroundColor:"rgba(31, 39, 49, 0.8)"}}>
                                    <div className="col-lg-2 col-4 py-2">
                                        <div style={{fontSize:"0.8em", display:"inline"}}>
                                            <strong>Blockchain</strong>
                                        </div>
                                        <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                            {blockchain}
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-4 py-2">
                                        <div style={{fontSize:"0.8em", display:"inline"}}>
                                            <strong>Public</strong>
                                        </div>
                                        <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                            {public_mint_price === null ? allow_list_mint_price : public_mint_price} {blockchain}
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-4 py-2">
                                        <div style={{fontSize:"0.8em", display:"inline"}}>
                                            <strong>Supply</strong>
                                        </div>
                                        <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                            {collection_supply}
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-4 py-2">
                                        <div style={{fontSize:"0.8em", display:"inline"}}>
                                            <strong>Twitter</strong>
                                        </div>
                                        <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                            <TwitterURL twitter={twitter} twitter_follower_count={twitter_follower_count} />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-4 py-2">
                                        <div style={{fontSize:"0.8em", display:"inline"}}>
                                            <strong>Discord</strong>
                                        </div>
                                        <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                            <DiscordURL discord={discord} discord_user_count={discord_user_count} />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-4 py-2">
                                        <div style={{fontSize:"0.8em", display:"inline"}}>
                                            <strong>Website</strong>
                                        </div>
                                        <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                        <WebsiteURL website={website} />
                                        </div>
                                    </div>
                                </div>       
                            </div>
                        </div>     
                        <SmartContractInsightsReportMarketplace smartContract={smart_contract_url} insightsReport={insights_report_twitter_url} marketplace={marketplace} marketplace_link={marketplace_link} />                                      
                    </div>
                </div>
                <div className="col-12 rounded py-3 px-3 mt-4" style={{ backgroundColor:"rgba(31, 39, 49, 0.8)"}} >
                    <div className="row">
                        <div className="col-12">
                            <div className="row" style={{fontSize:"1.5em"}}>
                                <img alt="" src="/images/descriptionIcon.png" style={{maxWidth:"60px"}} />Description
                            </div>

                            <div className="row my-3" style={{fontSize:"1em"}}>
                                <div>
                                    <ReactMarkdown
                                        remarkPlugins={[remarkGfm]} 
                                        linkTarget="_BLANK"
                                        className={markdownStyle.reactMarkdown}
                                        children={collection_description}  
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div>
                                    <div className="badge" style={{backgroundColor:"#E5EAFA", fontSize:"0.6em", fontWeight:"1em", marginRight:"5px", color:"#000000"}}>
                                        {roadmap_category_1}
                                    </div>
                                    <div className="badge" style={{backgroundColor:"#E5EAFA", fontSize:"0.6em", fontWeight:"1em", marginRight:"5px", color:"#000000"}}>
                                        {roadmap_category_2}
                                    </div>
                                    <div className="badge" style={{backgroundColor:"#E5EAFA", fontSize:"0.6em", fontWeight:"1em", marginRight:"5px", color:"#000000"}}>
                                        {roadmap_category_3}
                                    </div>
                                    <div className="badge" style={{backgroundColor:"#E5EAFA", fontSize:"0.6em", fontWeight:"1em", marginRight:"5px", color:"#000000"}}>
                                        {roadmap_category_4}
                                    </div>
                                    <div className="badge" style={{backgroundColor:"#E5EAFA", fontSize:"0.6em", fontWeight:"1em", marginRight:"5px", color:"#000000"}}>
                                        {roadmap_category_5}
                                    </div>
                                    <div className="badge" style={{backgroundColor:"#E5EAFA", fontSize:"0.6em", fontWeight:"1em", marginRight:"5px", color:"#000000"}}>
                                        {roadmap_category_6}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 rounded py-3 px-3 mt-4" style={{ backgroundColor:"rgba(31, 39, 49, 0.8)"}} >
                    <div className="row" style={{fontSize:"1.5em"}}>
                        <img alt="" src="/images/likeIcon.png" style={{maxWidth:"60px"}} />Ratings
                    </div>
                    {<Ratings nftCollectionID={collectionData.id}/>}
                </div>
                <div className="col-12 rounded py-3 px-3 mt-4" style={{ backgroundColor:"rgba(31, 39, 49, 0.8)"}} >
                    <div className="row" style={{fontSize:"1.5em"}}>
                        <img alt="" src="/images/calendarIcon.png" style={{maxWidth:"60px"}} />Mint Dates
                    </div>

                    <div className="row table-responsive text-nowrap my-3" style={{fontSize:"1em"}}>                
                        <div className="col-12">
                            <table className="table" style={{ color:"#ffffff" }}>
                                <thead>
                                    <tr style={{ backgroundColor:"#1F2731"}}>
                                        <th scope="col">Mint Phase</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Time (ET)</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Supply</th>                                
                                    </tr>
                                </thead>
                            
                    
                                <tbody>
                                    {publicMintDates()}    
                                    {allowListMintDates()}   
                                    {dutchAuctionMintDates()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    
                                 

                </div>

                <div className="col-12 rounded py-3 px-3 mt-4" style={{ backgroundColor:"rgba(31, 39, 49, 0.8)"}} >
                    <div className="row" style={{fontSize:"1.5em"}}>
                        <img alt="" src="/images/raffle.png" style={{maxWidth:"60px"}} />Benefits
                    </div>

                    {renderBenefits()}

                </div>

                { mint_status === 'Upcoming Mint' ?
                    <div className="col-12 rounded py-3 px-3 mt-4" style={{ backgroundColor:"rgba(31, 39, 49, 0.8)"}} >
                        <div className="row" style={{fontSize:"1.5em"}}>
                            <img alt="" src="/images/raffle.png" style={{maxWidth:"60px"}} />Raffles
                        </div>

                        {renderRaffles()}

                    </div>
                    :
                    '' 
                }
                <div className="col-12 rounded py-3 px-3 mt-4" style={{ backgroundColor:"rgba(31, 39, 49, 0.8)"}} >
                    <div className="row">
                        <div className="col-12">
                            <div className="row" style={{fontSize:"1.5em"}}>
                                <img alt="" src="/images/keyTakeawaysIcon.png" style={{maxWidth:"60px"}} />Key Details
                            </div>

                            <div className="row my-3" style={{fontSize:"1em"}}>
                                <div className="col-lg-2 col-4">
                                    <div><strong>Team</strong></div>
                                </div>
                                <div className="col-lg-10 col-8">
                                    {team}
                                </div>
                            </div>
                            <div className="row my-3" style={{fontSize:"1em"}}>
                                <div className="col-lg-2 col-4">
                                    <div><strong>Community</strong></div>
                                </div>
                                <div className="col-lg-10 col-8">
                                    <CommunityTwitterURL twitter={twitter} twitter_follower_count={twitter_follower_count} />&nbsp;&nbsp;&nbsp;<CommunityDiscordURL discord={discord} discord_user_count={discord_user_count} />
                                </div>
                            </div> 
                            <div className="row my-3" style={{fontSize:"1em"}}>
                                <div className="col-lg-2 col-4">
                                    <div><strong>Utility</strong></div>
                                </div>
                                <div className="col-lg-10 col-8">
                                    {utility}
                                </div>
                            </div> 
                            <div className="row my-3" style={{fontSize:"1em"}}>
                                <div className="col-lg-2 col-4">
                                    <div><strong>Perks</strong></div>
                                </div>
                                <div className="col-lg-10 col-8">
                                    {perks}
                                </div>
                            </div>   
                            <div className="row my-3" style={{fontSize:"1em"}}>
                                <div className="col-lg-2 col-4">
                                    <div><strong>Royalty</strong></div>
                                </div>
                                <div className="col-lg-10 col-8">
                                    {royalty}
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>
                <div className="col-12 rounded py-3 px-3 mt-4" style={{ backgroundColor:"rgba(31, 39, 49, 0.8)"}} >
                    {renderLikesDislikes(access)}
                </div>

                <div className="col-12 rounded py-3 px-3 mt-4" style={{ backgroundColor:"rgba(31, 39, 49, 0.8)"}} >
                    <div className="row" style={{fontSize:"1.5em"}}>
                        <img alt="" src="/images/raffle.png" style={{maxWidth:"60px"}} />Related NFTs
                    </div>
                    <div className="row" style={{fontSize:"1em"}}>
                        <div className="row table-responsive my-3" style={{fontSize:"1em"}}>                
                            <div className="col-12">
                                Associated NFTs receive the same benefits as this NFT, because even though they're different smart contract addresses, they're intended to be the same NFT. Related NFTs have a common contract creator, but don't always share benefits.
                            </div>
                        </div>
                    </div>

                    {renderRelatedNFTs(id)}

                </div>
            </div>
        </div>
        
    );
};

function mapStateToProps({ form }) {
    return { form };
}

export default connect(mapStateToProps, actions)(CollectionPage);
