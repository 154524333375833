import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

import AutocompleteNFTWithContract from "./SearchBarAutocompleteNFTWithContract";
require("./searchStyles.css");


const SearchForm = ({ initialValues, handleSubmit, onSubmit}) => {

    const submitForm = () => {
        return (
            handleSubmit( values => onSubmit({
                ...values, nftWithContract: document.getElementsByName('nftWithContract')[0].value, nftWithContractId: document.getElementsByName('nftWithContractId')[0].value,nftWithoutContract: document.getElementsByName('nftWithoutContract')[0].value, nftWithoutContractId: document.getElementsByName('nftWithoutContractId')[0].value                
            }) )
        )
    }

    return (
        <>
            <div className="col-12 mt-5">
                {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
                <form name="searchBarAutocomplete" onSubmit={submitForm()}>
                    <div className="form-group">
                        <div className="row">                                    
                            <div className="col-md-5">
                                <Field
                                    className="form-control"
                                    name="nftWithContract"
                                    fieldName="nftWithContract"
                                    hiddenField="nftWithContractId"
                                    hasContractId={true}
                                    component={AutocompleteNFTWithContract}     
                                    placeholder="Search"      
                                    initialSearch={initialValues?.search !== undefined ? initialValues.search : ''}                                      
                                    label="NFT With Contract"
                                    />
                            </div>   
                            <div className="col-2"></div>
                            <div className="col-md-5">
                                <Field
                                    className="form-control"
                                    name="nftWithoutContract"
                                    fieldName="nftWithoutContract"
                                    hiddenField="nftWithoutContractId"
                                    hasContractId={false}
                                    component={AutocompleteNFTWithContract}     
                                    placeholder="Search"      
                                    initialSearch={initialValues?.search !== undefined ? initialValues.search : ''}                                      
                                    label="NFT Without Contract"
                                    />
                            </div>                                    
                        </div>
                        <div className="row mt-5"> 
                            <div className="col-2">
                                <button className="btn btn-light" >
                                    Merge
                                </button>
                            </div> 
                        </div>                            
                    </div>                    
                </form>                
            </div>
        </>
    );
}

const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}        

    // console.log('errors', errors)
    return errors
}


function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'allNFTsFiltersForm',
    validate,
})(SearchForm));