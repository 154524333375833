import { SET_TOKEN_GATED_ACCESS_PRIORITIZED_ACCESS_TOKEN } from '../actions/types';

const tokenGatedAccessReducer = (state = {}, action) => {
    switch(action.type) {
        case SET_TOKEN_GATED_ACCESS_PRIORITIZED_ACCESS_TOKEN:
            // action.payload should include a prioritizedAccessToken key { prioritizedAccessToken: '0xabcdef' }
            // console.log('tokenGatedAccessReducer:', state, action.type, action.payload);
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default tokenGatedAccessReducer
