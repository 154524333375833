import { useState, useMemo, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import axios from 'axios';

import BenefitsHeader from './BenefitsHeader';
import Pagination from '../pagination/Pagination';

let PageSize = 50;

const Card = ({ benefit, page }) => {


    const { benefit_id, nft_collection, nft_collection_id, description, benefit_type, url, partner, claim_deadline_date, claim_deadline_time, alert_read_ts, redeemed_ts, alert_sent_ts, slug, collection_image, partner_slug, partner_image } = benefit;       

    const [benefitRedeemed, setBenefitRedeemed] = useState(redeemed_ts !== null ? true : false);

    const redeemBenefit = async (redeemStatus, benefit_id) => {
        try {
            const response = await axios.put(`/api/put/benefit/redeem`, { redeemStatus, benefit_id });         
            setBenefitRedeemed(redeemStatus);
            
        } catch (error) {
            console.error(error.response);
        }
        
    }

    const convertTime = (time) => {
        if (time !== null && time !== undefined) {
            const timeSplit = time.split(':');  
    
            let hour = timeSplit[0] % 12;
            if (hour === 0) { hour = 12 };
    
            let timePeriod;
            if (timeSplit[0] / 12 >= 1) { timePeriod = ' PM' } else { timePeriod = ' AM'}
    
            return hour + ':' + timeSplit[1] + timePeriod + ' ET';
        }
        return '';
    }

    const convertDate = (date) => {
        const dateFormatted = new Date(date);
        const dateConverted = dateFormatted.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' }); 

        return dateConverted;
    }
    
    const renderCollectionImage = ( collection_image, collection_slug ) => {

        if ( collection_slug !== null ) {
            const collectionPageURL = '/collection/' + collection_slug;
            return <>
                <Link to={{ pathname: collectionPageURL}} style={{ color:"rgba(255,255,255,.8)"}}>
                    <img
                        alt=""
                        src={collection_image}
                        height="80"
                        className="d-inline-block align-top rounded"
                    /> 
                </Link>
            </>;
        }
        else {
            return <>
                <img
                    alt=""
                    src={collection_image}
                    height="80"                    
                    className="d-inline-block align-top rounded"
                /> 
            </>;
        }
    }

    const renderCollectionImageMobile = ( collection_image, collection_slug ) => {

        if ( collection_slug !== null ) {
            const collectionPageURL = '/collection/' + collection_slug;
            return <>
                <Link to={{ pathname: collectionPageURL}} style={{ color:"rgba(255,255,255,.8)"}}>
                    <img
                        alt=""
                        src={collection_image}
                        height="40"
                        className="d-inline-block align-top rounded"
                    /> 
                </Link>
            </>;
        }
        else {
            return <>
                <img
                    alt=""
                    src={collection_image}
                    height="40"                    
                    className="d-inline-block align-top rounded"
                /> 
            </>;
        }
    }

    const renderCollectionName = ( collection_name, collection_slug ) => {

        if ( collection_slug !== null ) {
            const collectionPageURL = '/collection/' + collection_slug;
            return <>
                <Link to={{ pathname: collectionPageURL}} style={{ color:"rgba(255,255,255,.8)"}}>
                    {collection_name}
                </Link>
            </>;
        }
        else {
            return <>
                {collection_name}
            </>;
        }
    }

    const addedTimestamp = new Date(alert_sent_ts);

    return <>
        {/*DESKTOP*/}
        <div className="row mt-4 d-lg-block d-none" style={{backgroundColor:"#280B22", padding:"10px", margin:"0px", borderRadius:"10px"}}>
            <div className="col-12">
                <div className="row">
                    <div className="col-2">
                        {renderCollectionImage( collection_image, slug )}                                                
                    </div>  
                    <div className="col-7">
                        <span style={{fontSize:"1.25em"}}>                
                            {renderCollectionName( nft_collection, slug )}
                        </span><br />
                        <span className="align-self-end" style={{fontSize:"0.6em"}}>
                            Added {addedTimestamp?.toLocaleDateString('en-US', {timeZone: 'America/New_York', month: 'short', day: 'numeric', year: 'numeric'})} {addedTimestamp?.toLocaleTimeString('en-US', {timeZone: 'America/New_York', timeZoneName:"short", hour: '2-digit', minute:'2-digit'})}
                        </span><br />
                        <span style={{fontSize:"1em"}}>
                            {benefit.description}
                        </span>                        
                    </div>                    
                    <div className="col-3">
                        <span className="d-flex justify-content-end" style={{textAlign:"right", fontSize:"0.8em"}}>
                            Claim: {claim_deadline_date === null ? 'Expiration TBD' : convertDate(claim_deadline_date)}<br />{convertTime(claim_deadline_time)}<br />
                        </span>
                        <span className="d-flex justify-content-end">
                            <div className="badge bg-secondary">
                                {benefit_type}
                            </div>
                        </span>
                    </div>
                </div>               
                <div className="row mt-3">                    
                    <div className="col-12 d-flex align-items-end" style={{fontSize:"0.9em"}}>
                        {partner !== null ? partner_slug !== null ? <>Partner: <Link to={'/collection/' + partner_slug} style={{color:"#ffffff"}}><img src={partner_image} width={20} />{partner}</Link></> : 'Partner: ' + partner : null}
                    </div>
                </div>
                <hr />
                <div className="row mt-3">
                    <div className="col-6" style={{borderRight:"1px rgba(255,255,255,0.2) solid"}}>
                        {benefit.url !== null ?
                            <div className="row text-center">
                                <a href={benefit.url} target="_BLANK" rel="noreferrer" className="justify-content-bottom" style={{color:"rgba(255,255,255,.8)", textDecoration:"none"}}>
                                    <div className="col-12">
                                        
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#ffffff" 
                                                className="bi bi-box-arrow-up-right" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                                                <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                                            </svg> Redeem
                                        
                                    </div>
                                </a>
                            </div>
                            :
                            null
                        }
                    </div>            
                    <div className="col-6">
                        <div className="row text-center">
                            {page === 'user' ?
                                <a onClick={async () => redeemBenefit(!benefitRedeemed, benefit_id)} className="justify-content-bottom btn btn-link" style={{color:"rgba(255,255,255,.8)", textDecoration:"none"}}>
                                    <div className="col-12">
                                        {benefitRedeemed !== true ?
                                            
                                            <>Mark as Redeemed</>
                                            :
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" 
                                                    width="16" 
                                                    height="16" 
                                                    fill="#ffffff" 
                                                    className="bi bi-check2-square" 
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z"/>
                                                    <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
                                                </svg> Redeemed
                                            </>
                                        }   
                                    </div>
                                </a>
                                :
                                null
                            }
                        </div>                     
                    </div>        
                </div>
            </div>
        </div>      

        {/*MOBILE*/}    
        <div className="row mt-4 d-block d-lg-none" style={{backgroundColor:"#280B22", padding:"5px", margin:"0px", borderRadius:"5px"}}>
        <div className="col-12">
                <div className="row">
                    <div className="col-2">
                        {renderCollectionImageMobile( collection_image, slug )}                                                
                    </div>  
                    <div className="col-7">
                        <span style={{fontSize:"1em"}}>                
                            {renderCollectionName( nft_collection, slug )}
                        </span><br />
                        <span className="align-self-end" style={{fontSize:"0.6em"}}>
                            Added {addedTimestamp?.toLocaleDateString('en-US', {timeZone: 'America/New_York', month: 'short', day: 'numeric', year: 'numeric'})} {addedTimestamp?.toLocaleTimeString('en-US', {timeZone: 'America/New_York', timeZoneName:"short", hour: '2-digit', minute:'2-digit'})}
                        </span><br />
                        <span style={{fontSize:"0.8em"}}>
                            {benefit.description}
                        </span>                        
                    </div>                    
                    <div className="col-3" style={{padding:"0px"}}>
                        <span className="d-flex justify-content-end" style={{textAlign:"right", fontSize:"0.6em"}}>
                            Claim: {claim_deadline_date === null ? 'Expiration TBD' : convertDate(claim_deadline_date)}<br />{convertTime(claim_deadline_time)}<br />
                        </span>
                        <span className="d-flex justify-content-end">
                            <div className="badge bg-secondary">
                                {benefit_type}
                            </div>
                        </span>
                    </div>
                </div>               
                <div className="row mt-3">                    
                    <div className="col-12 d-flex align-items-end" style={{fontSize:"0.9em"}}>
                        {partner !== null ? partner_slug !== null ? <>Partner: <Link to={partner_slug} style={{color:"#ffffff"}}><img src={partner_image} width={20} />{partner}</Link></> : 'Partner: ' + partner : null}
                    </div>
                </div>
                <hr />
                <div className="row mt-3">
                    <div className="col-6" style={{borderRight:"1px rgba(255,255,255,0.2) solid"}}>
                        {benefit.url !== null ?
                            <div className="row text-center">
                                <a href={benefit.url} target="_BLANK" rel="noreferrer" className="justify-content-bottom" style={{color:"rgba(255,255,255,.8)", textDecoration:"none"}}>
                                    <div className="col-12">
                                        
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                fill="#ffffff" 
                                                className="bi bi-box-arrow-up-right" 
                                                viewBox="0 0 16 16"
                                            >
                                                <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                                                <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                                            </svg> Redeem
                                        
                                    </div>
                                </a>
                            </div>
                            :
                            null
                        }
                    </div>            
                    <div className="col-6">
                        <div className="row text-center">
                            {page === 'user' ?
                                <a href="#" onClick={async () => redeemBenefit(!benefitRedeemed, benefit_id)} className="justify-content-bottom" style={{color:"rgba(255,255,255,.8)", textDecoration:"none"}}>
                                    <div className="col-12">
                                        {benefitRedeemed !== true ?
                                            
                                            <>Mark as Redeemed</>
                                            :
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" 
                                                    width="16" 
                                                    height="16" 
                                                    fill="#ffffff" 
                                                    className="bi bi-check2-square" 
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z"/>
                                                    <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
                                                </svg> Redeemed
                                            </>
                                        }   
                                    </div>
                                </a>
                                :
                                null
                            }                            
                        </div>                     
                    </div>        
                </div>
            </div>
        </div>
    </>;
}

const BenefitsFeed = ({ benefits, page, urlParams, initialValues, account_type }) => {    
 
    const totalCount = benefits.length;    
    const filters = urlParams;

    let redeemedUrl, sortUrl;

    if ( page === 'user' ) {
        redeemedUrl = '/benefits?';
        sortUrl = '/benefits?';
    }
    else if ( page === 'all' ) {
        redeemedUrl = '/all-benefits?';
        sortUrl = '/all-benefits?';
    }

    let hideRedeemed = true;    

    if ( initialValues?.sort !== undefined && initialValues?.sort !== null ) {
        redeemedUrl += 'sort=' + initialValues.sort + '&';
    }
    
    if ( initialValues?.redeemed !== undefined && initialValues?.redeemed !== null ) {
        sortUrl += 'redeemed=' + initialValues.redeemed + '&';

        if ( initialValues.redeemed === 'false' ) {
            hideRedeemed = false;
        }
    }

    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);   

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;        
        return benefits.slice(firstPageIndex, lastPageIndex);        
    }, [currentPage, benefits]);    
    
    return (
        <>
                
        <BenefitsHeader page={page} account_type={account_type} />
        
        {/*DESKTOP*/}
        <span className="d-lg-block d-none">
            <div className="row mt-5">
                <div className="col-1"></div>
                <div className="col-4">                
                    <select 
                        className="form-select" 
                        name="sort" 
                        onChange={e => window.location.assign(`${sortUrl}sort=${e.target.value}`)}
                        value={initialValues?.sort !== undefined && initialValues?.sort !== '' ? initialValues.sort : null}
                    >
                        <option value="claimDeadlineEarToLat">Claim Deadline (Earliest to Latest)</option>
                        <option value="claimDeadlineLatToEar">Claim Deadline (Latest to Earliest)</option>
                        <option value="addedDateEarToLat">Added Date (Earliest to Latest)</option>
                        <option value="addedDateLatToEar">Added Date (Latest to Earliest)</option>
                        <option value="nftNameAtoZ">NFT Name (A to Z)</option>
                        <option value="nftNameZtoA">NFT Name (Z to A)</option>
                    </select>
                </div>
                <div className="col-3 align-self-end">
                    {page === 'user' ? 
                        <><input className="form-check-input" type="checkbox" checked={hideRedeemed === true ? 'checked' : ''} onChange={e => window.location.assign(`${redeemedUrl}redeemed=${e.target.checked}`)} /> Hide Redeemed</>
                        :
                        null 
                    }
                </div>
            </div>
            <div className="row">                                         

                <div className="col-1"></div>
                <div className="col-10">
                    {pageData.length > 0 ? pageData.map(benefit => <Card key={benefit.benefit_id} benefit={benefit} page={page}  />) : null}
                </div>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalCount}
                    pageSize={PageSize}
                    filters={filters}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>      
        </span>     

        {/*MOBILE*/}
        <span className="d-block d-lg-none">
            <div className="row mt-5">
                <div className="col-12">                
                    <select 
                        className="form-select" 
                        name="sort" 
                        onChange={e => window.location.assign(`${sortUrl}sort=${e.target.value}`)}
                        value={initialValues?.sort !== undefined && initialValues?.sort !== '' ? initialValues.sort : null}
                    >
                        <option value="claimDeadlineEarToLat">Claim Deadline (Earliest to Latest)</option>
                        <option value="claimDeadlineLatToEar">Claim Deadline (Latest to Earliest)</option>
                        <option value="addedDateEarToLat">Added Date (Earliest to Latest)</option>
                        <option value="addedDateLatToEar">Added Date (Latest to Earliest)</option>
                        <option value="nftNameAtoZ">NFT Name (A to Z)</option>
                        <option value="nftNameZtoA">NFT Name (Z to A)</option>
                    </select>
                </div>
                <div className="col-12 align-self-end mt-2">
                    {page === 'user' ? 
                        <><input className="form-check-input" type="checkbox" checked={hideRedeemed === true ? 'checked' : ''} onChange={e => window.location.assign(`${redeemedUrl}redeemed=${e.target.checked}`)} /> Hide Redeemed</>
                        :
                        null 
                    }
                </div>
            </div>
            <div className="row">                                         

                <div className="col-12">
                    {pageData.length > 0 ? pageData.map(benefit => <Card key={benefit.benefit_id} benefit={benefit} page={page}  />) : null}
                </div>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalCount}
                    pageSize={PageSize}
                    filters={filters}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>      
        </span>          
        
        </>
    );
};

function mapStateToProps({ form }) {
    return { form };
}

export default connect(mapStateToProps, actions)(BenefitsFeed);
