import RenderFormField from './RenderFormField';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';

import AutocompletePartner from "./AutocompletePartner";

const RaffleForm = ({ onRaffleFormSubmit, initialValues, handleSubmit, error, submitting }) => {
    
    const renderPartner = () => {
        return <>
            <td style={{width:"100px", minWidth:"100px"}}>
                <Field
                    className="form-control"
                    name="rafflePartner"
                    fieldName="rafflePartner"
                    hiddenField="rafflePartnerId"
                    endpoint="/api/get/autocomplete/search?searchTerm="
                    component={AutocompletePartner}     
                    placeholder="Raffle Partner"      
                    initialSearch={initialValues?.search !== undefined ? initialValues.search : ''}                                      
                    label="Raffle Partner"
                    />
            </td>
        </>
    }

    const submitForm = () => {
        return (
            handleSubmit( values => onRaffleFormSubmit({
                ...values, partner: document.getElementsByName('rafflePartner')[0].value, partnerId: document.getElementsByName('rafflePartnerId')[0].value          
            }) )
        )
    }

    return (<>
        {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
        <div className="row table-responsive text-nowrap my-3" style={{fontSize:"1em"}}>                
            <div className="col-12">
                <form onSubmit={submitForm()}>
                    <div>Add a raffle</div>
                    <table className="table" style={{ color:"#ffffff" }}>                        
                        <tbody>
                            <tr>
                                
                                {renderPartner()}
                                
                                <td style={{width:"100px", minWidth:"100px"}}>
                                    <Field
                                        className="form-control"
                                        name="quantity"
                                        component={RenderFormField}
                                        type="text"
                                        placeholder="Quantity"
                                        label="Quantity"
                                    />
                                </td>
                                <td>
                                    <Field
                                        className="form-control"
                                        name="registerDate"
                                        component={RenderFormField}
                                        type="date"
                                        placeholder="mm/dd/yyyy"
                                        label="Date" 
                                    />
                                </td>
                                <td>
                                    <Field
                                        className="form-control"
                                        name="registerTime"
                                        component={RenderFormField}
                                        type="time"
                                        placeholder="00:00 AM"
                                        label="Time (ET)" 
                                    />
                                </td>
                                <td style={{width:"150px", minWidth:"150px"}}>
                                    <Field
                                        className="form-control"
                                        name="signupLink"
                                        component={RenderFormField}
                                        type="text"
                                        placeholder="Sign Up Link"
                                        label="Sign Up Link"
                                    />
                                </td>  
                                <td>
                                    <button className="btn btn" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff" }} disabled={submitting}>
                                        Add
                                    </button>  
                                </td>     
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    
    </>
    );
}

const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}
    
    if (!values.partner) {
        errors.partner = 'Required'
    }
    else if (values.partner) {
        if (values.partner.length > 250) {
         errors.partner = 'Must be 250 characters or less'
        }
    }    

    if (values.quantity) {
        
        if (! ( values.quantity > 0 ) || ( typeof values.quantity != 'number' && values.quantity.search(/[.]/) > -1 ) ) {
         errors.quantity = 'Must be a whole number'
        }

        if (values.quantity.length > 10) {
            errors.quantity = 'Too long for a number'
        }
    }    

    if (!values.signupLink) {
        errors.signupLink = 'Required'
    }
    else if (values.signupLink) {
        if (values.signupLink.length > 250) {
         errors.signupLink = 'Must be 250 characters or less'
        }
    }   
    

    // console.log('errors', errors)
    return errors
}

function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'raffleForm',
    validate,
})(RaffleForm));