import { useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import Pagination from '../../../pagination/Pagination';
import RelatedHeader from '../RelatedHeader';
import ReviewNav from '../../ReviewNav';

const updateStatus = async (id, status) => {
    try {
        const response = await axios.put(`/api/admin/put/related-nft/status/${id}`, { status });      
        return response.data;
    } catch (error) {
        console.error(error.response);
    }
}

const TableRow = ({ item }) => {

    const { id, relationship, nft_collection_id_1, nft_collection_id_2, submitted_ts, submitted_username, nft_collection_1, nft_collection_2 } = item;
    const [status, setStatus] = useState(item.status);

    const ApproveButton = () => {
        return <button
            onClick={async () => {
                const data = await updateStatus(id, 'approved');
                setStatus(data.status);        
            }}
            type="button" className="btn btn-success btn-sm me-2">
            &#x2713;
        </button>;
    }

    const DisapproveButton = () => {
        return <button
            onClick={async () => {
                const data = await updateStatus(id, 'disapproved');
                setStatus(data.status);
            }}
            type="button" className="btn btn-danger btn-sm">
            X
        </button>;
    }

    const renderActionsButtons = () => {
        return <><ApproveButton /><div className="float-end"><DisapproveButton /></div></>;
    }

    const submittedTs = new Date(submitted_ts);
    const submittedTsConverted = submittedTs.toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });

    if ( status === 'submitted' ) {
        return <tr>
            <td style={{width:"100px", minWidth:"100px"}}>{renderActionsButtons()}</td>
            <td>{relationship}</td>
            <td><a href={`/collection/${nft_collection_id_1}`} target="_BLANK" style={{color:'#ffffff'}}>{nft_collection_1}</a></td>
            <td><a href={`/collection/${nft_collection_id_2}`} target="_BLANK" style={{color:'#ffffff'}}>{nft_collection_2}</a></td>
            <td>{submitted_username}</td>
            <td>{submittedTsConverted}</td>
        </ tr>;
    }
    return null;
}

let PageSize = 50;
let filters = '';

const Review = ({ related, accountType, reviewCount }) => {
    
    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);    

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return related.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, related]);

    return (
        <>        
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-2">
                <ReviewNav page="related" accountType={accountType}/>
            </div>
            <div className="col-10">
                <RelatedHeader page="review" reviewCount={reviewCount}/>                
                <div className="row table-responsive" style={{ marginTop:"20px"}}>
                    <div className="col-12">                    
                    <table className="table" style={{ color:"#ffffff" }}>
                        <thead className="text-nowrap">
                            <tr style={{ backgroundColor:"#1F2731"}}>
                                <th scope="col" style={{width:"100px", minWidth:"100px"}}>Action</th>
                                <th scope="col">Relation</th>
                                <th scope="col">NFT 1</th>
                                <th scope="col">NFT 2</th>  
                                <th scope="col">Submitted By</th>
                                <th scope="col">Submitted Date</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize:".8em"}}>
                            {pageData.map(item => <TableRow key={item.id} item={item} />)}
                        </tbody>
                        <tfoot style={{ backgroundColor:"#1F2731", textAlign: "center"}}>
                            <tr>                     
                                <td colSpan="10">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={related.length}
                                        pageSize={PageSize}
                                        filters={filters}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Review;
