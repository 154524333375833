import { Link } from 'react-router-dom';

const ReviewHeader = () => {
    return (
        <div className="row">
            <div className="col-10" style={{ fontSize: "2em"}}>
                Review - NFT Calendar Submissions
            </div>
            <div className="col-2">
                <Link to="/add/nft">
                    <button className="btn btn-sm btn-light">
                        Add NFT
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default ReviewHeader;