import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';
import axios from 'axios';


import RenderField from './RenderFieldMinMax';
import RenderCheckbox from '../addNFT/RenderCheckbox';
import MultiSelectInput from './MultiSelectInput';

import Autocomplete from "./Autocomplete";
import { initial, values } from 'lodash';
require("./searchStyles.css");

let collectionNames = []
let collectionImages = []


const SelectField = ({ input, label, meta: {touched, error}, children }) => (
    <div className="field">
        <label style={{marginBottom: "5px"}}>{label}</label>
        <div className="control">
            <div className={'select ' + (touched ? (error ? 'is-danger' : 'is-success') : '')}>
                <select {...input} className="form-select" style={{backgroundColor:"#1F2731", color:"#ffffff", backgroundImage:"linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc)", backgroundPosition: "calc(100% - 17px) calc(1em + 2px), calc(100% - 12px) calc(1em + 2px), calc(100% - 2em) 0.4em", backgroundSize:"5px 5px, 5px 5px, 1px 1.5em", backgroundRepeat: "no-repeat"}}>
                {children}
                </select>
                {touched && (error && <p className="help is-danger">{error}</p>)}
            </div>
        </div>
    </div>
);

const renderCheckboxes = () => {
    return <>
        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
            <div className="form-check">
                <Field 
                    className="form-check-input" 
                    type="checkbox" 
                    component={RenderCheckbox}
                    name="watchlist"
                    label="Watchlist" />
                
            </div>
        </div>

        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
            <div className="form-check">
                <Field 
                    className="form-check-input" 
                    type="checkbox" 
                    component={RenderCheckbox}
                    name="haveAL"
                    label="Have AL" />
                
            </div>
        </div>

        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
            <div className="form-check">
                <Field 
                    className="form-check-input" 
                    type="checkbox" 
                    component={RenderCheckbox}
                    name="wantAL"
                    label="Want AL" />
                
            </div>
        </div>
    </>
}

const fetchCollectionData = async () => {
    const response = await axios.get('/api/mintCalendar', {
        validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
        }
    });

    for (let i = 0; i < response.data.length; i++) {
        collectionNames.push(response.data[i].nft_collection);
        if (response.data[i].collection_image){
            collectionImages.push(response.data[i].collection_image);
        }
        else{
            collectionImages.push('');
        }
    }
}
fetchCollectionData()


const CalendarFiltersForm = ({ initialValues, handleSubmit, access, onSubmit }) => {

    let blockchainOptions = [
        {value: 'ETH', label: "Ethereum"},
        {value: 'FLOW', label: "Flow"},
        {value: 'PALM', label: "Palm"},
        {value: 'MATIC', label: "Polygon"},
        {value: 'SOL', label: "Solana"},
        {value: 'ADA', label: "Cardano"},
        {value: 'AVAX', label: "Avalanche"},
        {value: 'BNB', label: "Binance"},
        {value: 'OP', label: "Optimism"}
    ]

    let roadmapCategories = [
    {value: "Access Future NFTs", label: "Access Future NFTs"},
    {value: "Airdrop", label: "Airdrop"},
    {value: "Allow List Other NFTs", label: "Allow List Other NFTs"},
    {value: "Analysis", label: "Analysis"},
    {value: "Android App", label: "Android App"},
    {value: "Art", label: "Art"},
    {value: "Beta Access", label: "Beta Access"},
    {value: "Carbon Offset", label: "Carbon Offset"},
    {value: "CC0", label: "CC0"},
    {value: "Celebrate Diversity", label: "Celebrate Diversity"},
    {value: "Charity", label: "Charity"},
    {value: "Coffee", label: "Coffee"},
    {value: "Community Fund", label: "Community Fund"},
    {value: "DAO", label: "DAO"},
    {value: "Discount", label: "Discount"},
    {value: "Education", label: "Education"},
    {value: "Free Shipping", label: "Free Shipping"},
    {value: "Game", label: "Game"},
    {value: "iPhone App", label: "iPhone App"},
    {value: "IRL Events", label: "IRL Events"},
    {value: "Merchandise", label: "Merchandise"},
    {value: "Metaverse", label: "Metaverse"},
    {value: "Newsletter", label: "Newsletter"},
    {value: "NFT Calendar", label: "NFT Calendar"},
    {value: "Movie", label: "Movie"},
    {value: "Music", label: "Music"},
    {value: "Other", label: "Other"},
    {value: "PFP", label: "PFP"},
    {value: "Physical Product", label: "Physical Product"},
    {value: "Play-to-Earn", label: "Play-to-Earn"},
    {value: "Product Roadmap Influence", label: "Product Roadmap Influence"},
    {value: "Q&As", label: "Q&As"},
    {value: "Raffle", label: "Raffle"},
    {value: "Rent / Lend", label: "Rent / Lend"},
    {value: "Research", label: "Research"},
    {value: "Restaurant", label: "Restaurant"},
    {value: "Shared Ownership", label: "Shared Ownership"},
    {value: "Sports", label: "Sports"},
    {value: "Staking", label: "Staking"},
    {value: "Startup", label: "Startup"},
    {value: "Token", label: "Token"},
    {value: "Token-Gated Community", label: "Token-Gated Community"},
    {value: "Token-Gated Content", label: "Token-Gated Content"},
    {value: "Toy", label: "Toy"},
    {value: "Virtual Events", label: "Virtual Events"}
    ]

    function initialValueToArray(array, options){
        
        let initialValuesArray = [];
        for (let i = 0; i < array.length; i++){
            initialValuesArray.push(options.filter(item => item.value === array[i])[0])
        }
        return initialValuesArray;
    }

    function pullMultiselectValues(field){
        let values = [];
        if (document.getElementById(field).querySelectorAll("input")[1].value === ''){
            return '';
        }
        else{
            for (let i = 1; i < document.getElementById(field).querySelectorAll("input").length; i++){
                values.push(document.getElementById(field).querySelectorAll("input")[i].value)
                console.log('value ' + i + ': ' + document.getElementById(field).querySelectorAll("input")[i].value)
            }
            console.log("OK" + values)
            return values;
        }
    }

    return (
        <>
            <div className="col-12 rounded" style={{backgroundColor: "#0E1117" }}>
                {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
                <form autoComplete="off" onSubmit={handleSubmit( values => onSubmit({
                    ...values,                     
                    blockchain: pullMultiselectValues('blockchain'),
                    //roadmapCategories: pullMultiselectValues('roadmapCategories')
                }) )}>   
                                                     
                            
                    <div className="row" style={{marginTop:"15px"}}>                        

                        { access !== 'public' ? renderCheckboxes() : null}                        
                        
                        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
                            <div className="form-group">
                                <div>                                    
                                    <Field
                                        className="form-select"
                                        name="sort"
                                        component={SelectField}
                                        type="select"
                                        label="Sort"
                                    >
                                        <option value="mintDateNewToOld">Upcoming Mint: New to Old</option>
                                        <option value="mintDateOldToNew">Upcoming Mint: Old to New</option>   
                                        <option value="mintPriceHighToLow">Mint Price: High to Low</option>
                                        <option value="mintPriceLowToHigh">Mint Price: Low to High</option>
                                        <option value="likesHighToLow">Likes: High to Low</option>
                                        <option value="likesLowToHigh">Likes: Low to High</option>
                                        <option value="addedDateNewToOld">Date Added: New to Old</option>
                                        <option value="addedDateOldToNew">Date Added: Old to New</option>
                                        <option value="percentLikesHighToLow">Percent Likes: High to Low</option>
                                        <option value="percentLikesLowToHigh">Percent Likes: Low to High </option>
                                    </Field>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
                            <div className="form-group">
                                <div>  
                                <label style={{marginBottom: "5px"}}>Blockchain</label> 
                                    <Field
                                        name="blockchain"
                                        fieldName="blockchain"
                                        id="blockchain"
                                        component={MultiSelectInput}
                                        label="Blockchain"
                                        options={blockchainOptions}
                                        isMulti
                                        initialFilter={initialValueToArray(initialValues.blockchain, blockchainOptions)}
                                    >
                                    </Field>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
                            <div className="form-group">
                                <div className="row">                                    
                                <label style={{ color: "#ffffff", marginBottom: "5px" }}>Mint Price</label>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="priceMin"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Min"
                                            label="" />
                                    </div>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="priceMax"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Max"
                                            label="" />
                                    </div>  
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
                            <div className="form-group">
                            <div className="row">                                    
                                <label style={{ color: "#ffffff", marginBottom: "5px" }}>Mint Date Range</label>
                                    <div className="col-12">
                                       
                                        <Field
                                            className="form-control"
                                            name="startDate"
                                            component={RenderField}
                                            type="date"
                                            placeholder=""
                                            label="" />
                                    </div>
                                    <div className="col-12"> to</div>
                                    <div className="col-12">
                                       
                                        <Field
                                            className="form-control"
                                            name="endDate"
                                            component={RenderField}
                                            type="date"
                                            placeholder=""
                                            label="" />
                                    </div>  
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
                            <div className="form-group">
                                <div>  
                                <label style={{marginBottom: "5px"}}>Roadmap Category</label>                               
                                    <Field
                                        name="roadmapCategories"
                                        fieldName="roadmapCategories"
                                        component={MultiSelectInput}
                                        label="Roadmap Category"
                                        options={roadmapCategories}
                                        isMulti
                                        initialFilter={initialValueToArray(initialValues.roadmapCategories, roadmapCategories)}
                                    >
                                    </Field>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
                            <div className="form-group">
                                <div className="row">                                    
                                <label style={{ color: "#ffffff", marginBottom: "5px" }}>Supply</label>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="supplyMin"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Min"
                                            label="" />
                                    </div>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="supplyMax"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Max"
                                            label="" />
                                    </div>  
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
                            <div className="form-group">
                                <div className="row">                                    
                                <label style={{ color: "#ffffff", marginBottom: "5px" }}>Twitter Followers</label>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="twitterMin"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Min"
                                            label="" />
                                    </div>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="twitterMax"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Max"
                                            label="" />
                                    </div>  
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-sm-3 col-12" style={{marginTop:"10px"}}>
                            <div className="form-group">
                                <div className="row">                                    
                                <label style={{ color: "#ffffff", marginBottom: "5px" }}>Discord Users</label>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="discordMin"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Min"
                                            label="" />
                                    </div>
                                    <div className="col-6">
                                       
                                        <Field
                                            className="form-control"
                                            name="discordMax"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Max"
                                            label="" />
                                    </div>  
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row" style={{ marginTop: "30px", marginBottom: "10px" }}>
                        <div className="col-lg-12 col-sm-3 col-12">
                            <button className="btn btn-light" style={{ width: "100%", height: "50px" }} >
                                Submit
                            </button>
                        </div>                        
                    </div>
                </form>
                <div className="row text-center" style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <div className="col-lg-12 col-sm-3 col-12">
                        <a href="/calendar" style={{ color: "#ffffff", verticalAlign: "baseline" }}>
                            Reset
                        </a>
                    </div>                        
                </div>
            </div>
        </>
    );
}

const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {} 

    if (values.priceMin) {
        
        if (typeof values.priceMin != 'number' && values.priceMin.search(/[a-zA-Z!#$%&’*+/=?^_`{|}~-]/) > -1) {
            errors.priceMin = 'Only input a number or decimal'
        }

        if (values.priceMin.length > 10) {
         errors.priceMin = 'Too long for a number'
        }        
    }

    if (values.priceMax) {
        
        if (typeof values.priceMax != 'number' && values.priceMax.search(/[a-zA-Z!#$%&’*+/=?^_`{|}~-]/) > -1) {
            errors.priceMax = 'Only input a number or decimal'
        }

        if (values.priceMax.length > 10) {
         errors.priceMax = 'Too long for a number'
        }        
    }

    if (values.supplyMin) {
        
        if (! ( values.supplyMin > 0 ) || ( typeof values.supplyMin != 'number' && values.supplyMin.search(/[.]/) > -1 ) ) {
         errors.supplyMin = 'Must be a whole number'
        }

        if (values.supplyMin.length > 10) {
            errors.supplyMin = 'Too long for a number'
        }
    }

    if (values.supplyMax) {
        
        if (! ( values.supplyMax > 0 ) || ( typeof values.supplyMax != 'number' && values.supplyMax.search(/[.]/) > -1 ) ) {
         errors.supplyMax = 'Must be a whole number'
        }

        if (values.supplyMax.length > 10) {
            errors.supplyMax = 'Too long for a number'
        }
    }

    if (values.twitterMin) {
        
        if (! ( values.twitterMin > 0 ) || ( typeof values.twitterMin != 'number' && values.twitterMin.search(/[.]/) > -1 ) ) {
         errors.twitterMin = 'Must be a whole number'
        }

        if (values.twitterMin.length > 10) {
            errors.twitterMin = 'Too long for a number'
        }
    }

    if (values.twitterMax) {
        
        if (! ( values.twitterMax > 0 ) || ( typeof values.twitterMax != 'number' && values.twitterMax.search(/[.]/) > -1 ) ) {
         errors.twitterMax = 'Must be a whole number'
        }

        if (values.twitterMax.length > 10) {
            errors.twitterMax = 'Too long for a number'
        }
    }

    if (values.discordMin) {
        
        if (! ( values.discordMin > 0 ) || ( typeof values.discordMin != 'number' && values.discordMin.search(/[.]/) > -1 ) ) {
         errors.discordMin = 'Must be a whole number'
        }

        if (values.discordMin.length > 10) {
            errors.discordMin = 'Too long for a number'
        }
    }

    if (values.discordMax) {
        
        if (! ( values.discordMax > 0 ) || ( typeof values.discordMax != 'number' && values.discordMax.search(/[.]/) > -1 ) ) {
         errors.discordMax = 'Must be a whole number'
        }

        if (values.discordMax.length > 10) {
            errors.discordMax = 'Too long for a number'
        }
    }

    // console.log('errors', errors)
    return errors
}


function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        keepDirtyOnReinitialize: true,
        enableReinitialize: true,
        updateUnregisteredFields: true
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'calendarFiltersForm',
    validate,
})(CalendarFiltersForm));