import { Link } from 'react-router-dom';

const RelatedNFTs = ( { data } ) => {    
    
    const url = '/collection/' + data.slug;
    return <>
    <tr>
        <td>{data.relationship}</td>
        <td>
            <div className="row">
                <div className="col-lg-1 col-2">
                    <Link to={url} style={{color:"#ffffff"}}>
                        <img src={data.collection_image} width={30} />
                    </Link>
                </div>
                <div className="col-lg-11 col-10">
                    <Link to={url} style={{color:"#ffffff"}}>
                        {data.nft_collection}
                    </Link>
                </div>
            </div>
        </td>  
    </tr>
    
    </>;
}

export default RelatedNFTs;