import { find } from 'lodash';

import accessTokens from '../config/accessTokens';

const getTokenDetails = (contractAddress) => {
    const tokenDetails = find(accessTokens, { contractAddress })
    if (tokenDetails) {
        return tokenDetails
    } else {
        return { }
    }
}

export default getTokenDetails
