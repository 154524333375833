import { Link } from 'react-router-dom';

const BenefitsHeader = ( { page, account_type } ) => {
    
    const userBenefitsHighlight = page === 'user' ? '#7945E1' : '#140510';
    const userBenefitsStyleInputs = { color:"#ffffff", backgroundColor: userBenefitsHighlight, padding:"5px", textDecoration:"none" };    
    const userBenefitsText = page === 'user' ? <strong>My Benefits</strong> : <span>My Benefits</span>;

    const allBenefitsHighlight = page === 'all' ? '#7945E1' : '#140510';
    const allBenefitsStyleInputs = { color:"#ffffff", backgroundColor: allBenefitsHighlight, padding:"5px", textDecoration:"none" };    
    const allBenefitsText = page === 'all' ? <strong>All Benefits</strong> : <span>All Benefits</span>;   
    
    const rafflesFeedHighlight = page === 'rafflesFeed' ? '#7945E1' : '#140510';
    const rafflesFeedStyleInputs = { color:"#ffffff", backgroundColor: rafflesFeedHighlight, padding:"5px", textDecoration:"none" };    
    const rafflesFeedText = page === 'rafflesFeed' ? <strong>Raffles</strong> : <span>Raffles</span>;   

    return (
        <>
        
        {/*DESKTOP*/}
        <span className="d-lg-block d-none">
            <div className="row" style={{marginTop:"20px"}}>              
                <div className="col-12" >                
                    <div className="row">
                        <div className="col-1" ></div>     
                        <div className="col-10 rounded" style={{fontSize:"2em"}}>
                            Benefits
                        </div>                    
                    </div>
                </div>              
            </div>
            <div className="row" style={{marginTop:"20px"}}>              
                <div className="col-12" >                
                    <div className="row">
                        <div className="col-1" ></div>     
                        <div className="col-10 rounded" style={{fontSize:"1em"}}>
                            <Link to="/benefits" className="rounded" style={userBenefitsStyleInputs}>{userBenefitsText}</Link>&nbsp;|&nbsp;
                            <Link to="/all-benefits" className="rounded" style={allBenefitsStyleInputs}>{allBenefitsText}</Link>&nbsp;|&nbsp;
                            <Link to="/raffles-feed" className="rounded" style={rafflesFeedStyleInputs}>{rafflesFeedText}</Link>
                        </div>
                    </div>
                </div>                      
            </div>
            {account_type === 'free' ?
                <div className="row mt-2">
                    <div className="col-1"></div>
                    <div className="col-10 text-center">
                        Receive benefit alerts on ALL of your NFTs. &nbsp;
                        <Link to="/signup">
                            <button className="btn-sm btn-light" style={{color:"#7945E1"}}>
                                Upgrade To Premium
                            </button>
                        </Link>
                    </div>
                </div>
                :
                null
            }
        </span>

        {/*MOBILE*/}
        <span className="d-block d-lg-none">
            <div className="row" style={{marginTop:"20px"}}>              
                <div className="col-12" >                
                    <div className="row">
                        <div className="col-12 rounded" style={{fontSize:"2em"}}>
                            Benefits
                        </div>                    
                    </div>
                </div>              
            </div>
            <div className="row" style={{marginTop:"20px"}}>              
                <div className="col-12" >                
                    <div className="row">  
                        <div className="col-12 rounded" style={{fontSize:"1em"}}>
                            <Link to="/benefits" className="rounded" style={userBenefitsStyleInputs}>{userBenefitsText}</Link>&nbsp;|&nbsp;
                            <Link to="/all-benefits" className="rounded" style={allBenefitsStyleInputs}>{allBenefitsText}</Link>&nbsp;|&nbsp;
                            <Link to="/raffles-feed" className="rounded" style={rafflesFeedStyleInputs}>{rafflesFeedText}</Link>
                        </div>
                    </div>
                </div>                      
            </div>
            {account_type === 'free' ?
                <div className="row mt-2">
                    <div className="col-12 text-center">
                        Receive benefit alerts on ALL of your NFTs. &nbsp;
                        <Link to="/signup">
                            <button className="btn-sm btn-light" style={{color:"#7945E1"}}>
                                Upgrade To Premium
                            </button>
                        </Link>
                    </div>
                </div>
                :
                null
            }
        </span>
        
        </>
    );
};

export default BenefitsHeader;