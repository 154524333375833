import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const LikeDislike = ( { data, username, access } ) => {
    
    //console.log(data);
    const updateVote = async (id, vote) => {
        try {
            const response = await axios.put(`/api/like-dislike-vote/${id}`, { vote });         
            return response.data.vote;
        } catch (error) {
            console.error(error.response);
        }
    }

    const fetchCounts = async (id) => {
        try {
            const counts = await axios.get(`/api/count-likes-dislikes-votes/${id}`);
            return counts.data;
        } catch (error) {
            console.error(error.resposne);
        }
    }

    const [vote, setVote] = useState(null);
    const [upvote_count_update, setUpvoteCount] = useState(data.upvotes);
    const [downvote_count_update, setDownvoteCount] = useState(data.downvotes);
    const [vote_percent_update, setVotePercent] = useState(data.vote_percent);
    const [total_vote, setTotalVote] = useState(data.upvotes + data.downvotes);
    
    useEffect(() => {
        const fetchVote = async () => {
            const response = await axios.get(`/api/fetch-like-dislike-vote/${data.id}`);
            setVote(response?.data);
            
            const votesCount = await fetchCounts(data.id);                        
            setUpvoteCount(votesCount.upvote_count);
            setDownvoteCount(votesCount.downvote_count);
            setVotePercent(votesCount.vote_percent);
            setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
        }
        fetchVote();
    }, [data.id])

    //make sure that the user is taken to the top of the page each time it loads
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
      
    
    //console.log(`ID: ${id}\nVote: ${vote?.data}`);

    const ThumbsUpNeutral = ({upvote_count_update}) => {
    
        return <>
            <div className="col-3">
                <button 
                    className="btn btn-sm btn-secondary"
                    onClick={async () => {
                        const newVote = await updateVote(data.id, 'up');
                        setVote(newVote);
                        const votesCount = await fetchCounts(data.id);                        
                        setUpvoteCount(votesCount.upvote_count);
                        setDownvoteCount(votesCount.downvote_count);
                        setVotePercent(votesCount.vote_percent);
                        setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                    }}
                >
                    <i className="bi bi-hand-thumbs-up"></i>
                </button>&nbsp;&nbsp;{upvote_count_update}              
            </div>            
            </>
        
    }

    const ThumbsUpSelected = ({upvote_count_update}) => {
    
        return <>
            <div className="col-3">
                <button 
                    className="btn btn-sm btn-success"
                    onClick={async () => {
                        const newVote = await updateVote(data.id, 'neutral');
                        setVote(newVote);
                        const votesCount = await fetchCounts(data.id);
                        setUpvoteCount(votesCount.upvote_count);
                        setDownvoteCount(votesCount.downvote_count);
                        setVotePercent(votesCount.vote_percent);
                        setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                    }}
                >
                    <i className="bi bi-hand-thumbs-up"></i>
                </button>&nbsp;&nbsp;{upvote_count_update}
            </div>            
            </>
        
    }

    const ThumbsDownNeutral = ({downvote_count_update}) => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <button 
                    className="btn btn-sm btn-secondary"
                    onClick={async () => {
                        const newVote = await updateVote(data.id, 'down');
                        setVote(newVote);
                        const votesCount = await fetchCounts(data.id);                        
                        setUpvoteCount(votesCount.upvote_count);
                        setDownvoteCount(votesCount.downvote_count);                        
                        setVotePercent(votesCount.vote_percent);
                        setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                    }}
                >
                    <i className="bi bi-hand-thumbs-down"></i>
                </button>&nbsp;&nbsp;{downvote_count_update}
            </div>
            </>
        
    }
    const ThumbsDownSelected = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <button 
                    className="btn btn-sm btn-danger"
                    onClick={async () => {
                        const newVote = await updateVote(data.id, 'neutral');
                        setVote(newVote);
                        const votesCount = await fetchCounts(data.id);
                        setUpvoteCount(votesCount.upvote_count);
                        setDownvoteCount(votesCount.downvote_count);
                        setVotePercent(votesCount.vote_percent);
                        setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                    }}
                >
                    <i className="bi bi-hand-thumbs-down"></i>
                </button>&nbsp;&nbsp;{downvote_count_update}  
            </div>
            </>
        
    }

    const ThumbsUpSignup = () => {
    
        return <>
            <div className="col-3">
                <Link to="/signup">
                    <button className="btn btn-sm btn-secondary">
                        <i className="bi bi-hand-thumbs-up"></i>
                    </button>
                </Link>&nbsp;&nbsp;{upvote_count_update}
            </div>
        </> 
    }
    const ThumbsDownSignup = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <Link to="/signup">
                    <button className="btn btn-sm btn-secondary">
                        <i className="bi bi-hand-thumbs-down"></i>
                    </button>
                </Link>&nbsp;&nbsp;{downvote_count_update}  
            </div>
        </> 
    }

    const renderThumbs = (access) => {
        //console.log(vote);
        if ( access !== 'public' ) {
            switch(vote) {
                case 'up': 
                    return <><div className="col-2"></div><ThumbsUpSelected upvote_count_update={upvote_count_update} /><ThumbsDownNeutral downvote_count_update={downvote_count_update} /></>;
                case 'down':
                    return <><div className="col-2"></div><ThumbsUpNeutral upvote_count_update={upvote_count_update} /><ThumbsDownSelected downvote_count_update={downvote_count_update} /></>;
                default:
                    return <><div className="col-2"></div><ThumbsUpNeutral upvote_count_update={upvote_count_update} /><ThumbsDownNeutral downvote_count_update={downvote_count_update} /></>;
            }
        }        
        else {
            return <>                                           
                <div className="col-2"></div><ThumbsUpSignup upvote_count_update={upvote_count_update}/><ThumbsDownSignup downvote_count_update={downvote_count_update}/>
            </>;
        }
    }

    const renderVote = (access) => {
        
        return <>            
            <div className="row">                            
                {renderThumbs(access)}
            </div>            
        </>;            
        
    }

    const createDate = new Date(data.created_at);
    const createDateConverted = createDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });

    const deleteButton = (data, username) => {
        //console.log(username, data.username);        
        if ( username === data.username) {
            /*
            return ( <>                
                <div className="col-1 btn btn-sm btn-light m-auto" style={{fontSize:"0.5em", padding:"0px"}}>delete</div>
            </>);
            */
           return null
        }
        else {

        }
        return null;
    }
    
    return (
        <>
        <div className="row my-3" style={{fontSize:"1em"}}>
            <div className="col-1"></div>
            <div className="col-10 px-3">{data.value}</div>
            <div className="col-2"></div>
            <div className="col-8" style={{fontSize:"0.8em"}}>Submitted by {data.username} on {createDateConverted}</div>
            {deleteButton(data, username)}            
            {renderVote(access)}
            <div className="col-1 pt-3"></div>
            <div className="col-10 pt-2" style={{borderBottom:"1px solid #6c757d"}}></div>
        </div>
        
        </>
    );
};

export default LikeDislike;