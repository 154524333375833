import React, {Component} from 'react'
import { Field, reduxForm } from 'redux-form';
import RenderField from './RenderField';

export default class DutchAuctionDetails extends Component{

    render(){
        return(
            <><div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-4">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="dutchAuctionMintDate"
                                component={RenderField}
                                type="date"
                                placeholder="mm/dd/yyyy"
                                label="Dutch Auction Mint Date (ET)" />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="dutchAuctionMintTime"
                                component={RenderField}
                                type="time"
                                placeholder="00:00 AM"
                                label="Dutch Auction Mint Time (ET)" />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="dutchAuctionMintQuantity"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                label="Dutch Auction Mint Quantity" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-4">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="dutchAuctionMintStartPrice"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                label="Dutch Auction Mint Start Price" />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="dutchAuctionMintPriceChange"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                label="Dutch Auction Mint Price Change" />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="dutchAuctionMintPriceChangeFrequency"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                label="Dutch Auction Mint Price Change Frequency" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-4">
                    <div className="form-group">
                        <div>
                            <Field
                                className="form-control"
                                name="dutchAuctionMintEndPrice"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                label="Dutch Auction Mint End Price" />
                        </div>
                    </div>
                </div>                        
            </div>
            </>
        )
    }
}