// RenderField contains logic to render a single label and text input
import React from 'react';

//{input} is the same as props.input
export default ({ input, className, placeholder, label, type, meta: { touched, error, warning } }) => {
    
    return (
        <div>            
            <div>
                <input {...input} className={className} placeholder={placeholder} type={type} style={{backgroundColor:"#120B21", color:"rgba(255,255,255,0.8)", colorScheme:"dark"}} />
                {touched && ((error && <span className="text-danger" style={{fontSize:"0.7em"}}>{error}</span>) || (warning && <span>{warning}</span>))}
            </div>
        </div>
    );
};