import { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { getElements } from 'domutils';

import UserNFTs from '../../dashboard/userNFTs/UserNFTs';

const Body = ({ initialUserNFTs, free }) => {    
    
    return (
        <>                                  
                
                <div className="row mt-4">
                    <div className="col-lg-2 col-1"></div>
                    <div className="col-lg-8 col-10">
                        <div className="row">
                            <Link to="/onboard/step-5">
                                <button className="btn my-1" style={{ backgroundColor: "#7945E1", border: "#7945E1", color:"rgba(255,255,255,1)", width: "100%", height: "50px" }}>
                                    Next
                                </button>     
                            </Link> 
                        </div>
                            
                        
                    </div>
                </div> 

                <UserNFTs initialUserNFTs={initialUserNFTs} onboarding={true} free={free} />

                
                            
            
        </>
    );
};

export default Body;
