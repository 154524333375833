import { useState, useEffect } from 'react';
import axios from 'axios';

const useNFTData = (nft_collection_id) => {

    const [values, setValues] = useState(null);   

    useEffect(() => {
        const fetchInitialValues = async () => {
            const initialValues = await axios.get(`/api/fetch-nft/${nft_collection_id}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            const formattedValues = {
                'collectionName':           initialValues.data[0].nft_collection,
                'collectionSupply':         initialValues.data[0].collection_supply,
                'collectionDescription':    initialValues.data[0].collection_description,
                'collectionImage':          initialValues.data[0].collection_image,
                'twitter':                  initialValues.data[0].twitter,
                'discord':                  initialValues.data[0].discord,
                'website':                  initialValues.data[0].website,
                'blockchain':               initialValues.data[0].blockchain,
                'mintDateKnown':            initialValues.data[0].mint_date_known,
                'publicMintDate':           initialValues.data[0].public_mint_date,
                'publicMintTime':           initialValues.data[0].public_mint_time,
                'mintDateUnknownMonth':     initialValues.data[0].mint_date_unknown_month,
                'mintDateUnknownYear':      initialValues.data[0].mint_date_unknown_year,
                'publicMintPrice':          initialValues.data[0].public_mint_price,
                'publicMintQuantity':       initialValues.data[0].public_mint_quantity,
                'limitPerWallet':           initialValues.data[0].limit_per_wallet,
                'limitPerTransaction':      initialValues.data[0].limit_per_transaction,                
                'allowList':                initialValues.data[0].allow_list,
                'allowListMintDate':        initialValues.data[0].allow_list_mint_date,
                'allowListMintTime':        initialValues.data[0].allow_list_mint_time,
                'allowListMintPrice':       initialValues.data[0].allow_list_mint_price,
                'allowListMintQuantity':    initialValues.data[0].allow_list_mint_quantity,
                'allowListLimitPerWallet':  initialValues.data[0].allow_list_limit_per_wallet,
                'allowListLimitPerTransaction':     initialValues.data[0].allow_list_limit_per_transaction,
                'dutchAuction':             initialValues.data[0].dutch_auction,
                'dutchAuctionMintDate':     initialValues.data[0].dutch_auction_mint_date,
                'dutchAuctionMintTime':     initialValues.data[0].dutch_auction_mint_time,
                'dutchAuctionMintQuantity': initialValues.data[0].dutch_auction_mint_quantity,
                'dutchAuctionMintStartPrice':   initialValues.data[0].dutch_auction_mint_start_price,
                'dutchAuctionMintPriceChange':  initialValues.data[0].dutch_auction_mint_price_change,
                'dutchAuctionMintPriceChangeFrequency':     initialValues.data[0].dutch_auction_mint_price_change_frequency,
                'dutchAuctionMintEndPrice': initialValues.data[0].dutch_auction_mint_end_price,
                'team':                     initialValues.data[0].team,
                'utility':                  initialValues.data[0].utility,
                'perks':                    initialValues.data[0].perks,
                'royalty':                  initialValues.data[0].royalty,
                'roadmapCategory1':         initialValues.data[0].roadmap_category_1,
                'roadmapCategory2':         initialValues.data[0].roadmap_category_2,
                'roadmapCategory3':         initialValues.data[0].roadmap_category_3,
                'roadmapCategory4':         initialValues.data[0].roadmap_category_4,
                'roadmapCategory5':         initialValues.data[0].roadmap_category_5,
                'roadmapCategory6':         initialValues.data[0].roadmap_category_6,    
                'insightsReport':           initialValues.data[0].insights_report_twitter_url,
                'smartContract':            initialValues.data[0].smart_contract_url,     
                'mintStatus':               initialValues.data[0].mint_status,      
                'warning':                  initialValues.data[0].warning,
                'warningMessage':           initialValues.data[0].warning_message,
                'marketplace':              initialValues.data[0].marketplace,
                'marketplaceLink':          initialValues.data[0].marketplace_link,
            }
            setValues(formattedValues);
        }

        if ( nft_collection_id != null ) fetchInitialValues();

    }, [nft_collection_id]);
    
    return { values };   
}

export default useNFTData;
