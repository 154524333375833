import { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';

const Body = ({ registeredUser, fetchRegisteredUser, account, dailySummaryCheckedInitial }) => {    
        
    const [email, setEmail] = useState(registeredUser.email);
    const [emailError, setEmailError] = useState('');
    const [personalPhrase, setPersonalPhrase] = useState(registeredUser?.personal_phrase);
    const [phraseError, setPhraseError] = useState('');

    const [dailySummaryBackgroundOn, setDailySummaryBackgroundOn] = useState(dailySummaryCheckedInitial ? "#7945E1" : "transparent");
    const [dailySummaryBackgroundOff, setDailySummaryBackgroundOff] = useState(!dailySummaryCheckedInitial ? "#7945E1" : "transparent");

    const dailySummaryBackground = async ( status ) => {
             
        if ( email ) {
            if ( status === 'on' ) {
                await setDailySummaryBackgroundOn("#7945E1");
                await setDailySummaryBackgroundOff("transparent");
                await notificationCommunicationUpdate('Daily Summary', 'Email', 12, true, 'Daily');
            }
            else {
                await setDailySummaryBackgroundOn("transparent");
                await setDailySummaryBackgroundOff("#7945E1");
                await notificationCommunicationUpdate('Daily Summary', 'Email', 12, false, 'Daily');
            }
        }
        else {
            await setDailySummaryBackgroundOn("transparent");
            await setDailySummaryBackgroundOff("#7945E1");
            await notificationCommunicationUpdate('Daily Summary', 'Email', 12, false, 'Daily');
        }        

    }
    const updateNotificationPreferences = async () => {
        
        if (email !== '' && !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            
            setEmailError('Invalid email address');
        }
        else if (personalPhrase !== null && personalPhrase.length > 50) {
             setPhraseError('Must be 50 characters or less');
        }
        else {
            setEmailError('');
            setPhraseError('');
            
            const values = {
                'email':                email,
                'personalPhrase':       personalPhrase
            }
            try {
                await axios.post(`/api/post/settings/notifications`, values);
                fetchRegisteredUser(account);
            } catch (error) {
                // This should be visible in FS and (hopefully) alert FS of the issue
                console.error(error.response)
            }

            if ( email === '' ) {
                dailySummaryBackground("off");
            }
        }
    }    

    const notificationCommunicationUpdate = async ( template, medium, hour_send, opt_in, cadence ) => {        
        const values = { template, medium, hour_send, opt_in, cadence };
        try { 
            await axios.post(`/api/post/settings/notifications/communication`, values);
        } catch (error) {
            // This should be visible in FS and (hopefully) alert FS of the issue
            console.error(error.response)
        }    
    }

    return (
        <>      
                <div className="row mt-4">
                    <div className="col-lg-2 col-1"></div>
                    <div className="col-lg-8 col-10">
                        Email
                        <input
                            className="form-control"
                            name="email"                            
                            type="text"
                            placeholder="Email"
                            label="Email"
                            value={email}
                            onChange={async (event) => await setEmail(event.target.value)}
                            onBlur={async () => await updateNotificationPreferences()}
                            style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}
                        />
                        {emailError !== '' ? <><span className="text-danger">{emailError}</span></> : ''}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-2 col-1"></div>
                    <div className="col-lg-8 col-10">
                        Personal Phrase
                        <input
                            className="form-control"
                            name="personalPhrase"                            
                            type="text"
                            placeholder="Personal Phrase"
                            label="Personal Phrase"
                            value={personalPhrase}
                            onChange={async (event) => await setPersonalPhrase(event.target.value)}
                            onBlur={async () => await updateNotificationPreferences()}
                            style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}
                        />
                        {phraseError !== '' ? <><span className="text-danger">{phraseError}</span></> : ''}
                    </div>
                </div>
                
                <div className="row mt-4">
                    <div className="col-lg-2 col-1"></div>
                    <div className="col-lg-8 col-10">                        
                        <div className="row mt-2">
                            <div className="col-7 textLeft d-inline-block h-100 align-bottom" style={{paddingRight:"0px"}}>
                                <span className="align-middle h-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" 
                                        width="30" 
                                        height="30" 
                                        fill="currentColor" 
                                        className="bi bi-envelope" 
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                                    </svg>
                                    <span className="align-bottom">
                                        &nbsp;&nbsp;Daily Summary
                                    </span>
                                </span>   
                            </div>    
                            <div className="col-5 d-inline-block" style={{textAlign:"right", paddingRight:"24px"}}>
                                <div className="row">
                                    <div className="col-6 border border-secondary p-2 mx-auto" style={{backgroundColor:dailySummaryBackgroundOn, textAlign:"center", borderTopLeftRadius:"5px", borderBottomLeftRadius:"5px" }} onClick={async() => await dailySummaryBackground("on")}>
                                        On
                                    </div>
                                    <div className="col-6 border border-secondary p-2 mx-auto" style={{backgroundColor:dailySummaryBackgroundOff, height:"100%", textAlign:"center", borderTopRightRadius:"5px", borderBottomRightRadius:"5px" }} onClick={async() => await dailySummaryBackground("off")}>
                                        Off
                                    </div>
                                </div>
                            </div>                     
                        </div>                        
                    </div>
                </div>
                
                <div className="row mt-4">
                    <div className="col-lg-2 col-1"></div>
                    <div className="col-lg-8 col-10">
                        <div className="row">
                            <Link to="/onboard/step-6">
                                <button className="btn my-1" style={{ backgroundColor: "#7945E1", border: "#7945E1", color:"rgba(255,255,255,1)", width: "100%", height: "50px" }}>
                                    Next
                                </button>     
                            </Link> 
                        </div>
                            
                        
                    </div>
                </div> 

                
                            
            
        </>
    );
};

export default Body;
