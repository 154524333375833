import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.scss';

//Source: https://github.com/mayankshubham/react-pagination/blob/master/src/Pagination.js

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    filters
  } = props;
    
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  const location = useLocation();
  const thisPage = useParams().page;

  const previousLocation = `${location.pathname.replace(`/${thisPage}`, '')}/${currentPage - 1}`;
  const nextLocation = `${location.pathname.replace(`/${thisPage}`, '')}/${currentPage + 1}`;  

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  /*
  const onPrevious = () => {
    onPageChange(currentPage - 1);    
  };
  */

  let lastPage = paginationRange[paginationRange.length - 1];  
  
  return (
    <><ul
      className={classnames('pagination-container', { [className]: className })}
    >
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1
        })}
        //onClick={onPrevious}    
        key='0'    
      >
        <a href={previousLocation}><div className="arrow left" /></a>
      </li>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }
        const newLocation = `${location.pathname.replace(`/${thisPage}`, '')}/${pageNumber}${filters}`;
        
        return (
          <li
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage,
              disabled: pageNumber === currentPage
            })}
            //onClick={() => onPageChange(pageNumber)}
            key={pageNumber}
          >
            <a href={newLocation} style={{ color:'#ffffff', textDecoration:'none'}}>{pageNumber}</a>
          </li>
        );
      })}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage
        })}
        //onClick={onNext}   
        key='-1'     
      >
          <a href={nextLocation}><div className="arrow right" /></a>
      </li>
    </ul>
    <div className="d-inline" style={{marginLeft:"40px"}}>{totalCount} Total</div>
    </>
  );
};

export default Pagination;