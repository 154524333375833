import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import useTokenGatedAccess from '../../hooks/useTokenGatedAccess';

// components
import PleaseConnectWallet from '../access/PleaseConnectWallet';
import PleaseWait from '../access/PleaseWait';
//import PleaseRegister from '../access/PleaseRegister';
import AccessDenied from '../access/AccessDenied';
import Calendar from './Calendar';
import CalendarHeader from './CalendarHeader';

const RegisterSuccess = ({ wallet, registeredUser }) => {

    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, _walletAccessTokens } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars    
    
    const [calendarItems, setCalendarItems] = useState([]);
    useEffect(() => {
        const fetchCalendarItems = async () => {
            const response = await axios.get("/api/mintCalendar", {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setCalendarItems(response.data);
        }
        fetchCalendarItems()
    }, [])

    const renderCalendar = () => {
        if (!account) return <PleaseConnectWallet />;

        switch(access) {
            case null:
                return <PleaseWait />;
            case false:
                return <AccessDenied />;
            case true:
                return <Calendar calendarItems={calendarItems}/>;
            default:
                return <p style={{ color: "white" }}>Something went wrong. Do you <a href="/mint">have a token</a>?</p>;
        }
    }

    return (
        <div style={{ backgroundColor: "#000000", color: "#ffffff"}}>
            <CalendarHeader />            
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderCalendar() }
        </div>
    );
};

function mapStateToProps({ wallet, registeredUser }) {
    return { wallet, registeredUser };
}

export default connect(mapStateToProps)(RegisterSuccess);
