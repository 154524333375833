import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

import useTokenGatedAccess from '../../hooks/useTokenGatedAccess';

// components
import PleaseConnectWallet from '../access/PleaseConnectWallet';
import PleaseWait from '../access/PleaseWait';
import PleaseRegister from '../access/PleaseRegister';
import NoAccessPublic from '../access/NoAccessPublic';
import PleaseSignIn from '../access/PleaseSignIn';
import AccessDenied from '../access/AccessDenied';
import AllNFTs from './AllNFTs';

const AllNFTsMain = ({ auth, wallet, registeredUser }) => {    

    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, _walletAccessTokens } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars  

    const pageSize = 50;
    
    const urlCount = "/api/nft/all/count";       

    const url = "/api/nft/all";
    let urlParams = useLocation().search;
    const thisPage = ( useParams().page * 1) || 1;   
    const appendPageParams = useLocation().search === '' ? '?' : '&';
    const pageParams = appendPageParams + 'page=' + thisPage + '&pageSize=' + pageSize;     
    const [params, setParams] = useState(url + urlParams + pageParams);
    const [initialURLParams, setInitialURLParams] = useState(true);
    const [allNFTs, setAllNFTs] = useState( [] );    
    const [totalCount, setTotalCount] = useState( [] );
    const [filters, setFilters] = useState('');
    
    useEffect(() => {
        if (urlParams != '' && !!initialURLParams){
            setParams(url + urlParams + pageParams);
            setInitialURLParams(false);
            setFilters(urlParams);
        }
        else if (urlParams == '' && !!initialURLParams) {
            setFilters('');            
        }
        const fetchAllNFTs = async () => {
            const response = await axios.get(params, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setAllNFTs(response.data.data);
            setTotalCount(response.data.count);
        }
        fetchAllNFTs()
    }, [params]);

    const paramsCount = urlCount + useLocation().search;        
    const [publicUser, setPublicUser] = useState(false);  
    const [allNFTsPremiumCount, setAllNFTsPremiumCount] = useState(0);     

    useEffect(() => {
        

        const fetchAllCalendarsPremiumCount = async () => {
            const response = await axios.get(paramsCount, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setAllNFTsPremiumCount(response.data);
        }

        fetchAllCalendarsPremiumCount();
    }, [])
    
    //this avoids a flicker where the public info is shown just before the logged in
    //user's info is recognized which changes the info on the page to the appropriate details
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const delayPublic = async () => {
        await delay(2000);
        //console.log("Waited 5s");
        setPublicUser(true);
      };
    delayPublic();

    useEffect(() => {
        axios.get(`/api/update-earliest-mint-date`); 
    }, []);

    const renderAllNFTs = () => {
        if (!account && publicUser) {
            return <AllNFTs  access="public" allNFTsPremiumCount={allNFTsPremiumCount} pageSize={pageSize} allNFTs={allNFTs} totalCount={totalCount} originalFilters={filters} thisPage={thisPage} urlParams={urlParams} url={url} originalParams={params} /> 
        }           
        switch(access) { 
            case null:                
                return <PleaseWait />;
            case false:                              
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;
                if (registeredUser.account_type === 'top 100') {                                        
                    return ( <AllNFTs  access="premium" allNFTsPremiumCount={allNFTsPremiumCount} pageSize={pageSize} allNFTs={allNFTs} totalCount={totalCount} originalFilters={filters} thisPage={thisPage} urlParams={urlParams} url={url} originalParams={params} /> ); 
                }                    
                else if (registeredUser.username) {                    
                    return ( <AllNFTs  access="free" allNFTsPremiumCount={allNFTsPremiumCount} pageSize={pageSize} allNFTs={allNFTs} totalCount={totalCount} originalFilters={filters} thisPage={thisPage} urlParams={urlParams} url={url} originalParams={params} /> ); 
                }
                return <p style={{ color: "white" }}>Something went wrong. Try disconnecting your wallet and refresh the page.</p>;
            case true:                
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;          
                if (registeredUser.username) {                    
                    return ( <AllNFTs  access="premium" allNFTsPremiumCount={allNFTsPremiumCount} pageSize={pageSize} allNFTs={allNFTs} totalCount={totalCount} originalFilters={filters} thisPage={thisPage} urlParams={urlParams} url={url} originalParams={params} /> ); 
                }                
                else { 
                    return (  <Redirect to="/register" />  );    
                }
            default:
                return <p style={{ color: "white" }}>Something went wrong. Do you <a href="/mint">have a token</a>?</p>;
        }
    }

    return (
        <div>                        
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderAllNFTs() }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser }) {
    return { auth, wallet, registeredUser };
}

export default connect(mapStateToProps)(AllNFTsMain);
