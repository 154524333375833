import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";

import axios from 'axios';

import Mint from './Mint';

import season1MintConfig from '../../config/mint/season1/mintConfig';
import season1ContractAbi from '../../config/contracts/season1/contractAbi';

const MintPage = ({ wallet, bottomPadding }) => {

    const { account } = wallet;

    const [season1MerkleProof, setSeason1MerkleProof] = useState(null);

    useEffect(() => {
        const fetchSeason1MerkleProof = async () => {
            const response = await axios.get(`/api/mint/season1/${account}/merkle_proof`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setSeason1MerkleProof(response.data.merkleProof);         
        }
        if (account) fetchSeason1MerkleProof();
    }, [account]);  
    
    const paddingBottom = bottomPadding !== undefined ? "0px" : "200px";    

    return (
        <> 
            <Row style={{ paddingTop:"75px", paddingBottom:{paddingBottom}, height:"100%", textAlign: "center" }}>
                <div className="col-md-2"></div>
                <div className="col-md-3">
                    <img fluid="true" alt="Future Mints - Generation II NFT Card" src="images/AnnualPass-Card.png" width="200px" className="d-inline-block align-top"/>
                </div>    
                <div className="col-md-5">
                    <Mint mintConfig={season1MintConfig} contractAbi={season1ContractAbi} merkleProof={season1MerkleProof} />
                    <Row  style={{ paddingBottom:"25px" }}></Row>
                </div>
                <div className="col-md-2"></div>
            </Row>                  
        </>
    )
}

function mapStateToProps({ wallet }) {
    return { wallet };
}

export default connect(mapStateToProps)(MintPage);
