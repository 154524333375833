import { useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import Pagination from '../../pagination/Pagination';
import ReviewHeader from './ReviewHeader';
import ReviewNav from '../ReviewNav';

const updateStatus = async (id, status, twitter, discord) => {
    try {
        const response = await axios.put(`/api/admin/non_fungible_tokens/${id}/update_status`, { status });  
        axios.get(`/api/twitter/${twitter}/${id}`);
        axios.get(`/api/discord/${discord}/${id}`);      
        return response.data;
    } catch (error) {
        console.error(error.response)
    }
}

const TableRow = ({ nonFungibleToken }) => {

    const { id, username, nft_collection, earliest_mint_date, date_submitted, approved_by, disapproved_by, twitter, discord } = nonFungibleToken;
    const [status, setStatus] = useState(nonFungibleToken.status);
    const reviewedInitial = (status==="approved") ? approved_by : (status==="disapproved") ? disapproved_by : null;
    const [reviewed_by, setReviewedBy] = useState(reviewedInitial);

    const badgeModifierClassName = {
        "submitted": "bg-secondary",
        "disapproved": "bg-danger",
        "approved": "bg-success"
    };

    const ApproveButton = () => {
        return <button
            onClick={async () => {
                const data = await updateStatus(id, 'approved', twitter, discord);
                setStatus(data.status);
                setReviewedBy(data.reviewed_by);                
            }}
            type="button" className="btn btn-success btn-sm me-2">
            &#x2713;
        </button>;
    }

    const DisapproveButton = () => {
        return <button
            onClick={async () => {
                const data = await updateStatus(id, 'disapproved', twitter, discord);
                setStatus(data.status);
                setReviewedBy(data.reviewed_by);
            }}
            type="button" className="btn btn-danger btn-sm">
            X
        </button>;
    }

    const renderActionsButtons = () => {
        switch(status) {
            case 'submitted':
                return <><ApproveButton /><div className="float-end"><DisapproveButton /></div></>;
            case 'disapproved':
                return <ApproveButton />;
            case 'approved':
                return <div className="float-end"><DisapproveButton /></div>;
            default:
                return null;
        }
    }

    const earliestMintDate = new Date(earliest_mint_date);
    const earliestMintDateConverted = earliestMintDate.toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });

    const dateSubmitted = new Date(date_submitted);
    const dateSubmittedConverted = dateSubmitted.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });

    return <tr>
        <td style={{width:"100px", minWidth:"100px"}}>{renderActionsButtons()}</td>
        <td>{username}</td>
        <td>{reviewed_by}</td>
        <td><Link to={`/edit/nft/${id}`} style={{color:'#ffffff'}}>{nft_collection}</Link></td>
        <td><Link to={`/collection/${id}`} style={{color:'#ffffff'}}>Link</Link></td>
        <td>{earliestMintDateConverted}</td>
        <td>{dateSubmittedConverted}</td>
        <td><span className={`badge ${badgeModifierClassName[status]} text-capitalize`}>{status}</span></td>
    </ tr>;
}

let PageSize = 50;
let filters = '';

const Review = ({ nonFungibleTokens, accountType, nonFungibleTokensCount }) => {
    
    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);

    return (
        <>        
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-2">
                <ReviewNav page="review" accountType={accountType}/>
            </div>
            <div className="col-10">
                <ReviewHeader />                
                <div className="row table-responsive text-nowrap" style={{ marginTop:"20px"}}>
                    <div className="col-12">                    
                    <table className="table" style={{ color:"#ffffff" }}>
                        <thead>
                            <tr style={{ backgroundColor:"#1F2731"}}>
                                <th scope="col" style={{width:"100px", minWidth:"100px"}}>Action</th>
                                <th scope="col">User</th>
                                <th scope="col">Reviewed By</th>
                                <th scope="col">Edit NFT Collection</th>
                                <th scope="col">View Page</th>
                                <th scope="col">Earliest Mint Date</th>
                                <th scope="col">Date Submitted</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize:".8em"}}>
                            {nonFungibleTokens.map(nonFungibleToken => <TableRow key={nonFungibleToken.id} nonFungibleToken={nonFungibleToken} />)}
                        </tbody>
                        <tfoot style={{ backgroundColor:"#1F2731", textAlign: "center"}}>
                            <tr>                     
                                <td colSpan="7">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={nonFungibleTokensCount}
                                        pageSize={PageSize}
                                        filters={filters}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Review;
