import { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { getElements } from 'domutils';

const Body = () => {    

    const [freeEnabled, setFreeEnabled] = useState(true);  

    const setFreeLogic = () => {
        let freeAlerts = true;
        let freeUpcoming = true;

        if ( document.getElementsByName("NFTAlerts")[0].checked ) {
            freeAlerts = true;
        }
        else {
            freeAlerts = false;
        }

        if ( document.getElementsByName("UpcomingMints")[0].checked ) {
            freeUpcoming = true;
        }
        else {
            freeUpcoming = false;
        }

        setFreeEnabled(freeAlerts === true && freeUpcoming === true ? true : false);
    }
    return (
        <>                  
                <div className="row mt-4">
                    <div className="col-lg-2 col-1"></div>
                    <div className="col-lg-8 col-10">
                        <div className="row">
                            <label style={{marginBottom: "5px"}}>How many NFTs would you like to receive benefit alerts for?</label>
                        </div>
                        <div className="row mt-2" style={{padding:"0px 12px"}}>
                            <input
                                type="radio"
                                className="form-check-input"
                                name="NFTAlerts"                                        
                                value="1"
                                style={{padding:"0px"}}
                                defaultChecked='checked'
                                onClick={async() => setFreeLogic()}
                             ></input> 
                            &nbsp;&nbsp;&nbsp;&nbsp;1
                        </div>
                        <div className="row mt-1" style={{padding:"0px 12px"}}>
                            <input
                                type="radio"
                                className="form-check-input"
                                name="NFTAlerts"                                        
                                value="unlimited"
                                style={{padding:"0px"}}
                                onClick={async() => setFreeLogic()}
                             ></input> 
                            &nbsp;&nbsp;&nbsp;&nbsp;Unlimited
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-2 col-1"></div>
                    <div className="col-lg-8 col-10">
                        <div className="row">
                            <label style={{marginBottom: "5px"}}>How many days of upcoming mints do you want to see?</label>
                        </div>
                        <div className="row mt-2" style={{padding:"0px 12px"}}>
                            <input
                                type="radio"
                                className="form-check-input"
                                name="UpcomingMints"                                        
                                value="5"
                                style={{padding:"0px"}}
                                defaultChecked='checked'
                                onClick={async() => setFreeLogic()}
                             ></input> 
                            &nbsp;&nbsp;&nbsp;&nbsp;5 Days
                        </div>
                        <div className="row mt-1" style={{padding:"0px 12px"}}>
                            <input
                                type="radio"
                                className="form-check-input"
                                name="UpcomingMints"                                        
                                value="unlimited"
                                style={{padding:"0px"}}
                                onClick={async() => setFreeLogic()}
                             ></input> 
                            &nbsp;&nbsp;&nbsp;&nbsp;Unlimited
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-2 col-1"></div>
                    <div className="col-lg-8 col-10">
                        <div className="row">
                            
                            <div className="col-12 border border-secondary rounded p-2 mx-auto" >

                                {freeEnabled ? <>
                                    <div className="row" style={{fontSize:"2em"}}>
                                        <span>Free</span>
                                    </div>
                                    <Link to="/onboard/step-4">
                                        <button className="btn my-1" style={{ backgroundColor: "#7945E1", border: "#7945E1", color:"rgba(255,255,255,1)", width: "100%", height: "50px" }}>
                                            Continue as a Free user
                                        </button>     
                                    </Link> 
                                    <div className="row mt-2">
                                        <span className="d-block align-bottom">
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="20" 
                                                height="20" 
                                                fill="#7945E1" 
                                                class="bi bi-check-circle-fill" 
                                                viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;Select 1 NFT to receive benefit alerts
                                        </span>
                                    </div>
                                    <div className="row">
                                        <span className="d-block align-bottom">
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="20" 
                                                height="20" 
                                                fill="#7945E1" 
                                                class="bi bi-check-circle-fill" 
                                                viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;Link up to 10 wallets
                                        </span>
                                    </div>
                                    <div className="row">
                                        <span className="d-block align-bottom">
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="20" 
                                                height="20" 
                                                fill="#7945E1" 
                                                class="bi bi-check-circle-fill" 
                                                viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;Get 5 days of upcoming mints
                                        </span>
                                    </div>
                                    <div className="row">
                                        <span className="d-block align-bottom">
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="20" 
                                                height="20" 
                                                fill="#7945E1" 
                                                class="bi bi-check-circle-fill" 
                                                viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;Access all research for minted NFTs
                                        </span>
                                    </div>
                                    <div className="row">
                                        <span className="d-block align-bottom">
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="20" 
                                                height="20" 
                                                fill="#7945E1" 
                                                class="bi bi-check-circle-fill" 
                                                viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;Track allow lists you want or have
                                        </span>
                                    </div>                                    
                                       
                                    </>
                                    :
                                    <>
                                    <div className="row" style={{fontSize:"2em", textDecoration:"line-through"}}>
                                        <span>Free</span>
                                    </div>
                                    <button className="btn my-2" style={{ backgroundColor: "#7945E1", border: "#7945E1", color:"rgba(255,255,255,1)", width: "100%", height: "50px" }} disabled>
                                        Premium features selected
                                    </button>   
                                    </>    
                                }              
                            </div>
                        </div>
                            
                        
                    </div>
                </div> 

                <div className="row mt-4" style={{marginBottom:"100px"}}>
                    <div className="col-lg-2 col-1"></div>
                    <div className="col-lg-8 col-10">
                        <div className="row">
                            
                            <div className="col-12 border border-secondary rounded p-2 mx-auto" >
                                <div className="row" style={{fontSize:"2em"}}>
                                    <span>Premium</span>
                                </div>
                                <div className="row mt-2" style={{fontSize:"1em"}}>
                                    <span>
                                        <span style={{fontSize:"2em"}}>0.05 ETH </span>/ Year (mint Oct 20)</span>
                                </div>
                                <a href="https://heymint.xyz/future-mints-annual-registered" target="_BLANK" rel="noreferrer">
                                    <button className="btn my-1" style={{ backgroundColor: "#7945E1", border: "#7945E1", color:"rgba(255,255,255,1)", width: "100%", height: "50px" }}>
                                        Sign-up for Allow List
                                    </button>     
                                </a> 
                                <div className="row mt-1" style={{fontSize:"1em"}}>
                                    <span>
                                        <span style={{fontSize:"2em"}}>0.25 ETH </span>/ Lifetime (only 500)</span>
                                </div>
                                <Link to="/mint" target="_BLANK" rel="noreferrer">
                                    <button className="btn my-1" style={{ backgroundColor: "#7945E1", border: "#7945E1", color:"rgba(255,255,255,1)", width: "100%", height: "50px" }}>
                                        Get Lifetime Access
                                    </button>     
                                </Link> 
                                <div className="row mt-2">
                                    <span className="d-block align-bottom">
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="20" 
                                            height="20" 
                                            fill="#7945E1" 
                                            class="bi bi-check-circle-fill" 
                                            viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                        </svg>
                                        &nbsp;&nbsp;&nbsp;<strong>Unlimited</strong> NFT benefit alerts
                                    </span>
                                </div>
                                <div className="row">
                                    <span className="d-block align-bottom">
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="20" 
                                            height="20" 
                                            fill="#7945E1" 
                                            class="bi bi-check-circle-fill" 
                                            viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                        </svg>
                                        &nbsp;&nbsp;&nbsp;<strong>Unlimited</strong> Linked wallets
                                    </span>
                                </div>
                                <div className="row">
                                    <span className="d-block align-bottom">
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="20" 
                                            height="20" 
                                            fill="#7945E1" 
                                            class="bi bi-check-circle-fill" 
                                            viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                        </svg>
                                        &nbsp;&nbsp;&nbsp;<strong>Unlimited</strong> upcoming mints
                                    </span>
                                </div>
                                <div className="row">
                                    <span className="d-block align-bottom">
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="20" 
                                            height="20" 
                                            fill="#7945E1" 
                                            class="bi bi-check-circle-fill" 
                                            viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                        </svg>
                                        &nbsp;&nbsp;&nbsp;Earn Giveaway Entries Daily
                                    </span>
                                </div>
                                <div className="row">
                                    <span className="d-block align-bottom">
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                            width="20" 
                                            height="20" 
                                            fill="#7945E1" 
                                            class="bi bi-check-circle-fill" 
                                            viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                        </svg>
                                        &nbsp;&nbsp;&nbsp;Access to all new features
                                    </span>
                                </div>    
                            </div>
                        </div>
                            
                        
                    </div>
                </div>                                 
            
        </>
    );
};

export default Body;
