const AccessDenied = () => {
    return <div className="row">
        <div className="col-3"></div>
        <div className="col-6">
            <p style={{ color: "rgba(255,255,255,0.8)" }}>Access Denied. Please <a href="/mint" style={{color:"rgba(255,255,255,0.8)"}}>mint a token</a>.</p>
        </div>
    </div>
}

export default AccessDenied
