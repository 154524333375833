import ProgressIndicator from '../../onboard/ProgressIndicator';

const RegisterPrompt = ({username, walletCount}) => {
    return (
        <>
            <ProgressIndicator current={4} total={5}/>            
            
            <div className="row">
                <div className="col-1 col-lg-2"></div>
                <div className="col-10 col-lg-8">                   
                    <span style={{fontSize:"2em"}}>
                        Select which NFT to receive benefit alerts
                    </span><br />Free accounts are limited to 1 alert per wallet. You have {walletCount} wallet{walletCount > 1 ? 's':''}.
                </div>
            </div>            
        </>
    );
};

export default RegisterPrompt;
