import React, { useState } from 'react';
import RenderFormField from '../../../collectionPage/RenderFormField';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../../../actions/index';

const PromoCodeForm = ({ onPromoCodeFormSubmit, initialValues, handleSubmit, error, submitting }) => {    

    return (<>
        {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
        <div className="row table-responsive text-nowrap my-3" style={{fontSize:"1em"}}>                
            <div className="col-12">
                <form onSubmit={handleSubmit( onPromoCodeFormSubmit )}>
                    <div>Add A Promo Code</div>
                    <table className="table" style={{ color:"#ffffff" }}>                        
                        <tbody>
                            <tr>
                                <td>
                                    <Field
                                        className="form-control"
                                        name="promoCode"
                                        component={RenderFormField}
                                        type="text"
                                        placeholder="Promo Code"
                                        label="Promo Code"
                                    />
                                </td>

                                <td>
                                    <Field
                                        className="form-control"
                                        name="partner"
                                        component={RenderFormField}
                                        type="text"
                                        placeholder="Partner"
                                        label="Partner"
                                    />
                                </td>
                                <td>
                                    <Field
                                        className="form-control"
                                        name="quantity"
                                        component={RenderFormField}
                                        type="text"
                                        placeholder="Quantity"
                                        label="Quantity"
                                    />
                                </td>                                
                                <td>
                                    <Field
                                        className="form-control"
                                        name="endDate"
                                        component={RenderFormField}
                                        type="date"
                                        placeholder="mm/dd/yyyy"
                                        label="End Date" 
                                    />
                                </td>                                                                
                                <td>
                                    <button className="btn btn" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff" }} disabled={submitting}>
                                        Add
                                    </button>  
                                </td>     
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    
    </>
    );
}

const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}
    
    if (!values.promoCode) {
        errors.promoCode = 'Required'
    }

    if (values.promoCode) {
        if (values.promoCode.length > 10) {
         errors.promoCode = 'Must be 10 characters or less'
        }
    } 
    
    if (values.partner) {
        if (values.partner.length > 50) {
         errors.partner = 'Must be 50 characters or less'
        }
    } 

    if (!values.quantity) {
        errors.quantity = 'Required'
    }

    if (! ( values.quantity > 0 ) || ( typeof values.quantity != 'number' && values.quantity.search(/[.]/) > -1 ) ) {
        errors.quantity = 'Must be a whole number'
    }

    if (!values.endDate) {
        errors.endDate  = 'Required'
    }  
    

    // console.log('errors', errors)
    return errors
}

function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'promoCodeForm',
    validate,
})(PromoCodeForm));