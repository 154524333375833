const SomethingWentWrong = () => {
    return <div className="row">
        <div className="col-3"></div>
        <div className="col-6">
            <p style={{ color: "rgba(255,255,255,0.8)" }}>Something went wrong. Do you <a href="/mint">have a token</a>?</p>
        </div>
    </div>
}

export default SomethingWentWrong
