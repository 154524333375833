import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import axios from 'axios';

import useWalletLinkStatus from '../../../hooks/useWalletLinkStatus';

import PleaseWait from '../../access/PleaseWait';

const ConfirmUnlinkWallet = ({ auth, wallet, registeredUser }) => {
    const linkedWalletId = useParams().linkedWalletId;
    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determine if this account has access
    const { userData } = useWalletLinkStatus(account); // eslint-disable-line no-unused-vars   
    //console.log(userData);

    const [linkedWalletAddress, setLinkedWalletAddress] = useState('');

    useEffect(() => {
        const fetchLinkedWalletAddress = async () => {
            const response = await axios.get(`/api/get/linked-wallet/${linkedWalletId}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            console.log(response.data.address);
            setLinkedWalletAddress(response.data.address);
        }
        fetchLinkedWalletAddress()
    }, [linkedWalletId])

    const [done, setDone] = useState('');
    
    useEffect(() => {
        const fetchUnlinkWallet = async (linkedWalletAddress) => {            
            console.log('Linked Wallet Address: ', linkedWalletAddress);
            const response2 = await axios.post('/api/post/unlink-wallet/confirm', { address: linkedWalletAddress });
            console.log(response2);
            setDone(response2.data.done);
        }
        if (( account === registeredUser.account || account === linkedWalletAddress) && linkedWalletAddress !== '') fetchUnlinkWallet(linkedWalletAddress);
    }, [account, registeredUser, linkedWalletAddress])    

    const renderDone = () => {        

        switch(done) {
            case null:
                return <PleaseWait />;
            case true:
                return account === linkedWalletAddress ? <Redirect to="/logout"/> : <Redirect to="/settings/link-wallets" />;
        }
    }

    return (
        <div style={{ backgroundColor: "#000000", color: "#ffffff"}}>            
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderDone() }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser }) {
    return { auth, wallet, registeredUser };
}

export default connect(mapStateToProps)(ConfirmUnlinkWallet);
