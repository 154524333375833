import { useState, useEffect } from 'react';
import axios from 'axios';

const useMarketplaces = () => {

    const [marketplaces, setMarketplaces] = useState(null);   

    useEffect(() => {
        const fetchMarketplaces = async () => {
            const marketplacesData = await axios.get(`/api/get/marketplaces`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            
            setMarketplaces(marketplacesData.data);
        }

        fetchMarketplaces();

    }, []);
    
    return { marketplaces };   
}

export default useMarketplaces;
