// RenderField contains logic to render a single label and text input
import React from 'react';

//{input} is the same as props.input
export default ({ input, className, label, fieldValue, onclick, meta: { touched, error, warning } }) => {
    
    return (
        <div>
            <label style={{ color: "#ffffff", marginBottom: "5px" }}>{label}</label>
            <div>
                <input {...input} className={className} type="radio" value={fieldValue}  onClick={onclick} checked={ fieldValue === input.value } /> {fieldValue}
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
            </div>
        </div>
    );
};