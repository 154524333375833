// RenderField contains logic to render a single label and text input
import React from 'react';

//{input} is the same as props.input
const RenderTextarea = ({ input, className, placeholder, label, rows, meta: { touched, error, warning } }) => {
    
    return (
        <div>
            <label style={{ color: "#ffffff", marginBottom: "5px" }}>{label}</label>
            <div>
                <textarea {...input} className={className} placeholder={placeholder} rows={rows} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
            </div>
        </div>
    );
};

export default RenderTextarea;