const QuickAddNFTHeader = ({ accountType }) => {
    return (
        <>
        <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-12" style={{ fontSize: "2em"}}>
                Add NFT
            </div>
        </div>
        <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-12" style={{ fontSize: "1em"}}>
                You tell us a project name, we do the research. You earn 10 points if the NFT isn't already in our platform (make sure you're signed in).                
            </div>
        </div>        
        </>
    );
};

export default QuickAddNFTHeader;