import { combineReducers } from "redux";
import { reducer as reduxForm } from 'redux-form';
import authReducer from "./authReducer";
import surveysReducer from "./surveysReducer";
import registeredUserReducer from "./registeredUserReducer";
import walletReducer from "./walletReducer";
import refreshReducer from "./refreshReducer";
import tokenGatedAccessReducer from "./tokenGatedAccessReducer";
import alertsReducer from "./alertsReducer";

export default combineReducers({
    tokenGatedAccess: tokenGatedAccessReducer,
    auth: authReducer,
    wallet: walletReducer,
    registeredUser: registeredUserReducer,
    refresh: refreshReducer,
    form: reduxForm,
    surveys: surveysReducer,
    alerts: alertsReducer,
});
