import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import axios from 'axios';
import * as actions from '../../actions';

import useTokenGatedAccess from '../../hooks/useTokenGatedAccess';

import FreeTier from './FreeTier';
import PremiumTier from './PremiumTier';
import Table from './Table';
import NFTs from './NFTs';
import SignupPromoCodeForm from './SignupPromoCodeForm';
import PleaseSignIn from '../access/PleaseSignIn';

const SignupMain = ({ auth, wallet, registeredUser, form, fetchRegisteredUser }) => {        

    const { account } = wallet; // use the account from the wallet store object    

    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, _walletAccessTokens } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars  

    //make sure that the user is taken to the top of the page each time it loads
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    const [delayComplete, setDelayComplete] = useState(false);     

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const delayPublic = async () => {
        await delay(1000);
        //console.log("Waited 5s");
        setDelayComplete(true);
      };
    delayPublic();

    const renderPromoCodeForm = () => {
        const initialValues={};
        return (
            <SignupPromoCodeForm
                initialValues={initialValues}
                onSignupPromoCodeFormSubmit={
                    async (values) => {                        
                            try {                                                        
                                await axios.post(`/api/post/upgrade/promo-code`, values); 
                                fetchRegisteredUser(account);
                                window.location.assign('/dashboard/home');
                            } catch (error) {
                                // This should be visible in FS and (hopefully) alert FS of the issue
                                console.error(error.response)
                            }
                        }
                    }
            />
        )
    }

    const renderSignup = () => {

        switch(access) { 
            case null: //PUBLIC
                if ( delayComplete ) {
                    return <>
                        <div className="row" style={{fontSize:"3em"}}> 
                            <div className="col-md-1 col-xl-2">                
                            </div>
                            <div className="col-md-10 col-xl-8 col-12" style={{ padding:"10px"}} >
                                Our NFTs
                            </div>
                        </div>   
                        <div className="row" style={{fontSize:"1.25em"}}>
                            
                            <div className="col-12" style={{ padding:"10px"}} >
                                <NFTs />
                            </div>
                        </div>
                        <div className="row" style={{fontSize:"3em"}}> 
                            <div className="col-md-1 col-xl-2"></div>
                            <div className="col-md-10 col-xl-8 col-12" style={{ padding:"10px"}} >
                                Signup Now
                            </div>
                        </div>
                        <div className="row" style={{fontSize:"1.25em"}}>
                            <div className="col-md-1 col-xl-2"></div>
                            <div className="col-md-10 col-xl-8 col-12" style={{ padding:"10px"}} >
                                Not all features are available for public users. Signup to take full advantage of our tools.
                            </div>
                        </div>   
                        <div className="row" style={{fontSize:"1.25em"}}>
                            <div className="col-md-1 col-xl-2"></div>
                            <div className="col-md-10 col-xl-8 col-12" style={{ padding:"10px"}} >
                                <Table account={registeredUser.account_type} />
                            </div>
                        </div>                       
                    </>;
                }
            default: //FREE and PREMIUM users                
                if ( delayComplete ) {
                    //if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;
                    return <>                         
                        { registeredUser.account_type === 'free' || registeredUser.account_type === 'top 100' ? renderPromoCodeForm() : null}
                        <div className="row" style={{fontSize:"3em"}}> 
                            <div className="col-md-1 col-xl-2">                
                            </div>
                            <div className="col-md-10 col-xl-8 col-12" style={{ padding:"10px"}} >
                                Our NFTs
                            </div>
                        </div>   
                        <div className="row" style={{fontSize:"1.25em"}}>
                            
                            <div className="col-12" style={{ padding:"10px"}} >
                                <NFTs />
                            </div>
                        </div>
                        <div className="row" style={{fontSize:"3em"}}> 
                            <div className="col-md-1 col-xl-2"></div>
                            <div className="col-md-10 col-xl-8 col-12" style={{ padding:"10px"}} >
                                Upgrade
                            </div>
                        </div>                         
                        <div className="row" style={{fontSize:"1.25em"}}>
                            <div className="col-md-1 col-xl-2"></div>
                            <div className="col-md-10 col-xl-8 col-12" style={{ padding:"10px"}} >
                                <Table account={registeredUser.account_type} />
                            </div>
                        </div>
                    </>;        
                }    
        }
    }

    const renderTable = () => {
        
        if ( delayComplete ) {
            return (
                <>
                <a name="nfts" id="nfts"></a>
                <div className="row" style={{fontSize:"3em"}}> 
                    <div className="col-md-1 col-xl-2">                
                    </div>
                    <div className="col-md-10 col-xl-8 col-12" style={{ padding:"10px"}} >
                        Our NFTs
                    </div>
                </div>   
                <div className="row" style={{fontSize:"1.25em"}}>
                    
                    <div className="col-12" style={{ padding:"10px"}} >
                        <NFTs />
                    </div>
                </div>
                <div className="row" style={{fontSize:"3em"}}> 
                    <div className="col-md-1 col-xl-2"></div>
                    <div className="col-md-10 col-xl-8 col-12" style={{ padding:"10px"}} >
                        Signup Now
                    </div>
                </div>   
                <div className="row" style={{fontSize:"1.25em"}}>
                <div className="col-md-1 col-xl-2">                
                    </div>
                    <div className="col-md-10 col-xl-8 col-12" style={{ padding:"10px"}} >
                        <Table account={registeredUser.account_type} />
                    </div>
                </div>   
                </>
            )
        }
    }

    return (
        <div>                        
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            {renderSignup()}
            {/*renderTable()*/}
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser, form, fetchRegisteredUser }) {
    return { auth, wallet, registeredUser, form, fetchRegisteredUser };
}

export default connect(mapStateToProps, actions)(SignupMain);
