const RegisterAccountDetails = ({ wallet, nft, accessEndDate, message, imageUrl }) => {
    //console.log( wallet, nft );

    const hasNFT = () => ( 
        <>
            <div className="d-block d-lg-none">
                <div className="row" style={{ paddingTop:"10px"}}>
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div className="row rounded text-left p-2" style={{fontSize:"1em", backgroundColor:"rgba(31, 39, 49, 0.8)"}}>
                            <div className="col-2 m-auto">
                                <img alt="" className="m-auto rounded" src={imageUrl} style={{width:"40px"}} />
                            </div>
                            <div className="col-10">                                                    
                                {message}
                            </div>
                        </div>
                    </div>
                </div>            
                <div className="row" style={{ paddingTop:"10px"}}>
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div className="row">
                            <div className="col-5">
                                <strong>Account Type</strong>
                            </div>
                            <div className="col-7" style={{textAlign:"left"}}>
                                Premium - Full Access
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingTop:"10px"}}>
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div className="row">
                            <div className="col-5">
                                <strong>NFT</strong>
                            </div>
                            <div className="col-7" style={{textAlign:"left"}}>
                                {nft}
                            </div>
                        </div>
                    </div>
                </div>   
                <div className="row" style={{ paddingTop:"10px"}}>
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div className="row">
                            <div className="col-5">
                                <strong>Access End Date</strong>
                            </div>
                            <div className="col-7" style={{textAlign:"left"}}>
                                {accessEndDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingTop:"10px"}}>
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div className="row">
                            <div className="col-5">
                                <strong>Wallet</strong>
                            </div>
                            <div className="col-7" style={{textAlign:"left", wordWrap:"break-word"}}>
                                {wallet}
                            </div>
                        </div>
                    </div>
                </div>   
            </div>  
            <div className="d-lg-block d-none">
                <div className="row" style={{ paddingTop:"10px"}}>
                    <div className="col-2"></div>
                    <div className="col-8">
                        <div className="row rounded text-left p-2" style={{fontSize:"1.5em", backgroundColor:"rgba(31, 39, 49, 0.8)"}}>
                            <div className="col-2 m-auto">
                                <img alt="" className="m-auto rounded" src={imageUrl} style={{width:"40px"}} />
                            </div>
                            <div className="col-10">                                                    
                                {message}
                            </div>
                        </div>
                    </div>
                </div>            
                <div className="row" style={{ paddingTop:"10px"}}>
                    <div className="col-2"></div>
                    <div className="col-8">
                        <div className="row">
                            <div className="col-5">
                                <strong>Account Type</strong>
                            </div>
                            <div className="col-7" style={{textAlign:"left"}}>
                                Premium - Full Access
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingTop:"10px"}}>
                    <div className="col-2"></div>
                    <div className="col-8">
                        <div className="row">
                            <div className="col-5">
                                <strong>NFT</strong>
                            </div>
                            <div className="col-7" style={{textAlign:"left"}}>
                                {nft}
                            </div>
                        </div>
                    </div>
                </div>   
                <div className="row" style={{ paddingTop:"10px"}}>
                    <div className="col-2"></div>
                    <div className="col-8">
                        <div className="row">
                            <div className="col-5">
                                <strong>Access End Date</strong>
                            </div>
                            <div className="col-7" style={{textAlign:"left"}}>
                                {accessEndDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingTop:"10px"}}>
                    <div className="col-2"></div>
                    <div className="col-8">
                        <div className="row">
                            <div className="col-5">
                                <strong>Wallet</strong>
                            </div>
                            <div className="col-7" style={{textAlign:"left", wordWrap:"break-word"}}>
                                {wallet}
                            </div>
                        </div>
                    </div>
                </div>   
            </div>                              
        </>
    );

    const noNFT = () => ( 
        <>
            <div className="d-block d-lg-none">
                <div className="row" style={{ paddingTop:"25px"}}>
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div className="row">
                            <div className="col-md-3 col-xs-4">
                                <strong>Account Type</strong>
                            </div>
                            <div className="col-md-9 col-xs-8" style={{textAlign:"left"}}>
                                Free
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingTop:"25px"}}>
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div className="row">
                            <div className="col-md-3 col-xs-4">
                                <strong>Wallet</strong>
                            </div>
                            <div className="col-md-9 col-xs-8" style={{textAlign:"left", wordWrap:"break-word"}}>
                                {wallet}
                            </div>
                        </div>
                    </div>
                </div>  
            </div>  
            <div className="d-lg-block d-none">
                <div className="row" style={{ paddingTop:"25px"}}>
                    <div className="col-lg-2"></div>
                    <div className="col-12 col-lg-8">
                        <div className="row">
                            <div className="col-md-3 col-xs-4">
                                <strong>Account Type</strong>
                            </div>
                            <div className="col-md-9 col-xs-8" style={{textAlign:"left"}}>
                                Free
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingTop:"25px"}}>
                    <div className="col-lg-2"></div>
                    <div className="col-12 col-lg-8">
                        <div className="row">
                            <div className="col-md-3 col-xs-4">
                                <strong>Wallet</strong>
                            </div>
                            <div className="col-md-9 col-xs-8" style={{textAlign:"left", wordWrap:"break-word"}}>
                                {wallet}
                            </div>
                        </div>
                    </div>
                </div>  
            </div>          
        </>
    )

    return (
        <>
            
            
            {nft ? hasNFT() : noNFT()}
        </>
    );
};

export default RegisterAccountDetails;
