import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import axios from 'axios';

import useWalletLinkStatus from '../../hooks/useWalletLinkStatus';
import useTokenGatedAccess from '../../hooks/useTokenGatedAccess';

import PleaseSignIn from '../access/PleaseSignIn';

// components
import PleaseWait from '../access/PleaseWait';
import UnlinkedWalletPage from './UnlinkedWalletPage';

const UnlinkedWallet = ({ auth, wallet, registeredUser }) => {
    const linkedWalletId = useParams().linkedWalletId;
    const { account } = wallet; // use the account from the wallet store object
    //console.log(linkedWalletId);
    //console.log(account);
    //console.log(registeredUser);

    // Pass the account to the useTokenGatedAccess hook to determine if this account has access    
    const { access } = useTokenGatedAccess(account); 
    //console.log(access); 

    const [linkedWalletAddress, setLinkedWalletAddress] = useState('');

    useEffect(() => {
        const fetchLinkedWalletAddress = async () => {
            const response = await axios.get(`/api/get/linked-wallet/${linkedWalletId}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setLinkedWalletAddress(response.data.address);
        }
        fetchLinkedWalletAddress()
    }, [linkedWalletId])
    
    const renderUnlinkedWallet = () => {        

        switch(access) {
            case null:
                return <PleaseWait />;
            default:
                return ( account === registeredUser.account || account === linkedWalletAddress) ? <UnlinkedWalletPage username={registeredUser.username} linkedWalletAddress={linkedWalletAddress} linkedWalletId={linkedWalletId} /> : <Redirect to="/dashboard/home" />;
                //return <ConfirmLinkedWalletPage userData={userData} account={account}/>;
                
        }
    }

    return (
        <div style={{ backgroundColor: "#000000", color: "#ffffff"}}>            
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderUnlinkedWallet() }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser }) {
    return { auth, wallet, registeredUser };
}

export default connect(mapStateToProps)(UnlinkedWallet);
