import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import useWalletLinkStatus from '../../hooks/useWalletLinkStatus';

import PleaseSignIn from '../access/PleaseSignIn';

// components
import PleaseWait from '../access/PleaseWait';
import ConfirmLinkedWalletPage from './ConfirmLinkedWalletPage';

const ConfirmLinkedWallet = ({ auth, wallet, registeredUser }) => {

    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determine if this account has access
    const { walletLinkedStatus, userData } = useWalletLinkStatus(account); // eslint-disable-line no-unused-vars    
    
    const renderConfirmLinkedWallet = () => {        

        switch(walletLinkedStatus) {
            case null:
                return <PleaseWait />;
            case 'pending':
                return registeredUser.id.split('pending').length > 1 ? <ConfirmLinkedWalletPage userData={userData} account={account}/> : <PleaseSignIn />
                //return <ConfirmLinkedWalletPage userData={userData} account={account}/>;
                
        }
    }

    return (
        <div style={{ backgroundColor: "#000000", color: "#ffffff"}}>            
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderConfirmLinkedWallet() }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser }) {
    return { auth, wallet, registeredUser };
}

export default connect(mapStateToProps)(ConfirmLinkedWallet);
