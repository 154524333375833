import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from "./redux/store";

import { DAppProvider, Mainnet } from "@usedapp/core";

import App from './components/App';

//Development-only. Axios helpers
import axios from 'axios';
window.axios = axios;

// Change this to your own Infura project id: https://infura.io/register
const REACT_APP_INFURA_PROJECT_ID = process.env.REACT_APP_INFURA_PROJECT_ID;
const config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: "https://mainnet.infura.io/v3/" + REACT_APP_INFURA_PROJECT_ID,
  },
}

const container = document.querySelector('#root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <DAppProvider config={config}>
            <App />
        </DAppProvider>
    </Provider>
);
