import { Link } from 'react-router-dom';
import registeredUserReducer from '../../reducers/registeredUserReducer';

const GiveawaysHeader = ({page, entries, account}) => {
        
    const liveHighlight = page === 'live' ? '#7945E1' : '#140510';
    const liveStyleInputs = { color:"rgba(255,255,255,0.8)", backgroundColor: liveHighlight, padding:"5px", textDecoration:"none" };

    const upcomingHighlight = page === 'upcoming' ? '#7945E1' : '#140510';
    const upcomingStyleInputs = { color:"rgba(255,255,255,0.8)", backgroundColor: upcomingHighlight, padding:"5px", textDecoration:"none" };

    const endedHighlight = page === 'ended' ? '#7945E1' : '#140510';
    const endedStyleInputs = { color:"rgba(255,255,255,0.8)", backgroundColor: endedHighlight, padding:"5px", textDecoration:"none" };

    return (
        <>            
        <div className="row text-center"> 
            <div className="col-md-1 col-xl-2">                
            </div>
            <div className="col-md-10 col-xl-8 col-12" style={{ padding:"10px", fontSize:"3em", color:"rgba(255,255,255,0.8)"}} >
                Giveaways
            </div>                        
        </div>                                   

        <div className="row" style={{paddingBottom:"20px"}}> 
            <div className="col-1"></div>
            <div className="col-md-10">
                {account !== undefined ? <>If you win, we will use {account} to airdrop your NFT or submit your Allow List entry within 48 hours. To change the wallet, please send an email to info@futurenftmints.com from the <Link to="/settings/notifications" style={{color:"rgba(255,255,255,0.8)"}}>email associated with your account</Link>.</> : ''}
            </div>
        </div>  

        {/*
        <div className="row text-center mt-3">             
            
            <div className="col-1"></div>
            <div className="col-md-10">
                When you vote on <Link to="/all-nfts?upcomingMints=true&sort=mintDateNewToOld" style={{color:"rgba(255,255,255,0.8)"}}>upcoming mints</Link>, Future Mints will try to mint as many of those NFTs as we can for giveaways. Want to give away one of your collection's NFTs or raffle spots for an upcoming mint? <a href="https://twitter.com/futurenftmints" taget="_BLANK" rel="noreferrer" style={{color:"rgba(255,255,255,0.8)"}}>DM us on Twitter</a>.
            </div>             
        </div>  
        */}
                    
        

        <div className="row mt-3" style={{paddingBottom:"10px"}}> 
            <div className="col-1"></div>
            <div className="col-md-10">
                <Link to="/giveaways/live" className="rounded" style={liveStyleInputs}>Live</Link>&nbsp;|&nbsp;
                <Link to="/giveaways/upcoming" className="rounded" style={upcomingStyleInputs}>Upcoming</Link>&nbsp;|&nbsp;
                <Link to="/giveaways/ended" className="rounded" style={endedStyleInputs}>Ended</Link>
            </div>
        </div>  
        <div className="row text-center mb-3">             
            <div className="col-12" style={{fontSize:"2em", color:"rgba(255,255,255,0.8)", textAlign:"center"}}>
                {account !== undefined ? <>You have {entries > 0 ? entries : 0} entries</> : ''}
            </div>
            <div className="col-12" style={{fontSize:"1em", color:"rgba(255,255,255,0.8)", textAlign:"center"}}>
                <Link to="/giveaways/rules" style={{color:"rgba(255,255,255,0.8)"}}>How To Earn Entries & Official Rules</Link>
            </div>    
        </div> 
                 
        </>
    )
}

export default GiveawaysHeader;