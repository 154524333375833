import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../actions';

import DashboardHeader from '../DashboardHeader';

const DashboardHome = ({ registeredUser, upcomingMintsCount, benefitsCount, rafflesCount, allowlistCount, watchlistCount, nftCount }) => {    
    
    return (
        <>
                
        <DashboardHeader page='home' />
        {registeredUser.email === '' || registeredUser.email === null ?
            <div className="row mt-2">
                <div className="col-12 text-center">
                    Add your email to receive a Daily Summary update. &nbsp;
                    <Link to="/settings/notifications">
                        <button className="btn-sm btn-light" style={{color:"#7945E1"}}>
                            Add Email
                        </button>
                    </Link>
                </div>
            </div>
            :
            null
        }
        <div className="row mt-5">
            
            <div className="col-12 col-lg-4" >
                <Link to="/benefits" style={{textDecoration:"none"}}>
                    <div className="card text-center" style={{borderColor:"rgba(255,255,255,0)", background:"rgba(255,255,255,0)"}}>
                        <div className="card-header" style={{ backgroundColor:"#29194A", color:"rgba(255,255,255,.8)", fontSize:"30px" }}>
                            Benefits
                        </div>
                        <div className="card-body" style={{backgroundColor:"#280B22", color:"rgba(255,255,255,.8)", padding:"8px"}}>
                            <span style={{fontSize:"75px"}}>
                                {benefitsCount}
                            </span>
                            <br />                     
                            <Link to="/benefits" style={{color:"#7945E1"}}>                            
                                See all                            
                            </Link>
                        </div>
                        <div className="card-footer text-muted">                        
                        </div>
                    </div>
                </Link>
            </div>            

            <div className="col-12 col-lg-4" >
                <Link to="/raffles-feed" style={{textDecoration:"none"}}>
                    <div className="card text-center" style={{borderColor:"rgba(255,255,255,0)", background:"rgba(255,255,255,0)"}}>
                        <div className="card-header" style={{ backgroundColor:"#29194A", color:"rgba(255,255,255,.8)", fontSize:"30px" }}>
                            Raffles
                        </div>
                        <div className="card-body" style={{backgroundColor:"#280B22", color:"rgba(255,255,255,.8)", padding:"8px"}}>
                            <span style={{fontSize:"75px"}}>
                                {rafflesCount}
                            </span>
                            <br />                     
                            <Link to="/raffles-feed" style={{color:"#7945E1"}}>                            
                                See all                            
                            </Link>
                        </div>
                        <div className="card-footer text-muted">                        
                        </div>
                    </div>
                </Link>
            </div>

            <div className="col-12 col-lg-4" >
                <Link to="/giveaways" style={{textDecoration:"none"}}>
                    <div className="card text-center" style={{borderColor:"rgba(255,255,255,0)", background:"rgba(255,255,255,0)"}}>
                        <div className="card-header" style={{ backgroundColor:"#29194A", color:"rgba(255,255,255,.8)", fontSize:"30px" }}>
                            Giveaway Entries
                        </div>
                        <div className="card-body" style={{backgroundColor:"#280B22", color:"rgba(255,255,255,.8)", padding:"8px"}}>
                            <span style={{fontSize:"75px"}}>
                                {registeredUser.current_giveaway_entries > 0 ? registeredUser.current_giveaway_entries : 0}
                            </span>
                            <br />                     
                            <Link to="/giveaways" style={{color:"#7945E1"}}>                            
                                Spend Entries                            
                            </Link>
                        </div>
                        <div className="card-footer text-muted">                        
                        </div>
                    </div>
                </Link>
            </div>
        </div>  
        <div className="row mt-2">                                         

            <div className="col-12 col-lg-4" >
                <Link to="/dashboard/allow-lists" style={{textDecoration:"none"}}>
                    <div className="card text-center" style={{borderColor:"rgba(255,255,255,0)", background:"rgba(255,255,255,0)"}}>
                        <div className="card-header" style={{ backgroundColor:"#29194A", color:"rgba(255,255,255,.8)", fontSize:"30px" }}>
                            Allow Lists
                        </div>
                        <div className="card-body" style={{backgroundColor:"#280B22", color:"rgba(255,255,255,.8)", padding:"8px"}}>
                            <span style={{fontSize:"75px"}}>
                                {allowlistCount}
                            </span>
                            <br />                     
                            <Link to="/dashboard/allow-lists" style={{color:"#7945E1"}}>                            
                                See all                            
                            </Link>
                        </div>
                        <div className="card-footer text-muted">                        
                        </div>
                    </div>
                </Link>
            </div>

            <div className="col-12 col-lg-4" >
                <Link to="/all-nfts?upcomingMints=true&sort=mintDateNewToOld" style={{textDecoration:"none"}}>
                    <div className="card text-center" style={{borderColor:"rgba(255,255,255,0)", background:"rgba(255,255,255,0)"}}>
                        <div className="card-header" style={{ backgroundColor:"#29194A", color:"rgba(255,255,255,.8)", fontSize:"30px" }}>
                            Upcoming Mints
                        </div>
                        <div className="card-body" style={{backgroundColor:"#280B22", color:"rgba(255,255,255,.8)", padding:"8px"}}>
                            <span style={{fontSize:"75px"}}>
                                {upcomingMintsCount}
                            </span>
                            <br />                     
                            <Link to="/all-nfts?upcomingMints=true&sort=mintDateNewToOld" style={{color:"#7945E1"}}>                            
                                See all                            
                            </Link>
                        </div>
                        <div className="card-footer text-muted">                        
                        </div>
                    </div>
                </Link>
            </div>

            <div className="col-12 col-lg-4" >
                <Link to="/dashboard/watchlist" style={{textDecoration:"none"}}>
                    <div className="card text-center" style={{borderColor:"rgba(255,255,255,0)", background:"rgba(255,255,255,0)"}}>
                        <div className="card-header" style={{ backgroundColor:"#29194A", color:"rgba(255,255,255,.8)", fontSize:"30px" }}>
                            Watchlist
                        </div>
                        <div className="card-body" style={{backgroundColor:"#280B22", color:"rgba(255,255,255,.8)", padding:"8px"}}>
                            <span style={{fontSize:"75px"}}>
                                {watchlistCount}
                            </span>
                            <br />                     
                            <Link to="/dashboard/watchlist" style={{color:"#7945E1"}}>                            
                                See all                            
                            </Link>
                        </div>
                        <div className="card-footer text-muted">                        
                        </div>
                    </div>
                </Link>
            </div>

            
        </div>   

        <div className="row mt-2">
            <div className="col-12 col-lg-4" >
                <Link to="/dashboard/user-nfts" style={{textDecoration:"none"}}>
                    <div className="card text-center" style={{borderColor:"rgba(255,255,255,0)", background:"rgba(255,255,255,0)"}}>
                        <div className="card-header" style={{ backgroundColor:"#29194A", color:"rgba(255,255,255,.8)", fontSize:"30px" }}>
                            My NFTs
                        </div>
                        <div className="card-body" style={{backgroundColor:"#280B22", color:"rgba(255,255,255,.8)", padding:"8px"}}>
                            <span style={{fontSize:"75px"}}>
                                {nftCount}
                            </span>
                            <br />                     
                            <Link to="/dashboard/user-nfts" style={{color:"#7945E1"}}>                            
                                See all                            
                            </Link>
                        </div>
                        <div className="card-footer text-muted">                        
                        </div>
                    </div>
                </Link>
            </div>
        </div> 
        {/*}
        <div className="row mt-5">
            <div className="col-12" >
                <div className="card text-center" style={{borderColor:"rgba(255,255,255,0)", background:"rgba(255,255,255,0)"}}>
                    <div className="card-header" style={{ backgroundColor:"#29194A", color:"rgba(255,255,255,.8)", fontSize:"30px", textAlign:"left" }}>
                        Exclusive Offer
                    </div>
                    <div className="card-body" style={{backgroundColor:"#280B22", color:"rgba(255,255,255,.8)", textAlign:"left"}}>
                        <h5 className="card-title">Future Mints Annual NFT Pass - Allow List Raffle</h5>
                        Register by Oct 18 at 3pm ET<br />100 spots
                        <br /><br />
                        <span style={{paddingBottom:"50px"}}>Signup to enter the exclusive HeyMint raffle for Future Mints registered account users. This is a separate raffle from the public one (link in the banner up top), and you can enter both.</span>
                        <br /><br />                         
                        <span>Requirements: Must have a Future Mints account (if you're seeing this, you qualify).</span>
                        <br /><br /><br />                                       
                        <a href="https://heymint.xyz/future-mints-annual-registered" target="_BLANK" rel="noreferrer" style={{color:"#ffffff"}}>
                            <button className="btn-lg btn-light mt-3" style={{color:"#7945E1", marginTop:"0px"}}>
                                Signup
                            </button>
                        </a>                        
                    </div>
                    <div className="card-footer text-muted">                        
                    </div>
                </div>
            </div>    
        </div>      
        */}      
        
        </>
    );
};

function mapStateToProps({ form, registeredUser }) {
    return { form, registeredUser };
}

export default connect(mapStateToProps, actions)(DashboardHome);
