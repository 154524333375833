import RenderField from '../addNFT/RenderField';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';

const DislikeForm = ({ onFormSubmit, initialValues, handleSubmit, error, submitting }) => {

    
    return (<>
        {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
        <form onSubmit={handleSubmit( onFormSubmit )}>

            <div className="row mt-3 mb-5" >
                <div className="col-1"></div>
                <div className="col-lg-8 col-7">
                    <div className="form-group">
                        <div>                            

                            <Field
                                className="form-control"
                                name="dislike"
                                component={RenderField}
                                type="text"
                                placeholder="Enter your dislike"
                                label="Add a dislike"
                            />
                        </div>
                    </div>  
                </div>
            
                <div className="col-lg-2 col-3">
                    <button className="btn btn" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff", width:"100%", marginTop:"28px", height:"40px" }} disabled={submitting}>
                        Add
                    </button>
                </div>
            </div> 
        </form>
    
    </>
    );
}

const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}
    
    if (!values.inputStatement) {
        errors.inputStatement = 'Required'
    }

    else if (values.inputStatement) {
        if (values.inputStatement.length > 250) {
         errors.inputStatement = 'Must be 250 characters or less'
        }
    }    

    // console.log('errors', errors)
    return errors
}

function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'dislikeForm',
    validate,
})(DislikeForm));