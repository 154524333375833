import { useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import * as actions from '../../../actions';

import NotificationsHeader from './NotificationsHeader';
import NotificationsForm from './NotificationsForm';
import SettingsNav from '../SettingsNav';

const NotificationsPage = ({ registeredUser, account, notifications,  dailySummaryCheckedInitial, fetchRegisteredUser }) => {
        
    const [notificationsFormSubmitted, setNotificationsFormSubmitted] = useState(null);
    const [dailySummaryChecked, setDailySummaryChecked] = useState(dailySummaryCheckedInitial);

    const renderNotificationsForm = ( ) => {
        const initialValues = {
            'email':                registeredUser.email,
            'personalPhrase':       registeredUser?.personal_phrase
        }

        return <>
        <NotificationsForm
            initialValues={initialValues}
            onNotificationsFormSubmit={
                async (values) => {                        
                        try {                                                        
                            await axios.post(`/api/post/settings/notifications`, values);
                            fetchRegisteredUser(account);
                        } catch (error) {
                            // This should be visible in FS and (hopefully) alert FS of the issue
                            console.error(error.response)
                        }
                        setNotificationsFormSubmitted("We've updated your email and personal phrase.");
                    }
                }
            />
        </>
    }

    const renderNotificationsFormSubmitted = (notificationsFormSubmitted) => {
        return <>
            <div className="row mt-3 mb-5">                
                <div className="col-12">
                    {notificationsFormSubmitted}
                </div>
            </div>
        </>;
    }   

    const notificationCommunicationUpdate = async ( template, medium, hour_send, opt_in, cadence ) => {        
        const values = { template, medium, hour_send, opt_in, cadence };
        try { 
            await axios.post(`/api/post/settings/notifications/communication`, values);
            setDailySummaryChecked( opt_in );
        } catch (error) {
            // This should be visible in FS and (hopefully) alert FS of the issue
            console.error(error.response)
        }    
    }
    
    return (
        <>        
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-lg-2 col-12" style={{paddingLeft:"20px"}}>
                <SettingsNav page="notifications"/>
            </div>            
            <div className="col-lg-10 col-12">            
                <NotificationsHeader />
                
                {renderNotificationsForm( )}

                {notificationsFormSubmitted === null ? null : renderNotificationsFormSubmitted(notificationsFormSubmitted)}

                <div className="col-12">
                    *Heads up: The formatting of the emails is basic while we figure out how to make them as useful as possible. Please tell us via Twitter or Discord what you think.
                </div>
                <div className="row table-responsive" style={{ marginTop:"20px"}}>
                    <div className="col-12">
                    <table className="table" style={{ color:"#ffffff" }}>
                        <thead>
                            <tr style={{ backgroundColor:"#1F2731"}}>                                
                                <th scope="col">Notification</th>
                                <th scope="col">Medium</th>
                                <th scope="col" style={{textAlign:"center", width:"80px"}}>Opt In</th>
                                <th scope="col">Time</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize:"1em"}}>
                            <tr>
                                <td>Daily Summary</td>
                                <td>Email</td>
                                <td style={{textAlign:"center"}}>
                                    <input className="form-check-input" type="checkbox" checked={dailySummaryChecked === true ? 'checked' : ''} onChange={e => notificationCommunicationUpdate('Daily Summary', 'Email', 12, e.target.checked, 'Daily')} />
                                </td>
                                <td>12pm ET</td>
                            </ tr>                            
                        </tbody>                                           
                    </table>
                    </div>
                </div>                
            </div>
        </div>        
        </>
    );
};

function mapStateToProps({ auth, wallet, registeredUser, fetchRegisteredUser }) {
    return { auth, wallet, registeredUser, fetchRegisteredUser };
}

export default connect(mapStateToProps, actions)(NotificationsPage);
