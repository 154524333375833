import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const AllowListButtons = ({ id, access }) => {

    const [allowlist, setAllowlist] = useState({have:false, want:false});        

    useEffect(() => {
        const fetchAllowlist = async () => {
            const response = await axios.get(`/api/fetch-allowlist/${id}`);
            setAllowlist(response?.data);
        }
        if ( access !== 'public' ) {
            fetchAllowlist();
        }
    }, [id, access])

    const updateAllowlist = async (id, updatedAllowlist, access) => {
        if ( access !== 'public ') {
            try {
                const response = await axios.put(`/api/allowlist/${id}`, { updatedAllowlist });         
                return response.data.updatedAllowlist;
                
            } catch (error) {
                console.error(error.response);
            }
        }
        
        return '';
    }

    const HaveAllowlist = ({ allowlist }) => {
        return <>
            <button 
                className="btn btn-sm btn-success"
                style={{width:"45%", marginRight:"10%"}}
                onClick={async () => {
                    const newAllowlist = await updateAllowlist(id, { have: false, want: !!allowlist?.want });
                    setAllowlist(newAllowlist);                     
                }}
            >
                <i className="bi bi-check-square-fill"></i> Have AL
            </button>
        </>;
    }

    const WantAllowlist = ({ allowlist }) => {
        return <>
            <button 
                className="btn btn-sm btn-success"
                style={{width:"45%"}}
                onClick={async () => {
                    const newAllowlist = await updateAllowlist(id, { have: !!allowlist?.have, want: false });
                    setAllowlist(newAllowlist);
                }}
            >
                <i className="bi bi-check-square-fill"></i> Want AL
            </button> 
        </>
    }

    const DontHaveAllowlist = ({ allowlist }) => {
        return <>
            <button 
                className="btn btn-sm btn-secondary"
                style={{width:"45%", marginRight:"10%"}}
                onClick={async () => {
                    const newAllowlist = await updateAllowlist(id, { have: true, want: !!allowlist?.want });
                    setAllowlist(newAllowlist);      
                }}
            >
                Have AL
            </button>
        </>;
    }

    const DontWantAllowlist = ({ allowlist }) => { 
        return <>
            <button 
                className="btn btn-sm btn-secondary"
                style={{width:"45%"}}
                onClick={async () => {
                    const newAllowlist = await updateAllowlist(id, { have: !!allowlist?.have, want: true });
                    setAllowlist(newAllowlist);
                }}
            >
                Want AL
            </button> 
        </>
    }

    if ( access !== 'public' ) {
        if ( !!allowlist?.have && !!allowlist?.want ) {
            return <>
                <HaveAllowlist allowlist={allowlist}/>
                <WantAllowlist allowlist={allowlist}/>
            </>;
        }
        else if ( !allowlist?.have && !!allowlist?.want ) {
            return <>
                <DontHaveAllowlist allowlist={allowlist}/>
                <WantAllowlist allowlist={allowlist}/>
            </>;
        }
        else if ( !!allowlist?.have && !allowlist?.want ) {
            return <>
                <HaveAllowlist allowlist={allowlist}/>
                <DontWantAllowlist allowlist={allowlist}/>
            </>;
        }
        else if ( !allowlist?.have && !allowlist?.want ) {
            return <>
                <DontHaveAllowlist allowlist={allowlist}/>
                <DontWantAllowlist allowlist={allowlist}/>
            </>;
        }
    }
    else {
        return <>
            <Link to="/signup">
                <button 
                    className="btn btn-sm btn-secondary"
                    style={{width:"45%", marginRight:"10%"}}                
                >
                    Have AL
                </button>
            </Link>
            <Link to="/signup">
                <button 
                    className="btn btn-sm btn-secondary"  
                    style={{width:"45%"}}              
                >
                    Want AL
                </button> 
            </Link>
        </>
    }
    
}

export default AllowListButtons;