// RenderField contains logic to render a single label and text input
import React from 'react';

//{input} is the same as props.input
const CollectionImage = ({ url }) => {
    
    return (<>
            <div className="col-4" style={{ marginBottom:"10px"}}>
                <img alt="" src={url}  className="d-inline-block align-top rounded img-fluid" style={{width:"100%"}} />  
            </div>
            
        </>
    );
};

export default CollectionImage;