import { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as s from "../../styles/mintPage";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PleaseConnectWallet from '../access/PleaseConnectWallet';

import { ethers } from "ethers";
import axios from 'axios';
    
const Mint = ({ wallet, mintConfig, contractAbi, merkleProof }) => {
    const referrer = window.location.pathname.replace("/", "") || "none";
    const { account } = wallet; // use the account from the wallet store object

    const [contractInstances, setContractInstances] = useState({});
    const [data, setData] = useState({});
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
    const [mintRemaining, setMintRemaining] = useState();
    const [mintAmount, setMintAmount] = useState(1);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        NETWORK: {
          NAME: "",
          SYMBOL: "",
          ID: 0,
        },
        MINT_DESCRIPTION: "",
        NFT_NAME: "",
        SYMBOL: "",
        ALLOW_LIST_ALLOCATION: 0,
        PRESALE_LIST_ALLOCATION: 0,
        PUBLIC_ALLOCATION: 0,
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
    });

    const fetchData = async (account, smartContract) => {
        try {
            const totalSupply = (await smartContract.totalSupply()).toString();
            const isAllowListMintEnabled = (await smartContract.isAllowListMintEnabled());
            const isPresaleMintEnabled = (await smartContract.isPresaleMintEnabled());
            const isPublicMintEnabled = (await smartContract.isPublicMintEnabled());
            const balance = (await smartContract.balanceOf(account)).toString();
    
            const contractData = {
                totalSupply,
                isAllowListMintEnabled,
                isPresaleMintEnabled,
                isPublicMintEnabled,
                balance,
            };
            
            setData(contractData);
        } catch (err) {
            console.log(err);
            console.error("Could not load data from contract.");
        }
    };

    const fetchSmartContract = async (mintConfig, contractAbi) => {
        // console.log('fetchSmartContract ...')
        const { provider, signer, network } = wallet;
        const { NETWORK, CONTRACT_ADDRESS, NFT_NAME } = mintConfig;

        if (provider) { // https://docs.ethers.io/v5/api/providers/provider/#Provider
            console.log("ethereum provider present.");

            try {
                // console.log('fetchSmartContract try')
                // eslint-disable-next-line 
                if (network?.chainId == NETWORK.ID) {
                    // https://docs.ethers.io/v5/api/contract/
                    setContractInstances({
                        smartContract: new ethers.Contract(CONTRACT_ADDRESS, contractAbi, provider),
                        smartContractRw: new ethers.Contract(CONTRACT_ADDRESS, contractAbi, signer),
                    });
                } else {
                    console.error(`Change network to ${NETWORK.NAME} for ${CONTRACT_ADDRESS}.`);
                }
            } catch (err) {
                // console.log('fetchSmartContract catch')
                console.log(err);
                console.error(`Something went wrong with ${NFT_NAME}.`);
            }
        } else {
            console.log("no ethereum provide present.");
        }
    };    

    const claimNFTs = () => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        /*
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        console.log("totalSupply: ", data.totalSupply)
        console.log("isAllowListMintEnabled: ", data.isAllowListMintEnabled)
        console.log("isPresaleMintEnabled: ", data.isPresaleMintEnabled)
        console.log("isPublicMintEnabled: ", data.isPublicMintEnabled)
        */
    
        if (data.isAllowListMintEnabled) {
          setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
          setMintRemaining();
          setClaimingNft(true);
    
          if (data.isPublicMintEnabled) {
            publicMint(totalCostWei, totalGasLimit)
          } else if (data.isPresaleMintEnabled) {
            presaleMint(totalCostWei, totalGasLimit)
          } else {
            allowListMint(totalCostWei, totalGasLimit)
          }
        } else {
          console.log('mint phases not enabled')
        }
    };
    
    const allowListMint = async (totalCostWei, totalGasLimit) => { 
      try {
            let mint;
            
            if (merkleProof) {
                mint = await contractInstances.smartContractRw.allowListMint(mintAmount, merkleProof.allowListLeafProof, {
                    gasLimit: String(totalGasLimit),
                    from: wallet.account,
                    value: totalCostWei,
                });
            } else {
                mint = await contractInstances.smartContractRw.allowListMint(mintAmount, {
                    gasLimit: String(totalGasLimit),
                    from: wallet.account,
                    value: totalCostWei,
                });
            }

            await mint.wait();

            setFeedback(
                `WOW, the ${CONFIG.NFT_NAME} is yours! Go visit OpenSea to view it.`
            );
            setMintRemaining();
            axios.get(`https://app.futurenftmints.com/api/referral/${CONFIG.CONTRACT_ADDRESS}/${wallet.account}/${referrer}/${mintAmount}`);           //console.log('sent referral');
            setClaimingNft(false);
            fetchData(wallet.account, contractInstances.smartContract);
        } catch (err) {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setMintRemaining();
            setClaimingNft(false);
        }
    };
    
    const presaleMint = async (totalCostWei, totalGasLimit) => {
        try {
            let mint;

            if (merkleProof) {
                await contractInstances.smartContractRw.presaleMint(mintAmount, merkleProof.presaleListLeafProof, {
                    gasLimit: String(totalGasLimit),
                    from: wallet.account,
                    value: totalCostWei,
                });
            } else {
                await contractInstances.smartContractRw.presaleMint(mintAmount, {
                    gasLimit: String(totalGasLimit),
                    from: wallet.account,
                    value: totalCostWei,
                });
            }
            
            await mint.wait();

            setFeedback(
                `WOW, the ${CONFIG.NFT_NAME} is yours! Go visit OpenSea to view it.`
            );
            setMintRemaining();
            axios.get(`https://app.futurenftmints.com/api/referral/${CONFIG.CONTRACT_ADDRESS}/${wallet.account}/${referrer}/${mintAmount}`);           //console.log('sent referral');
            setClaimingNft(false);
            fetchData(wallet.account, contractInstances.smartContract);
        } catch (err) {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setMintRemaining();
            setClaimingNft(false);
        }
    };
    
    const publicMint = async (totalCostWei, totalGasLimit) => {
        try {
            const mint = await contractInstances.smartContractRw.publicMint(mintAmount, {
                gasLimit: String(totalGasLimit),
                from: wallet.account,
                value: totalCostWei,
            });
            await mint.wait();

            setFeedback(
                `WOW, the ${CONFIG.NFT_NAME} is yours! Go visit OpenSea to view it.`
            );
            setMintRemaining();
            axios.get(`https://app.futurenftmints.com/api/referral/${CONFIG.CONTRACT_ADDRESS}/${wallet.account}/${referrer}/${mintAmount}`);            //console.log('sent referral');
            setClaimingNft(false);
            fetchData(wallet.account, contractInstances.smartContract);
        } catch (err) {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setMintRemaining();
            setClaimingNft(false);
        }
    };

    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
          newMintAmount = 0;
        }
        setMintAmount(newMintAmount);
    };
    
    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > maxRemainingPerAddressDuringMint()) {
          newMintAmount = maxRemainingPerAddressDuringMint();
        }
        setMintAmount(newMintAmount);
    };

    const maxRemainingPerAddressDuringMint = () => {
        const presaleLimit = 2,
              publicLimit = 5;
    
        let limit = 0;
        if (data.isPublicMintEnabled) {
          limit = publicLimit
        } else if (data.isPresaleMintEnabled || data.isAllowListMintEnabled) {
          limit = presaleLimit
        }
    
        return data.balance >= limit ? 0 : (limit - data.balance);
    }

    const getData = () => {
        if (wallet.account && contractInstances.smartContract !== null) {
            fetchData(wallet.account, contractInstances.smartContract);
        }
    };

    const getConfig = async () => {
        SET_CONFIG(mintConfig);
    };

    const getFeedback = async () => {
        let currentPhase;
        let maxRemainingMint;
    
        let balance = parseInt(data.balance),
            allowListAllocation = parseInt(CONFIG.ALLOW_LIST_ALLOCATION),
            presaleListAllocation = parseInt(CONFIG.PRESALE_LIST_ALLOCATION),
            publicAllocation = parseInt(CONFIG.PUBLIC_ALLOCATION);
    
        if (data.isPublicMintEnabled) {
          currentPhase = 'Public'
          maxRemainingMint = publicAllocation - balance
        } else if (data.isPresaleMintEnabled) {
          currentPhase = 'Presale'
          maxRemainingMint = presaleListAllocation - balance
        } else if (data.isAllowListMintEnabled) {
          currentPhase = 'Allow List'
          maxRemainingMint = allowListAllocation - balance
        } else {
          maxRemainingMint = 0
          // currentPhase = 'this'
        }

        if ( maxRemainingMint <= 0 ) {          
          maxRemainingMint = 0;
          setMintAmount(0);          
        }
        else {
          setMintAmount(1);
        }

        if ( !data.isAllowListMintEnabled && !data.isPresaleMintEnabled && !data.isPublicMintEnabled ) {
          if ( merkleProof?.allowListLeafProof?.length === 0 ) {
            setFeedback('');
            setMintAmount(0);
            setMintRemaining(<>Public mint starts Oct 21 at 12pm ET</>)
          }
          else {
            setFeedback('');
            setMintAmount(0);
            setMintRemaining(<>You're on the List! Allow List mint starts Oct 20 at 12pm ET</>)
          }
        }
        else if ( data.isAllowListMintEnabled && !data.isPresaleMintEnabled && !data.isPublicMintEnabled && merkleProof?.allowListLeafProof?.length === 0 ) {
          setFeedback('');
          setMintAmount(0);
          setMintRemaining(<>{account}<br />is not on the Allow List</>)
        }
        else {          
          setMintRemaining(<>{account}<br />can mint up to {maxRemainingMint}</>)
        }
    };

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        if (wallet.account) fetchSmartContract(mintConfig, contractAbi);
    }, [wallet.account, mintConfig, contractAbi]);
    
    useEffect(() => {
        if (data) getFeedback();
    }, [wallet.account, data]);

    useEffect(() => {
        if (contractInstances.smartContract) getData()
    }, [wallet.account, contractInstances.smartContract]);
    
    const progressBar = () => {

        const totalSupply = data.totalSupply;
        const { MAX_SUPPLY, WEI_COST } = CONFIG;

        if (totalSupply && MAX_SUPPLY && WEI_COST) {
          const width = totalSupply / MAX_SUPPLY * 100;
          return <><Row>
                <Col>
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{width: `${width}%`, backgroundColor:"#F83700"}} aria-valuemin="0" aria-valuemax="100" ></div>
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop:"5px" }}>
                <Col style={{ textAlign: "left", color: "#ffffff" }}>{WEI_COST / 1e18} ETH</Col>
                <Col style={{ textAlign: "right", color: "#ffffff" }}>{totalSupply} / {MAX_SUPPLY}</Col>
              </Row>
              </>
        }
    }
    
    const shouldRenderConnectedWalletMintUI = () => {
        return (data.isAllowListMintEnabled && parseInt(CONFIG.ALLOW_LIST_ALLOCATION) && merkleProof?.allowListLeafProof?.length > 0 ) ||
          (data.isPresaleMintEnabled && parseInt(CONFIG.PRESALE_LIST_ALLOCATION) ) ||
          data.isPublicMintEnabled
    }

    const shouldRenderFeedbackMessage = () => {
        return (wallet.account && contractInstances.smartContract !== null)
    }
    
    const feedbackMessage = () => {
        return shouldRenderFeedbackMessage() ? <Row
          style={{
            color: "#ffffff",
          }}
        >
          <Col style={{textAlign: "center", fontSize:"0.8em", paddingTop:"10px" }}>
            {feedback}
            <br />
            <div style={{marginTop:"10px"}}>
              {mintRemaining}
            </div>
          </Col>
        </Row> : null
    }
    
    const connectedWalletMintUI = () => {
    
        return shouldRenderConnectedWalletMintUI() ? <>
          <s.SpacerSmall />
          <s.Container ai={"center"} jc={"center"} fd={"row"}>
            <s.StyledRoundButton
              style={{ lineHeight: 0.4 }}
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                decrementMintAmount();
              } }
            >
              -
            </s.StyledRoundButton>
            <s.SpacerMedium />
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "#ffffff",
                marginTop:"auto",
                marginBottom:"auto"
              }}
            >
              {mintAmount}
            </s.TextDescription>
            <s.SpacerMedium />
            <s.StyledRoundButton
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                incrementMintAmount();
              } }
            >
              +
            </s.StyledRoundButton>
          </s.Container>
          <s.SpacerSmall />
          <s.Container ai={"center"} jc={"center"} fd={"row"}>
          <Button style={{ backgroundColor: "#F83700", border: "#F83700", width:"100%" }}
              disabled={claimingNft || mintAmount === 0 ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                claimNFTs();
                getData();
              } }
            >
              {claimingNft ? "BUSY" : "BUY"}
            </Button>
          </s.Container>
          {feedbackMessage()}
        </> : feedbackMessage()
    }

    const soldOut = () => {
        return <>
            <s.TextTitle
            style={{ textAlign: "center", color: "#ffffff" }}
            >
            The sale has ended.
            </s.TextTitle>
            <s.TextDescription
            style={{ textAlign: "center", color: "#ffffff" }}
            >
            You can still find {CONFIG.NFT_NAME} on
            </s.TextDescription>
            <s.SpacerSmall />
            <s.StyledLink style={{ color:"#fff", textDecoration:"underline"}} target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
            {CONFIG.MARKETPLACE}
            </s.StyledLink>
        </>
    }

    return (
        <>
                <Row style={{ paddingTop:"25px" }}>
                  <Col style={{ textAlign: "center", color: "#ffffff", fontSize:"1.5em" }}>{CONFIG.NFT_NAME}</Col>                  
                </Row>
                <Row style={{ paddingBottom:"25px" }}>
                  <Col style={{ textAlign: "center", color: "#ffffff", fontSize:"1em" }}>{CONFIG.MINT_DESCRIPTION}</Col>
                </Row>
    
                { wallet.account && contractInstances.smartContract !== null ? progressBar() : null }
    
                <Row>
    
                  <Col xs={12} style={{ paddingTop:"0px", textAlign: "center" }}>
                    {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? soldOut() : (
                      <>
                        { (!wallet.account || contractInstances.smartContract === null) ? <PleaseConnectWallet /> : connectedWalletMintUI() }
                      </>
                    )}
                  </Col>
                </Row>
    
                <Row style={{ paddingTop:"10px" }}>
                  <Col>
                  <s.StyledLink style={{ color:"#fff", textDecoration:"underline", fontSize:"0.6em"}} target={"_blank"} href={`https://etherscan.io/address/${CONFIG.CONTRACT_ADDRESS}`}>Smart Contract on Etherscan</s.StyledLink>
                  </Col>
                </Row>
        </>
    );
}

function mapStateToProps({ wallet }) {
    return { wallet };
}

export default connect(mapStateToProps)(Mint);
