import { useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import Pagination from '../../../pagination/Pagination';
import ReviewRaffleHeader from '../ReviewRaffleHeader';
import ReviewNav from '../../ReviewNav';

import AutocompleteGeneral from "../../../autocomplete/noForm/AutocompleteGeneral";
require("../../../autocomplete/noForm/autocompleteStyles.css");

const createRaffle = async (id, url, raffleCollection, partner, partnerUrl, registrationClosed, raffleSpots, registrationCloseTime, raffleTime, totalSupply, mintPrice, blockchain, mintDate, website, twitter, discord, nft_collection_id, partner_collection_id, newNFT ) => {
    const values = {
        id,
        url,
        raffleCollection,
        partner,
        partnerUrl,
        registrationClosed,
        raffleSpots,
        registrationCloseTime,
        raffleTime,
        totalSupply,
        mintPrice,
        blockchain,
        mintDate,
        website,
        twitter,
        discord,
        nft_collection_id,
        partner_collection_id,
        newNFT
    }

    try {
        const response = await axios.post(`/api/admin/post/raffles-automated/create`, values);
        console.log(response.data.status);
        return response.data.status;
    } catch (error) {
        console.error(error.response)
    }
}

const skipRaffle = async (id ) => {
    const values = { id };

    try {
        const response = await axios.post(`/api/admin/post/raffles-automated/skip`, values);
        console.log(response.data.status);
        return response.data.status;
    } catch (error) {
        console.error(error.response)
    }
}


const TableRow = ({ automatedRafflesDataRow }) => {
    const { id
        ,original_url 
        ,twitter_search_url_id 
        ,added_ts 
        ,jsonb_raw_data 
        ,full_url 
        ,type
        ,project_name
        ,num_winners
        ,official_website
        ,total_supply
        ,spots_reserved
        ,selection_method
        ,verified_twitter
        ,verified_discord
        ,mint_date
        ,mint_price
        ,registration_close_time
        ,raffle_time
        ,registration_close
        ,partner
        ,partner_url
        ,blockchain } = automatedRafflesDataRow;         
        
    const [raffleStatus, setRaffleStatus] = useState(null);
    
    const [inputProjectName, setInputProjectName] = useState(project_name === null ? undefined : project_name);
    const [inputRaffleSpots, setInputRaffleSpots] = useState(num_winners !== null ? num_winners : spots_reserved !== null ? spots_reserved : undefined);
    const [inputRaffleTime, setInputRaffleTime] = useState(raffle_time === null ? undefined : raffle_time);
    const [inputRegistrationTimeClose, setInputRegistrationTimeClose] = useState(registration_close_time === null ? undefined : registration_close_time);
    const [inputPartner, setInputPartner] = useState(partner === null ? undefined : partner);
    const [inputPartnerUrl, setInputPartnerUrl] = useState(partner_url === null ? undefined : partner_url);
    const [inputTotalSupply, setInputTotalSupply] = useState(total_supply === null ? undefined : total_supply);
    const [inputMintPrice, setInputMintPrice] = useState(mint_price === null ? undefined : mint_price);
    const [inputMintDate, setInputMintDate] = useState(mint_date === null ? undefined : mint_date);
    const [inputWebsite, setInputWebsite] = useState(official_website === null ? undefined : official_website);
    const [inputTwitter, setInputTwitter] = useState(verified_twitter === null ? undefined : verified_twitter);
    const [inputDiscord, setInputDiscord] = useState(verified_discord === null ? undefined : verified_discord);

    const nftFieldName = 'nftCollection' + id;
    const nftFieldNameId = 'nftCollection' + id + 'Id';
    const partnerFieldName = 'partner' + id;
    const partnerFieldNameId = 'partner' + id + 'Id';
    const originalCollectionName = 'originalCollectionName' + id;
    const partnerName = 'partner' + id;
    const partnerUrlName = 'partnerUrl' + id;
    const registrationClosedName = 'registrationClosed' + id;
    const raffleSpotsName = 'raffleSpots' + id;
    const registrationCloseTimeName = 'registrationCloseTime' + id;
    const raffleTimeName = 'raffleTime' + id;
    const totalSuppyName = 'totalSuppy' + id;
    const mintPriceName = 'mintPrice' + id;
    const blockchainName = 'blockchain' + id;
    const mintDateName = 'mintDate' + id;
    const websiteName = 'website' + id;
    const twitterName = 'twitter' + id;
    const discordName = 'discord' + id;
    const newName = 'new' + id;
    
    return raffleStatus === 'Added' || raffleStatus === 'Skipped' ? null : <>
    <tr>
        <td>
            <div className="row">
                <a href={full_url} target="_BLANK" rel="noreferrer" style={{color:"#ffffff"}}>
                    Premint
                </a>
            </div>            
        </td>
        <td>
            <div className="row">
                <span>            
                    <input className="form-control" placeholder="Original Collection Name" type="text" name={originalCollectionName} value={inputProjectName} onChange={(e) => setInputProjectName(e.currentTarget.value)} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/>                
                </span>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                    Partner
                </div>
                <div className="col-8">
                    <span>            
                        <input className="form-control" placeholder="Partner" type="text" name={partnerName} value={inputPartner} onChange={(e) => setInputPartner(e.currentTarget.value)} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/>                
                    </span>
                    Raw Data: {jsonb_raw_data.partnerRaw}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                    Partner URL
                </div>
                <div className="col-8">
                    <span>            
                        <input className="form-control" placeholder="Partner URL" type="text" name={partnerUrlName} value={inputPartnerUrl} onChange={(e) => setInputPartnerUrl(e.currentTarget.value)} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/> 
                        Raw Data: {jsonb_raw_data.partnerUrlRaw}     
                    </span>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                    Registration Closed
                </div>
                <div className="col-8">
                    <select
                        className="form-select"
                        name={registrationClosedName}                                        
                        label="registrationClosed"
                        style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)", backgroundImage:"linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc)", backgroundPosition: "calc(100% - 17px) calc(1em + 2px), calc(100% - 12px) calc(1em + 2px), calc(100% - 2em) 0.4em", backgroundSize:"5px 5px, 5px 5px, 1px 1.5em", backgroundRepeat: "no-repeat"}}
                    >
                        <option value='false'>No</option>
                        <option value="true">Yes</option>                        
                    </select>
                    No raw data
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                     Raffle Spots
                </div>
                <div className="col-8">
                    <span>            
                        <input className="form-control" placeholder="Raffle Spots" type="number" name={raffleSpotsName} value={inputRaffleSpots} onChange={(e) => setInputRaffleSpots(e.currentTarget.value)} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/>                
                    </span>
                    Raw Data: A. {jsonb_raw_data.numWinnersRaw} OR B. {jsonb_raw_data.spotsReservedRaw}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                    Registration Close Time
                </div>
                <div className="col-8">
                    <span>            
                        <input className="form-control" placeholder="YYYY-MM-DDTHH:MM:SS.MMMZ" type="text" name={registrationCloseTimeName} value={inputRegistrationTimeClose} onChange={(e) => setInputRegistrationTimeClose(e.currentTarget.value)} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/>                
                    </span>
                    Raw Data: {jsonb_raw_data.registrationCloseTimeRaw}
                    <br />{registration_close_time}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                    Raffle Time
                </div>
                <div className="col-8">
                    <span>            
                        <input className="form-control" placeholder="YYYY-MM-DDTHH:MM:SS.MMMZ" type="text" name={raffleTimeName} value={inputRaffleTime} onChange={(e) => setInputRaffleTime(e.currentTarget.value)} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/>                
                    </span>
                    Raw Data: {jsonb_raw_data.raffleTimeRaw}
                    <br />{raffle_time}
                </div>
            </div>            
            <div className="row mt-2">
                <div className="col-4">
                    Total Supply
                </div>
                <div className="col-8">
                    <span>            
                        <input className="form-control" placeholder="Total Supply" type="text" name={totalSuppyName} value={inputTotalSupply} onChange={(e) => setInputTotalSupply(e.currentTarget.value)} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/>                                    
                    </span>
                    Raw Data: {jsonb_raw_data.totalSupplyRaw}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                    Mint Price
                </div>
                <div className="col-8">
                    <span>            
                        <input className="form-control" placeholder="Mint Price" type="text" name={mintPriceName} value={inputMintPrice} onChange={(e) => setInputMintPrice(e.currentTarget.value)} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/>    
                        Raw Data: {jsonb_raw_data.mintPriceRaw}
                    </span>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                    Blockchain
                </div>
                <div className="col-8">                
                    <select
                        className="form-select"
                        name={blockchainName}                                        
                        label="Blockchain"
                        style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)", backgroundImage:"linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc)", backgroundPosition: "calc(100% - 17px) calc(1em + 2px), calc(100% - 12px) calc(1em + 2px), calc(100% - 2em) 0.4em", backgroundSize:"5px 5px, 5px 5px, 1px 1.5em", backgroundRepeat: "no-repeat"}}
                    >
                        <option value=''>None</option>
                        <option value="ETH">Ethereum (ETH)</option>
                        <option value="FLOW">Flow</option>
                        <option value="PALM">Palm</option>
                        <option value="MATIC">Polygon (MATIC)</option>   
                        <option value="SOL">Solana (SOL)</option>   
                        <option value="ADA">Cardano (ADA)</option>  
                        <option value="AVAX">Avalanche (AVAX)</option>
                        <option value="BNB">Binance (BNB)</option>
                        <option value="OP">Optimism (OP)</option>
                    </select>
                    Raw Data: (see Mint Price)
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                    Mint Date
                </div>
                <div className="col-8">
                    <span>            
                        <input className="form-control" placeholder="Mint Date" type="text" name={mintDateName} value={inputMintDate} onChange={(e) => setInputMintDate(e.currentTarget.value)} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/>                
                    </span>
                    Raw Data: {jsonb_raw_data.mintDateRaw}
                    <br />{mint_date}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                    Website
                </div>
                <div className="col-8">
                    <span>            
                        <input className="form-control" placeholder="Website" type="text" name={websiteName} value={inputWebsite} onChange={(e) => setInputWebsite(e.currentTarget.value)} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/>                
                    </span>
                    Raw Data: {jsonb_raw_data.officialWebsiteRaw}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                    Twitter
                </div>
                <div className="col-8">
                    <span>            
                        <input className="form-control" placeholder="Twitter" type="text" name={twitterName} value={inputTwitter} onChange={(e) => setInputTwitter(e.currentTarget.value)} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/>                
                    </span>
                    Raw Data: {jsonb_raw_data.verifiedTwitter}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                    Discord
                </div>
                <div className="col-8">
                    <span>            
                        <input className="form-control" placeholder="Discord" type="text" name={discordName} value={inputDiscord} onChange={(e) => setInputDiscord(e.currentTarget.value)} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/>                
                    </span>
                    Raw Data: {jsonb_raw_data.verifiedDiscord}
                </div>
            </div>
        </td>  
        
        <td>
            <div className="row">
                <AutocompleteGeneral 
                    className="form-control"
                    name={nftFieldName}
                    fieldName={nftFieldName}
                    hiddenField={nftFieldNameId}
                    endpoint="/api/get/autocomplete/search?searchTerm="     
                    placeholder="NFT Collection"
                    label="NFT Collection"
                />
            </div>
            <div className="row mt-2">
                <AutocompleteGeneral 
                    className="form-control"
                    name={partnerFieldName}
                    fieldName={partnerFieldName}
                    hiddenField={partnerFieldNameId}
                    endpoint="/api/get/autocomplete/search?searchTerm="     
                    placeholder="Partner"
                    label="Partner"
                />
            </div>
        </td>      
        
        <td style={{textAlign:"center"}}>
            <input className="form-check-input" type="checkbox" name={newName} />
        </td>
        <td>
            <button   
                onClick={async () => {
                    const status = await createRaffle( 
                        id, 
                        full_url, 
                        document.getElementsByName(originalCollectionName)[0].value,
                        document.getElementsByName(partnerName)[0].value,
                        document.getElementsByName(partnerUrlName)[0].value,
                        document.getElementsByName(registrationClosedName)[0].value,
                        document.getElementsByName(raffleSpotsName)[0].value,
                        document.getElementsByName(registrationCloseTimeName)[0].value,
                        document.getElementsByName(raffleTimeName)[0].value,
                        document.getElementsByName(totalSuppyName)[0].value,
                        document.getElementsByName(mintPriceName)[0].value,
                        document.getElementsByName(blockchainName)[0].value,
                        document.getElementsByName(mintDateName)[0].value,
                        document.getElementsByName(websiteName)[0].value,
                        document.getElementsByName(twitterName)[0].value,
                        document.getElementsByName(discordName)[0].value,
                        document.getElementsByName(nftFieldNameId)[0].value,
                        document.getElementsByName(partnerFieldNameId)[0].value,    
                        document.getElementsByName(newName)[0].checked,
                         );
                    setRaffleStatus(status);
                }}             
                type="button" className="btn btn-light btn-sm">
                Create
            </button>
            <br /><button
                style={{marginTop:"10px"}}
                onClick={async () => {
                    const status = await skipRaffle( id );
                    setRaffleStatus(status);
                }}             
                type="button" className="btn btn-light btn-sm">
                Skip
            </button>
            {raffleStatus === 'Missing NFT' ? <><br />The NFT Collection is missing or you forgot to check the box to create a new collection</> : raffleStatus === 'Create error' ? <><br />There was an error creating this raffle. Double-check date formats. If needed, Slack Elliot.</> : null}
        </td>    
            
             
        
    </ tr>
    </>;
}

const Automated = ({ automatedRafflesData, automatedRafflesCount, accountType }) => {
    
    return (
        <>
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-2">
                <ReviewNav page="raffles" accountType={accountType}/>
            </div>
            <div className="col-10">            
                <ReviewRaffleHeader page="automated" automatedRafflesCount={automatedRafflesCount} />
                <div className="row table-responsive" style={{ marginTop:"20px"}}>
                    <div className="col-12">
                    <table className="table" style={{ color:"#ffffff" }}>
                        <thead>
                            <tr style={{ backgroundColor:"#1F2731"}}>                                
                                <th scope="col">Raffle</th>  
                                <th scope="col">Original Collection Name</th>  
                                <th scope="col">NFT Collection</th>                                
                                <th scope="col">New</th>
                                <th scope="col">Create</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize:".8em"}}>
                            {automatedRafflesData.map(automatedRafflesDataRow => <TableRow key={automatedRafflesDataRow.id} automatedRafflesDataRow={automatedRafflesDataRow} />)}
                        </tbody>                        
                    </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Automated;
