// RenderField contains logic to render a single label and text input
import React from 'react';

//{input} is the same as props.input
export default ({ input, className, label, fieldValue, onClick, meta: { touched, error, warning } }) => {
    
    return (
        <div>            
            <div style={{color:"rgba(255,255,255,0.8)"}}>
                <input {...input} className={className} type="checkbox" value={fieldValue} onClick={onClick} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
                <label>{label}</label>
            </div>
        </div>
    );
};