import React, { useState } from 'react';
import RenderFormField from '../../../collectionPage/RenderFormField';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../../../actions/index';

import AutocompleteGeneral from "../../../autocomplete/AutocompleteGeneral";
require("../../../autocomplete/autocompleteStyles.css");

const BenefitEditForm = ({ onBenefitEditFormSubmit, initialValues, handleSubmit, error, submitting, benefitsTypes }) => {
    
    const [benefitType, setBenefitType] = useState(initialValues.type);

    const autocompleteFieldName = 'benefitPartner';
    const autocompleteHiddenFieldName = 'benefitPartnerId';

    const renderAddType = () => {
        return <>
            <div className="row" style={{marginBottom:"10px"}}>
                <div className="col-lg-8 col-12">
                    <label style={{ color: "#ffffff", marginBottom: "5px" }}>Add Type</label>
                    <Field
                        className="form-control"
                        name="addType"
                        component={RenderFormField}
                        type="text"
                        placeholder="ex: Raffle"
                        label="Add Type" />
                </div>
            </div>
        </>
    }

    const renderDescription = () => {
        return <>
            <div className="row" style={{marginBottom:"10px"}}>
                <div className="col-lg-8 col-12">
                    <label style={{ color: "#ffffff", marginBottom: "5px" }}>Description</label>
                    <Field
                        className="form-control"
                        name="description"
                        component={RenderFormField}
                        type="text"
                        placeholder="Description"
                        label="Description"
                    />
                </div>
            </div>
        </>
    }

    const renderRaffleQuantity = () => {
        return <>
            <div className="row" style={{marginBottom:"10px"}}>
                <div className="col-lg-8 col-12">
                    <label style={{ color: "#ffffff", marginBottom: "5px" }}>Quantity</label>
                    <Field
                        className="form-control"
                        name="raffleQuantity"
                        component={RenderFormField}
                        type="number"
                        placeholder="Quantity"
                        label="Quantity"
                    />
                </div>
            </div>
        </>
    }
    
    const submitForm = () => {
        return (
            handleSubmit( values => onBenefitEditFormSubmit({
                ...values, benefitPartner: document.getElementsByName(autocompleteFieldName)[0].value, benefitPartnerId: document.getElementsByName(autocompleteHiddenFieldName)[0].value
            }) )
        )
    }
    
    return (<>
        {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
        <div className="row table-responsive text-nowrap my-3" style={{fontSize:"1em"}}>                
            <div className="col-12">
                <form onSubmit={submitForm()}>
                    <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-lg-6 col-12">
                            <label style={{ color: "#ffffff", marginBottom: "5px" }}>NFT Collection</label>
                            <Field
                                className="form-control"
                                name="nftCollection"
                                component={RenderFormField}
                                type="text"
                                label="NFT Collection"
                                placeholder="NFT Collection"                                
                                disabled={true}
                                >                                                                      
                            </Field>
                        </div>
                        <div className="col-lg-6 col-12">
                            <label style={{ color: "#ffffff", marginBottom: "5px" }}>NFT Collection ID</label>
                            <Field
                                className="form-control"
                                name="nftCollectionID"
                                component={RenderFormField}
                                type="text"
                                label="NFT Collection ID"
                                placeholder="NFT Collection ID"                                
                                disabled={true}
                                >                                                                      
                            </Field>
                        </div>
                    </div>

                    <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-lg-8 col-12">
                            <label style={{ color: "#ffffff", marginBottom: "5px" }}>Type</label>
                            <Field
                                className="form-select"
                                name="type"
                                component="select"
                                type="select"
                                label="Type"
                                placeholder="Type"
                                onChange={e => setBenefitType(e.target.value)}
                                style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)", backgroundImage:"linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc)", backgroundPosition: "calc(100% - 17px) calc(1em + 2px), calc(100% - 12px) calc(1em + 2px), calc(100% - 2em) 0.4em", backgroundSize:"5px 5px, 5px 5px, 1px 1.5em", backgroundRepeat: "no-repeat"}}
                                >
                                <option value="" key={-1} disabled>Type</option>
                                {benefitsTypes.map(benefitsType =>
                                    <option key={benefitsType.id} value={benefitsType.benefit_type}>{benefitsType.benefit_type}</option>                                            
                                )};
                                <option value="Other" key={-2}>Other</option>                                        
                            </Field>
                        </div>
                    </div>

                    {benefitType === 'Other' ? renderAddType() : null} 

                    { ( initialValues.type === 'Raffle' && benefitType === undefined ) || benefitType === 'Raffle' ? renderRaffleQuantity() : renderDescription()}

                    
                    <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-lg-8 col-12">
                            <label style={{ color: "#ffffff", marginBottom: "5px" }}>Partner</label>
                            <Field
                                className="form-control"
                                name={autocompleteFieldName}
                                fieldName={autocompleteFieldName}
                                hiddenField={autocompleteHiddenFieldName}
                                endpoint="/api/get/autocomplete/search?searchTerm="
                                component={AutocompleteGeneral}     
                                placeholder="Benefit Partner"      
                                initialValue={initialValues?.benefitPartner !== undefined ? initialValues.benefitPartner : ''}                                      
                                label="Benefit Partner"
                            />
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-lg-8 col-12">
                            <label style={{ color: "#ffffff", marginBottom: "5px" }}>Details Link</label>
                            <Field
                                className="form-control"
                                name="detailsLink"
                                component={RenderFormField}
                                type="text"
                                placeholder="Details Link"
                                label="Details Link"
                            />
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-lg-8 col-12">
                            <label style={{ color: "#ffffff", marginBottom: "5px" }}>Claim Date</label>
                            <Field
                                className="form-control"
                                name="claimDate"
                                component={RenderFormField}
                                type="date"
                                placeholder="mm/dd/yyyy"
                                label="Claim Date" 
                            />
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-lg-8 col-12">
                            <label style={{ color: "#ffffff", marginBottom: "5px" }}>Claim Time (ET)</label>
                            <Field
                                className="form-control"
                                name="claimTime"
                                component={RenderFormField}
                                type="time"
                                placeholder="00:00 AM"
                                label="Claim Time (ET)" 
                            />
                        </div>
                    </div>      
                    <div className="row" style={{marginTop:"30px"}}>
                        <div className="col-lg-8 col-12">
                            <button className="btn btn" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff", width:"100%" }} disabled={submitting}>
                                Update
                            </button>  
                        </div>              
                    </div>                        
                </form>
            </div>
        </div>
    
    </>
    );
}

const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}
    
    if (values.partner) {
        if (values.partner.length > 250) {
         errors.partner = 'Must be 250 characters or less'
        }
    } 
    
    if (values.description) {
        if (values.description.length > 500) {
         errors.partner = 'Must be 500 characters or less'
        }
    } 
    

    // console.log('errors', errors)
    return errors
}

function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'benefitEditForm',
    validate,
})(BenefitEditForm));