import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';


const WatchlistButtons = ({ id }) => {
    
    const [watching, setWatching] = useState(false);

    useEffect(() => {
        const fetchWatching = async () => {
            const response = await axios.get(`/api/fetch-watching/${id}`);
            setWatching(response?.data);
        }
        
        fetchWatching();

    }, [id])

    const updateWatching = async (id, watching) => {
        
        try {
            const response = await axios.put(`/api/watching/${id}`, { watching });         
            return response.data.watching;
        } catch (error) {
            console.error(error.response);
        }
        
    }

    //console.log(watching);
    
    if ( watching ) {
        return <>
            <button 
                className="btn btn-sm btn-success"
                onClick={async () => {
                    const newWatching = await updateWatching(id, !watching);
                    setWatching(newWatching);                            
                }}
            >
                <i className="bi bi-check-square-fill"></i>
            </button>                 
        </>;            
    }
    else {
        return <>
            <button 
                className="btn btn-sm btn-secondary"
                onClick={async () => {
                    const newWatching = await updateWatching(id, !watching);
                    setWatching(newWatching);    
                }}
            >
                <i className="bi bi-square"></i>
            </button> 
        </>;   
    }
    
}

export default WatchlistButtons;