import { Link } from 'react-router-dom';

const ReviewRaffleHeader = ({page, automatedRafflesCount }) => {
    
    const upcomingInputHighlight = page === 'upcoming' ? '#B0228C' : '#000000';
    const upcomingInputUnderline = page === 'upcoming' ? 'none' : 'underline';
    const upcomingInputStyleInputs = { color:"#ffffff", backgroundColor: upcomingInputHighlight, padding:"5px", textDecoration: upcomingInputUnderline };

    const endedHighlight = page === 'ended' ? '#B0228C' : '#000000';
    const endedUnderline = page === 'ended' ? 'none' : 'underline';
    const endedStyleInputs = { color:"#ffffff", backgroundColor: endedHighlight, padding:"5px", textDecoration: endedUnderline };
    
    return (
        <>
        <div className="row">
            <div className="col-8 col-lg-10" style={{ fontSize: "2em"}}>
                Review - Giveaways
            </div>
            <div className="col-4 col-lg-2 align-self-center" style={{textAlign:"right"}}>
                <Link to="/admin/giveaways/new">
                    <button className="btn btn-light" style={{color:"#7945E1"}}>
                        Add Giveaway
                    </button>
                </Link>
            </div>
        </div>
        <div className="row">
            <div className="col-6 col-lg-3" style={{ fontSize: "1em"}}>
                <Link to="/admin/giveaways/upcoming" className="rounded" style={upcomingInputStyleInputs}>Upcoming</Link>
            </div>
            <div className="col-6 col-lg-3" style={{ fontSize: "1em"}}>
                <Link to="/admin/giveaways/ended" className="rounded" style={endedStyleInputs}>Ended</Link>
            </div>
        </div>
        </>
    );
};

export default ReviewRaffleHeader;