import { FETCH_REGISTERED_USER } from '../actions/types';

const registeredUserReducer = (state = {}, action) => {
    switch(action.type) {
        case FETCH_REGISTERED_USER:
            return action.payload || {};
        default:
            return state;
    }
}

export default registeredUserReducer
