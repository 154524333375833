import { Link } from 'react-router-dom';

const DashboardHeader = ( { page, layout } ) => {
    
    //console.log(page);
    //console.log(layout);

    const linesHighlight = layout === 'lines' ? '#7945E1' : '#ffffff';
    const linesFatHighlight = layout === 'linesFat' ? '#7945E1' : '#ffffff';
    const gridHighlight = layout === 'grid' ? '#7945E1' : '#ffffff';

    const allNFTsHighlight = page === 'all-nfts' ? '#7945E1' : '#140510';
    const allNFTsStyleInputs = { color:"#ffffff", backgroundColor: allNFTsHighlight, padding:"5px", textDecoration:"none" };    
    const allNFTsText = page === 'notifications' ? <strong>All NFTs</strong> : <span>All NFTs</span>;

    const upcomingMintsHighlight = page === 'upcoming-mints' ? '#7945E1' : '#140510';
    const upcomingMintsStyleInputs = { color:"#ffffff", backgroundColor: upcomingMintsHighlight, padding:"5px", textDecoration:"none" };    
    const upcomingMintsText = page === 'notifications' ? <strong>Upcoming Mints</strong> : <span>Upcoming Mints</span>;

    const freeMintsHighlight = page === 'free-mints' ? '#7945E1' : '#140510';
    const freeMintsStyleInputs = { color:"#ffffff", backgroundColor: freeMintsHighlight, padding:"5px", textDecoration:"none" };    
    const freeMintsText = page === 'notifications' ? <strong>Free Mints</strong> : <span>Free Mints</span>;  
    
    const linesFatUrlAppendAmp = layout === 'linesFat' ? '&linesFat=true' : '';
    const linesFatUrlAppendQuestion = layout === 'linesFat' ? '?linesFat=true' : '';

    const pageUrlAppendAmp = page === 'upcoming-mints' ? '&upcomingMints=true&sort=mintDateNewToOld' : page === 'free-mints' ? '&freeMints=true' : '';
    const pageUrlAppendQuestion = page === 'upcoming-mints' ? '?upcomingMints=true&sort=mintDateNewToOld' : page === 'free-mints' ? '?freeMints=true' : '';

    return (
        <>
                
        <div className="row" style={{marginTop:"20px"}}>              
            <div className="col-lg-2 col-6 order-1" >  
                <div className="d-lg-block d-none">
                    <Link to="/add/nft/quick">
                        <button className="btn btn-light" style={{color:"#7945E1"}}>
                            Add NFT
                        </button>
                    </Link>  
                </div>
                <div className="d-block d-lg-none">
                    <Link to="/add/nft/quick">
                        <button className="btn btn-sm btn-light" style={{color:"#7945E1"}}>
                            Add NFT
                        </button>
                    </Link>  
                </div>
            </div>
            <div className="col-lg-8 col-12 order-lg-2 order-3" >    
                <div className="d-block d-lg-none" style={{paddingTop:"20px"}}></div>
                <div className="row text-center">
                    <div className="col-12 rounded" style={{fontSize:"1em"}}>
                        <a href={"/all-nfts" + linesFatUrlAppendQuestion} className="rounded" style={allNFTsStyleInputs}>{allNFTsText}</a>&nbsp;|&nbsp;
                        <a href={"/all-nfts?upcomingMints=true&sort=mintDateNewToOld" + linesFatUrlAppendAmp} className="rounded" style={upcomingMintsStyleInputs}>{upcomingMintsText}</a>&nbsp;|&nbsp;
                        <a href={"/all-nfts?freeMints=true" + linesFatUrlAppendAmp} className="rounded" style={freeMintsStyleInputs}>{freeMintsText}</a>
                    </div>
                </div>
            </div>      
            <div className="col-6 order-2 d-block d-lg-none" style={{textAlign:"right"}}>
                <Link to={"/all-nfts" + pageUrlAppendQuestion}>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="24" 
                        height="24" 
                        fill={linesHighlight}
                        className="bi bi-list" 
                        viewBox="0 0 16 16"
                        style={{marginRight:"5px"}}
                    >
                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                </Link>
                
                <Link to={"/all-nfts?linesFat=true" + pageUrlAppendAmp}>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        fill={linesFatHighlight}
                        className="bi bi-grip-vertical" 
                        viewBox="0 0 16 16"
                        style={{marginRight:"5px"}}
                    >
                        <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                </Link>
                
                <Link to="/calendar">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="20" 
                        height="20" 
                        fill={gridHighlight}
                        className="bi bi-grid" 
                        viewBox="0 0 16 16"
                    >
                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
                    </svg>
                </Link>
            </div>        
            <div className="col-lg-2 order-lg-3 d-lg-block d-none" style={{textAlign:"right"}}>
                <Link to="/all-nfts">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="24" 
                        height="24" 
                        fill={linesHighlight}
                        className="bi bi-list" 
                        viewBox="0 0 16 16"
                        style={{marginRight:"5px"}}
                    >
                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                </Link>                
                
                <Link to="/calendar">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="20" 
                        height="20" 
                        fill={gridHighlight}
                        className="bi bi-grid" 
                        viewBox="0 0 16 16"
                    >
                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
                    </svg>
                </Link>
            </div>        
        </div>
        <div className="row" style={{marginTop:"20px"}}>
            <div className="col-lg-2"></div>
            <div className="col-lg-8">                
                Are we missing something? <Link to="/add/nft/quick" style={{color:"rgba(255,255,255,0.8"}}>Add an NFT</Link> and earn 10 points PLUS 0.2 points every time someone upvotes an NFT you've added.
            </div>
        </div>
        
        </>
    );
};

export default DashboardHeader;