const EditNFTHeader = () => {
    return (
        <>
        <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-12" style={{ fontSize: "2em"}}>
                Edit NFT
            </div>
        </div>
        <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-12" style={{ fontSize: "1em"}}>
                Hello researcher! Slack Elliot for any questions.<br /><br />REMEMBER: Likes and Dislikes do NOT show up below, because they cannot be edited. They can only be deleted.
            </div>
        </div>        
        </>
    );
};

export default EditNFTHeader;