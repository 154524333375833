import { Link } from 'react-router-dom';

const ReviewRaffleHeader = ({page, automatedRafflesCount }) => {
    
    const userInputHighlight = page === 'userInput' ? '#B0228C' : '#000000';
    const userInputUnderline = page === 'userInput' ? 'none' : 'underline';
    const userInputStyleInputs = { color:"#ffffff", backgroundColor: userInputHighlight, padding:"5px", textDecoration: userInputUnderline };

    const automatedHighlight = page === 'automated' ? '#B0228C' : '#000000';
    const automatedUnderline = page === 'automated' ? 'none' : 'underline';
    const automatedStyleInputs = { color:"#ffffff", backgroundColor: automatedHighlight, padding:"5px", textDecoration: automatedUnderline };
    
    return (
        <>
        <div className="row">
            <div className="col-12" style={{ fontSize: "2em"}}>
                Review - Raffle Submissions
            </div>
        </div>
        <div className="row">
            <div className="col-6 col-lg-3" style={{ fontSize: "1em"}}>
                <Link to="/admin/review-raffles" className="rounded" style={userInputStyleInputs}>User Input</Link>
            </div>
            <div className="col-6 col-lg-3" style={{ fontSize: "1em"}}>
                <Link to="/admin/review-automated-raffles" className="rounded" style={automatedStyleInputs}>Automated ({automatedRafflesCount})</Link>
            </div>
        </div>
        </>
    );
};

export default ReviewRaffleHeader;