import { useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import Pagination from '../../pagination/Pagination';
import OpenseaHeader from './OpenseaHeader';
import ReviewNav from '../ReviewNav';

const matchOSAndNFT = async (contract, nft_collection_id, token_id_for_shared_contracts, collection_name_type, contract_id) => {
    try {
        await axios.get(`/api/admin/opensea/match/${contract}/${token_id_for_shared_contracts}/${nft_collection_id}/${collection_name_type}/${contract_id}`);
        return "Matched";
    } catch (error) {
        console.error(error.response)
    }
}

const newOSNFT = async (contract, token_id_for_shared_contracts, collection_name_type, contract_id) => {
    try {
        await axios.get(`/api/admin/opensea/new/${contract}/${token_id_for_shared_contracts}/${collection_name_type}/${contract_id}/none/none`);
        return "Matched";
    } catch (error) {
        console.error(error.response)
    }
}

const newOSCautionNoPageNFT = async (contract, token_id_for_shared_contracts, collection_name_type, contract_id) => {
    try {
        await axios.get(`/api/admin/opensea/new/${contract}/${token_id_for_shared_contracts}/${collection_name_type}/${contract_id}/Caution/noOSpage`);
        return "Matched";
    } catch (error) {
        console.error(error.response)
    }
}


const TableRow = ({ openseaRow, unmatchedNFTs }) => {
    const { id, address, token_id_for_shared_contracts, collection_name, name, os_collection_slug, image_url, match, nft_collection_id, update_nft_collection_image, opensea_error_response, user_count } = openseaRow;    

    let token_id = token_id_for_shared_contracts;
    if ( address !== '0x495f947276749ce646f68ac8c248420045cb7b5e' ) token_id = 'none';

    //const [status, setStatus] = useState(raffleData.approval_status ? 'approved' : 'not approved');    
    //const [reviewed_by, setReviewedBy] = useState(raffleData.reviewed_username);

    /*
    const badgeModifierClassName = {
        "not approved": "bg-danger",
        "approved": "bg-success"
    };

    const ApproveButton = () => {
        return <button
            onClick={async () => {
                const data = await updateStatus(id, 'approved');
                setStatus(data.status ? 'approved' : 'not approved');
                setReviewedBy(data.reviewed_username);
            }}
            type="button" className="btn btn-success btn-sm me-2">
            &#x2713;
        </button>;
    }

    const DisapproveButton = () => {
        return <button
            onClick={async () => {
                const data = await updateStatus(id, 'not approved');
                setStatus(data.status ? 'approved' : 'not approved');
                setReviewedBy(data.reviewed_username);
            }}
            type="button" className="btn btn-danger btn-sm">
            X
        </button>;
    }

    const renderActionsButtons = () => {
        switch(status) {            
            case 'not approved':
                return <ApproveButton />;
            case 'approved':
                return <div className="float-end"><DisapproveButton /></div>;
            default:
                return null;
        }
    }

    const registerByDate = new Date(register_by_date);
    const registerByDateConverted = registerByDate.toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });

    const dateSubmitted = new Date(submitted_ts);
    const dateSubmittedConverted = dateSubmitted.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    */

    const [nftLink, setNFTLink] = useState(match === true ? ( '/collection/' + nft_collection_id ) : '');    
    const [nftPage, setNFTPage] = useState(match === true ? ( collection_name ) : '');  
    const [nftID, setNFTid] = useState('');  
    const [matchOption, setMatchOption] = useState(match === true ? 'Matched' : '');     
    const [collectionNameType, setCollectionNameType] = useState('collectionName'); 

    const matchButton = (matchOption, contract, nft_collection_id, token_id, collection_name_type, id) => {
        if ( matchOption === 'Match' ) {
            return (
                <>
                    <button
                        onClick={async () => {
                            const matchStatus = await matchOSAndNFT( contract, nft_collection_id, token_id, collection_name_type, id );
                            setMatchOption(matchStatus);
                        }}
                        type="button" className="btn btn-light btn-sm">
                        {matchOption}
                    </button>
                </>
            )
        }
        else if ( matchOption === 'New' ) {
            return (
                <>
                    <button
                        onClick={async () => {
                            const matchStatus = await newOSNFT( contract, token_id, collection_name_type, id );
                            setMatchOption(matchStatus);
                        }}
                        type="button" className="btn btn-light btn-sm">
                        {matchOption}
                    </button>
                </>
            )
        }
        else if ( matchOption === 'NewCautionOSNoPage' ) {
            return (
                <>
                    <button
                        onClick={async () => {
                            const matchStatus = await newOSCautionNoPageNFT( contract, token_id, collection_name_type, id );
                            setMatchOption(matchStatus);
                        }}
                        type="button" className="btn btn-light btn-sm">
                        {matchOption}
                    </button>
                </>
            )
        }

        return matchOption;
    }


    const setNFTVariables = (target) => {
        if ( target[target.selectedIndex].innerText !== '--------' && target[target.selectedIndex].innerText !== 'NEW' && target[target.selectedIndex].innerText !== 'NEW - Caution - No OS Page' && target[target.selectedIndex].innerText !== '' ) {
            setNFTLink('/collection/' + target.value);
            setNFTPage(target[target.selectedIndex].innerText);
            setNFTid(target.value);
            setMatchOption('Match');
        }
        else if (target[target.selectedIndex].innerText === 'NEW') {
            setNFTLink('');
            setNFTPage('');
            setNFTid('');
            setMatchOption('New');
        }
        else if (target[target.selectedIndex].innerText === 'NEW - Caution - No OS Page') {
            setNFTLink('');
            setNFTPage('');
            setNFTid('');
            setMatchOption('NewCautionOSNoPage');
        }
        else {
            setNFTLink('');
            setNFTPage('');
            setNFTid('');
            setMatchOption('');
        }
    }

    const showSelect = ( matchOption ) => {
        if ( matchOption !== 'Matched' ) {
            return (
                <select className="form-select" 
                        aria-label="Default select example" name="unmatchedNFT" 
                        onChange={e => setNFTVariables( e.target )}                    
                >
                    <option></option>
                    {unmatchedNFTs.map(unmatchedNFT =>
                        <option key={unmatchedNFT.id} value={unmatchedNFT.id}>{unmatchedNFT.nft_collection}</option>
                    )};
                    <option>--------</option>
                    <option>NEW</option>
                    <option>NEW - Caution - No OS Page</option>
                </select>                
            );
        }
        return '';
    }

    const showNameSelect = ( matchOption ) => {
        if ( matchOption !== 'Matched' ) {
            return (
                <select className="form-select" 
                        aria-label="Default select example" name="unmatchedNFT" 
                        onChange={e => setCollectionNameType( e.target.value )}                    
                >
                    <option value='collectionName'>Collection</option>
                    <option value='name'>Name</option>                    
                </select>                
            );
        }
        return '';
    }

    const showLink = ( nftLink, nftPage ) => {
        
        return (
            <Link to={nftLink} style={{color:"#ffffff"}}>{nftPage}</Link>
        );
        
    }

    const openseaUrl = 'https://opensea.com/collection/' + os_collection_slug;
    return <tr>
        <td>{user_count}</td>
        <td>
            <div className="row">
                <div className="col-2">
                    <a href={openseaUrl} target="_BLANK" rel="noreferrer" style={{color:"#ffffff"}}>
                        <img src={image_url} width={40} />
                    </a>
                </div>
                <div className="col-10">
                    <a href={openseaUrl} target="_BLANK" rel="noreferrer" style={{color:"#ffffff"}}>
                        COLLECTION: {collection_name}<br />NAME: {name}
                    </a>
                </div>
            </div>
        </td>  
        { opensea_error_response ? <td colspan="5">OpenSea Error.<br/>{address} | {token_id_for_shared_contracts}</td>
            :
            <><td>{showNameSelect(matchOption)}</td>      
            <td style={{width:"300px"}}>
                {showSelect(matchOption)}
            </td>
            <td>{showLink( nftLink, nftPage )}<br />{update_nft_collection_image === true ? '(Update image)' : null}</td>
            <td>{matchButton(matchOption, address, nftID, token_id, collectionNameType, id)}</td>    
            <td>{address}<br/>{token_id_for_shared_contracts}</td>  
            </>  
        }
    </ tr>;
}

let PageSize = 50;
let filters = '';

const OpenseaReview = ({ openseaData, unmatchedNFTs, accountType }) => {
    
    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);    

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return openseaData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, openseaData]);

    return (
        <>
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-2">
                <ReviewNav page="opensea" accountType={accountType}/>
            </div>
            <div className="col-10">            
                <OpenseaHeader />
                <div className="row table-responsive text-nowrap" style={{ marginTop:"20px"}}>
                    <div className="col-12">
                    <table className="table" style={{ color:"#ffffff" }}>
                        <thead>
                            <tr style={{ backgroundColor:"#1F2731"}}>                                
                                <th scope="col">User Count</th>  
                                <th scope="col">Opensea Collection</th>  
                                <th scope="col">Preferred Name</th>                                
                                <th scope="col" style={{width:"300px"}}>NFTs Already In Platform</th>
                                <th scope="col">NFT page</th>                                
                                <th scope="col">Match</th>
                                <th scope="col">Contract</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize:".8em"}}>
                            {pageData.map(openseaRow => <TableRow key={openseaRow.id} openseaRow={openseaRow} unmatchedNFTs={unmatchedNFTs} />)}
                        </tbody>
                        <tfoot style={{ backgroundColor:"#1F2731", textAlign: "center"}}>
                            <tr>                     
                                <td colSpan="7">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={openseaData.length}
                                        pageSize={PageSize}
                                        filters={filters}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default OpenseaReview;
