module.exports = {
    /* TESTING - RINKEBY */
    /*
    "CONTRACT_ADDRESS": "0xc01a357137f188b3c3da0601b6a455a39961af9d",
    "NETWORK": {
      "NAME": "Rinkeby Testnet",
      "SYMBOL": "ETH",
      "ID": 4
    },
    "MINT_DESCRIPTION": "Test - TestNFT",
    "NFT_NAME": "TestNFT",
    "SYMBOL": "TEST",
    "ALLOW_LIST_ALLOCATION": 2,
    "PRESALE_LIST_ALLOCATION": 2,
    "PUBLIC_ALLOCATION": 5,
    "MAX_SUPPLY": 500,
    "WEI_COST": 2500000000000000,
    "GAS_LIMIT": 100000,
    "MARKETPLACE": "OPENSEA",
    "MARKETPLACE_LINK": "https://testnets.opensea.io/collection/testnft-2xixn3jjbz",
    */
    /* MAINNET */
    
    "CONTRACT_ADDRESS": "0x5f66F72a4f87ec3Cd06241400bD2bA867F1233c7",
    "NETWORK": {
      "NAME": "Mainnet",
      "SYMBOL": "ETH",
      "ID": 1
    },
    "MINT_DESCRIPTION": "Mint Genesis NFT",
    "NFT_NAME": "Future Mints - Genesis NFT",
    "SYMBOL": "FNFTM0",
    "ALLOW_LIST_ALLOCATION": 2,
    "PRESALE_LIST_ALLOCATION": 2,
    "PUBLIC_ALLOCATION": 5,
    "MAX_SUPPLY": 500,
    "WEI_COST": 250000000000000000,
    "GAS_LIMIT": 100000,
    "MARKETPLACE": "OPENSEA",
    "MARKETPLACE_LINK": "https://opensea.io/collection/future-nft-mints-genesis-nft",
    
}
  