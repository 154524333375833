import React, {Component} from 'react'
import { Field, reduxForm } from 'redux-form';

export default class MintDateUnknown extends Component{

    render(){
        return(
            <><div className="col-md-4">
                <div className="form-group">
                    <div>
                        <label style={{marginBottom: "5px"}}>TBD Month</label>
                        <Field
                            className="form-select"
                            name="mintDateUnknownMonth"
                            component="select"
                            type="select">
                            <option></option>
                            <option value="12-31">Not Sure</option>
                            <option value="01-31">Jan</option>
                            <option value="02-28">Feb</option>                                        
                            <option value="03-31">Mar</option>                                        
                            <option value="04-30">Apr</option>                                        
                            <option value="05-31">May</option>                                        
                            <option value="06-30">Jun</option>                                        
                            <option value="07-31">Jul</option>                                        
                            <option value="08-31">Aug</option>                                        
                            <option value="09-30">Sep</option>                                        
                            <option value="10-31">Oct</option>                                        
                            <option value="11-30">Nov</option>                                        
                            <option value="12-31">Dec</option>                                        
                        </Field>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <div>
                        <label style={{marginBottom: "5px"}}>TBD Year</label>
                        <Field
                            className="form-select"
                            name="mintDateUnknownYear"
                            component="select"
                            type="select">
                            <option></option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>                                        
                            <option value="2024">2024</option>
                        </Field>
                    </div>
                </div>
            </div>
            </>
        )
    }
}