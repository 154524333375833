import { useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import Pagination from '../../../pagination/Pagination';
import ReviewGiveawaysHeader from '../ReviewGiveawaysHeader';
import ReviewNav from '../../ReviewNav';

const updateStatus = async (id, status) => {
    try {
        const response = await axios.put(`/api/admin/raffles/${id}/update_status`, { status });
        return response.data;
    } catch (error) {
        console.error(error.response)
    }
}

const TableRow = ({ giveawayRowData }) => {

    const { id, nft_collection_id, nft_collection, quantity, start_date, start_time, end_date, end_time, entries_count, started } = giveawayRowData;

    return <tr>
        <td><Link to={`/collection/${nft_collection_id}`} style={{color:'#ffffff'}}>{nft_collection}</Link></td>
        <td>{quantity}</td>
        <td>{start_date}<br />{start_time}</td>
        <td>{end_date}<br />{end_time}</td>
        <td>{entries_count}</td>
        <td>{started === true ? 'Yes' : 'No'}</td>
        <td><Link to={`/admin/giveaways/edit/${id}`} style={{color:'#ffffff'}}>Edit</Link></td>
    </ tr>;
}

let PageSize = 50;
let filters = '';

const ReviewRaffles = ({ giveawaysData, accountType }) => {
    
    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);    

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return giveawaysData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, giveawaysData]);

    return (
        <>
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-2">
                <ReviewNav page="giveaways" accountType={accountType}/>
            </div>
            <div className="col-10">            
                <ReviewGiveawaysHeader page="upcoming" />
                <div className="row table-responsive text-nowrap" style={{ marginTop:"20px"}}>
                    <div className="col-12"></div>
                    <div className="col-12">
                    <table className="table" style={{ color:"#ffffff" }}>
                        <thead>
                            <tr style={{ backgroundColor:"#1F2731"}}>
                                <th scope="col">NFT</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Start ET</th>
                                <th scope="col">End ET</th>
                                <th scope="col">Entries</th>
                                <th scope="col">Started</th>
                                <th scope="col">Edit</th>                                
                            </tr>
                        </thead>
                        <tbody style={{ fontSize:".8em"}}>
                            {pageData.map(giveawayRowData => <TableRow key={giveawayRowData.id} giveawayRowData={giveawayRowData} />)}
                        </tbody>
                        <tfoot style={{ backgroundColor:"#1F2731", textAlign: "center"}}>
                            <tr>                     
                                <td colSpan="8">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={giveawaysData.length}
                                        pageSize={PageSize}
                                        filters={filters}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default ReviewRaffles;
