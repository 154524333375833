import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import WalletButton from '../WalletButton';
import WatchListButtons from '../Watchlist';
import AllowListButtons from '../Allowlist';

const updateVote = async (id, vote, access) => {
    if ( access !== 'public ') {
        try {
            const response = await axios.put(`/api/vote/${id}`, { vote });         
            return response.data.vote;
        } catch (error) {
            console.error(error.response);
        }
    }
    
    return '';
}



const fetchCounts = async (id, access) => {
    if ( access !== 'public ') {
        try {
            const counts = await axios.get(`/api/count-votes/${id}`);
            return counts.data;
        } catch (error) {
            console.error(error.resposne);
        }
    }
    return '';
}

const TwitterURL = ({ twitter, twitter_follower_count }) => {
    
    if (twitter === null || twitter === '' ) {
        return <>TBD</>;
    }
    else {
        const twitter_url = 'https://twitter.com/' + twitter + '?utm_source=futurenftmints';
        
        let twitter_follower_count_formatted = twitter_follower_count;

        if ( twitter_follower_count > 1000000 ) {
            const whole_number = Math.floor(twitter_follower_count / 1000000);            
            const remainder = Math.round(twitter_follower_count % 1000000 * 0.00001) / 10;
            twitter_follower_count_formatted = whole_number + remainder + 'M';
        }
        else if ( twitter_follower_count > 1000 ) {
            const whole_number = Math.floor(twitter_follower_count / 1000);
            const remainder = Math.round(twitter_follower_count % 1000 * 0.01) / 10;
            twitter_follower_count_formatted = whole_number + remainder + 'K';
        }

        return <>
            <Link to={{ pathname: twitter_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}><img alt="" src="/images/twitter.png" height="10" className="d-inline-block align-middle"/> {twitter_follower_count_formatted}</Link>
        </>
    }
}

const DiscordURL = ({ discord, discord_user_count }) => {
    
    if (discord === null || discord === '' ) {
        return <>TBD</>;
    }

    else {
        let discord_user_count_formatted = discord_user_count;

        if ( discord_user_count > 1000000 ) {
            const whole_number = Math.floor(discord_user_count / 1000000);            
            const remainder = Math.round(discord_user_count % 1000000 * 0.00001) / 10;
            discord_user_count_formatted = whole_number + remainder + 'M';
        }
        else if ( discord_user_count > 1000 ) {
            const whole_number = Math.floor(discord_user_count / 1000);
            const remainder = Math.round(discord_user_count % 1000 * 0.01) / 10;
            discord_user_count_formatted = whole_number + remainder + 'K';
        }
    
        const discord_url = 'https://discord.gg/' + discord + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: discord_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}><img alt="" src="/images/discord.png" height="10" className="d-inline-block align-middle"/> {discord_user_count_formatted}</Link>
        </>
    }
}

const WebsiteURL = ({ website }) => {
    
    if (website === null || website === '' ) {
        return <>TBD</>;
    }
    else {
        const website_url = website + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: website_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}><img alt="" src="/images/globe.png" height="10" className="d-inline-block align-middle"/> Link</Link>
        </>
    }
}

const SmartContractURL = ({ smartContract }) => {
    
    if (smartContract === null || smartContract === '' ) {
        return <>TBD</>;
    }
    else {
        const smartContract_url = smartContract + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: smartContract_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}><img alt="" src="/images/globe.png" height="10" className="d-inline-block align-middle"/> Link</Link>
        </>
    }
}

const InsightsReportURL = ({ insightsReport }) => {
    
    if (insightsReport === null || insightsReport === '' ) {
        return <>TBD</>;
    }
    else {
        const insightsReport_url = insightsReport + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: insightsReport_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}><img alt="" src="/images/globe.png" height="10" className="d-inline-block align-middle"/> Link</Link>
        </>
    }
}

const MarketplaceURL = ({ marketplace, marketplace_link }) => {
    if ( marketplace_link === null || marketplace_link === '' ) {
        return <>TBD</>
    }
    if ( marketplace === null || marketplace === '' ) {
        return <>TBD</>
    }
    else {
        const marketplace_url = marketplace_link + '?utm_source=futurenftmints';
        return <>
            <Link to={{ pathname: marketplace_url }} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}>{marketplace}</Link>
        </>
    }

}

const SmartContractInsightsReportMarketplace = ({ smartContract, insightsReport, marketplace, marketplace_link }) => {

    if ( (insightsReport !== null && insightsReport !== '') || (smartContract !== null && smartContract !== '') || (marketplace !== null && marketplace !== '' && marketplace_link !== null && marketplace_link !== '') ) {
        return <>
            <div className="row" style={{ marginTop:"20px"}}>
                <div className="col-4">
                    <div style={{fontSize:"0.8em", display:"inline"}}>
                        <strong>Marketplace</strong>
                    </div>
                    <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                        <MarketplaceURL marketplace={marketplace} marketplace_link={marketplace_link} />
                    </div>
                </div>
                <div className="col-4">
                    <div style={{fontSize:"0.8em", display:"inline"}}>
                         <strong>Smart Contract</strong>
                    </div>
                    <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                        <SmartContractURL smartContract={smartContract} />
                    </div>
                </div>
                <div className="col-4">
                    <div style={{fontSize:"0.8em", display:"inline"}}>
                        <strong>Insights Report</strong>
                    </div>
                    <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                        <InsightsReportURL insightsReport={insightsReport} />
                    </div>
                </div>                                                       
            </div>   
        </>
    }
}

const NFTCalendar = ({ calendarItem, access, registeredUsername }) => {

    const { id, username, nft_collection, collection_image, status, earliest_mint_date, earliest_mint_date_type, date_submitted, collection_supply, collection_description, twitter, twitter_follower_count, twitter_follower_count_update_ts, discord, discord_user_count, discord_user_count_update_ts, website, approved_by, approved_date, public_mint_price, blockchain, roadmap_category_1, roadmap_category_2, roadmap_category_3, roadmap_category_4, roadmap_category_5, roadmap_category_6, upvote_count, downvote_count, vote_percent, slug, smart_contract_url, insights_report_twitter_url, warning, warning_message, marketplace, marketplace_link, allow_list_mint_price } = calendarItem;

    let reviewed_by;
    let earliestMintDateConverted;
    const collectionPageURL = '/collection/' + slug;
    const approvedDate = new Date(approved_date);
    const approvedDateConverted = approvedDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });

    const [vote, setVote] = useState(null);
    const [upvote_count_update, setUpvoteCount] = useState(upvote_count);
    const [downvote_count_update, setDownvoteCount] = useState(downvote_count);
    const [vote_percent_update, setVotePercent] = useState(vote_percent);
    const [total_vote, setTotalVote] = useState(upvote_count + downvote_count);    

    

    //make sure that the user is taken to the top of the page each time it loads
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
      
    
    //console.log(`ID: ${id}\nVote: ${vote?.data}`);

    const ThumbsUpNeutral = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <button 
                    className="btn btn-sm btn-secondary"
                    onClick={async () => {
                        const newVote = await updateVote(id, 'up');
                        setVote(newVote);
                        const votesCount = await fetchCounts(id, access);                        
                        setUpvoteCount(votesCount.upvote_count);
                        setDownvoteCount(votesCount.downvote_count);
                        setVotePercent(votesCount.vote_percent);
                        setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                    }}
                >
                    <i className="bi bi-hand-thumbs-up"></i>
                </button>  
            </div>
            </>
        
    }

    const ThumbsUpSelected = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <button 
                    className="btn btn-sm btn-success"
                    onClick={async () => {
                        const newVote = await updateVote(id, 'neutral');
                        setVote(newVote);
                        const votesCount = await fetchCounts(id, access);
                        setUpvoteCount(votesCount.upvote_count);
                        setDownvoteCount(votesCount.downvote_count);
                        setVotePercent(votesCount.vote_percent);
                        setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                    }}
                >
                    <i className="bi bi-hand-thumbs-up"></i>
                </button>  
            </div>
            </>
        
    }

    const ThumbsDownNeutral = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <button 
                    className="btn btn-sm btn-secondary"
                    onClick={async () => {
                        const newVote = await updateVote(id, 'down');
                        setVote(newVote);
                        const votesCount = await fetchCounts(id, access);                        
                        setUpvoteCount(votesCount.upvote_count);
                        setDownvoteCount(votesCount.downvote_count);                        
                        setVotePercent(votesCount.vote_percent);
                        setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                    }}
                >
                    <i className="bi bi-hand-thumbs-down"></i>
                </button>  
            </div>
            </>
        
    }
    const ThumbsDownSelected = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <button 
                    className="btn btn-sm btn-danger"
                    onClick={async () => {
                        const newVote = await updateVote(id, 'neutral');
                        setVote(newVote);
                        const votesCount = await fetchCounts(id, access);
                        setUpvoteCount(votesCount.upvote_count);
                        setDownvoteCount(votesCount.downvote_count);
                        setVotePercent(votesCount.vote_percent);
                        setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                    }}
                >
                    <i className="bi bi-hand-thumbs-down"></i>
                </button>  
            </div>
            </>
        
    }
    const ThumbsUpSignup = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <Link to="/signup">
                    <button className="btn btn-sm btn-secondary">
                        <i className="bi bi-hand-thumbs-up"></i>
                    </button>  
                </Link>
            </div>
        </> 
    }
    const ThumbsDownSignup = () => {
    
        return <>
            <div className="col-6" style={{padding:"0rem"}}>
                <Link to="/signup">
                    <button className="btn btn-sm btn-secondary">
                        <i className="bi bi-hand-thumbs-down"></i>
                    </button>  
                </Link>
            </div>
        </> 
    }

    const renderThumbs = ( access ) => {
        //console.log(vote);
        if ( access !== 'public' ) {
            switch(vote) {
                case 'up': 
                    return <><ThumbsUpSelected /><ThumbsDownNeutral /></>;
                case 'down':
                    return <><ThumbsUpNeutral /><ThumbsDownSelected /></>;
                default:
                    return <><ThumbsUpNeutral /><ThumbsDownNeutral /></>;
            }
        }
        else {
            return <>                                           
                <ThumbsUpSignup /><ThumbsDownSignup />
            </>;
        }
    }

    
    
    if ( status === 'approved' ) {
        reviewed_by = `Reviewed by ${approved_by} (${approvedDateConverted}).`;        
    }
    else {
        reviewed_by = 'NOT REVIEWED.';        
    }

    let vote_percent_render = '-';

    if ( vote_percent_update != null ) {
        vote_percent_render = `${vote_percent_update}%`;
    }    

    const dateSubmitted = new Date(date_submitted);
    const dateSubmittedConverted = dateSubmitted.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    //const dateSubmittedConverted = dateSubmitted.toISOString();

    const earliestMintDate = new Date(earliest_mint_date);      
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const threeDaysAhead = new Date();
    threeDaysAhead.setHours(0, 0, 0, 0);
    threeDaysAhead.setDate( threeDaysAhead.getDate() + 4);

    const fiveDaysAhead = new Date();
    fiveDaysAhead.setHours(0, 0, 0, 0);
    fiveDaysAhead.setDate( fiveDaysAhead.getDate() + 6);

    //console.log( 'today: ', today, threeDaysAhead, fiveDaysAhead);

    if ( earliestMintDate > new Date('1984-01-01') ) {
        if ( earliest_mint_date_type === 'TBD' && earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', month: 'short' }) === 'Dec' ) {
            earliestMintDateConverted = 'TBD';
        }
        else if ( earliest_mint_date_type === 'TBD' && earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric' }) === today.toLocaleDateString('en-US', {timeZone: 'UTC', year: 'numeric' }) ) {
            earliestMintDateConverted = earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', month: 'short' });
        }
        else if ( earliest_mint_date_type === 'TBD' ) {
            earliestMintDateConverted = earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short' });
        }
        else if ( earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric' }) === today.toLocaleDateString('en-US', {timeZone: 'UTC', year: 'numeric' }) ) {
            earliestMintDateConverted = earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', month: 'short', day: 'numeric' });
        }
        else {
            earliestMintDateConverted = earliestMintDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });
        }
    }
    else {
        earliestMintDateConverted = 'No Future Date'
    }
    
    const twelveHoursEarlier = new Date();
    twelveHoursEarlier.setHours( twelveHoursEarlier.getHours() - 12 );     

    const twitter_ts = new Date(twitter_follower_count_update_ts);
    const discord_ts = new Date(discord_user_count_update_ts);

    if ( ( twitter !== null && twitter !== '' && twitter_ts === null ) 
            || ( twitter !== null && twitter !== '' && twitter_ts !== null && twitter_ts < twelveHoursEarlier ) ) {
        //console.log(`twitter: ${twitter}`);
        axios.get(`/api/twitter/${twitter}/${id}`);

    }
    
    
    if ( ( discord !== null && discord !== '' && discord_ts === null ) 
            || ( discord !== null && discord !== '' && discord_ts !== null && discord_ts < twelveHoursEarlier ) ) {       
        //console.log(`discord: ${discord}`);
        axios.get(`/api/discord/${discord}/${id}`);

    }    

    let publicMintPrice = '';
    let allowListMintPrice = '';
    if ( public_mint_price != '' && public_mint_price != null ) {
        publicMintPrice = public_mint_price + ' ' + ( blockchain === null ? '' : blockchain );
    }
    else if ( allow_list_mint_price != '' && allow_list_mint_price != null ) {
        allowListMintPrice = allow_list_mint_price + ' ' + ( blockchain === null ? '' : blockchain );
    }

    /*
    if ( earliestMintDate > fiveDaysAhead && access=== 'free' && username != registeredUsername ) {
        return null;
    }

    if ( earliestMintDate > threeDaysAhead && access=== 'public' ) {
        return null;
    }
    */
    const renderWarningMessage = ( warning, warning_message ) => {
        
        if ( warning === 'Caution' ) {
            return <>
            
                <div className="col-12 rounded py-2 px-3" style={{ backgroundColor:"rgba(31, 39, 49, 0.8)"}} >
                    <div className="row" style={{fontSize:"0.8em"}}>
                        <div className="col-1 align-middle">
                            <img alt="" src="/images/cautionSymbol.png" style={{width:"30px"}} />
                        </div>
                        <div className="col-11 px-4">
                            CAUTION. Raised some warnings. Make sure you research diligently.                            
                        </div>                    
                    </div>
                </div>
            
            </>
        }   
        else if ( warning === 'Avoid' ) {
            return <>
            
                <div className="col-12 rounded py-2 px-3" style={{ backgroundColor:"rgba(31, 39, 49, 0.8)"}} >
                    <div className="row" style={{fontSize:"0.8em"}}>
                        <div className="col-1 align-middle">
                            <img alt="" src="/images/avoidSymbol.png" style={{width:"30px"}} />
                        </div>
                        <div className="col-11 px-4">
                            AVOID. Reports of a scam, rug, or worse.                            
                        </div>                    
                    </div>
                </div>
            
            </>
        }     
    }

    return (
        <div className="row" style={{ marginTop:"30px"}}>            
            <div className="col-12 rounded" style={{backgroundColor: "#1F2731", padding: "10px" }}>
                <div className="row">
                    { warning !== 'None' ? renderWarningMessage( warning, warning_message) : null}
                    <div className="col-sm-3 col-12" style={{ marginBottom:"10px"}}>
                        <div>
                            <Link to={{ pathname: collectionPageURL}} style={{ color:"#ffffff"}}>
                                <img alt="" src={collection_image}  className="d-inline-block align-top rounded img-fluid" />  
                            </Link>
                        </div>                        
                        
                    </div>                
                    <div className="col-sm-7 col-8">
                        <div className="row">
                            <div style={{display:"inline", fontSize:"1.2em"}}>
                                
                                <Link to={{ pathname: collectionPageURL}} style={{ color:"#ffffff"}}><strong>{nft_collection}</strong></Link>  
                                    
                            </div>
                        </div>                        
                        <div className="row">
                            <div style={{fontSize:"0.5em", display:"inline"}}>Submitted by {username} ({dateSubmittedConverted}). {reviewed_by}</div>
                        </div>
                        <div className="row">
                            <div>
                                <div className="badge" style={{backgroundColor:"#E5EAFA", fontSize:"0.6em", fontWeight:"1em", marginRight:"5px", color:"#000000"}}>
                                    {roadmap_category_1}
                                </div>
                                <div className="badge" style={{backgroundColor:"#E5EAFA", fontSize:"0.6em", fontWeight:"1em", marginRight:"5px", color:"#000000"}}>
                                    {roadmap_category_2}
                                </div>
                                <div className="badge" style={{backgroundColor:"#E5EAFA", fontSize:"0.6em", fontWeight:"1em", marginRight:"5px", color:"#000000"}}>
                                    {roadmap_category_3}
                                </div>
                                <div className="badge" style={{backgroundColor:"#E5EAFA", fontSize:"0.6em", fontWeight:"1em", marginRight:"5px", color:"#000000"}}>
                                    {roadmap_category_4}
                                </div>
                                <div className="badge" style={{backgroundColor:"#E5EAFA", fontSize:"0.6em", fontWeight:"1em", marginRight:"5px", color:"#000000"}}>
                                    {roadmap_category_5}
                                </div>
                                <div className="badge" style={{backgroundColor:"#E5EAFA", fontSize:"0.6em", fontWeight:"1em", marginRight:"5px", color:"#000000"}}>
                                    {roadmap_category_6}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{fontSize:"1em", marginTop:"10px"}}>
                            <div>{collection_description}</div>
                        </div>
                        <div className="row" style={{ marginTop:"5px"}}>
                            <div className="col-lg-2 col-4">
                                <div style={{fontSize:"0.8em", display:"inline"}}>
                                    <strong>Blockchain</strong>
                                </div>
                                <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                    {blockchain}
                                </div>
                            </div>
                            <div className="col-lg-2 col-4">
                                <div style={{fontSize:"0.8em", display:"inline"}}>
                                    <strong>{allowListMintPrice !== '' ? <>AL Price</> : <>Public</>}</strong>
                                </div>
                                <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                    {allowListMintPrice !== '' ? allowListMintPrice : publicMintPrice}
                                </div>
                            </div>
                            <div className="col-lg-2 col-4">
                                <div style={{fontSize:"0.8em", display:"inline"}}>
                                    <strong>Supply</strong>
                                </div>
                                <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                    {collection_supply}
                                </div>
                            </div>
                            <div className="col-lg-2 col-4">
                                <div style={{fontSize:"0.8em", display:"inline"}}>
                                    <strong>Twitter</strong>
                                </div>
                                <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                    <TwitterURL twitter={twitter} twitter_follower_count={twitter_follower_count} />
                                </div>
                            </div>
                            <div className="col-lg-2 col-4">
                                <div style={{fontSize:"0.8em", display:"inline"}}>
                                    <strong>Discord</strong>
                                </div>
                                <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                    <DiscordURL discord={discord} discord_user_count={discord_user_count} />
                                </div>
                            </div>
                            <div className="col-lg-2 col-4">
                                <div style={{fontSize:"0.8em", display:"inline"}}>
                                    <strong>Website</strong>
                                </div>
                                <div style={{ marginTop:"5px", fontSize:"0.7em"}}>
                                <WebsiteURL website={website} />
                                </div>
                            </div>
                        </div>
                        <SmartContractInsightsReportMarketplace smartContract={smart_contract_url} insightsReport={insights_report_twitter_url} marketplace={marketplace} marketplace_link={marketplace_link} /> 
                    </div>
                    <div className="col-sm-2 col-4">
                        <div className="row text-center">
                            <div className="rounded w-75 h-25" style={{backgroundColor:"#5F1951", color:"#ffffff", textAlign:"center", fontSize:"1em", marginLeft:"12.5%"}}>
                                {earliestMintDateConverted} 
                                <div style={{fontSize:"0.5em"}}>{earliest_mint_date_type}</div>
                            </div>
                        </div>                        
                        <div className="row text-center" style={{marginTop:"20px", padding:"2px"}}>                            
                            <div className="col-12" style={{padding:"0rem"}}>
                                <div style={{fontSize:"2rem", margin:"auto"}}>
                                    {vote_percent_render}
                                </div>
                                <div style={{fontSize:"0.6rem", margin:"auto"}}>
                                    {total_vote} Votes
                                </div>
                            </div>
                        </div>
                        <div className="row text-center" style={{marginTop:"10px", padding:"2px"}}>                            
                            {renderThumbs( access )}
                        </div>
                        <div className="row text-center" style={{padding:"2px"}}>                            
                            <div className="col-6" style={{padding:"0rem"}}>                                
                                <span style={{fontSize:"0.6em"}}>{upvote_count_update}</span>                                                                                           
                            </div>                                                        
                            <div className="col-6" style={{padding:"0rem"}}>                                                               
                                <span style={{fontSize:"0.6em"}}>{downvote_count_update}</span>                                
                            </div>                                                        
                        </div>
                        <div className="mt-2">
                            <WatchListButtons id={id} access={access} />
                        </div>
                        <div className="mt-2">
                            <AllowListButtons id={id} access={access} /> 
                        </div>
                    </div>
                </div>                
            </div>            
        </div>
        
    );
    
    

};

export default NFTCalendar;
