import React, { useState, Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../actions/index';

import RenderField from '../addNFT/RenderField';
import QuickAddNFTHeader from './QuickAddNFTHeader';


const QuickAddNFTForm = ({ tokenContractAddress, onCollectionSubmit, initialValues, handleSubmit, pristine, error, reset, submitting, withRouter, accountType, marketplaces }) => {    

    return (
        <>
        <QuickAddNFTHeader />
        <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-12">
                {error && <span className="text-danger">{error}</span>}
            </div>
        </div>
        <div className="row" >
            <div className="col-lg-1"> </div>
            <div className="col-lg-10 col-12">
                {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
                <form onSubmit={handleSubmit( onCollectionSubmit )} style={{ paddingBottom: "50px" }}>                                    

                    <div className="row pt-5" >                        

                        <div className="col-12">
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="collectionName"
                                        component={RenderField}
                                        type="text"
                                        placeholder="Enter the NFT collection name"
                                        label="NFT Collection Name (Required)"
                                    />
                                </div>
                            </div>
                                                                                  
                        </div>

                    </div>                                                                                                    
                    

                    <div className="row" style={{ marginTop: "30px", marginBottom: "30px" }}>
                        <div className="col-5">
                            <button className="btn" style={{ color:"#ffffff", backgroundColor: "#7945E1", border: "#7945E1", width: "100%", height: "50px" }} disabled={submitting}>
                                Submit
                            </button>
                        </div>
                        <div className="col-2 text-center">OR</div>
                        <div className="col-5">
                            <Link to="/add/nft">
                                <button className="btn btn-light" style={{ width: "100%", height:"50px" }} >
                                    Add more details
                                </button>
                            </Link>
                        </div>
                    </div>                    
                    
                </form>
            </div>
        </div>
        <div style={{height:"300px"}}>

        </div>
        </>
    );
};


const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}
    
    if (!values.collectionName) {
        errors.collectionName = 'Required'
    }
    else if (values.collectionName) {
        if (values.collectionName.length > 250) {
         errors.collectionName = 'Must be 250 characters or less'
        }
    }    

    // console.log('errors', errors)
    return errors
}


function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'quickAddNFTForm',
    validate,
})(QuickAddNFTForm));

