import { initial } from 'lodash';
import React from 'react';
import Select from 'react-select';

const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#1F2731",
      // Overwrittes the different states of border
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Removes border around container
      boxShadow: state.isFocused ? null : null
    }),
    menu: base => ({
      ...base,
      background: "#1F2731",
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    }),
    option: (styles, { isFocused, isSelected, isDisabled }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
          ? undefined
          : isFocused
          ? "blue"
          : undefined
        };
      }
  };

export default (props) => ( 
  <Select
    {...props}
    name={props.fieldName}
    id={props.id}
    styles={customStyles}
    closeMenuOnSelect={false}
    defaultValue={props.initialFilter}
    value={props.options.filter(item => item.value === props.input.value)[0]}
    options={props.options}
    onChange={(value) => props.input.onChange(value)}
    onBlur={event => event.preventDefault()}
  />
); 