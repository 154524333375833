import { useState, useEffect } from 'react';
import axios from 'axios';

const useWalletLinkStatus = (account) => {
    
    const [walletLinkedStatus, setWalletLinkedStatus] = useState('');
    const [userData, setUserData] = useState('');

    /*
    Determines if the input wallet is linked 
    */

    useEffect(() => {
        const fetchLinkWalletStatus = async (account) => {
            const response = await axios.get(`/api/get/linked-wallet/status/${account}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setWalletLinkedStatus(response.data.status)            
        }
        const fetchUserData = async (account) => {
            const response = await axios.get(`/api/get/linked-wallet/user-data/${account}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setUserData(response.data);            
        }
        if (account) fetchLinkWalletStatus(account) // avoid querying an account's tokens if there is no account present
        if (walletLinkedStatus === 'confirmed' || walletLinkedStatus === 'pending') fetchUserData(account);
    }, [account, walletLinkedStatus])
    
 
    return { walletLinkedStatus, userData }
}

export default useWalletLinkStatus
