import { useState, useMemo, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import axios from 'axios';
import WalletButton from '../WalletButton';

const NFTs = () => {        
    
    return (
        <>  
            <div className="row" style={{ marginTop:"20px" }}>
                <div className="col-2"></div>
                <div className="col-md-3 col-12">                    
                    <div className="card mx-auto border-secondary" style={{width: "18rem", marginBottom:"20px", zIndex:"0"}}>
                        <img className="card-img-top" src="/images/futuremints-annualpass.png" alt="Card image cap" />
                        <div className="card-body" style={{backgroundColor:"#280B22", color:"rgba(255,255,255,.8)", fontSize:"0.8em", height:"250px"}}>
                            <h5 className="card-title">Annual Pass NFT</h5>
                            <p className="card-text">
                                0.05 ETH
                                <br />550 Supply
                            </p>
                            <p className="card-text">
                                <span style={{textDecoration:"underline"}}>Thru Dec 2024</span>
                                <br />&#10003; Premium Tier access
                            </p>
                            <p className="card-text">
                                <span style={{textDecoration:"underline"}}>Lifetime</span>
                                <br />&#10003; 2 Giveaway Entries PER DAY
                            </p>                                                       
                        </div>
                        <div className="card-footer text-muted" style={{padding: "10px", textAlign:"center", backgroundColor:"#280B22"}}>                            
                            <span style={{fontSize:"0.8em", color:"#ffffff"}}>
                                &nbsp;&nbsp;
                            </span>
                            <br />
                            <Link to="/mint-annual-pass" style={{color:"#ffffff"}}>
                                <button className="btn btn-lg" style={{backgroundColor: "#F83700", border: "#F83700", color:"#ffffff"}}>
                                    Mint now
                                </button>    
                            </Link>                                         
                        </div>   
                    </div>
                </div>
                <div className="col-2"></div>
                <div className="col-md-3 col-12">                    
                    <div className="card mx-auto border-secondary" style={{width: "18rem", marginBottom:"20px", zIndex:"0"}}>
                        <img className="card-img-top" src="/images/fnftm-genesis.png" alt="Card image cap" />
                        <div className="card-body" style={{backgroundColor:"#280B22", color:"rgba(255,255,255,.8)", fontSize:"0.8em", height:"250px"}}>
                            <h5 className="card-title">Genesis NFT</h5>
                            <p className="card-text">
                                0.25 ETH
                                <br />500 Supply
                            </p>
                            <p className="card-text">
                                <span style={{textDecoration:"underline"}}>Lifetime</span>
                                <br />&#10003; Premium Tier access
                                <br />&#10003; 12 Giveaway Entries PER DAY                                
                            </p>                                                        
                        </div>
                        <div className="card-footer text-muted" style={{padding: "10px", textAlign:"center", backgroundColor:"#280B22"}}>
                            <span style={{fontSize:"0.8em", color:"#ffffff"}}>
                                &nbsp;&nbsp;
                            </span>
                            <br />
                            <Link to="/mint-genesis">
                                <button className="btn btn-lg" style={{backgroundColor: "#F83700", border: "#F83700", color:"#ffffff"}}>
                                    Mint now
                                </button>   
                            </Link>                                         
                        </div>   
                    </div>
                </div>
                <div className="col-2"></div>                
            </div>
        </>
    )
};

export default NFTs;