// RenderField contains logic to render a single label and text input
import React from 'react';

//{input} is the same as props.input
export default ({ input, className, placeholder, type, value1, label, onKeyDown, meta: { touched, error, warning } } ) => {    

        return (
        <div>
            <label style={{ color: "rgba(255,255,255,0.8)", marginBottom: "5px" }}>{label}</label>
            <div>                
                <input {...input} 
                    style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8", width:"250px"}} 
                    className={className} 
                    placeholder={placeholder} 
                    type={type} 
                    value={value1}        
                    onKeyDown={onKeyDown}                            
                />
            </div>
        </div>
        )
};