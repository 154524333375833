import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import useTokenGatedAccess from '../../../hooks/useTokenGatedAccess';
import useUnmatchedNFTs from '../../../hooks/useUnmatchedNFTs';

// components
import PleaseConnectWallet from '../../access/PleaseConnectWallet';
import PleaseWait from '../../access/PleaseWait';
import PleaseSignIn from '../../access/PleaseSignIn';
import PleaseRegister from '../../access/PleaseRegister';
import AccessDenied from '../../access/AccessDenied';
import SomethingWentWrong from '../../access/SomethingWentWrong';
import OpenseaReview from './OpenseaReview';

const AdminReview = ({ auth, wallet, registeredUser }) => {

    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determine if this account has access
    const { access, _walletAccessTokens } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars

    const { unmatchedNFTs } = useUnmatchedNFTs();  
    const [openseaData, setOpenseaData] = useState([]);   

    useEffect(() => {
        const fetchOpenseaDataInPlatform = async () => {
            const response = await axios.get("/api/admin/opensea", {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setOpenseaData(response.data[0]);
        }
        if (access && auth) {
            fetchOpenseaDataInPlatform()
        }
    }, [access, auth])

    const renderOpenseaReview = () => {
        if (!account) return <PleaseConnectWallet />;

        switch(access) {
            case null:
                return <PleaseWait />;
            case false:
                return <AccessDenied />;
            case true:
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <PleaseRegister />; // when the PleaseSignIn component renders, the user will be prompted to sign a message
                if (auth.account_type === 'user' || auth.account_type === 'guest' ) return <Redirect to="/dashboard/home" />;
                if (registeredUser.username) { 
                    return <OpenseaReview openseaData={openseaData} unmatchedNFTs={unmatchedNFTs} accountType={auth.account_type} />;
                }
                else { return <PleaseRegister />  };
            default:
                return <SomethingWentWrong />;
        }
    }

    return (
        <div>            
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderOpenseaReview() }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser }) {
    return { auth, wallet, registeredUser };
}

export default connect(mapStateToProps)(AdminReview);
