import React, { useState } from 'react';
import RenderFormField from '../../../collectionPage/RenderFormField';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../../../actions/index';

import AutocompleteGeneral from "../../../autocomplete/AutocompleteGeneral";
import { initial } from 'lodash';
require("../../../autocomplete/autocompleteStyles.css");

const GiveawayEditForm = ({ onGiveawayAddFormSubmit, initialValues, handleSubmit, error, submitting }) => {

    const autocompleteFieldName = 'giveawayNFT';
    const autocompleteHiddenFieldName = 'giveawayNFTId';
    
    const submitForm = () => {
        return (
            handleSubmit( values => onGiveawayAddFormSubmit({
                ...values
            }) )
        )
    }
    
    return (<>
        {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
        <div className="row table-responsive text-nowrap my-3" style={{fontSize:"1em"}}>                
            <div className="col-12">
                <form onSubmit={submitForm()}>                                        
                    
                    <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-lg-8 col-12">
                            <label style={{ color: "#ffffff", marginBottom: "5px" }}>NFT</label>
                            <Field
                                className="form-control"
                                name={autocompleteFieldName}
                                fieldName={autocompleteFieldName}
                                hiddenField={autocompleteHiddenFieldName}
                                endpoint="/api/get/autocomplete/search?searchTerm="
                                component={RenderFormField}
                                placeholder="NFT"      
                                initialValue={initialValues?.giveawayNFT !== undefined ? initialValues.giveawayNFT : ''}                                      
                                label="NFT"
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-lg-8 col-12">
                            <label style={{ color: "#ffffff", marginBottom: "5px" }}>Quantity</label>
                            <Field
                                className="form-control"
                                name="quantity"
                                component={RenderFormField}
                                type="number"
                                placeholder="Quantity"
                                label="Quantity"
                            />
                        </div>
                    </div>
                    
                    <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-lg-8 col-12">
                            <label style={{ color: "#ffffff", marginBottom: "5px" }}>Start Date</label>
                            <Field
                                className="form-control"
                                name="startDate"
                                component={RenderFormField}
                                type="date"
                                placeholder="mm/dd/yyyy"
                                label="Start Date" 
                            />
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-lg-8 col-12">
                            <label style={{ color: "#ffffff", marginBottom: "5px" }}>Start Time (ET)</label>
                            <Field
                                className="form-control"
                                name="startTime"
                                component={RenderFormField}
                                type="time"
                                placeholder="00:00 AM"
                                label="Start Time (ET)" 
                            />
                        </div>
                    </div>

                    <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-lg-8 col-12">
                            <label style={{ color: "#ffffff", marginBottom: "5px" }}>End Date</label>
                            <Field
                                className="form-control"
                                name="endDate"
                                component={RenderFormField}
                                type="date"
                                placeholder="mm/dd/yyyy"
                                label="End Date" 
                            />
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-lg-8 col-12">
                            <label style={{ color: "#ffffff", marginBottom: "5px" }}>End Time (ET)</label>
                            <Field
                                className="form-control"
                                name="endTime"
                                component={RenderFormField}
                                type="time"
                                placeholder="00:00 AM"
                                label="End Time (ET)" 
                            />
                        </div>
                    </div>      
                    <div className="row" style={{marginTop:"30px"}}>
                        <div className="col-lg-8 col-12">
                            <button className="btn btn" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff", width:"100%" }} disabled={submitting}>
                                Update
                            </button>  
                        </div>              
                    </div>                        
                </form>
            </div>
        </div>
    
    </>
    );
}

const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}
    
    if (values.quantity) {
        
        if (! ( values.quantity > 0 ) || ( typeof values.quantity != 'number' && values.quantity.search(/[.]/) > -1 ) ) {
         errors.quantity = 'Must be a whole number'
        }

        if (values.quantity.length > 10) {
            errors.quantity = 'Too long for a number'
        }
    }
    

    // console.log('errors', errors)
    return errors
}

function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'giveawayEditForm',
    validate,
})(GiveawayEditForm));