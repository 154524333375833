import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';


const WatchlistButtons = ({ id, access }) => {
    
    const [watching, setWatching] = useState(false);

    useEffect(() => {
        const fetchWatching = async () => {
            const response = await axios.get(`/api/fetch-watching/${id}`);
            setWatching(response?.data);
        }
        if ( access !== 'public' ) {
            fetchWatching();
        }
    }, [id, access])

    const updateWatching = async (id, watching, access) => {
        if ( access !== 'public ') {
            try {
                const response = await axios.put(`/api/watching/${id}`, { watching });         
                return response.data.watching;
            } catch (error) {
                console.error(error.response);
            }
        }
        
        return '';
    }

    //console.log(watching);
    if ( access !== 'public' ) {
        if ( watching ) {
            return <>
                <button 
                    className="btn btn-sm btn-success"
                    style={{width:"100%"}}
                    onClick={async () => {
                        const newWatching = await updateWatching(id, !watching);
                        setWatching(newWatching);                            
                    }}
                >
                    <i className="bi bi-check-square-fill"></i> Watching
                </button>                 
            </>;            
        }
        else {
            return <>
                <button 
                    className="btn btn-sm btn-secondary"
                    style={{width:"100%"}}
                    onClick={async () => {
                        const newWatching = await updateWatching(id, !watching);
                        setWatching(newWatching);    
                    }}
                >
                    Watch
                </button> 
            </>;   
        }
    }
    else {
        return <>
            <Link to="/signup">
                <button 
                    className="btn btn-sm btn-secondary"
                    style={{width:"100%"}}                
                >
                    Watch
                </button> 
            </Link>
        </>;   
    }
}

export default WatchlistButtons;