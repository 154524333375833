import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';

const RenderMobileBadges = ({accountType}) => {        
    
    const NavLinkReview = () => {
        return <>
            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <Link to="/admin/review" className="nav-link" style={{ color: "#ffffff", verticalAlign: "baseline" }} activeStyle={{ backgroundColor: "transparent"}}>
                    Review
                </Link>    
            </Navbar.Toggle>
            
        </>;
    }

    const BadgeAccountType = ({ accountType }) => {
        if ( accountType === 'Free' || accountType === 'Public' ) {
            return <>
                    <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                        <Link to="/signup" className="nav-link" style={{color:"#ffffff", textDecoration:"underline", padding:"4px 16px"}} activeStyle={{ backgroundColor: "transparent"}}>                        
                            <span className="badge" style={{backgroundColor:"#7945E1"}}>{accountType}</span>                    
                        </Link>
                    </Navbar.Toggle>
                </>;
        }
        else {
            return <>
                <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                    <span className="nav-link">
                        <span className="badge" style={{backgroundColor:"#7945E1", padding:"4px 16px", marginTop:"10px"}} activeStyle={{ backgroundColor: "transparent"}}>
                            {accountType}
                        </span>
                    </span>
                </Navbar.Toggle>
                </>;
        }
    }
    
    switch(accountType) {
        case null:
            return;
        case 'researcher':
            return <>
            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <BadgeAccountType accountType={accountType} />
            </Navbar.Toggle>  

            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <NavLinkReview />
            </Navbar.Toggle>              
            </>;
        case 'admin':
            //console.log(this.props.auth)
            //console.log(this.props.registeredUser)
            return <>
            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <BadgeAccountType accountType={accountType} />
            </Navbar.Toggle>

            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <NavLinkReview />
            </Navbar.Toggle>                        
            </>;
        case 'owner':
            return <>
            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <BadgeAccountType accountType={accountType} />
            </Navbar.Toggle>

            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <NavLinkReview />
            </Navbar.Toggle>
            </>   ;
        case 'free':
            return <>   
            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <BadgeAccountType accountType="Free" />
            </Navbar.Toggle>
            </>   ;
        case 'guest':
            return <>    
            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <BadgeAccountType accountType="Premium" />
            </Navbar.Toggle>
            </>   ;
        case 'user':
            return <>
            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <BadgeAccountType accountType="Premium" />
            </Navbar.Toggle>
            </>   ;
        case 'top 100':
            return <>
            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <BadgeAccountType accountType="Premium - Top 100" />
            </Navbar.Toggle>
            </>   ;
        default:
            return <>
            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <BadgeAccountType accountType="Public" />
            </Navbar.Toggle>
            </>;
    }
} 

export default RenderMobileBadges;