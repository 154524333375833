import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setWalletProvider, setWalletAccount, setWalletSigner, setWalletNetwork } from '../actions';

import { shortenAddress } from "@usedapp/core";
import { ethers } from "ethers";
import web3Modal from "../utils/web3Modal";

import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from 'react-router-bootstrap';

require("./style/navDropdown.css");

function WalletButton({ setWalletProvider, setWalletAccount, setWalletSigner, setWalletNetwork, size, btnColor, rightOfButton, accountType, username, buttonCopy, margin, registerLink }) {
    const [instance, setInstance] = useState(); // eslint-disable-line no-unused-vars
    const [provider, setProvider] = useState();
    const [signer, setSigner] = useState();
    const [account, setAccount] = useState("");
    const [network, setNetwork] = useState();

    const [ens, setEns] = useState("");
    const [ensOrAddress, setEnsOrAddress] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        const lookupEns = async () => {
            try {
                if (account && provider) {
                    const ens = await provider?.lookupAddress(account)
                    setEns(ens)
                }
            } catch (error) {
                console.error('Caught erorr in lookupEns')
                setError(error)
            }
        }
        lookupEns()
    }, [account, provider])

    useEffect(() => {
        if (ens) {
            setEnsOrAddress(ens);
        } else if (account) {
            setEnsOrAddress(shortenAddress(account));
        } else {
            setEnsOrAddress("");
        }
    }, [account, ens, setEnsOrAddress]);

    useEffect(() => {
        if (error) {
            console.error("Error while connecting wallet:", error.message);
        }
    }, [error]);

    // add provider to the wallet object of the store
    useEffect(() => {
        if (provider) setWalletProvider({ provider })
    }, [provider, setWalletProvider])

    // add account to the wallet object of the store
    useEffect(() => {
        if (account) setWalletAccount({ account })
    }, [account, setWalletAccount])

    // add signer to the wallet object of the store
    useEffect(() => {
        if (signer) setWalletSigner({ signer })
    }, [signer, setWalletSigner])
    
    // add network to the wallet object of the store
    useEffect(() => {
        if (network) setWalletNetwork({ network })
    }, [network, setWalletNetwork])

    useEffect(() => {
        if (web3Modal.cachedProvider) {
            connectWallet();
        }
    }, []);

    const connectWallet = async () => {
        try {
            const instance = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(instance);
            const signer = provider.getSigner();
            const accounts = await provider.listAccounts();
            const network = await provider.getNetwork();
            setInstance(instance);
            setSigner(signer);
            setProvider(provider);
            if (accounts) {
                setAccount(accounts[0]);
            }
            setNetwork(network);
        } catch (error) {
            console.error("Caught error in connectWallet")
            setError(error)
        }
    }

    
    const disconnectWallet = async () => {
        
        try {
            await web3Modal.clearCachedProvider();
            setInstance()
            setProvider()
            setSigner()
            setAccount("")
            setEnsOrAddress("")
        } catch (error) {
            console.error("Caught error in disconnectWallet:", error)
            setError(error)
        }
    }
    

    const connectOrDisconnectWallet = async () => {
        if (!ensOrAddress) {
            connectWallet()
        } else {
            //disconnectWallet();
        }
    }

    let buttonSize = "btn btn-sm";    
    let buttonStyle = { backgroundColor: "#F83700", border: "#F83700", color:"#ffffff" };
    let color;
    let paddingDesktop = "0px 16px";
    let paddingMobile = "8px 16px";
    
    if ( btnColor === undefined ) {
        btnColor = "#F83700";
        color = "#ffffff";
    }
    else {
        color = "#F83700";
    }

    if (size === 'sm' ) {
        if (margin !== 'Left') { 
            buttonSize += " mx-auto";             
        }        
        else {
            paddingDesktop = "0px 0px";
            paddingMobile = "8px 0px";
        }
        buttonStyle = { backgroundColor: btnColor, border: "#F83700", color, fontSize: "0.6em" };
    }
    else if (size === 'lg' ) {
        if (margin !== 'Left') { 
            buttonSize = "btn btn-lg mx-auto";             
        }
        else { 
            buttonSize = "btn btn-lg"; 
            paddingDesktop = "0px 0px";
            paddingMobile = "8px 0px";
        }
        buttonStyle = { backgroundColor: btnColor, border: "#F83700", color, fontSize: "1em" };
    }

    if ( btnColor === undefined ) {
        btnColor = '';
    }    

    const upgradeDesktop = () => {
        return <>
            <LinkContainer to="/signup" style={{color:"#ffffff", textDecoration:"none"}} activeStyle={{ backgroundColor: "transparent"}}>
                <NavDropdown.Item>                            
                        Upgrade
                </NavDropdown.Item>
            </LinkContainer>
            
            
                            
            <NavDropdown.Divider />
        </>;
    }

    const NavLinkReview = () => {
        return <>
            <LinkContainer to="/admin/review" style={{ color: "#ffffff", verticalAlign: "baseline" }} activeStyle={{ backgroundColor: "transparent"}}>
                <NavDropdown.Item>
                    Review
                </NavDropdown.Item>
            </LinkContainer>    
        </>;
    }
    
    const BadgeAccountType = ({ accountType }) => {
        if ( accountType === 'Free' || accountType === 'Public' ) {
            return <>
                    <LinkContainer to="/signup" className="nav-link" style={{color:"#ffffff", textDecoration:"underline", padding:"4px 16px"}} activeStyle={{ backgroundColor: "transparent"}}>
                        <NavDropdown.Item>
                            <span className="badge" style={{backgroundColor:"#7945E1"}}>{accountType}</span>
                        </NavDropdown.Item>
                    </LinkContainer>
                </>;
        }
        else {
            return <>
                <NavDropdown.Item>
                    <span className="badge" style={{backgroundColor:"#7945E1"}} activeStyle={{ backgroundColor: "transparent"}}>{accountType}</span>
                </NavDropdown.Item>
                </>;
        }
    }

    const renderBadges = (accountType) => {        
        //console.log(`account type: ${accountType}`);
        //console.log(this.props.registeredUser);
        //console.log(this.props.auth);
        switch(accountType) {
            case null:
                return;
            case 'researcher':
                return <>
                <NavLinkReview />
                <BadgeAccountType accountType={accountType} />
                </>;
            case 'admin':
                //console.log(this.props.auth)
                //console.log(this.props.registeredUser)
                return <>
                <NavLinkReview />
                <BadgeAccountType accountType={accountType} />
                </>;
            case 'owner':
                return <>
                <NavLinkReview />
                <BadgeAccountType accountType={accountType} />
                </>   ;
            case 'free':
                return <>                
                <BadgeAccountType accountType="Free" />
                </>   ;
            case 'guest':
                return <>    
                <BadgeAccountType accountType="Premium" />
                </>   ;
            case 'user':
                return <>
                <BadgeAccountType accountType="Premium" />
                </>   ;
            case 'top 100':
                return <>
                <BadgeAccountType accountType="Premium - Top 100" />
                </>   ;
            default:
                return <><BadgeAccountType accountType="Public" /></>;
        }
    }

    const renderMobileBadges = (accountType) => {        
            //console.log(`account type: ${accountType}`);
            //console.log(this.props.registeredUser);
            //console.log(this.props.auth);
            switch(accountType) {
                case null:
                    return;
                case 'researcher':
                    return <>
                    <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                        <NavLinkReview />
                    </Navbar.Toggle>

                    <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                        <BadgeAccountType accountType={accountType} />
                    </Navbar.Toggle>    
                    </>;
                case 'admin':
                    //console.log(this.props.auth)
                    //console.log(this.props.registeredUser)
                    return <>
                    <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                        <NavLinkReview />
                    </Navbar.Toggle>
                    
                    <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                        <BadgeAccountType accountType={accountType} />
                    </Navbar.Toggle>
                    </>;
                case 'owner':
                    return <>
                    <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                        <NavLinkReview />
                    </Navbar.Toggle>

                    <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                        <BadgeAccountType accountType={accountType} />
                    </Navbar.Toggle>
                    </>   ;
                case 'free':
                    return <>   
                    <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                        <BadgeAccountType accountType="Free" />
                    </Navbar.Toggle>
                    </>   ;
                case 'guest':
                    return <>    
                    <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                        <BadgeAccountType accountType="Premium" />
                    </Navbar.Toggle>
                    </>   ;
                case 'user':
                    return <>
                    <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                        <BadgeAccountType accountType="Premium" />
                    </Navbar.Toggle>
                    </>   ;
                case 'top 100':
                    return <>
                    <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                        <BadgeAccountType accountType="Premium - Top 100" />
                    </Navbar.Toggle>
                    </>   ;
                default:
                    return <>
                    <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                        <BadgeAccountType accountType="Public" />
                    </Navbar.Toggle>
                    </>;
            }
    } 

    return ( registerLink ? 
        <>
            <div className="d-xl-block d-none" >
                <div className="btn btn-link" onClick={connectOrDisconnectWallet} style={{padding: paddingDesktop, textDecoration:"underline", color:"#ffffff"}}>
                    Register
                </div>
            </div>
            <div className="d-block d-xl-none">
                <div className="btn btn-link" onClick={connectOrDisconnectWallet} style={{padding: paddingMobile, textDecoration:"underline", color:"#ffffff"}}>
                    Register
                </div>
            </div>
        </>
        :
        ensOrAddress === "" ?
        <>
        <div className="d-xl-block d-none" >
            <div onClick={connectOrDisconnectWallet} style={{padding: paddingDesktop}}>
                <button className={buttonSize} style={buttonStyle}>
                    {ensOrAddress === "" && buttonCopy ? buttonCopy : "Connect Wallet"}
                    {ensOrAddress !== "" && ensOrAddress}
                </button>{rightOfButton}
            </div>
        </div>
        <div className="d-block d-xl-none">
            <Navbar.Toggle bsPrefix="nothing" as="span" label="none">
            <div onClick={connectOrDisconnectWallet} style={{padding: paddingMobile}}>
                <button className={buttonSize} style={buttonStyle}>
                    {ensOrAddress === "" && buttonCopy ? buttonCopy : "Connect Wallet"}
                    {ensOrAddress !== "" && ensOrAddress}
                </button>{rightOfButton}
            </div>
            </Navbar.Toggle>
        </div>
        </>
        :
        <>
        <div className="d-block d-xl-none" style={{padding:"0px 16px"}}>
            <span className="d-inline-block">
                
                <img
                    alt=""
                    src="/images/profilePlaceholder.png"
                    height="20"
                    className="d-inline-block"
                    style={{marginRight:"10px"}}
                />
            </span>  
            <span style={{color:"#ffffff"}}>
                &nbsp;{ensOrAddress !== "" && username ? username : ensOrAddress !== "" && ensOrAddress ? ensOrAddress : null}
            </span>                                                                            
        </div>   
        <div className="d-xl-block d-none" style={{padding:"0px 8px"}}>
            <NavDropdown
                menuVariant="dark"
                id="navDropdownMenuBgColor"
                title={
                    <>
                    <span className="d-inline-block">
                
                        <img
                            alt=""
                            src="/images/profilePlaceholder.png"
                            height="20"
                            className="d-inline-block"
                        />
                    </span>  
                    <span style={{color:"#ffffff"}}>
                        &nbsp;{ensOrAddress !== "" && username ? username : ensOrAddress !== "" && ensOrAddress ? ensOrAddress : null}
                    </span>
                    </>
                }                 
                style={{minWidth:"150px"}}
            >                                        

                    <LinkContainer to="/leaderboard" style={{ color: "#ffffff", textDecoration:"none" }} activeStyle={{ backgroundColor: "transparent"}}>
                        <NavDropdown.Item>   
                            Leaderboard
                        </NavDropdown.Item>
                    </LinkContainer>

                    <LinkContainer to="/add/nft/quick" style={{color:"#ffffff", textDecoration:"none"}} activeStyle={{ backgroundColor: "transparent"}}>
                        <NavDropdown.Item>                            
                                Add NFT
                        </NavDropdown.Item>
                    </LinkContainer>      

                    <LinkContainer to="/settings/notifications" style={{color:"#ffffff", textDecoration:"none"}} activeStyle={{ backgroundColor: "transparent"}}>
                        <NavDropdown.Item>                            
                                Profile Settings
                        </NavDropdown.Item>
                    </LinkContainer>
                    
                    <NavDropdown.Divider />

                    {accountType !== 'user' && accountType !== 'admin' && accountType !== 'researcher' && accountType !== 'owner' ? upgradeDesktop() : null}
                    
                    {renderBadges(accountType)}

                    <NavDropdown.Divider />
                    
                    <LinkContainer to="/logout" style={{color:"#ffffff", textDecoration:"none"}} activeStyle={{ backgroundColor: "transparent"}}>
                        <NavDropdown.Item>                            
                                Logout
                        </NavDropdown.Item>
                    </LinkContainer>                    

            </NavDropdown>
        </div>           
        </>        
    );
}

export default connect(null, { setWalletProvider, setWalletAccount, setWalletSigner, setWalletNetwork })(WalletButton);
