import React, { useState } from 'react';
import RenderFormField from '../collectionPage/RenderFormField';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';

const SignupPromoCodeForm = ({ onSignupPromoCodeFormSubmit, initialValues, handleSubmit, error, submitting }) => {    

    return (<>
        {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
        <div className="row" style={{marginTop:"10px", margingBottom:"10px"}}>              
            
            <div className="col-md-3 col-xl-4">                    
            </div>
            <div className="col-md-6 col-xl-4 col-12" >
                <form onSubmit={handleSubmit( onSignupPromoCodeFormSubmit )}>
                    <div className="row">
                        <div className="col-9">
                            <Field
                                className="form-control"
                                name="promoCode"
                                component={RenderFormField}
                                type="text"
                                placeholder="Promo Code"
                                label="Promo Code"
                            />
                        </div>
                        <div className="col-3">
                            <button className="btn btn" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff", width:"100%" }} disabled={submitting}>
                                Submit
                            </button>  
                        </div>
                    </div>
                </form>   
            </div>                  
        </div>
    
    </>
    );
}

const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}
    
    if (!values.promoCode) {
        errors.promoCode = 'Required'
    }

    // console.log('errors', errors)
    return errors
}

function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'signupPromoCodeForm',
    validate,
})(SignupPromoCodeForm));