import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import axios from 'axios';

import useTokenGatedAccess from '../../../hooks/useTokenGatedAccess';

// components
import PleaseConnectWallet from '../../access/PleaseConnectWallet';
import PleaseWait from '../../access/PleaseWait';
import PleaseRegister from '../../access/PleaseRegister';
import NoAccessPublic from '../../access/NoAccessPublic';
import PleaseSignIn from '../../access/PleaseSignIn';
import AccessDenied from '../../access/AccessDenied';
import Home from './Home';

const HomeMain = ({ auth, wallet, registeredUser }) => {    

    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, _walletAccessTokens } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars  
    
    const [publicUser, setPublicUser] = useState(false);   
    
    const [benefitsCount, setBenefitsCount] = useState('-');
    const [upcomingMintsCount, setUpcomingMintsCount] = useState('-');
    const [rafflesCount, setRafflesCount] = useState('-');
    const [allowlistCount, setAllowlistCount] = useState('-');
    const [watchlistCount, setWatchlistCount] = useState('-');
    const [nftCount, setNFTCount] = useState('-');    

    useEffect(() => {
                
        const fetchBenefitsCount = async () => {
            const response = await axios.get('/api/get/benefits-count', {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setBenefitsCount(response.data);
        }        
        const fetchRafflesCount = async () => {
            const response = await axios.get('/api/get/raffles-count', {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setRafflesCount(response.data);
        }        
        const fetchAllowlistCount = async () => {
            const response = await axios.get('/api/get/allowlist-count', {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setAllowlistCount(response.data);
        }        
        const fetchWatchlistCount = async () => {
            const response = await axios.get('/api/get/watchlist-count', {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setWatchlistCount(response.data);
        }
        const fetchUpcomingMintsCount = async () => {
            const response = await axios.get('/api/nft/all/count?upcomingMints=true', {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setUpcomingMintsCount(response.data);
        }        
        const fetchNFTCount = async () => {
            const response = await axios.get('/api/get/nft-count', {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setNFTCount(response.data);
        }        
        if ( auth ) {
            fetchUpcomingMintsCount();
            fetchBenefitsCount();
            fetchRafflesCount();
            fetchAllowlistCount();
            fetchWatchlistCount();
            fetchNFTCount();
        }
    }, [auth])
    
    //this avoids a flicker where the public info is shown just before the logged in
    //user's info is recognized which changes the info on the page to the appropriate details
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const delayPublic = async () => {
        await delay(3000);
        //console.log("Waited 5s");
        setPublicUser(true);
      };
    delayPublic();

    const renderHomeMain = () => {
        if (!account && publicUser) {
            return <Redirect to="/all-nfts" />
        }           
        switch(access) { 
            case null:                
                return <PleaseWait />;
            case false:                              
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;
                if (registeredUser.account_type === 'top 100') {                                        
                    return ( <Home upcomingMintsCount={upcomingMintsCount} benefitsCount={benefitsCount} rafflesCount={rafflesCount} allowlistCount={allowlistCount} watchlistCount={watchlistCount} nftCount={nftCount} />  ); 
                }                    
                else if (registeredUser.username) {                                        
                    return ( <Home upcomingMintsCount={upcomingMintsCount} benefitsCount={benefitsCount} rafflesCount={rafflesCount} allowlistCount={allowlistCount} watchlistCount={watchlistCount} nftCount={nftCount} />  ); 
                }
                return <p style={{ color: "white" }}>Something went wrong. Try disconnecting your wallet and refresh the page.</p>;
            case true:                
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;          
                if (registeredUser.username) {                    
                    return ( <Home upcomingMintsCount={upcomingMintsCount} benefitsCount={benefitsCount} rafflesCount={rafflesCount} allowlistCount={allowlistCount} watchlistCount={watchlistCount} nftCount={nftCount} />  ); 
                }                
                else { 
                    return (  <Redirect to="/register" />  );    
                }
            default:
                return <p style={{ color: "white" }}>Something went wrong. Do you <a href="/mint">have a token</a>?</p>;
        }
    }

    return (
        <div >                        
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderHomeMain() }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser }) {
    return { auth, wallet, registeredUser };
}

export default connect(mapStateToProps)(HomeMain);
