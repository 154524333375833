import React, {Component} from 'react'
import { Field, reduxForm } from 'redux-form';
import RenderField from './RenderField';

export default class AllowListDetails extends Component{

    render(){
        return(
            <><div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="allowListMintDate"
                                        component={RenderField}
                                        type="date"
                                        placeholder="mm/dd/yyyy"
                                        label="Allow List Mint Date (ET)" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="allowListMintTime"
                                        component={RenderField}
                                        type="time"
                                        placeholder="00:00 AM"
                                        label="Allow List Mint Time (ET)" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="allowListMintPrice"
                                        component={RenderField}
                                        type="text"
                                        placeholder=""
                                        label="Allow List Mint Price" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="allowListMintQuantity"
                                        component={RenderField}
                                        type="text"
                                        placeholder=""
                                        label="Allow List Mint Quantity" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="allowListLimitPerWallet"
                                        component={RenderField}
                                        type="text"
                                        placeholder=""
                                        label="Allow List Limit per Wallet" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <Field
                                        className="form-control"
                                        name="allowListLimitPerTransaction"
                                        component={RenderField}
                                        type="text"
                                        placeholder=""
                                        label="Allow List Limit per transaction" />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        )
    }
}