import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import RenderWatchIcon from './RenderWatchIcon';

const RenderVote = ({ access, id, upvote_count, downvote_count, vote_percent }) => {
    
    const updateVote = async (id, vote) => {
        try {
            const response = await axios.put(`/api/vote/${id}`, { vote });         
            return response.data.vote;
        } catch (error) {
            console.error(error.response);
        }
    }

    const fetchCounts = async (id) => {
        try {
            const counts = await axios.get(`/api/count-votes/${id}`);
            return counts.data;
        } catch (error) {
            console.error(error.resposne);
        }    
    }

    const [vote, setVote] = useState(null);
    const [upvote_count_update, setUpvoteCount] = useState(upvote_count);
    const [downvote_count_update, setDownvoteCount] = useState(downvote_count);

    const [total_vote, setTotalVote] = useState(upvote_count + downvote_count);  
    const [vote_percent_update, setVotePercent] = useState(vote_percent);

    useEffect(() => {
        const fetchVote = async () => {
            const response = await axios.get(`/api/fetch-vote/${id}`);
            setVote(response?.data);
        }
        if ( access !== 'public' ) fetchVote();
    }, [id, access])
    
    //console.log(`ID: ${id}\nVote: ${vote?.data}`);

    const ThumbsUpNeutral = () => {
    
        return <>
            <td style={{textAlign:"center"}}>
                <div className="row">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        fill="#ffffff" 
                        className="bi bi-hand-thumbs-up-fill" 
                        viewBox="0 0 16 16"
                        onClick={async () => {
                            const newVote = await updateVote(id, 'up');
                            setVote(newVote);
                            const votesCount = await fetchCounts(id);                        
                            setUpvoteCount(votesCount.upvote_count);
                            setDownvoteCount(votesCount.downvote_count);
                            setVotePercent(votesCount.vote_percent);
                            setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                        }}
                    >
                        <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/>
                    </svg>      
                    <br /><span style={{fontSize:"0.6em"}}>{upvote_count_update}</span> 
                </div>         
            </td>
            </>
        
    }

    const ThumbsUpSelected = () => {
    
        return <>
            <td style={{textAlign:"center"}}>
                <div className="row">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        fill="#7945E1" 
                        className="bi bi-hand-thumbs-up-fill" 
                        viewBox="0 0 16 16"
                        onClick={async () => {
                            const newVote = await updateVote(id, 'neutral');
                            setVote(newVote);
                            const votesCount = await fetchCounts(id);
                            setUpvoteCount(votesCount.upvote_count);
                            setDownvoteCount(votesCount.downvote_count);
                            setVotePercent(votesCount.vote_percent);
                            setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                        }}
                    >
                        <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/>
                    </svg>          
                    <br /><span style={{fontSize:"0.6em"}}>{upvote_count_update}</span> 
                </div>                      
            </td>
            </>
        
    }

    const ThumbsDownNeutral = () => {
    
        return <>
            <td style={{textAlign:"center"}}>
                <div className="row">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        fill="#ffffff" 
                        className="bi bi-hand-thumbs-down-fill" 
                        viewBox="0 0 16 16"
                        onClick={async () => {
                            const newVote = await updateVote(id, 'down');
                            setVote(newVote);
                            const votesCount = await fetchCounts(id);                        
                            setUpvoteCount(votesCount.upvote_count);
                            setDownvoteCount(votesCount.downvote_count);                        
                            setVotePercent(votesCount.vote_percent);
                            setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                        }}
                    >
                        <path d="M6.956 14.534c.065.936.952 1.659 1.908 1.42l.261-.065a1.378 1.378 0 0 0 1.012-.965c.22-.816.533-2.512.062-4.51.136.02.285.037.443.051.713.065 1.669.071 2.516-.211.518-.173.994-.68 1.2-1.272a1.896 1.896 0 0 0-.234-1.734c.058-.118.103-.242.138-.362.077-.27.113-.568.113-.856 0-.29-.036-.586-.113-.857a2.094 2.094 0 0 0-.16-.403c.169-.387.107-.82-.003-1.149a3.162 3.162 0 0 0-.488-.9c.054-.153.076-.313.076-.465a1.86 1.86 0 0 0-.253-.912C13.1.757 12.437.28 11.5.28H8c-.605 0-1.07.08-1.466.217a4.823 4.823 0 0 0-.97.485l-.048.029c-.504.308-.999.61-2.068.723C2.682 1.815 2 2.434 2 3.279v4c0 .851.685 1.433 1.357 1.616.849.232 1.574.787 2.132 1.41.56.626.914 1.28 1.039 1.638.199.575.356 1.54.428 2.591z"/>
                    </svg>                
                    <br /><span style={{fontSize:"0.6em"}}>{downvote_count_update}</span> 
                </div>  
                <div className="row mt-4">
                    <RenderWatchIcon id={id} access={access} style="eye" mobile="true" /> 
                </div>     
            </td>
            </>
        
    }
    const ThumbsDownSelected = () => {
    
        return <>
            <td style={{textAlign:"center"}}>
                <div className="row">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        fill="#7945E1" 
                        className="bi bi-hand-thumbs-down-fill" 
                        viewBox="0 0 16 16"
                        onClick={async () => {
                            const newVote = await updateVote(id, 'neutral');
                            setVote(newVote);
                            const votesCount = await fetchCounts(id);
                            setUpvoteCount(votesCount.upvote_count);
                            setDownvoteCount(votesCount.downvote_count);
                            setVotePercent(votesCount.vote_percent);
                            setTotalVote(votesCount.upvote_count + votesCount.downvote_count);
                        }}
                    >
                        <path d="M6.956 14.534c.065.936.952 1.659 1.908 1.42l.261-.065a1.378 1.378 0 0 0 1.012-.965c.22-.816.533-2.512.062-4.51.136.02.285.037.443.051.713.065 1.669.071 2.516-.211.518-.173.994-.68 1.2-1.272a1.896 1.896 0 0 0-.234-1.734c.058-.118.103-.242.138-.362.077-.27.113-.568.113-.856 0-.29-.036-.586-.113-.857a2.094 2.094 0 0 0-.16-.403c.169-.387.107-.82-.003-1.149a3.162 3.162 0 0 0-.488-.9c.054-.153.076-.313.076-.465a1.86 1.86 0 0 0-.253-.912C13.1.757 12.437.28 11.5.28H8c-.605 0-1.07.08-1.466.217a4.823 4.823 0 0 0-.97.485l-.048.029c-.504.308-.999.61-2.068.723C2.682 1.815 2 2.434 2 3.279v4c0 .851.685 1.433 1.357 1.616.849.232 1.574.787 2.132 1.41.56.626.914 1.28 1.039 1.638.199.575.356 1.54.428 2.591z"/>
                    </svg>                  
                    <br /><span style={{fontSize:"0.6em"}}>{downvote_count_update}</span>
                </div>
                <div className="row mt-4">
                    <RenderWatchIcon id={id} access={access} style="eye" /> 
                </div>   
            </td>
            </>
        
    }

    const ThumbsUpSignup = () => {
    
        return <>
            <td style={{textAlign:"center"}}>
                <div className="row">
                    <Link to="/signup" style={{color:"rgba(255,255,255,0.8", textDecoration:"none"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" 
                            width="16" 
                            height="16" 
                            fill="#ffffff" 
                            className="bi bi-hand-thumbs-up-fill" 
                            viewBox="0 0 16 16"                        
                        >
                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/>
                        </svg>      
                        <br /><span style={{fontSize:"0.6em"}}>{upvote_count_update}</span>  
                    </Link>
                </div>
            </td>
        </> 
    }
    const ThumbsDownSignup = () => {
    
        return <>
            <td style={{textAlign:"center"}}>
                <div className="row">
                    <Link to="/signup" style={{color:"rgba(255,255,255,0.8", textDecoration:"none"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" 
                            width="16" 
                            height="16" 
                            fill="#ffffff" 
                            className="bi bi-hand-thumbs-down-fill" 
                            viewBox="0 0 16 16"                        
                        >
                            <path d="M6.956 14.534c.065.936.952 1.659 1.908 1.42l.261-.065a1.378 1.378 0 0 0 1.012-.965c.22-.816.533-2.512.062-4.51.136.02.285.037.443.051.713.065 1.669.071 2.516-.211.518-.173.994-.68 1.2-1.272a1.896 1.896 0 0 0-.234-1.734c.058-.118.103-.242.138-.362.077-.27.113-.568.113-.856 0-.29-.036-.586-.113-.857a2.094 2.094 0 0 0-.16-.403c.169-.387.107-.82-.003-1.149a3.162 3.162 0 0 0-.488-.9c.054-.153.076-.313.076-.465a1.86 1.86 0 0 0-.253-.912C13.1.757 12.437.28 11.5.28H8c-.605 0-1.07.08-1.466.217a4.823 4.823 0 0 0-.97.485l-.048.029c-.504.308-.999.61-2.068.723C2.682 1.815 2 2.434 2 3.279v4c0 .851.685 1.433 1.357 1.616.849.232 1.574.787 2.132 1.41.56.626.914 1.28 1.039 1.638.199.575.356 1.54.428 2.591z"/>
                        </svg>                
                        <br /><span style={{fontSize:"0.6em"}}>{downvote_count_update}</span> 
                    </Link>
                </div>
                <div className="row mt-4">
                    <RenderWatchIcon id={id} access={access} style="eye" /> 
                </div>   
            </td>            
        </> 
    }

    let vote_percent_render = '-';

    if ( vote_percent_update != null ) {
        vote_percent_render = `${vote_percent_update}%`;
    }    
    
    //console.log(vote);
    if ( access !== 'public' ) {
        switch(vote) {
            case 'up': 
                return <>
                    <ThumbsUpSelected />                         
                    <ThumbsDownNeutral />                    
                </>;
            case 'down':
                return <>
                    <ThumbsUpNeutral />                    
                    <ThumbsDownSelected />
                </>;
            default:
                return <>
                    <ThumbsUpNeutral />
                    <ThumbsDownNeutral />
                </>;
        }
    }
    else {
        return <>                                           
            <ThumbsUpSignup /><ThumbsDownSignup />
        </>;
    }

    /* 
    
        COMMUNITY VOTE REMOVED!
        <td>
            <div className="row text-center">
                <span style={{fontSize:"1.3em"}}>{vote_percent_render}</span>
                <span>{total_vote} votes</span>
            </div>
        </td>
    */
}

export default RenderVote;