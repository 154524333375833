import { useState, useMemo } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import axios from 'axios';

import GiveawayAddHeader from './GiveawayAddHeader';
import GiveawayAddForm from './GiveawayAddForm';
import ReviewNav from '../../ReviewNav';

const GiveawayAdd = ({ accountType }) => {    

    return (
        <>
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-2">
                <ReviewNav page="giveaways" accountType={accountType}/>
            </div>
            <div className="col-10">            
                <GiveawayAddHeader />
                <div className="row table-responsive text-nowrap" style={{ marginTop:"20px"}}>
                    <div className="col-12">

                        <GiveawayAddForm                            
                            onGiveawayAddFormSubmit={
                                async (values) => {
                                                                        
                                    try {                                                        
                                        await axios.post(`/api/admin/post/giveaway/new`, values);
                                        window.location.assign('/admin/giveaways/upcoming');
                                    } catch (error) {
                                        // This should be visible in FS and (hopefully) alert FS of the issue
                                        console.error(error.response)
                                    }
                                }
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default GiveawayAdd;
