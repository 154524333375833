import { Link } from 'react-router-dom';

const DashboardHeader = ( { page } ) => {
    
    const homeHighlight = page === 'home' ? '#7945E1' : '#140510';
    const homeStyleInputs = { color:"#ffffff", backgroundColor: homeHighlight, paddingRight:"5px", paddingLeft:"5px", textDecoration:"none", marginTop:"20px" };    
    const homeText = page === 'notifications' ? <strong>Home</strong> : <span>Home</span>;

    const userNFTsHighlight = page === 'userNFTs' || page === 'hidden' ? '#7945E1' : '#140510';
    const userNFTsStyleInputs = { color:"#ffffff", backgroundColor: userNFTsHighlight, paddingRight:"5px", paddingLeft:"5px", textDecoration:"none", marginTop:"20px" };    
    const userNFTsText = page === 'userNFTs' ? <strong>My NFTs</strong> : <span>My NFTs</span>;

    const userViewableNFTsHighligt = page === 'userNFTs' ? '#7945E1' : '#140510';
    const userViewableNFTsInputs = { color:"#ffffff", backgroundColor: userViewableNFTsHighligt, paddingRight:"5px", paddingLeft:"5px", textDecoration:"none", marginTop:"20px" };    
    const userViewableNFTsSubText = page === 'userNFTs' ? <strong>Viewable</strong> : <span>Viewable</span>;

    const userHiddenNFTsHighligt = page === 'hidden' ? '#7945E1' : '#140510';
    const userHiddenNFTsInputs = { color:"#ffffff", backgroundColor: userHiddenNFTsHighligt, paddingRight:"5px", paddingLeft:"5px", textDecoration:"none", marginTop:"20px" };    
    const userHiddenNFTsSubText = page === 'hidden' ? <strong>Hidden</strong> : <span>Hidden</span>;

    const allowListsHighlight = page === 'allowLists' ? '#7945E1' : '#140510';
    const allowListsStyleInputs = { color:"#ffffff", backgroundColor: allowListsHighlight, paddingRight:"5px", paddingLeft:"5px", textDecoration:"none", marginTop:"20px" };    
    const allowListsText = page === 'notifications' ? <strong>Allow Lists</strong> : <span>Allow Lists</span>;

    const watchlistHighlight = page === 'watchlist' ? '#7945E1' : '#140510';
    const watchlistStyleInputs = { color:"#ffffff", backgroundColor: watchlistHighlight, paddingRight:"5px", paddingLeft:"5px", textDecoration:"none", marginTop:"20px" };    
    const watchlistText = page === 'watchlist' ? <strong>Watchlist</strong> : <span>Watchlist</span>; 
    
    const entriesHistoryHighlight = page === 'entriesHistory' ? '#7945E1' : '#140510';
    const entriesHistoryStyleInputs = { color:"#ffffff", backgroundColor: entriesHistoryHighlight, paddingRight:"5px", paddingLeft:"5px", textDecoration:"none", marginTop:"20px" };    
    const entriesHistoryText = page === 'watchlist' ? <strong>Entries History</strong> : <span>Entries History</span>; 

    return (
        <>
        
        <div className="row" style={{marginTop:"20px"}}>              
            <div className="col-12" >                
                <div className="row">
                    <div className="col-8 col-lg-10 rounded" style={{fontSize:"2em"}}>
                        Dashboard
                    </div>
                    <div className="col-4 col-lg-2 align-self-center" style={{textAlign:"right"}}>
                        <Link to="/settings/link-wallets">
                            <button className="btn btn-light" style={{color:"#7945E1"}}>
                                Add Wallet
                            </button>
                        </Link>
                    </div>
                </div>
            </div>              
        </div>
        <div className="row" style={{marginTop:"20px"}}>              
            <div className="col-12" >                
                <div className="row">
                    <div className="col-12 rounded" style={{fontSize:"1em"}}>
                        <Link to="/dashboard/home" className="rounded" style={homeStyleInputs}>{homeText}</Link>&nbsp;|&nbsp;
                        <Link to="/dashboard/user-nfts" className="rounded" style={userNFTsStyleInputs}>{userNFTsText}</Link>&nbsp;|&nbsp;
                        <Link to="/dashboard/allow-lists" className="rounded" style={allowListsStyleInputs}>{allowListsText}</Link>&nbsp;|&nbsp;
                        <Link to="/dashboard/watchlist" className="rounded" style={watchlistStyleInputs}>{watchlistText}</Link>&nbsp;|&nbsp;
                        <Link to="/dashboard/entries-history" className="rounded" style={entriesHistoryStyleInputs}>{entriesHistoryText}</Link>
                    </div>
                </div>
            </div>
            {page === 'userNFTs' || page === 'hidden' ?
                <div className="col-12 mt-3" >                
                    <div className="row">
                        <div className="col-12 rounded" style={{fontSize:"0.8em"}}>
                            <Link to="/dashboard/user-nfts" className="rounded" style={userViewableNFTsInputs}>{userViewableNFTsSubText}</Link>&nbsp;|&nbsp;
                            <Link to="/dashboard/user-hidden-nfts" className="rounded" style={userHiddenNFTsInputs}>{userHiddenNFTsSubText}</Link>                       
                        </div>
                    </div>
                </div>  
                :
                null 
            }            
        </div>
        
        </>
    );
};

export default DashboardHeader;