import { useState, useMemo, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import axios from 'axios';

import Pagination from '../pagination/Pagination';

let PageSize = 50;

const convertTime = (time) => {
    if (time !== null && time !== undefined) {
        const timeSplit = time.split(':');  

        let hour = timeSplit[0] % 12;
        if (hour === 0) { hour = 12 };

        let timePeriod;
        if (timeSplit[0] / 12 >= 1) { timePeriod = ' PM' } else { timePeriod = ' AM'}

        return hour + ':' + timeSplit[1] + timePeriod + ' ET';
    }
    return '';
}

const benefitRead = async (readStatus, benefit_id) => {
    
    try {
        const response = await axios.put(`/api/put/benefit/read`, { readStatus, benefit_id });               
        return response.data.newReadValue;
    } catch (error) {
        console.error(error.response);
    }
}

const benefitRedeem = async (redeemStatus, benefit_id) => {
    
    try {
        const response = await axios.put(`/api/put/benefit/redeem`, { redeemStatus, benefit_id });         
        return response.data.newRedeemValue;
        
    } catch (error) {
        console.error(error.response);
    }
}

const TableRow = ({ alert, access }) => {


    const { benefit_id, alert_read_ts, redeemed_ts, nft_collection_id, nft_collection, benefit_type, description, url, partner, claim_deadline_date, claim_deadline_time, alert_sent_ts, slug } = alert;        

    let claimDeadlineDateConverted = '';
    if ( claim_deadline_date !== null && claim_deadline_date !== '' ) {
        const claimDeadlineDate = new Date(claim_deadline_date);
        claimDeadlineDateConverted = claimDeadlineDate.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' }); 
    }

    const alertSentTs = new Date(alert_sent_ts);
    const alertSentTsConverted = alertSentTs.toLocaleString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' }); 

    const [read, setRead] = useState(alert_read_ts); 
    const [redeemed, setRedeemed] = useState(redeemed_ts);      
    
    const collectionLink = '/collection/' + slug;

    let rowBackgroundColor = {backgroundColor:"transparent"};
    if ( read === null ) {
        rowBackgroundColor = {backgroundColor:"#B0228C"};
    }   
    else if ( redeemed !== null ) {
        rowBackgroundColor = {backgroundColor:"rgb(25, 135, 84, 0.5)"};
    } 

    return <>
    <tr style={rowBackgroundColor}>
        <td className="align-middle" style={{padding:"0px"}}>
            {read !== null ? 
                <div className="fs-3 text-center" 
                    onClick={async () => {
                        const newReadValue = await benefitRead( false, benefit_id );
                        setRead(newReadValue);                     
                    }}
                >
                    <i className="bi bi-envelope-open"></i>
                </div> 
            :   <div    className="fs-3 text-center"
                        onClick={async () => {
                            const newReadValue = await benefitRead( true, benefit_id );
                            setRead(newReadValue);                     
                        }}
                >
                    <i className="bi bi-envelope"></i>
                </div> 
            }
        </td>
        <td className="align-middle" style={{padding:"0px"}}>
            {redeemed !== null ? 
                <div className="button text-center"
                    onClick={async () => {
                        const newRedeemValue = await benefitRedeem( false, benefit_id );
                        setRedeemed(newRedeemValue);    
                        const newReadValue = await benefitRead( false, benefit_id );
                        setRead(newReadValue);                    
                    }}
                >
                    <button className="btn btn-sm btn-success" style={{padding:"2px"}}>
                        <i className="bi bi-check-square-fill"></i> Redeemed
                    </button>
                </div>
            :   <div className="button text-center"
                    onClick={async () => {
                        const newRedeemValue = await benefitRedeem( true, benefit_id );
                        setRedeemed(newRedeemValue);        
                        const newReadValue = await benefitRead( true, benefit_id );
                        setRead(newReadValue);               
                    }}
                >
                    <button className="btn btn-sm btn-light" style={{padding:"2px"}}>
                        Redeem
                    </button>
                </div> 
            }
        </td>
        <td className="align-middle">
            <span style={{fontSize:"1.25em"}}>
                <Link to={collectionLink} style={{ color:"#ffffff"}}>
                    {nft_collection}
                </Link>
            </span>
        </td>
        <td>{claimDeadlineDateConverted} {convertTime(claim_deadline_time)}</td> 
        <td>{benefit_type}</td>
        <td style={{width:"400px", minWidth:"400px"}}>{description}</td>
        <td>{partner}</td>
        <td>
            { url !== null ?
                <a href={url} target="_blank" rel="noreferrer" style={{ color:"#ffffff"}}>
                    Link
                </a>
                :
                ''
            }
        </td>           
        <td>{alertSentTsConverted}</td>        
    </tr>
    </>;
}

const Alerts = ({ allAlerts, account_type }) => {          

    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);   

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return allAlerts.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, allAlerts]);   
    
    return (
        <>
                
        <div className="row" style={{marginTop:"20px"}}>  
            <div className="col-lg-1 col-12"></div>
            <div className="col-lg-10 col-12" >                
                <div style={{paddingLeft:"10px"}}>
                    <div className="row">
                        <div className="col-12 rounded" style={{fontSize:"2em"}}>
                            Alerts
                        </div>
                    </div>
                </div>
            </div>               
        </div>
        {account_type === 'free' ?
            <div className="row mt-2">
                <div className="col-12 text-center">
                    Receive benefit alerts on ALL of your NFTs. &nbsp;
                    <Link to="/signup">
                        <button className="btn-sm btn-light" style={{color:"#7945E1"}}>
                            Upgrade To Premium
                        </button>
                    </Link>
                </div>
            </div>
            :
            null
        }
        <div className="row" style={{marginTop:"20px"}}>  
            <div className="col-lg-1 col-12"></div>
            <div className="col-lg-10 col-12" >                
                <div style={{paddingLeft:"10px"}}>
                    <div className="row">
                        <div className="col-12 rounded" style={{fontSize:"1em"}}>
                            Scroll right
                        </div>
                    </div>
                </div>
            </div>               
        </div>
        <div className="row" style={{marginTop:"10px"}}>                    
            <div className="col-lg-1 col-12">            
            </div>                

            <div className="col-lg-10 col-12 table-responsive" style={{ padding:"10px"}} >               
                <table className="table" style={{ color:"#ffffff" }}>
                    <thead className="text-nowrap">
                        <tr style={{ backgroundColor:"#1F2731"}}>
                            <th scope="col">Read</th>
                            <th scope="col">Track</th>
                            <th scope="col">NFT Collection</th>
                            <th scope="col">Claim Deadline</th>
                            <th scope="col">Benefit Type</th>
                            <th scope="col" style={{width:"400px", minWidth:"400px"}}>Benefit</th>
                            <th scope="col">Partner</th>
                            <th scope="col">Link</th>                            
                            <th scope="col">Added</th>                            
                        </tr>
                    </thead>
                    <tbody style={{ fontSize:".8em"}}>
                        {pageData.length > 0 ? pageData.map(alert => <TableRow key={alert.benefit_id} alert={alert}  />)  : null}
                    </tbody>
                    <tfoot style={{ backgroundColor:"#1F2731", textAlign: "center"}}>
                        <tr>                     
                            <td colSpan="12">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={allAlerts.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>                
        
        </>
    );
};

function mapStateToProps({ form, alerts }) {
    return { form, alerts };
}

export default connect(mapStateToProps, actions)(Alerts);
