import React, { useState } from 'react';
import RenderFormField from '../../collectionPage/RenderFormField';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../../actions/index';

const NotificationsForm = ({ onNotificationsFormSubmit, initialValues, handleSubmit, error, submitting }) => {    

    return (<>
        {/* https://redux-form.com/8.3.0/examples/submitvalidation/ */}
        <div className="row table-responsive my-3" style={{fontSize:"1em"}}>                
            <div className="col-12">
                <form onSubmit={handleSubmit( onNotificationsFormSubmit )}>
                    <div className="row">
                        <div className="col-lg-3 col-4" style={{fontSize:"1.5em"}}>
                            Email
                        </div> 
                        <div className="col-lg-6 col-8">
                            <Field
                                className="form-control"
                                name="email"
                                component={RenderFormField}
                                type="text"
                                placeholder="Email"
                                label="Email"
                            />
                        </div>    
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-3 col-4" style={{fontSize:"1.5em"}}>
                            Personal Phrase
                        </div> 
                        <div className="col-lg-6 col-8">                            
                            <Field
                                className="form-control"
                                name="personalPhrase"
                                component={RenderFormField}
                                type="text"
                                placeholder="Personal Phrase"
                                label="Personal Phrase"                                
                            />
                        </div>    
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-3 col-4" style={{fontSize:"1.5em"}}>
                            
                        </div> 
                        <div className="col-lg-6 col-8">
                            <button className="btn btn" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff" }} disabled={submitting}>
                                Update
                            </button>  
                        </div>    
                    </div>
                </form>
            </div>
        </div>
    
    </>
    );
}

const validate = values => {
    //TODO: change errors to appropriatly reflect nft form validation requirements
    const errors = {}

    if (values.email && !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.email)) {
        errors.email = 'Invalid email address'
    }
      
    if (values.personalPhrase) {
        if (values.personalPhrase.length > 50) {
         errors.personalPhrase = 'Must be 50 characters or less'
        }
    }    

    // console.log('errors', errors)
    return errors
}

function mapStateToProps(state) {
    //console.log(state);
    //return { formValues: state.form.RegisterForm.values };
    return {
         // if the connected wallet changes after the form has rendered,
         // this option should cause the form to re-render
        enableReinitialize: true,
    };

}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'notificationsForm',
    validate,
})(NotificationsForm));