import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import axios from 'axios';

import useTokenGatedAccess from '../../hooks/useTokenGatedAccess';

// components
import PleaseConnectWallet from '../access/PleaseConnectWallet';
import PleaseWait from '../access/PleaseWait';
import PleaseRegister from '../access/PleaseRegister';
import PleaseSignIn from '../access/PleaseSignIn';
import AccessDenied from '../access/AccessDenied';
import Calendar from './Calendar';

const TokenGatedCalendar = ({ auth, wallet, registeredUser }) => {    

    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, _walletAccessTokens } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars  

    const url = "/api/mintCalendar";
    const params = url + useLocation().search;    
    const [calendarItems, setCalendarItems] = useState([]);   
    const [publicUser, setPublicUser] = useState(false);        

    useEffect(() => {
        const fetchCalendarItems = async () => {
            const response = await axios.get(params, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setCalendarItems(response.data);
        }
        fetchCalendarItems()
    }, [])
    
    //this avoids a flicker where the public info is shown just before the logged in
    //user's info is recognized which changes the info on the page to the appropriate details
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const delayPublic = async () => {
        await delay(1000);
        //console.log("Waited 5s");
        setPublicUser(true);
      };
    delayPublic();

    //console.log('Access: ', access);
    //console.log('Account Type: ', registeredUser.account_type);

    const renderCalendar = () => {
        if (!account && publicUser) {
            axios.get(`/api/update-earliest-mint-date`); 
            return ( <Calendar calendarItems={calendarItems} access="public" /> ); 
        }           
        switch(access) { 
            case null:
                return <PleaseWait />;
            case false:                            
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;
                if (registeredUser.account_type === 'top 100' || registeredUser.account_type === 'guest' || registeredUser.account_type === 'user' || registeredUser.account_type === 'researcher' || registeredUser.account_type === 'admin' || registeredUser.account_type === 'owner') {
                    axios.get(`/api/update-earliest-mint-date`); 
                    return ( <Calendar calendarItems={calendarItems} access="premium"/> ); 
                }                    
                else if (registeredUser.username) {                    
                    axios.get(`/api/update-earliest-mint-date`); 
                    return ( <Calendar calendarItems={calendarItems} access="free" registeredUsername={registeredUser.username}/> ); 
                }
                return <p style={{ color: "white" }}>Something went wrong. Try disconnecting your wallet and refresh the page.</p>;
            case true:                
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;          
                if (registeredUser.username) {
                    axios.get(`/api/update-earliest-mint-date`); 
                    return ( <Calendar calendarItems={calendarItems} access="premium"/> ); 
                }                
                else { 
                    return (  <Redirect to="/register" />  );    
                }
            default:
                return <p style={{ color: "white" }}>Something went wrong. Do you <a href="/mint">have a token</a>?</p>;
        }
    }

    return (
        <div>                        
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderCalendar() }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser }) {
    return { auth, wallet, registeredUser };
}

export default connect(mapStateToProps)(TokenGatedCalendar);
