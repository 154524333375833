import ProgressIndicator from '../../onboard/ProgressIndicator';

const RegisterPrompt = ({username}) => {
    return (
        <>
                        
            <div className="row mt-5">
                <div className="col-1 col-lg-2"></div>
                <div className="col-10 col-lg-8" style={{textAlign:"center"}}> 
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="80" 
                        height="80" 
                        fill="#7945E1" 
                        className="bi bi-check-circle-fill" 
                        viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-1 col-lg-2"></div>
                <div className="col-10 col-lg-8" style={{textAlign:"center"}}>                   
                    <span style={{fontSize:"2em", textAlign:"center"}}>
                        Congratulations, {username}!
                    </span>
                </div>
            </div>            
        </>
    );
};

export default RegisterPrompt;
