import { useState, useMemo } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import axios from 'axios';

import GiveawayEditHeader from './GiveawayEditHeader';
import GiveawayEditForm from './GiveawayEditForm';
import ReviewNav from '../../ReviewNav';

const GiveawayAdd = ({ giveawayData, accountType }) => {

    const { id, nft_collection, nft_collection_id, quantity, start_date, start_time, end_date, end_time } = giveawayData;

    const initialValues = {
        'giveawayNFT':          nft_collection,
        'giveawayNFTId':        nft_collection_id,
        'quantity':             quantity,
        'startDate':            start_date,
        'startTime':            start_time,
        'endDate':              end_date,
        'endTime':              end_time        
    }

    return (
        <>
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-2">
                <ReviewNav page="giveaways" accountType={accountType}/>
            </div>
            <div className="col-10">            
                <GiveawayEditHeader />
                <div className="row table-responsive text-nowrap" style={{ marginTop:"20px"}}>
                    <div className="col-12">

                        <GiveawayEditForm
                            initialValues={initialValues}
                            onGiveawayAddFormSubmit={
                                async (values) => {
                                                                        
                                    try {                                                        
                                        await axios.post(`/api/admin/post/giveaway/edit/${id}`, values);
                                        window.location.assign('/admin/giveaways/upcoming');
                                    } catch (error) {
                                        // This should be visible in FS and (hopefully) alert FS of the issue
                                        console.error(error.response)
                                    }
                                }
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default GiveawayAdd;
