import { Link } from 'react-router-dom';

const SettingsNav = ({ page }) => {  
        
    const notificationsHighlight = page === 'notifications' ? '#B0228C' : '#140510';
    const notificationsStyleInputs = { color:"#ffffff", backgroundColor: notificationsHighlight, padding:"5px", textDecoration:"none" };    
    const notificationsText = page === 'notifications' ? <strong>Notifications</strong> : <span>Notifications</span>;

    const linkWalletsHighlight = page === 'link-wallets' ? '#B0228C' : '#140510';
    const linkWalletsStyleInputs = { color:"#ffffff", backgroundColor: linkWalletsHighlight, padding:"5px", textDecoration:"none" };    
    const linkWalletText = page === 'link-wallets' ? <strong>Link Wallets</strong> : <span>Link Wallets</span>;

    return (
    <>
        <div className="row my-2">            
            <div className="col-12" style={{fontSize:"1.25em", textDecoration:"underline"}}>
                Profile Settings
            </div>
        </div>
        <div className="row my-2">            
            <div className="col-12">
                <Link to="/settings/notifications" className="rounded" style={notificationsStyleInputs}>{notificationsText}</Link>
            </div>
        </div>
        <div className="row my-2">            
            <div className="col-12">
                <Link to="/settings/link-wallets" className="rounded" style={linkWalletsStyleInputs}>{linkWalletText}</Link>
            </div>
        </div>              
    </>
    );
}

export default SettingsNav;