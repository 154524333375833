// RenderField contains logic to render a single label and text input
import React from 'react';

//{input} is the same as props.input
export default ({ input, className, placeholder, label, type, disabled, meta: { touched, error, warning } }) => {
    
    return (
        <span>            
            <input {...input} className={className} placeholder={placeholder} type={type} disabled={disabled} style={{backgroundColor:"#29194A", color:"rgba(255,255,255,0.8)"}}/>
            {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}            
        </span>
    );
};