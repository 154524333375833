import { useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import Pagination from '../../../pagination/Pagination';
import ReviewNav from '../../ReviewNav';
import ReviewHeader from './ReviewHeader';
import PromoCodeForm from './PromoCodeForm';

const TableRow = ({ promoCode }) => {
    const { promo_code, partner, quantity, promo_end_date, created_ts } = promoCode;    

    const promoEndDate = new Date(promo_end_date);
    const promoEndDateConverted = promoEndDate.toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });

    const createdDate = new Date(created_ts);
    const createdDateConverted = createdDate.toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' });

    return <tr>                
        <td>{promo_code}</td>
        <td>{partner}</td>
        <td>{promoCode['users.redeemed']}</td>
        <td>{quantity}</td>
        <td>{promoEndDateConverted}</td>
        <td>{createdDateConverted}</td>        
    </ tr>;
}

let PageSize = 50;
let filters = '';

const Review = ({ promoCodes, accountType }) => {
    
    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);    
    const [promoCodeFormSubmitted, setPromoCodeFormSubmitted] = useState(null);

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return promoCodes.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, promoCodes]);

    const renderPromoCodeForm = ( ) => {
        const initialValues = {
        }
    
        return <>
        <PromoCodeForm
            initialValues={initialValues}
            onPromoCodeFormSubmit={
                async (values) => {                        
                        try {                                                        
                            await axios.post(`/api/admin/post/promo-codes`, values);
                            setPromoCodeFormSubmitted("Thanks for submitting a Promo Code! We'll review it before it is shown to others.");
                        } catch (error) {
                            // This should be visible in FS and (hopefully) alert FS of the issue
                            console.error(error.response)
                        }
                    }
                }
            />
        </>
    }
    
    const renderPromoCodeFormSubmitted = (promoCodeFormSubmitted) => {
        return <>
            <div className="row mt-3 mb-5">
                <div className="col-1"></div>
                <div className="col-10">
                    {promoCodeFormSubmitted}
                </div>
            </div>
        </>;
    }   

    return (
        <>        
        <div className="row" style={{ marginTop:"20px"}}>            
            <div className="col-2">
                <ReviewNav page="promoCode" accountType={accountType}/>
            </div>
            <div className="col-10">
                <ReviewHeader />                
                <div className="row table-responsive text-nowrap" style={{ marginTop:"20px"}}>
                    <div className="col-12">   
                        {promoCodeFormSubmitted === null ? renderPromoCodeForm( ) : renderPromoCodeFormSubmitted(promoCodeFormSubmitted)}
                    </div>
                    <div className="col-12">                    
                    <table className="table" style={{ color:"#ffffff" }}>
                        <thead>
                            <tr style={{ backgroundColor:"#1F2731"}}>                                
                                <th scope="col">Promo Code</th>
                                <th scope="col">Partner</th>
                                <th scope="col">Redeemed</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Promo End Date</th>
                                <th scope="col">Created</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize:".8em"}}>
                            {pageData.map(promoCode => <TableRow key={promoCode.promo_code} promoCode={promoCode} />)}
                        </tbody>
                        <tfoot style={{ backgroundColor:"#1F2731", textAlign: "center"}}>
                            <tr>                     
                                <td colSpan="6">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={promoCodes.length}
                                        pageSize={PageSize}
                                        filters={filters}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Review;