import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";

import axios from 'axios';

import Mint from './Mint';

import genesisMintConfig from '../../config/mint/genesis/mintConfig';
import genesisContractAbi from '../../config/contracts/genesis/contractAbi';

const MintPage = ({ wallet }) => {

    const { account } = wallet;

    return (
        <>             
            <Row style={{ paddingTop:"75px", paddingBottom:"200px", height:"100%", textAlign: "center" }}>
                <div className="col-md-2"></div>
                <div className="col-md-3">
                    <img fluid="true" alt="Future Mints - Genesis NFT Card" src="images/fnftm-card.png" width="200px" className="d-inline-block align-top"/>
                </div>    
                <div className="col-md-5">
                    <Mint mintConfig={genesisMintConfig} contractAbi={genesisContractAbi} />
                    <Row  style={{ paddingBottom:"25px" }}></Row>
                </div>
                <div className="col-md-2"></div>
            </Row>            
        </>
    )
}

function mapStateToProps({ wallet }) {
    return { wallet };
}

export default connect(mapStateToProps)(MintPage);
