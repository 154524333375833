const ReviewHeader = () => {
    return (
        <div className="row">
            <div className="col-12" style={{ fontSize: "2em"}}>
                Review - Benefits
            </div>
        </div>
    );
};

export default ReviewHeader;