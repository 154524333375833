// RenderField contains logic to render a single label and text input
import React from 'react';

//{input} is the same as props.input
export default ({ input, className, placeholder, label, type, meta: { touched, error, warning } }) => {
    
    return (
        <div>
            <label style={{ color: "rgba(255,255,255,0.8)", marginBottom: "5px" }}>{label}</label>
            <div>
                <input {...input} className={className} placeholder={placeholder} type={type} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
            </div>
        </div>
    );
};